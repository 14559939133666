import Header from "../../../Components/DashboardLayout/Header/Header";
import { useForm } from "react-hook-form";
import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import combinedServices from "../../../shared/services/user-service";
import getRemoveData from "../../../shared/localStorage/removeData";
import * as Yup from "yup";
import swal from "sweetalert";
import { Spinner } from "reactstrap";
const Setting = () => {
  const [old_password, setOldPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoder, setIsLoder] = useState(false);

  const formSchema = Yup.object().shape({
    old_password: Yup.string().required("Password is required"),

    new_password: Yup.string()
      .required("Password is required")
      .min(8, "Password length should be at least 8 characters")
      .max(12, "Password length should be only 12 characters")
      .matches(
        "^(?=.*[A-Za-z])(?=.*d)(?=.*[@$!%*#?&])[A-Za-zd@$!%*#?&]{8,}$",
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    confirm_password: Yup.string()
      .required("Confirm Password is required")
      .oneOf(
        [Yup.ref("new_password")],
        "New password and confirm password must match"
      ),
  });
  const validationOpt = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm(validationOpt);

  const onSubmit = async (data) => {
  
    //e.preventDefault();
    var data = {
      new_password: data.new_password,
      old_password: data.old_password,
    };

    const response = await combinedServices.changePassword(data);

    if(response.status==401)
    {
        swal(response.msg, "Please Re-login", "warning").then(() => {
          getRemoveData("user_info");
          localStorage.clear();
          window.location.href = "/login";
        });
      }

    if (response.status == 200) {
      setIsLoder(false);
      getRemoveData("user_info");
      swal("Password Change Successfully.").then(async (value) => {
        window.location.href = "/";
      });
    }
  };

  return (
    <>
      <Header />
      <div className="dbRight">
        <div className="dbMid dbInner">
          <div className="">
            <div className="searchBox flexBox spacebetween itemCenter nowrap">
              <input type="text" className="searchInput" placeholder="Search" />
              <button className="searchBtn">
                <span className="material-icons">search</span>
              </button>
            </div>
            <ul className="flexBox topLink">
              <li>
                <a href="#">
                  <span className="material-icons">mail_outline</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="material-icons-outlined">notifications</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="">
            <div className="dbMainHd">Settings</div>
            <ul className="flexBox profileDet">
              <li className="full">
                <div className="">
                  <div className="profileHd">Change Password</div>
                  <ul className="settingForm">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <li>
                        <input
                          classNameName="formInput"
                          type="password"
                          placeholder="Old Password"
                          name="old_password"
                          autoComplete="old_password"
                          {...register("old_password")}
                        />
                        <span classNameName="errorMsg">
                          {errors.old_password?.message}
                        </span>
                      </li>
                      <li>
                        <input
                          type="password"
                          placeholder="New Password"
                          name="new_password"
                          autoComplete="new_password"
                          {...register("new_password")}
                          classNameName="formInput"
                        />
                        <span classNameName="errorMsg">
                          {errors.new_password?.message}
                        </span>
                      </li>
                      <li>
                        <input
                          type="password"
                          placeholder="Confirm Password"
                          classNameName="formInput"
                          name="confirm_password"
                          autoComplete="confirm_password"
                          {...register("confirm_password")}
                        />
                        <span classNameName="errorMsg">
                          {errors.confirm_password?.message}
                        </span>
                      </li>
                      {isLoder ? (
                        <li classNameName="text-center mt-4 loadMore">
                          <Spinner color="primary" />
                        </li>
                      ) : (
                        ""
                      )}
                      <li>
                        <button type="submit" className="formBtn">
                          Change Password
                        </button>
                      </li>
                    </form>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default Setting;
