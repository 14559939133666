import React from 'react'
import styled from 'styled-components'
import { Draggable } from 'react-beautiful-dnd'
import Chips from "./Chips" //new line add
import combinedServices from "../../../shared/services/user-service"; //new line add

const Container = styled.div`
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 8px;
  transition: all 0.2s ease;
  background-color: ${(props) => (props.isDragging ? 'lightgreen' : props.isUnMade ? "#408fe1" : 'white')};
  color: ${(props) => props.isDragging && props.isUnMade ? 'black' : props.isUnMade ? "white" : "black"};
`

export default function Task(props) {

  const [eventParticipantByClass, setEventParticipantByClass] = React.useState([])
  React.useEffect(async () => {
    await combinedServices
      .getEventParticipantByClass({ event_id: parseInt(props.eventdata.id) })
      .then((res) => {
        if (res.status == 200) {
          setEventParticipantByClass(res.data)
        }
      })
      .catch((err) => { });
  }, [])
  var participantsData = []
  for (let participantList of eventParticipantByClass) {
    participantsData.push(participantList)
  }

  const participantsList = participantsData.flatMap(obj => obj.participants);

  const participantsListByClassName = participantsList.filter((data) => data.class_Class_Name === props.task.content && data.eventParticipantClass_participation_status == "ENTERED")

  return (
    <Draggable draggableId={props.task.id} index={props.index}
    isDragDisabled={participantsListByClassName.length === 0} //new line add
    >
      {(provided, snapshot) => (
        <Container
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
        isDragging={snapshot.isDragging}
        isUnMade={props.task.status === "NOT_MADE" ? true : false} 

      >
        <Chips
          // key={index}
          val={props.task.content}

          event_id={props.eventdata.id}
          minClass={props.task.content}
          isUnMade={props.task.status === "NOT_MADE" ? true : false}
          isColumnUnScheduled={props.column.runDate === null ? true : false}
        />

      </Container>
      )}
    </Draggable>
  )
}
