import React, { useState,useEffect } from 'react';
import {NavLink} from "react-router-dom";
import getLocalData from "../../../shared/localStorage/getData";
import getRemoveData from "../../../shared/localStorage/removeData";

const  Header = () =>{

    const [isLogin, setIsLogin] = useState(false)
    const db_logo = "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/db_logo.png"
    const user_img = "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/user_img.jpg"
    useEffect(() => {
      setIsLogin(getLocalData("user_info", "isLogin"));
    }, [])
  
      const handleLogout = () => {
         
          getRemoveData("user_info");
          window.location.href = "/";
      }

      const [isActive, setActive] = useState(false);

    const toggleClass = () => {
        setActive(!isActive);
    };

    

    return (
        
       <>
        <a className={isActive ? 'showDBMenu active' : 'showDBMenu'} 
      onClick={toggleClass} ><span><i></i><i></i><i></i></span></a>
        <div className={isActive ? 'dbLeft active' : 'dbLeft'}>
            <div className="dbLogo"><NavLink to="/"><img src={db_logo} alt=""/></NavLink></div>
            <div className="dbMenu">
                <ul>
                    <li key="home">
                    <NavLink to="/user-dashboard">
                        
                            <span className="icon"><span className="material-icons">home</span></span>
                            Home</NavLink></li>
                    <li key="my-race"><NavLink to="/my-race"><span className="icon"><span className="material-icons">directions_boat</span></span> My
                            Races</NavLink></li>
                    <li key="my-result"><NavLink to="/my-result"><span className="icon"><span className="material-icons">leaderboard</span></span> My
                            Results</NavLink></li>
                    <li key="setting"><NavLink to="/setting"><span className="icon"><span className="material-icons">settings</span></span> Settings</NavLink>
                    </li>
                    <li key="logout"><NavLink to="#" onClick={handleLogout}><span className="icon"><span className="material-icons">Logout</span></span> Logout</NavLink>
                    </li>
                </ul>
            </div>
            <div className="userImg"><NavLink to="/profile"><img src={user_img} alt=""/></NavLink></div>
        </div>

        
        </>

      

    )


}
export default Header ;