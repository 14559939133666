import axios from "axios";
import removeLocalData from "../localStorage/removeData";
import React, { useState, useEffect, useCallback } from "react";
import authHeader from "../auth-header/auth-header";
import { data } from "jquery";
let header = "";
let adminHeader = "";

if (authHeader("USER").Authorization != "") {
  header = authHeader("USER");
}

if (authHeader("ADMIN").Authorization != "") {
  adminHeader = authHeader("ADMIN");
}

// get base url according to environment
// const getBaseURLAccordingToEnvironment = (env) => {
//   // switch (env) {
//   //   case "prod":
//   //     return "https://api.race.namba.com/";
//   //   case "dev":
//   //     return "http://100.100.7.154:3000/";

//   //   case "local":
//   //     //return "http://100.100.7.34:3000/";
//          return "http://100.100.7.154:3002/";
//   //   return "http://100.100.7.56:3002/";
//   //   default:
//       // return "http://localhost:4026/auth/";
//   // }
//  // return "https://api.race.namba.com/";
// };

const getBaseURLAccordingToEnvironment = (env) => {
  switch (env) {
    case "prod":
      return "https://api.race.namba.com/";

    case "dev":
      return "https://api.concentricsinc.flexsin.org/";

    case "local":
      //return "http://100.100.7.34:3000/";
      return "http://localhost:3002/";

      return "http://localhost:3002/";
    default:
      return "http://localhost:4026/auth/";
  }
};
// const baseURL = getBaseURLAccordingToEnvironment("local");
// const baseURL = getBaseURLAccordingToEnvironment("prod");
const baseURL = getBaseURLAccordingToEnvironment("dev");

//All club list
async function getAllClub(data) {
  var response = "";
  if (data.district_id) {
    response = await axios.get(
      baseURL +
        "club?page=" +
        data.page +
        "&records_per_page=" +
        data.records_per_page +
        "&district_id=" +
        data.district_id,
      {}
    );
  } else {
    response = await axios.get(
      baseURL +
        "club?page=" +
        data.page +
        "&records_per_page=" +
        data.records_per_page,
      {}
    );
  }

  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getAllClubForDistric(district_id) {
  var response = await axios.get(
    baseURL + "club?district_id=" + district_id,
    {}
  );

  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

async function getClubDetails(id) {
  let response = await axios.get(baseURL + "club/for-public/" + id, {}, {});
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}
async function getUserDetails(id) {
  const userId = id.user_id;
  let response = await axios.get(baseURL + "user/get-userdetails/" + userId, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}
async function getAllDistric() {
  let response = await axios.get(baseURL + "district", {}, {});
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}
async function getAllEvent(data) {
  var response = "";
  if (data.district_id && !data.host_club_id && !data.user_id) {
    console.log("get1");
    response = await axios.get(
      baseURL +
        "event?district_id=" +
        data.district_id +
        "&page=" +
        data.page +
        "&records_per_page=" +
        data.records_per_page,
      {},
      {}
    );
  } else if (data.district_id && data.host_club_id && data.user_id) {
    console.log("get2");

    response = await axios.get(
      baseURL +
        "event?host_club_id=" +
        data.host_club_id +
        "&district_id=" +
        data.district_id +
        "&user_id=" +
        data.user_id +
        "&page=" +
        data.page +
        "&records_per_page=" +
        data.records_per_page,
      {},
      {}
    );
  } else if (!data.district_id && !data.host_club_id && !data.user_id) {
    console.log("get3");

    response = await axios.get(
      baseURL +
        "event?page=" +
        data.page +
        "&records_per_page=" +
        data.records_per_page,
      {},
      {}
    );
  } else if (data.district_id && data.user_id) {
    console.log("get4");

    response = await axios.get(
      baseURL +
        "event?district_id=" +
        data.district_id +
        "&user_id=" +
        data.user_id +
        "&page=" +
        data.page +
        "&records_per_page=" +
        data.records_per_page +
        "&" +
        data.event_status,
      {},
      {}
    );
  } else if (!data.district_id && data.user_id) {
    console.log("get5");

    response = await axios.get(
      baseURL +
        "event?" +
        "&user_id=" +
        data.user_id +
        "&page=" +
        data.page +
        "&records_per_page=" +
        data.records_per_page +
        "&" +
        data.event_status,
      {},
      {}
    );
  }

  //sresponse = await axios.get(baseURL + "event?page="+data.page+"&records_per_page="+data.records_per_page + str,{}, {});
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

async function getAllEventForHomePage(data) {
  var response = "";
  if (data.district_id) {
    response = await axios.get(
      baseURL +
        "event?district_id=" +
        data.district_id +
        "&page=" +
        data.page +
        "&records_per_page=" +
        data.records_per_page +
        "&event_status=" +
        data.event_status +
        "&sort_by=" +
        data.sort_by +
        "&order=" +
        data.order,
      {},
      {}
    );
  } else {
    response = await axios.get(
      baseURL +
        "event?page=" +
        data.page +
        "&records_per_page=" +
        data.records_per_page +
        "&event_status=" +
        data.event_status +
        "&sort_by=" +
        data.sort_by +
        "&order=" +
        data.order,
      {},
      {}
    );
  }

  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

// to get completed events

async function getAllCompleted(data) {
  var response = "";
  if (data.district_id) {
    response = await axios.get(
      baseURL +
        "event/completed/" +
        "?district_id=" +
        data.district_id +
        "&page=" +
        data.page +
        "&records_per_page=" +
        data.records_per_page +
        "&event_status=" +
        data.event_status +
        "&sort_by=" +
        data.sort_by +
        "&order=" +
        data.order,
      {},
      {}
    );
  } else {
    response = await axios.get(
      baseURL +
        "event?page=" +
        data.page +
        "&records_per_page=" +
        data.records_per_page +
        "&event_status=" +
        data.event_status +
        "&sort_by=" +
        data.sort_by +
        "&order=" +
        data.order,
      {},
      {}
    );
  }

  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

async function getEventDetail(id) {
  let response = await axios.get(baseURL + "event/" + id, {}, {});
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

async function deleteEventDetail(id) {
  let response = await axios.delete(baseURL + "event/" + id, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

async function login(data) {
  let response = await axios.post(baseURL + "auth/login", data, {});

  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

async function loginWithUserId(userId, parentToken) {
  var parentTokenHeader = {
    Authorization: parentToken,
  };
  let response = await axios.get(
    baseURL + "auth/login-with-user-id?user_id=" + userId,
    { headers: parentTokenHeader }
  );

  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

async function changePassword(data) {
  let response = await axios.post(baseURL + "auth/change-password", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

async function getProfileDetails(data) {
  let response = await axios.get(
    baseURL +
      "user/profile?user_id=" +
      data.user_id +
      "&clubs=" +
      data.clubs +
      "&events=" +
      data.events +
      "&district=" +
      data.district
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

async function getMyRaceDetails(data) {
  let response = "";
  if (data.user_id) {
    response = await axios.get(
      baseURL +
        "event/my-events?event_status=" +
        data.participation_status +
        "&user_id=" +
        data.user_id,
      { headers: header }
    );
  } else {
    response = await axios.get(
      baseURL + "event/my-events?event_status=" + data.participation_status,
      { headers: header }
    );
  }

  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getMyResultDetails(id) {
  let response = await axios.get(baseURL + "event/my-results?user_id=" + id, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getLeaderBoardDetails(data) {
  var response = "";
  if (data.user_id) {
    response = await axios.get(
      baseURL +
        "leader-board?event_id=" +
        data.event_id +
        "&user_id=" +
        data.user_id,
      { headers: header }
    );
  } else {
    response = await axios.get(
      baseURL + "leader-board?event_id=" + data.event_id,
      { headers: header }
    );
  }

  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getEventNamesWithClass(data) {
  var response = "";
  if (data.district_id) {
    response = await axios.get(
      baseURL +
        "event/names-with-classes?district_id=" +
        data.district_id +
        "&page=" +
        data.page +
        "&records_per_page=" +
        data.records_per_page,
      { headers: header }
    );
  } else {
    response = await axios.get(
      baseURL +
        "event/names-with-classes?page=" +
        data.page +
        "&records_per_page=" +
        data.records_per_page,
      { headers: header }
    );
  }

  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getMyClubSite(id) {
  let response = await axios.get(baseURL + "club-site?district_id=" + id, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getAllClasses(district_id) {
  let response = await axios.get(baseURL + "class?district_id=" + district_id, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getAllSubMemberList(parentToken) {
  var parentTokenHeader = {
    Authorization: parentToken,
  };

  let response = await axios.get(baseURL + "user/sub-members-list", {
    headers: parentTokenHeader,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function uploadImageFromAdmin(data) {
  const headersApplicationJson = {
    "Content-Type": "multipart/form-data",
    Authorization: adminHeader.Authorization,
  };

  let response = await axios.post(baseURL + "upload", data, {
    headers: headersApplicationJson,
  });
  return response;
}

async function uploadImage(data) {
  const headersApplicationJson = {
    "Content-Type": "multipart/form-data",
    Authorization: header.Authorization,
  };

  let response = await axios.post(baseURL + "upload", data, {
    headers: headersApplicationJson,
  });
  return response;
}
async function uploadMultipleImage(data) {
  const headersApplicationJson = {
    "Content-Type": "multipart/form-data",
    Authorization: header.Authorization,
  };

  let response = await axios.post(baseURL + "upload/multiple", data, {
    headers: headersApplicationJson,
  });
  return response;
}

async function createEvent(data) {
  let response = await axios.post(baseURL + "event", data, { headers: header });
  return response;
}

async function getEventParticipate(data) {
  let response = await axios.get(
    baseURL +
      "event-participant?event_id=" +
      data.event_id +
      "&classes=" +
      data.classes,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getEventParticipateAdmin(data) {
  let response = await axios.get(
    baseURL + "event-participant?event_id=" + data.event_id,
    { headers: adminHeader }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getEventParticipateByClasses(data) {
  let response = await axios.get(
    baseURL + "event-participant/grouped-by-classes?event_id=" + data.event_id,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getAllHighlite(data) {
  let response = "";
  if (data.limit && data.eventId) {
    response = await axios.get(
      baseURL +
        "event-highlight?event_id=" +
        data.eventId +
        "&limit=" +
        data.limit,
      { headers: header }
    );
  } else {
    response = await axios.get(
      baseURL + "event-highlight?limit=" + data.limit,
      { headers: header }
    );
  }

  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function deleteHighLights(id) {
  let response = await axios.delete(baseURL + "event-highlight/" + id, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getClassByEvent(event_id) {
  let response = await axios.get(baseURL + "event-class?event_id=" + event_id, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function addEventParticipate(data) {
  let response = await axios.post(baseURL + "event-participant", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}
async function addEventParticipateMany(data) {
  let response = await axios.post(baseURL + "event-participant/many", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}
async function emailParticipants(data) {
  let response = await axios.post(baseURL + "email-participants/pdf", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function cancleEventParticipateClassMany(data) {
  let response = await axios.patch(
    baseURL + "event-participant-class/cancel/many",
    data,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}
async function cancleEventParticipateClassManyForAdmin(data) {
  let response = await axios.patch(
    baseURL + "event-participant-class/cancel/many",
    data,
    { headers: adminHeader }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function addEventParticipateClassMany(data) {
  let response = await axios.post(
    baseURL + "event-participant-class/many",
    data,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function addEventParticipateClassManyForAdmin(data) {
  let response = await axios.post(
    baseURL + "event-participant-class/many",
    data,
    { headers: adminHeader }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function updatePaymentType(data) {
  let response = await axios.patch(
    baseURL + "event-participant/update-payment-type",
    data,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function addEvnetHighlights(data) {
  let response = await axios.post(baseURL + "event-highlight", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function EvnetHighlightsById(id) {
  let response = await axios.get(baseURL + "event-highlight/" + id, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getEventParticipateClass(event_participate_id) {
  let response = await axios.get(
    baseURL +
      "event-participant-class?event_participant_ids=" +
      event_participate_id,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getAvailableFrequency(event_participate_id) {
  let response = await axios.get(
    baseURL +
      "frequency/available-frequencies?event_participant_id=" +
      event_participate_id +
      "&available_frequencies=true",
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function addEventParticipateStep3(data) {
  let response = await axios.put(
    baseURL + "event-participant-class/update-frequencies",
    data,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function addPaypalForm(participantIdsArr) {
  var data = {
    event_participant_ids: participantIdsArr,
  };

  let response = await axios.post(
    baseURL + "event-participant/paypal-link",
    data,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function sendEmailForParticipant(participantIdsArr) {
  var data = {
    event_participant_ids: participantIdsArr,
  };

  let response = await axios.get(
    baseURL + "event-participant/send-email/" + participantIdsArr,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function addPaypalFormForAdmin(participantIdsArr) {
  var data = {
    event_participant_ids: participantIdsArr,
  };

  let response = await axios.post(
    baseURL + "event-participant/paypal-link",
    data,
    { headers: adminHeader }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function addPayment(data) {
  let response = await axios.post(baseURL + "payment", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}
async function getPaymentAdmin(data) {
  let response = "";
  if (data.user_id) {
    response = await axios.get(
      baseURL + "payment/paymentAll?user_id=" + data.user_id,
      { headers: adminHeader }
    );
  } else {
    response = await axios.get(baseURL + "payment/paymentAll", {
      headers: adminHeader,
    });
  }

  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getPaymentUser(data) {
  let response = "";
  if (data.user_id) {
    response = await axios.get(
      baseURL + "payment/paymentAll?user_id=" + data.user_id,
      { headers: header }
    );
  } else {
    response = await axios.get(baseURL + "payment/paymentAll", {
      headers: header,
    });
  }

  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function addPaymentMany(data) {
  let response = await axios.post(baseURL + "payment/many", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function addExbitionClasses(data) {
  let response = await axios.post(baseURL + "class", data, { headers: header });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function paymentSuccess(data) {
  let response = await axios.patch(baseURL + "payment/success", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}
async function deleteEventTemplate(id, selectclub) {
  let response = await axios.delete(
    baseURL +
      "event-template?template_name=" +
      selectclub +
      "&host_club_id=" +
      id,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}
async function getEventTemplateDetails(id, selectclub) {
  let response = await axios.get(
    baseURL +
      "event-template?template_name=" +
      selectclub +
      "&host_club_id=" +
      id,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}
async function getAllEventTemplateDetails(id) {
  let response = await axios.get(
    baseURL + "event-template/byhost?host_club_id=" + id,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function addTemplateDetails(data) {
  let response = await axios.post(baseURL + "event-template", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getPreference(data) {
  let response = await axios.get(baseURL + "preference/", { headers: header });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function editEvent(id, data) {
  let response = await axios.put(baseURL + "event/" + id, data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getClassByEventId(id) {
  let response = await axios.get(baseURL + "event/with-classes/" + id, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getScoreByEventID(data) {
  let response = await axios.get(
    baseURL + "score/getScore?event_id=" + data.event_id,
    // "class_Id=" +
    // data.class_id,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function checkIfUserJoined(data) {
  let response = await axios.get(
    baseURL +
      "event-participant/check-if-joined?event_id=" +
      data.event_id +
      "&user_id=" +
      data.user_id,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}
async function checkIfUserJoinedAdmin(data) {
  let response = await axios.get(
    baseURL +
      "event-participant/check-if-joined?event_id=" +
      data.event_id +
      "&user_id=" +
      data.user_id,
    { headers: adminHeader }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getAllUserPaymentStatus(event_id) {
  let response = await axios.get(
    baseURL + "event-participant?event_id=" + event_id,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function updatePaymentUserStatus(data) {
  let response = await axios.patch(
    baseURL + "event-participant/update-payment-status",
    data,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getClubMembers(id) {
  let response = await axios.get(baseURL + "club-member?club_id=" + id, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getClubMember() {
  let response = await axios.get(baseURL + "club-member", {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

//get heat according to heat number and event id
async function getHeatByHeatNumber(event_id, heatNumber) {
  let response = await axios.get(
    baseURL +
      "heat-sheet?event_id=" +
      event_id +
      "&event_heat_number=" +
      heatNumber,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getHeatSheet(data) {
  let response = await axios.get(
    baseURL + "heat-sheet?event_id=" + data.event_id + "&round=" + data.round,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}
async function getAllHeatSheet(data) {
  let response = await axios.get(
    baseURL + "heat-sheet?event_id=" + data.event_id,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getHeatNumbers(data) {
  let response = await axios.get(
    baseURL +
      "heat-sheet?event_id=" +
      data.event_id +
      "&class_id=" +
      data.class_id +
      "&round=" +
      data.round +
      "&heats_count_only=" +
      data.heats_cound_only,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function createHeat(data) {
  let response = await axios.post(baseURL + "heat-sheet/create-all", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getPaypalLink(id) {
  let response = await axios.get(baseURL + "event/paypal-link/" + id, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getLeaderBoardForAddScore(data) {
  let response = "";
  if (data.event_id && data.class_id && data.round && data.class_heat_number) {
    response = await axios.get(
      baseURL +
        "leader-board?event_id=" +
        data.event_id +
        "&class_id=" +
        data.class_id +
        "&round=" +
        data.round +
        "&class_heat_number=" +
        data.class_heat_number,
      { headers: header }
    );
  } else {
    response = await axios.get(
      baseURL +
        "leader-board?event_id=" +
        data.event_id +
        "&class_id=" +
        data.class_id,
      { headers: header }
    );
  }

  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function addLeaderBoard(data) {
  let response = await axios.post(baseURL + "leader-board/many", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function updateLeaderBoard(data) {
  let response = await axios.put(baseURL + "leader-board/many", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function updateEventStatus(data, id) {
  let response = await axios.patch(
    baseURL + "event/update-status/" + id,
    data,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}
async function closeOpenRegistration(data, id) {
  let response = await axios.patch(
    baseURL + "event/close-registration/" + id,
    data,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

//to open and close the race
async function openCloseRace(data, id) {
  let response = await axios.patch(
    baseURL + "event/race-open-close/" + id,
    data,
    { headers: header }
  );
  try {
    let responseData = response.data;
    return responseData;
  } catch (err) {
    return err;
  }
}

async function updateEventPublishStatus(data, id) {
  let response = await axios.patch(
    baseURL + "event/update-published-status/" + id,
    data,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function updateToken() {
  let response = await axios.patch(
    baseURL + "user/update-token",
    {},
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function loginWithToken(token) {
  let response = await axios.get(
    baseURL + "auth/login-with-token?token=" + token,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function tokenMemberCount() {
  let response = await axios.get(baseURL + "user/club-members-count", {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getPaypalCredetial(clubId) {
  let response = await axios.get(baseURL + "club-paypal?club_id=" + clubId, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function updatePaypalCredential(data) {
  let response = await axios.patch(baseURL + "club-paypal", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}
async function addPaypalCredential(data) {
  let response = await axios.post(baseURL + "club-paypal", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function countByClasses(eventId) {
  let response = await axios.get(
    baseURL + "event-participant/count-by-classes?event_id=" + eventId,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function assignDateForEvent(data) {
  let response = await axios.patch(baseURL + "event-class/assign-dates", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function alreadyAssignedDate(event_id) {
  let response = await axios.get(
    baseURL + "event-class/already-assigned-dates?event_id=" + event_id,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function mergeHeatSheet(data) {
  let response = await axios.put(baseURL + "heat-sheet/merge-heats", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}
async function splitHeatSheet(data) {
  let response = await axios.post(baseURL + "heat-sheet/split-heat", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function cancelNonQualifyingClass(data) {
  let response = await axios.post(
    baseURL + "event-class/cancel-non-qualifying-classes",
    data,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}
async function cancelNonQualifyingClassOnClick(data) {
  let response = await axios.post(
    baseURL + "event-class/cancel-non-qualifying-classes-on-click",
    data,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function moveHeatSheet(data) {
  let response = await axios.patch(baseURL + "heat-sheet/move-member", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function updateUserStatus(data) {
  let response = await axios.patch(
    baseURL + "event-participant-class/update-status",
    data,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function smsTemplateAll() {
  let response = await axios.get(baseURL + "sms-template/all", {
    headers: adminHeader,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getSmsTemplate(keyword) {
  let response = await axios.get(baseURL + "sms-template?keyword=" + keyword, {
    headers: adminHeader,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function smsTemplate(data) {
  let response = await axios.post(baseURL + "sms-template", data, {
    headers: adminHeader,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getMyRaceDetailsForAdmin(data) {
  let response = "";

  response = await axios.get(
    baseURL +
      "event/my-events?event_status=" +
      data.participation_status +
      "&user_id=" +
      data.user_id,
    { headers: adminHeader }
  );

  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function updateSmsTemplate(data, id) {
  let response = await axios.put(baseURL + "sms-template/" + id, data, {
    headers: adminHeader,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function deleteSmsTemplate(id) {
  let response = await axios.delete(baseURL + "sms-template/" + id, {
    headers: adminHeader,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getAllClubPaypal(club_name) {
  let response = "";
  if (club_name) {
    response = await axios.get(
      baseURL + "club-paypal/all?club_name=" + club_name,
      { headers: adminHeader }
    );
  } else {
    response = await axios.get(baseURL + "club-paypal/all", {
      headers: adminHeader,
    });
  }

  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getClubPaypalById(club_id) {
  let response = await axios.get(baseURL + "club-paypal?club_id=" + club_id, {
    headers: adminHeader,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}
async function getClubPaypalByIdForUser(club_id) {
  let response = await axios.get(baseURL + "club-paypal?club_id=" + club_id, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function addClubPaypal(data) {
  let response = await axios.post(baseURL + "club-paypal", data, {
    headers: adminHeader,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function updateClubPaypal(data) {
  let response = await axios.patch(baseURL + "club-paypal", data, {
    headers: adminHeader,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function deleteClubPaypal(id) {
  let response = await axios.delete(baseURL + "club-paypal/" + id, {
    headers: adminHeader,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function updateEventCost(data) {
  let response = await axios.patch(baseURL + "preference/", data, {
    headers: adminHeader,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getEventCost(data) {
  let response = await axios.get(baseURL + "preference/", {
    headers: adminHeader,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function eventParticipantClassPayment(data) {
  let response = await axios.post(
    baseURL + "event-participant-class/paypal-link",
    data,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function eventParticipantClassPaymentForAdmin(data) {
  let response = await axios.post(
    baseURL + "event-participant-class/paypal-link",
    data,
    { headers: adminHeader }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getAllUser() {
  let response = await axios.get(baseURL + "user/get-all-user");
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getAllRefundForAdmin(data) {
  let response = "";
  if (data.user_id) {
    response = await axios.get(
      baseURL + "refund/get-all-refund?user_id=" + data.user_id,
      { headers: header }
    );
  } else {
    response = await axios.get(baseURL + "refund/get-all-refund", {
      headers: header,
    });
  }

  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function emailTemplateAll() {
  let response = await axios.get(baseURL + "email-template/all", {
    headers: adminHeader,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getEmailTemplate(keyword) {
  let response = await axios.get(
    baseURL + "email-template?keyword=" + keyword,
    { headers: adminHeader }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function createEmailTemplate(data) {
  let response = await axios.post(baseURL + "email-template", data, {
    headers: adminHeader,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function updateEmailTemplate(data, id) {
  let response = await axios.put(baseURL + "email-template/" + id, data, {
    headers: adminHeader,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function deleteEmailTemplate(id) {
  let response = await axios.delete(baseURL + "email-template/" + id, {
    headers: adminHeader,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function homeContentDataAll() {
  let response = await axios.get(baseURL + "home-content/all", {
    headers: adminHeader,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}
async function bannerImageDataAll() {
  let response = await axios.get(baseURL + "banner/all", {
    headers: adminHeader,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function homeContentDataOne(id) {
  let response = await axios.get(
    baseURL + "home-content/one-home-content/" + id,
    { headers: adminHeader }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function createHomeContent(data) {
  let response = await axios.post(baseURL + "home-content/create", data, {
    headers: adminHeader,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function createBannerImage(data) {
  let response = await axios.post(baseURL + "banner/create", data, {
    headers: adminHeader,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function updateScore(data) {
  let response = await axios.post(baseURL + "score/createRaceScore", data, {
    headers: header,
  });

  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

//function to get the score of a particular heat and its participants
async function getHeatScore(data){
  let response = await axios.get(baseURL + "score/getLastHeatScore"+`?eventId=${data.eventId}&classId=${data.classId}&heatNumber=${data.heatNumber}`,{
    headers: header,
  })
  try{
    let responseData = response.data
    return responseData
  }catch(err){
    return err
  }

}

//to enter and update the fast time
async function enterFastTime(data) {
  let response = await axios.patch(baseURL + "score/fastTime", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

//to get all the audios
async function getAudios() {
  let response = await axios.get(baseURL + "race-audio/get-all", {
    headers: adminHeader,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

//to delete the audio
async function deleteAudio(data) {
  let response = await axios.delete(baseURL + "race-audio/delete/" + data.id, {
    headers: adminHeader,
  });

  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

//to add the audio
async function addAudio(data) {
  let response = await axios.post(baseURL + "race-audio/add-audio", data, {
    headers: adminHeader,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

//to get the active audios
async function getActiveAudios() {
  let response = await axios.get(
    baseURL + "race-audio/get-active-audios?status=active",
    {
      headers: header,
    }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

async function updateAudioStatus(data) {
  let response = await axios.patch(baseURL + "race-audio/update-status", data, {
    headers: adminHeader,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    console.log("err");
    return err;
  }
}

async function handleDeleteHomeContent(id) {
  let response = await axios.delete(baseURL + "home-content/" + id, {
    headers: adminHeader,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

// async function handleDeleteBannerImage(id) {

//   let response = await axios.delete(baseURL + "banner-image/" + id, { headers: adminHeader });
//   try {
//     let response_data = response.data;
//     return response_data;
//   } catch (err) {

//     return err;
//   }
// }

async function updateHomeContent(id, data) {
  let response = await axios.post(baseURL + "home-content/update/" + id, data, {
    headers: adminHeader,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function homeContentDataAllHomePage() {
  let response = await axios.get(baseURL + "home-content/all", {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getAllEventForAdmin(data) {
  let response = await axios.get(
    baseURL +
      "event?page=" +
      data.page +
      "&records_per_page=" +
      data.records_per_page,
    { headers: adminHeader }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getAllUserAdmin() {
  let response = await axios.get(baseURL + "user/get-all-user", {
    headers: adminHeader,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getAllUserForManageEntries() {
  let response = await axios.get(baseURL + "user/get-all-user", {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function deleteParticipant(data) {
  let response = await axios.delete(
    baseURL + "event-participant?id=" + data.id + "&user_id=" + data.user_id,
    { headers: adminHeader }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function removeHeats(data) {
  let response = await axios.delete(
    baseURL +
      "heat-sheet/remove-heat?event_id=" +
      data.event_id +
      "&round=" +
      data.round +
      "&scheduleClass=" +
      data.scheduleClass,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function generateHeat(data) {
  let response = await axios.post(baseURL + "heat-sheet/generate-heat", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}
async function updateScoreStatus(data) {
  let response = await axios.put(baseURL + "heat-sheet/update-status", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function finishStatus(data) {
  let response = await axios.put(baseURL + "heat-sheet/finish", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}
async function heatScore(data) {
  let response = await axios.put(baseURL + "heat-sheet/heat-score", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function sendChat(data) {
  let response = await axios.post(baseURL + "chat/send-chat", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getChat() {
  let response = await axios.get(baseURL + "chat/get-chat", {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getEventParticipantForHeat(data) {
  let response = await axios.get(
    baseURL + "event-participant?event_id=" + data.event_id,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function allowClassWithMinimumEntry(data) {
  let response = await axios.post(
    baseURL + "event-class/allow-classes-with-minimum-entry",
    data,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}
async function cancelClassWithZeroEntry(data) {
  let response = await axios.post(
    baseURL + "event-class/cancel-classes-with-minimum-entry",
    data,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getEventParticipantByClass(data) {
  let response = await axios.get(
    baseURL + "event-participant/grouped-by-classes?event_id=" + data.event_id,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getClassWithMinimumEntry(data) {
  let response = await axios.post(
    baseURL + "event-class/get-classes-with-minimum-entry",
    data,
    { headers: header }
  );
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function createRacerScore(data) {
  let response = await axios.post(
    baseURL + "createScore/createRaceScore",
    data,
    { headers: header }
  );
  try {
    let response_data = response.data.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getScore(id) {
  let response = await axios.get(
    baseURL + "score/getScore?event_id=" + id,

    { headers: header }
  );
  try {
    let response_data = response.data.data;
    return response_data;
  } catch (err) {
    return err;
  }
}
async function getFeed(data) {
  if (data.eventId && !data.classIds) {
    console.log("in if", data);
    let response = await axios.get(
      baseURL + "feed/getFeed?event_Id=" + data.eventId,
      {
        header: header,
      }
    );
    try {
      let response_data = response.data.data;
      return response_data;
    } catch (err) {
      return err;
    }
  } else if (data.eventId && data.classIds) {
    let response = await axios.get(
      baseURL +
        "feed/getFeed?event_Id=" +
        data.eventId +
        "&classIds=" +
        data.classIds,
      {
        header: header,
      }
    );
    try {
      let response_data = response.data.data;
      return response_data;
    } catch (err) {
      return err;
    }
  }
}
async function getFeedByHeat(id, heatNumber) {
  let response = await axios.get(
    baseURL +
      "feed/getFeed?event_Id=" +
      id +
      "&event_heat_number=" +
      heatNumber,
    {
      header: header,
    }
  );
  try {
    let response_data = response.data.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function getNumberId(num_ids) {
  let response = await axios.post(
    baseURL + "club-member/num",
    { num_ids },
    { header: header }
  );
  try {
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
}

async function getTransponders(data) {
  const user_id = data;

  try {
    // Assuming you want to pass user_id as a query parameter
    let response = await axios.get(baseURL + "transponder/getTransponder", {
      params: { user_id }, // pass user_id as a query parameter
      headers: header,
    });

    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
}

async function saveTransponders(data) {
  let response = await axios.post(
    baseURL + "transponder/createTransponder",
    data
  );
  try {
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
}
async function deleteTransponders(data) {
  const transponder_id = data.transponder_id;
  let response = await axios.delete(baseURL + "transponder", {
    params: { transponder_id }, // pass user_id as a query parameter
    headers: header,
  });
  try {
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
}
async function getUserInterest(id) {
  let response = await axios.get(baseURL + "user/get-userinterest/" + id, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}
async function UpdateProfile(data) {
  let response = await axios.patch(baseURL + "user/update-profile", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}
async function UpdateInterest(data) {
  let response = await axios.post(baseURL + "user/get-userinterest", data, {
    headers: header,
  });
  try {
    let response_data = response.data;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function clearFacebookCache(data){
  let response = await axios.post(baseURL + "facebook/clear-facebook-cache",data,{
    headers:header
  })
  try{
    let response_data = response.data;
    return response_data
  }catch(err){
    return err;
  }
}

const combinedServices = {
  UpdateInterest,
  getAllCompleted,
  UpdateProfile,
  getUserInterest,
  deleteTransponders,
  getTransponders,
  saveTransponders,
  getUserDetails,
  getFeed,
  getFeedByHeat,
  getAllClub,
  getClubDetails,
  getAllDistric,
  getAllEvent,
  login,
  loginWithUserId,
  getAllSubMemberList,
  changePassword,
  getProfileDetails,
  getEventDetail,
  getMyRaceDetails,
  getMyResultDetails,
  getLeaderBoardDetails,
  getEventNamesWithClass,
  getMyClubSite,
  getAllClasses,
  uploadImage,
  createEvent,
  getEventParticipate,
  getEventParticipateByClasses,
  getAllHighlite,
  getClassByEvent,
  addEventParticipate,
  getEventParticipateClass,
  getAvailableFrequency,
  getAllEventForHomePage,
  addEventParticipateStep3,
  addPaypalForm,
  addExbitionClasses,
  paymentSuccess,
  deleteEventTemplate,
  getEventTemplateDetails,
  getAllEventTemplateDetails,
  addTemplateDetails,
  getPreference,
  editEvent,
  getClassByEventId,
  checkIfUserJoined,
  getClubMembers,
  getClubMember,
  getHeatSheet,
  getPaypalLink,
  addPayment,
  createHeat,
  getLeaderBoardForAddScore,
  addLeaderBoard,
  updateLeaderBoard,
  updateToken,
  loginWithToken,
  tokenMemberCount,
  addPaypalCredential,
  getPaypalCredetial,
  updatePaypalCredential,
  countByClasses,
  assignDateForEvent,
  alreadyAssignedDate,
  mergeHeatSheet,
  moveHeatSheet,
  updateEventStatus,
  smsTemplateAll,
  getSmsTemplate,
  smsTemplate,
  updateSmsTemplate,
  deleteSmsTemplate,
  getAllClubPaypal,
  getClubPaypalById,
  addClubPaypal,
  updateClubPaypal,
  deleteClubPaypal,
  updateEventCost,
  getEventCost,
  getClubPaypalByIdForUser,
  updateEventPublishStatus,
  deleteEventDetail,
  updateUserStatus,
  uploadMultipleImage,
  addEvnetHighlights,
  deleteHighLights,
  getAllUserPaymentStatus,
  updatePaymentUserStatus,
  getHeatNumbers,
  getAllClubForDistric,
  addEventParticipateMany,
  addPaymentMany,
  cancleEventParticipateClassMany,
  cancelNonQualifyingClass,
  addEventParticipateClassMany,
  eventParticipantClassPayment,
  getAllUser,
  getMyRaceDetailsForAdmin,
  checkIfUserJoinedAdmin,
  cancleEventParticipateClassManyForAdmin,
  addEventParticipateClassManyForAdmin,
  addPaypalFormForAdmin,
  eventParticipantClassPaymentForAdmin,
  getPaymentAdmin,
  getPaymentUser,
  getAllRefundForAdmin,
  emailTemplateAll,
  getEmailTemplate,
  createEmailTemplate,
  updateEmailTemplate,
  deleteEmailTemplate,
  sendEmailForParticipant,
  homeContentDataAll,
  bannerImageDataAll,
  uploadImageFromAdmin,
  createHomeContent,
  createBannerImage,
  handleDeleteHomeContent,
  // handleDeleteBannerImage,
  homeContentDataAllHomePage,
  updateHomeContent,
  homeContentDataOne,
  EvnetHighlightsById,
  updatePaymentType,
  getAllEventForAdmin,
  getAllUserAdmin,
  getEventParticipateAdmin,
  deleteParticipant,
  getAllUserForManageEntries,
  removeHeats,
  generateHeat,
  splitHeatSheet,
  getAllHeatSheet,
  updateScoreStatus,
  finishStatus,
  heatScore,
  sendChat,
  closeOpenRegistration,
  openCloseRace,
  getChat,
  getEventParticipantForHeat,
  cancelNonQualifyingClassOnClick,
  cancelClassWithZeroEntry,
  getEventParticipantByClass,
  allowClassWithMinimumEntry,
  getClassWithMinimumEntry,
  createRacerScore,
  getScore,
  getScoreByEventID,
  updateScore,
  emailParticipants,
  getNumberId,
  enterFastTime,
  getAudios,
  deleteAudio,
  addAudio,
  getActiveAudios,
  updateAudioStatus,
  getHeatByHeatNumber,
  getHeatScore,
  clearFacebookCache
};
export default combinedServices;
