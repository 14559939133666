import Header from "../../../Components/DashboardLayout/Header/Header";
import combinedServices from "../../../shared/services/user-service";
import React, { useState, useEffect } from 'react';
import moment from "moment";
import getLocalData from "../../../shared/localStorage/getData";
import SearchPage from "../../../Components/clubDashboard/Header/SearchPage";
import getRemoveData from "../../../shared/localStorage/removeData";
import swal from "sweetalert";

const Profile = () => {
    const [userDetails, setUserDetails] = useState([]);
    const [clubUserDetails, setUserClubDetail] = useState([]);
    const profile_img = "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/profile_img.jpg"
    useEffect(async () => {
        var userId = '';


        if (getLocalData("user_info", "isLogin")) {

            userId = getLocalData("user_info", "all").userData.login_user_detail.data.user.id;

        }

        var data = {
            user_id: userId,
            clubs: true,
            events: true,
            district: true

        }




        let profileResponse = await combinedServices.getProfileDetails(data);
        if(profileResponse.status==401){
            
                swal(profileResponse.msg, "Please Re-login", "warning").then(() => {
                  getRemoveData("user_info");
                  localStorage.clear();
                  window.location.href = "/login";
                });
        }
        if (profileResponse.status==200) {
            let userDetailData = profileResponse.data;
            setUserDetails(userDetailData);
            setUserClubDetail(userDetailData.club_members)


        }


    }, [])
    const handleEditProfile = async() =>{
        var tokenResponse = await combinedServices.updateToken();
       
        if(tokenResponse.status ==200){
            var token = tokenResponse.data.token ;

           
             window.location.href = "http://concentricsinc.flexsin.org/namba/directtokenlogin.php?token="+token ;
    


        }


    }

    return (
        <>
            <Header />
            <div className="dbRight">
                <div className="dbMid dbInner">
                    
                   <SearchPage/>
                    <div className="">
                        <div className="dbMainHd">My Profile</div>
                        <ul className="flexBox profileDet">
                            <li>
                                <div className="">
                                    <div className="flexBox userInfo">
                                        <div className="left"><img src={profile_img} alt="" /></div>
                                        <div className="right">
                                            <a href="#" className="editBtn" onClick={handleEditProfile}>Edit</a>
                                    Name: {userDetails.user_firstname} {userDetails.user_lastname}<br />
                                                Age: {moment(new Date()).format("YYYY") - moment(userDetails.user_dob).format("YYYY")}<br />
Location:{/* {userDetails.district.uzip_primary_city}*/}<br />
                                                       
                                                            Years Racing: {moment(userDetails.user_createdon).format("YYYY")}<br />
                                                                Member Since: {moment(userDetails.user_createdon).format("ddd , MMM D , YYYY")}<br />
                                                                    Phone: +{userDetails.user_areacodemobile}-{userDetails.user_mobile}
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="">
                                    <div className="profileHd">Club Members</div>
                                    <ul className="clubMemebrList">
                                        {clubUserDetails.map((club) => {


                                            return (

                                                <li>
                                                    <span className="left">{club.club.club_name}</span>
                                                    <span className="right">since {moment(club.club.club_foundedon).format('YYYY')}</span>
                                                </li>
                                            )



                                        })}

                                    </ul>
                                </div>
                            </li>
                            {userDetails.event_participants && 
                            <li className="full">
                                <div className="">
                                    <div className="profileHd">Races Attended</div>
                                    <ul className="raceAttended">
                                    {userDetails.event_participants.map((raceDetail)=>{
                                        return(
                                            <li>
                                            <div className="left">{raceDetail.event.full_title}</div>
                                            
                                            </li>
                                        )
                                        })}
                                        
                                       
                                    </ul>
                                </div>
                            </li>
}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Profile;