import Header from "../../Components/Layout/Header/Header";
import Footer from "../../Components/Layout/Footer/Footer";
import "./How_it_works.scss"
const About_us = () => {
    return (
        <>
            <div className="mainCon">
                <Header />

                <div className="innerBanner">
                    <div className="container flexBox">
                        <div>
                            <div className="innerHd">How It Works</div>
                            <div className="bradcrumb"><a href="/">Home</a> How It Works</div>
                        </div>
                    </div>
                </div>
                <div className="pb50 pt50 whtBg">
                    <div className="container">
                        <div className="contentBox">

                            NAMBA (North American Model Boat Association) is committed to promoting and encouraging heats of racing in which each racer is awarded points based on their finish. Races are organized based on engine sizes, fuel types, and by various hull types, like hydroplane, mono, tunnel hull, etc.  NAMBA’s membership is a healthy mix of professional and amateur racers of all levels, who combine to make NAMBA one of the finest racing clubs in this part of the world. <br />


                            NAMBA is a non-profit boat racing club owned and operated by its members. Paid Membership is required for becoming a member of Clubs that represent various districts, and for racing your boat at NAMBA events and NAMBA National Championships. <br /><br />
                            <h5>Club Dashboard</h5>
                            <span> The Club Dashboard proves you access to the below: </span><br /><br />
                            <strong>Member Portal:</strong> The Member Dashboard will have all the information about the NAMBA Membership. One can also apply for New Membership, or New Club Application, Apply for Race Sections, Pay Fees, and Manage My Clubs. You can also upload your Profile Picture. <br /> <br />
                            <strong>Overview: </strong>The user will have an Overview of the Total Clubs, Total Members, and Total Membership, along with information on My Clubs. <br /> <br />
                            <strong>My Races:</strong> The Member will have access to the Upcoming Races and Completed Races. <br /> <br />
                            <strong>My Results:</strong> The Member will have access to all the results of the previous events.<br /><br />
                            <strong>Event Management: </strong>The Members can manage their events. The Event Portal allows for the creation of event easily by providing the following details in the Event Template. <br /> <br />
                            <ul>
                                <li>
                                    1. Event Name
                                </li>
                                <li>
                                    2. Event Start and End Date
                                </li>
                                <li>
                                    3. Event Locations
                                </li>
                                <li>
                                    4. Fees
                                </li>
                                <li>
                                    5. Exhibition Class
                                </li>
                                <li>
                                    6. Boat Per Class
                                </li>

                                <li>
                                    7. No. of Rounds
                                </li>
                                <li>
                                    8. Number of Boats and Racers
                                </li>
                                <li>
                                    9. Shootout Classes
                                </li>
                                <li>
                                    10 .Payment Types (Paypal, Mail -in Entries, Pay at Race)
                                </li>
                                <li>
                                    11. Hotel Reservation
                                </li>




                            </ul> <br /><br />
                            <strong>Paypal Credentials: </strong> Here, the Members can view ad update their Paypal credentials. <br /><br />


                            <strong>Payments: </strong>The Member can view details of the payments related to an event, and also its payment type. <br /><br />

                            <strong>Refunds: </strong>The Members can view their Refund details here.  <br /><br />

                            <strong>Settings: </strong>One can change the settings of their Member Portal, like changing password. <br /><br />


                            If you plan to race your own boat, you should immediately contact the Fleet Captain to introduce yourself, and learn of any requirements or dues for joining a specific fleet with our Clubs.  Our fees are designed to cover the expenses including coaching, regatta entries, gas, boat repair, etc. <br /><br />
                            NAMBA Members are provided with competitive privileges provided they:
                            <ul>
                                <li>1. Apply for membership prior to participation with a racing progam </li>

                                <li>2. They remain confirmed participants in the racing events</li>

                                <li>3. They are current with all payable fees to the Club </li></ul><br />

                            <strong>A variety of boats are available:  </strong>
                            NAMBA offers a variety of boats for use by its Members which include:
                            <ul><li>
                                1. Nitro Powered Boats with high pitch RPM
                            </li>
                                <li>
                                    2. Gas Powered Boats which are much less costly compared to Nitro boats
                                </li>
                                <li>
                                    3. Electric Powered Ready-To-Run boats with battery and motor technology
                                </li>
                            </ul><br/><br />
                            <strong>NAMBA Combat Areas for True-To-Life Battles</strong>

                            The Combat Area of NAMBA offers scale warship enthusiasts of past and present with competitions and gatherings to enjoy their radio control replicas wherein they can True-To-Life battles unfold on the miniature waterways.<br/><br/>

                            Nitro and GAS championships are run together and the Fast Electric (FE) and Combat warships have their own separate events.<br/><br/>

                            <strong>NAMBA National Championships</strong>

                            NAMBA Nationals are a weeklong event held every year in over twenty districts that offer fun-filled daily activities, traditions, great food, and awesome racing experience to the participants, their families, fans, and friends.<br/><br/>

                            <strong>Guest Membership</strong>

                            Under special circumstances, NAMBA may extend Guest Membership to the persons of other racing clubs in good standing, who are stationed in our district club areas for a short duration of time.<br/><br/>

                            Full Members have the voting privileges for NAMBA’s Board of Governors, as well as any Club issues or special elections. They can also run for any position of NAMBA governance during the elections. Each Member of the Club is required to follow the racing rules.<br/><br/>

                            NAMBA is organized into twenty geographic districts spanning the fifty United States, Puerto Rico and the US Virgin Islands. Members elect a President, as well as a number of District Directors who are responsible for keeping the organization running smoothly and growing.<br/><br/>

                            <strong>NAMBA International</strong><br></br>

                            North American Model Boat Association (NAMBA) was formed in 1969-70. In 1974, NAMBA expanded beyond the bounds of North America to include foreign countries, and became NAMBA International.<br/><br/>

                            NAMBA has fostered three generations of racing members, with some beginning as young as 3 to 4 years.  Members ranging from 06 to 70 years old have captured our Championships. NAMBA provides a level playing field of friendly competition wherein children learn the discipline of competition, and adults enjoy a sense of pride and accomplishment for themselves and their families.<br/><br/>

                            For more information about NAMBA, or for a membership application, contact:<br/><br/>

                            NAMBA International, C/O Al Waters, 162 Avenida Chapala, San Marcos, CA. 92069 (760) 522-9138 or by email at info@namba.com.<br/><br/>

                            <strong>Admin Dashboard</strong><br/>

                            The Admin Dashboard provides access to the below features:<br/>

                            <strong>SMS Templates: </strong><br/>It will have information related to Event Creation, Description, Status and Actions taken on the event. The Admin can also create an SMS Template by entering the name of the event, and providing content and keywords.<br></br>

                            <strong>Paypal Credentials: </strong> <br/>The Admin will be able to view Club Names, Client ID, Secret Key, and Details of actions taken. They can also edit/update Paypal payment gateway details, or Delete Paypal credentials.<br/>

                            <strong>Event Cost:</strong> <br/>Event Cost can be added by entering the cost in the Event Cost tab.<br/>

                            <strong>Cancel Class:</strong> <br/>Classes can be added or canceled with this tab.<br/>


                            <strong>All Payments: </strong> <br/>This will have details of all Payment Accounts along with Payment Type (Paypal, Mail-in Entries, Pay at Race), and Payment status. Easy Search functionality makes it easy for the Admin to search details of any payments. <br/> 

                            <strong>Refunds: </strong><br/>The Admin can make Refunds to the users, and view details of the Refunds along with Payment Type, Event Name, and Class. <br/> 












                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>


    )


}
export default About_us;