import React from "react";
import Chip from "@mui/material/Chip";
import { Box } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import combinedServices from "../../../../shared/services/user-service";

export default function Index(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [eventParticipantByClass, setEventParticipantByClass] = React.useState([])
    const { val, key, event_id, minClass, isUnMade, isColumnUnScheduled } = props
    React.useEffect(async () => {
        await combinedServices
            .getEventParticipantByClass({ event_id: parseInt(event_id) })
            .then((res) => {
                if (res.status == 200) {
                    setEventParticipantByClass(res.data)
                }
            })
            .catch((err) => { });
    }, [])
    var participantsData = []
    for (let participantList of eventParticipantByClass) {
        // if (participantList.class_name === minClass) {
            participantsData.push(participantList)
        // }
    }

    var participantsList = participantsData.flatMap(obj => obj.participants);

    function handleHoverIn(event) {
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    }

    function handleHoverOut() {
        setAnchorEl(null);
    }
    return (
        <>


            <Box>
                <span
                    aria-owns={anchorEl ? "simple-menu" : undefined}
                    aria-haspopup="true"
                    onMouseOver={handleHoverIn}
                >

                    {`${val}`}
                </span>
            </Box>


            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleHoverOut}
                MenuListProps={{ onMouseLeave: handleHoverOut }}
            >
                {
                    participantsList
                        .filter((data) => data.class_Class_Name === val && data.eventParticipantClass_participation_status == "ENTERED")
                        .length !== 0 ?
                        participantsList
                            .filter((data) => data.class_Class_Name === val && data.eventParticipantClass_participation_status == "ENTERED").map((data, index) => {
                                return (
                                    <MenuItem key={`${index}Participant list`} onClick={handleHoverOut}>{
                                        `${data.user_user_firstname} ${data.user_user_lastname}`}</MenuItem>
                                )
                            }) :
                        <MenuItem onClick={handleHoverOut}>{
                            "No Participant"
                        }</MenuItem>
                }
            </Menu>

        </>
    )
}
