import React, { useState, Fragment, useEffect } from "react";
// import { nanoid } from "nanoid"; //random id generator library
import ReadOnlyRow from "./ReadOnlyRow";
import EditableRow from "./EditableRow";
import combinedServices from "../../shared/services/user-service";
import swal from 'sweetalert';

const EditableForm = (props) => {
  const [contacts, setContacts] = useState([]);
  const [editfield, setEditfield] = useState([]);
  const [idError, setIdError] = useState("");
  const [idError1, setIdError1] = useState("");

  const [addFormData, setAddFormData] = useState({
    id: "",
    transponder_name: "",
  });

  const [editFormData, setEditFormData] = useState({
    id: "",
    transponder_name: "",
  });

  const [editContactId, setEditContactId] = useState(null);

  useEffect(async () => {
    let response = await combinedServices.getTransponders(props.data);
    setContacts(response.data.data);
  }, []);

  const handleAddFormChange = (event) => {
    setIdError1("");
    setIdError("");
    // event.preventDefault(); // ???
    const fieldName = event.target.getAttribute("name");

    const fieldValue = event.target.value;

    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;

    setAddFormData(newFormData);
  };

  const handleEditFormChange = (event) => {
    setIdError1("");
    setIdError("");
     event.preventDefault(); // ???

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;

    setEditFormData(newFormData);
  };
  const handleAddFormSubmit = async (event) => {
    event.preventDefault();

    // Validate the "id" field
    const isValidId =   /^\d{7}$/.test(addFormData.id);
   
    if (!isValidId) {
      setIdError1("Invalid ID. Please enter exactly 7 digits.");
      return;
    }

    const newContact = {
      id: addFormData.id,
      transponder_name: addFormData.transponder_name,
    };
   if(props.data==0){
    setIdError1("Please Select User First");
    return
   }
    const x = {
      user_id: props.data,
      transponder_id: addFormData.id,
      up_transponder_id: "",
      transponder_name: addFormData.transponder_name,
    };

    try {
      let eventMailsResponse = await combinedServices.saveTransponders(x);

      if (
        eventMailsResponse.data.data === "transponder saved successfully" ||
        eventMailsResponse.data.data === "transponder details updated"
      ) {
        const newContacts = [...contacts, newContact];
        setEditFormData({
          id: "",
          transponder_name: "",
        })
        setContacts(newContacts);
        setIdError1("");
        setIdError("");
      } else {
        setIdError1("transponder already exist");
        // Handle other status codes or errors if needed
        // console.error("Error: Unable to save transponder.");
      }
    } catch (error) {
      // console.error("Error: ", error);
    }
  };

  const handleEditFormSubmit = async (event) => {
    event.preventDefault(); // prevent submit
    const isValidId = /^\d{7}$/.test(editFormData.id);
    if (!isValidId) {
      setIdError("Invalid ID. Please enter exactly 7 digits.");
      return;
    }
    const editedContact = {
      id: editFormData.id,
      transponder_name: editFormData.transponder_name,
    };
    const x = {
      user_id: props.data,
      transponder_id: editfield.id,
      up_transponder_id: editFormData.id,
      
      transponder_name: editFormData.transponder_name,
    };
    let eventMailsResponse = await combinedServices.saveTransponders(x);

    const newContacts = [...contacts];
    const index = contacts.findIndex((contact) => contact.id === editContactId);
    newContacts[index] = editedContact;

    if (
      eventMailsResponse.data.data === "transponder saved successfully" ||
      eventMailsResponse.data.data === "transponder details updated"
    ) {
      setContacts(newContacts);
      setIdError("");
    } else {
      setIdError("Transponder Already Exist");
    }
    setEditContactId(null);
  };

  const handleEditClick = (event, contact) => {
    setIdError1("");
    setIdError("");
    setEditfield(editFormData);

    event.preventDefault(); // ???

    setEditContactId(contact.id);
    const formValues = {
      id: contact.id,
      transponder_name: contact.transponder_name,
    };
    setEditFormData(formValues);
    setEditfield(formValues);
  };
  const handleCancelClick = () => {
    setEditContactId(null);
  };
  const handleDeleteClick = async (contactId) => {
    swal("Are you sure you want to delete this Transponder?", {
      buttons: {
        yes: {
          text: "Yes",
          value: "yes",
        },
        no: {
          text: "No",
          value: "no",
        },
      },
    }).then(async (value) => {
      if (value === "yes") {
        try {
          let transponderResponse = await combinedServices.deleteTransponders({
            transponder_id: contactId,
          });
  
          if (transponderResponse.data.data === "Deleted Successfully") {
            const newContacts = contacts.filter((contact) => contact.id !== contactId);
            setContacts(newContacts);
  
            // Assuming smsResponse is defined somewhere in your code
            if (transponderResponse.data.data === "Deleted Successfully") {
              swal("Transponder Deleted Successfully").then((value) => {
                // getALlSmsTemplate();
              });
            }
          } else {
            swal("Error", "Transponder already in use", "error");
          }
        } catch (error) {
          console.error("Error deleting transponder:", error);
          swal("Error", "Transponder already in use", "error");
        }
      }
    });
  };
  
  

  return (
    <div className="app-container">
      <form onSubmit={handleEditFormSubmit}>
        <table className="transTable">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {contacts?.map((contact) => (
              <>
                {editContactId === contact.id ? (
                  <EditableRow
                    editFormData={editFormData}
                    handleEditFormChange={handleEditFormChange}
                    handleCancelClick={handleCancelClick}
                  />
                ) : (
                  <ReadOnlyRow
                    contact={contact}
                    handleEditClick={handleEditClick}
                    handleDeleteClick={handleDeleteClick}
                  />
                )}
              </>
            ))}
             {idError && (
                  <p style={{ color: "red", fontSize: "12px" }}>{idError}</p>
                )}
          </tbody>
        </table>
      </form>

      <h2 className="addTransponder">Add Transponder</h2>
      <form onSubmit={handleAddFormSubmit}>
        <table className="transTable">
          <tr>
            <td>
              <div className="transId">
                <input
                  type="number"
                  name="id"
                  required="required"
                  placeholder="Transponder ID..."
                  onChange={handleAddFormChange}
                />
               
              </div>
            </td>

            <td>
              <input
                type="text"
                name="transponder_name"
                required="required"
                placeholder="Transponder Name..."
                onChange={handleAddFormChange}
              />
            </td>
            <td>
              <div className="actBtn">
                <button type="submit">Add</button>
              </div>
            </td>
          </tr>
          <p style={{ color: "red", fontSize: "12px" }}>{idError1}</p>
              
        </table>
      </form>
    </div>
  );
};

export default EditableForm;
