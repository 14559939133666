import React, { useState, useEffect } from 'react';
import "./Event.scss";
import Header from "../../../Components/adminDashboard/Header/Header";
import combinedServices from "../../../shared/services/user-service";
import getLocalData from "../../../shared/localStorage/getData";
import { NavLink, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';




const JoinEventStep2= () => {
   
    
    const params = useParams();
    let navigate = useNavigate();
    const [eventId, setEventId] = useState('');
    const [eventDetails, setEventDetails] = useState([]);
    const [eventClassDetails, setEventClassDetails] = useState([])
    const [userDetail, setUserDetail] = useState([]);
    const [participantId, setParticipateId] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState([])
    const [selectPaymentMethod, setSelectPaymentMethod] = useState("")
    const [handicappedPitSpace, setHandicappedPitSpace] = useState(false)
    const [clubHostName, setClubHostName] = useState("");
    const [successPage, setSuccessPage] = useState(false)


    useEffect(async () => {
        if (getLocalData("admin_info", "isLogin")) {
            setParticipateId(params.id)
            var paymentArr = [];
            let eventClassResponse = await combinedServices.getEventParticipateClass(params.id);

            if (eventClassResponse.data.event.allow_mail_ins) {

                paymentArr.push("Payment By Mail")
            }
            if (eventClassResponse.data.event.allow_pay_at_race_entries) {
    
                paymentArr.push("Payment At Race")
            }
            if (eventClassResponse.data.event.allow_paypal_entries) {
    
                paymentArr.push("PayPal")
            }
            setPaymentMethod(paymentArr)
            setEventDetails(eventClassResponse.data.event);
            setEventId(eventClassResponse.data.event.id);    
            setEventClassDetails(eventClassResponse.data.participantsData);
           
           
        }
    }, [])

    const handlePaymentMethod = (e) => {
        var payment_type = ""
       
       switch(e.target.value) {
        case "Payment At Race":
          // code block
          payment_type = "PAY_AT_RACE";
          break;
        case "Payment By Mail":
          // code block
          payment_type =  "MAIL_IN"
          break;
          case "PayPal":
            // code block
            payment_type =  "PAYPAL" ;
            break;
        default:
            payment_type = ""
        }

        
        
        if(eventClassDetails){

            eventClassDetails.map(async(value)=>{
                var eventParticipantData = value.eventParticipant ;
                var data = {
                    id:eventParticipantData.id,
                    user_id:eventParticipantData.user_id,
                    payment_type:payment_type


                }


              let paymentTypeResponse = await combinedServices.updatePaymentType(data);
              if(paymentTypeResponse.status == 200){
                setSelectPaymentMethod(payment_type);

              }

            
            })


        }
    }

    const handleClassWiseAmount = (classData) => {

        var totalClass = classData.length;



        const totalCost =
            Number(eventDetails.registration_charge) +
            Number(
                eventDetails.boat_charge *
                (totalClass - 1)
            );
        //console.log(totalCost.toFixed(2))
        return totalCost.toFixed(2);


    }

  

    const handlePaypalChecout = async () => {
       

        var participantIdsArr = String(participantId).split(",").map((num) => {
            return Number(num)
        })
       
        if (selectPaymentMethod == "PAYPAL") {
            let eventClassResponse = await combinedServices.addPaypalForm(participantIdsArr);
            if (eventClassResponse.status == 200) {
                setSuccessPage(true)
                window.location.href = eventClassResponse.data.payment_link;
            } else {

                swal("Oops!", eventClassResponse.msg, "error");
            }

        } else if (selectPaymentMethod === "PAY_AT_RACE") {
            var paymentDataArr = [];


            participantIdsArr.map((value) => {

                var data = {
                    event_participant_id: value,
                    payment_for: "event_participation",
                    pit_space: handicappedPitSpace,

                    payment_type: "PAY_AT_RACE"
                }

                paymentDataArr.push(data)


            })


           
            let eventPayAtRace = await combinedServices.addPaymentMany(paymentDataArr);
            if (eventPayAtRace.status == 200) {
                setSuccessPage(true);
               // let emailResponse = await combinedServices.email template is not working, please ask developer to fixForParticipant(value);
                navigate("../payment-success?event_id="+eventId, { replace: true });
            } else {

                swal("Oops!", eventPayAtRace, "error");
            }

        } else if (selectPaymentMethod === "MAIL_IN") {
            var paymentDataArr = [];


            participantIdsArr.map((value) => {

                var data = {
                    event_participant_id: value,
                    payment_for: "event_participation",
                    pit_space: handicappedPitSpace,

                    payment_type: "MAIL_IN"
                }

                paymentDataArr.push(data)


            })


            let eventMailsResponse = await combinedServices.addPaymentMany(paymentDataArr);
            //console.log(eventMailsResponse)
            if (eventMailsResponse.status == 200) {
                setSuccessPage(true)
                navigate("../payment-success?event_id=" + eventId, { replace: true });
            } else {

                swal("Oops!", eventMailsResponse.msg, "error");
            }
        }


    }




    return (
        <>
            <Header />


            <div className="dbAdminRight">
                <div className="dbMid dbInner">
                    <div className="flexBox spacebetween topHdBox">
                       


                    </div>

                    <div className="pb50 grayBg">
                <div className="container">
                    <div className="whtBox">
                        <div className="addEventTop flexBox spacebetween itemCenter">
                          
                        </div>
                    




                        {eventClassDetails.length > 0 && <>
                            <div className="eventDetBot">

                                <div className="paymentMetHd">Users WITH CLASSES INFORMATION</div>
                                <ul className="flexBox cartList">
                                    {eventClassDetails.map((value, index) => {

                                        return (

                                            <li>
                                                <div className="inner">

                                                    <div className="hd">{value.eventParticipant.user.user_firstname + " " + value.eventParticipant.user.user_lastname}</div>
                                                    <div className="acctable">
                                                        <table>
                                                            {value.eventParticipantClasses.map((classes, classIndex) => {
                                                                return (

                                                                    <tr>
                                                                        <td>{classes.class.name}</td>
                                                                    </tr>

                                                                )



                                                            })}

                                                           


                                                        </table>
                                                    </div>
                                                    <div className="total">
                                                        <div>Total</div>
                                                        <div>{handleClassWiseAmount(value.eventParticipantClasses)}</div>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })}


                                </ul>

                            </div>
                        </>}


                        <div className="addEventBot">


                            <div className="paymentType">

                                <div className="addEventBot">

                                    <div className="paymentMetHd">PAYMENT METHOD </div>
                                    <div className="paymentType">
                                        <span>Select payment method:</span>
                                        <select className="formSelect" name="payment" onChange={handlePaymentMethod}>
                                            <option>Select</option>
                                            {paymentMethod.length > 0 ? paymentMethod.map((payValue, index) => {
                                                    return (
                                                        <option value={payValue}>{payValue}</option>
                                                    )

                                                }) : ""}
                                            

                                        </select>


                                    </div>

                                </div>

                            

                            </div>

                        </div>

                        <div className="addEventBot">
                            <div className="paymentType pad0">
                                <button type="button" className="continueBtn" onClick={handlePaypalChecout}>Complete Entry</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                   
                </div>
            </div>
        </>

    )

}
export default JoinEventStep2;