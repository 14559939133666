import Header from "../../../Components/clubDashboard/Header/Header";
import { useForm } from "react-hook-form";
import React, { useState } from "react";
import { yupResolver } from '@hookform/resolvers/yup' ;
import combinedServices from "../../../shared/services/user-service";
import getRemoveData from "../../../shared/localStorage/removeData";
import * as Yup from 'yup' ;
import { Spinner } from 'reactstrap';
import swal from 'sweetalert';
import SearchPage from "../../../Components/clubDashboard/Header/SearchPage";
const Setting = () =>{
  const [old_password, setOldPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoder, setIsLoder] = useState(false);

  const formSchema = Yup.object().shape({
    old_password: Yup.string()
      .required('Password is required'),
      new_password: Yup.string()
      .required('Password is required')
      .min(8, 'Password length should be at least 8 characters')
      .max(12 ,'Password length should be only 12 characters'),
      
      confirm_password: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('new_password')], 'New password and confirm password are not same . Please enter same password'),
  })
  const validationOpt = { resolver: yupResolver(formSchema) }
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm(validationOpt);

  const onSubmit = async (data) => {
   setIsLoder(true)
  //e.preventDefault();
    var data = {
        new_password:data.new_password,
        old_password:data.old_password,
    };

    const response = await combinedServices.changePassword(data);
    //const response = "";
   
    if(response.status==401){
        swal(response.msg, "Please Re-login", "warning").then(() => {
          getRemoveData("user_info");
          localStorage.clear();
          window.location.href = "/login";
        });
      }
    if(response.status == 200){
        setIsLoder(false)
        getRemoveData("user_info");
                swal("Password Change Successfully.")
                    .then(async (value) => {
                        window.location.href = "/";
                        
                       

                    });
       

    }else{

        swal("!Opps",response.msg,"error") 
    }



  
};



    return (
        <>
        <Header/>
        <div className="dbRight">
        <div className="dbMid dbInner">
            
            <div className="">
                
                <ul className="flexBox profileDet">
                    <li className="full">
                        <div className="">
                            <div className="profileHd">Change Password</div>
                            <ul className="settingForm">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <li key="old_password"><input 
                                className="formInput"
                                type="password" 
                                placeholder="Old Password"
                                name="old_password" 
                                autoComplete="old_password"
                                {...register('old_password')}
                                />
                                 <span className="errorMsg">{errors.old_password?.message}</span>
                                
                                </li>
                                <li key="new_password"><input 
                                type="password" 
                                placeholder="New Password" 
                                name="new_password"
                                autoComplete="new_password"
                                {...register('new_password')}
                                className="formInput"/>
                                 <span className="errorMsg">{errors.new_password?.message}</span>
                                </li>
                                <li key="confirm_password"><input type="password" 
                                placeholder="Confirm Password" 
                                className="formInput"
                                name="confirm_password"
                                autoComplete="confirm_password"
                                {...register('confirm_password')}
                                />
                                <span className="errorMsg">{errors.confirm_password?.message}</span>
                                </li>
                                {isLoder ?  <li key="setting-error" className="text-center mt-4 loadMore"><Spinner color="primary"/></li>  :"" }

                                <li key="change-password"><button type="submit" className="formBtn">Change Password</button></li>
                            </form>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    </>
    )

}
export default Setting ;