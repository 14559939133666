import React, { useState, useEffect } from "react";
import "./EmailTemplate.scss";
import Header from "../../../Components/adminDashboard/Header/Header";
import Switch from "react-switch";
import combinedServices from "../../../shared/services/user-service";
import getLocalData from "../../../shared/localStorage/getData";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { NavLink, useParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import RichTextEditor from "react-rte";
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import userConstants from "../../../shared/constants/user-constants";

const AdminDashboard = () => {
  let navigate = useNavigate();
  const params = useParams();
  const [checked, setChecked] = useState(false);
  const [statusCheck, setStatusCheck] = useState(false);
  const [allsmsData, setAllSmsData] = useState([]);
  const [smsDetails, setSmsDetails] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoder, setIsLoder] = useState(true);
  const [isHideSubmitButton, setIsHideSubmitButton] = useState(false);
  const [contentDetails, setContentDetails] = useState("");
  const [imageUrl,setImageUrl] = useState('')
  const [baseURL, setBaseUrl] = useState(userConstants.baseURL);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleFormStatus = (nextChecked) => {
    setStatusCheck(nextChecked);
  };

  const handleUpdateForm = async (keyword) => {
    setIsEdit(true);
    let editResponse = await combinedServices.getEmailTemplate(keyword);
    setSmsDetails(editResponse.data);

    // setContentDetails(
    //   RichTextEditor.createValueFromString(editResponse.data.html, "html")
    // );
    setContentDetails(editResponse.data.html);
  };

  useEffect(async () => {
    if (getLocalData("admin_info", "isLogin")) {
      let smsResponse = await combinedServices.emailTemplateAll();
      if (smsResponse.status == 200) {
        setIsLoder(false);
        setAllSmsData(smsResponse.data);
      }

      //setUserId(userId)
    }
  }, []);

  const getALlEmailTemplate = async () => {
    setIsLoder(true);
    let smsResponse = await combinedServices.emailTemplateAll();
    if (smsResponse.status == 200) {
      setIsLoder(false);
      setAllSmsData(smsResponse.data);
    }
  };

  const onSubmit = async (data) => {
    if (isEdit) {
      setIsHideSubmitButton(true);
      var updateformData = {
        title: data.title,
        keyword: data.keyword,
        html: contentDetails.toString("html"),
        special_variables: data.variable,
      };

      let smsResponse = await combinedServices.updateEmailTemplate(
        updateformData,
        smsDetails.id
      );
      if (smsResponse.status == 200) {
        swal("Yes", "Email Template Update Successfully", "success").then(
          (value) => {
            setIsHideSubmitButton(false);
            getALlEmailTemplate();
          }
        );
      } else {
        swal("!Opps", smsResponse.msg, "error");
        setIsHideSubmitButton(false);
      }
    } else {
      setIsHideSubmitButton(true);

      var formData = {
        subject: data.subject,
        title: data.title,
        keyword: data.keyword,
        html: contentDetails.toString("html"),
        special_variables: data.variable,
        status: statusCheck ? 1 : 0,
      };

      let smsResponse = await combinedServices.createEmailTemplate(formData);
      if (smsResponse.id > 0) {
        swal("Yes", "Email Template Create Successfully", "success").then(
          (value) => {
            setIsHideSubmitButton(false);
            getALlEmailTemplate();
          }
        );
      } else {
        swal("!Opps", smsResponse.msg, "error");
        setIsHideSubmitButton(false);
      }
    }
  };
  const handleDeleteSmsTemplate = async (id) => {
    swal("Are you sure want to delete this email template?", {
      buttons: {
        yes: {
          text: "Yes",
          value: "yes",
        },
        no: {
          text: "No",
          value: "no",
        },
      },
    }).then(async (value) => {
      if (value === "yes") {
        let smsResponse = await combinedServices.deleteEmailTemplate(id);

        if (smsResponse.status == 200) {
          swal("Yes", "Email Template Delete Successfully", "success").then(
            (value) => {
              getALlEmailTemplate();
            }
          );
        }
      }
      return false;
    });
  };

  const handleChangeContent = (e) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = e.value;
  
    // Loop through all the images in the content
    const images = tempElement.querySelectorAll('img');
    images.forEach((img) => {
      // Check if the image has a blob URL
      if (img.src.startsWith('blob:')) {
        // Replace the blob URL with the actual image URL
        img.src = imageUrl; // Replace with your actual image URL
      }
    });
  
    // Convert the modified HTML content back to a string and update the editor data
    const modifiedContent = tempElement.innerHTML;
    // setEditorData(modifiedContent);
    setContentDetails(modifiedContent);
  };

  const handleChange = async (nextChecked, keyword) => {
    console.log(nextChecked);
    let smsResponse = await combinedServices.getEmailTemplate(keyword);
    if (smsResponse.status == 200) {
      let smsDetails = smsResponse.data;
      let smsdata = {
        title: smsDetails.title,
        keyword: smsDetails.keyword,
        html: contentDetails.toString("html"),
        variables: smsDetails.variable,
        status: nextChecked ? "1" : "0",
      };

      let apiResponse = await combinedServices.updateEmailTemplate(
        smsdata,
        smsDetails.id
      );

      if (apiResponse.status == 200) {
        getALlEmailTemplate();
      }
    }

    //console.log(smsResponse)
    //setChecked(nextChecked);
  };



  const insertImageSettings = {
    saveUrl: "https://services.syncfusion.com/js/production/api/FileUploader/Save",
    removeUrl: "https://services.syncfusion.com/js/production/api/FileUploader/Remove",
    height: 165,
    width: 165,
  };

  const imageUploadHandler = async (args) => {
    try {
      const files = args.file.rawFile;
      const formData = new FormData();
      formData.append("image", files);
  
      const response = await combinedServices.uploadImage(formData);
      const imageUrl = (await baseURL) + "images/" + response.data.data.file_name;
      console.log('baseurl',imageUrl)
      // Create a new image element
      const imageElement = document.createElement("img");
      imageElement.setAttribute("src", imageUrl);
      imageElement.setAttribute("alt", "Uploaded Image");
      imageElement.setAttribute("width", "165px"); // Adjust as needed
      imageElement.setAttribute("height", "165px"); // Adjust as needed
      setImageUrl(imageUrl)
      // Create a new paragraph element
      const paragraph = document.createElement("p");
      paragraph.appendChild(imageElement);
  
  
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Header />

      <div className="dbAdminRight">
        <div className="dbMid dbInner">
          <div className="flexBox spacebetween topHdBox">
            <div className="left">
              <div className="dbMainHd">EMAIL TEMPLATE</div>
            </div>
          </div>
          <div className="flexBox spacebetween">
            <div className="stLeft whtBox">
              <div className="interfaceTable">
                <table>
                  <thead>
                    <tr>
                      <th>Sr. No</th>
                      <th>Title</th>
                      <th>Description</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {isLoder ? (
                    <div className="text-center mt-4 loadMore">
                      <Spinner color="primary" />
                    </div>
                  ) : (
                    <>
                      <tbody>
                        {allsmsData.length > 0 ? (
                          allsmsData.map((value, index) => {
                            return (
                              <tr key={value.id}>
                                <td>{index + 1}</td>
                                <td>
                                  <div className="title">{value.title}</div>
                                </td>
                                <td>
                                  <div className="dec">{value.keyword}</div>
                                </td>

                                <td>
                                  <div className="toggleSwitch">
                                    <Switch
                                      onChange={(e) =>
                                        handleChange(e, value.keyword)
                                      }
                                      checked={
                                        value.status == "1" ? true : false
                                      }
                                      Name="react-switch1"
                                      offColor="#e70000"
                                      onColor="#1ce10d"
                                      onHandleColor="#fff"
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      handleDiameter={14}
                                      height={22}
                                      width={48}
                                      className="react-switch"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="actionBtn flexBox nowrap">
                                    <button
                                      title="edit"
                                      type="button"
                                      onClick={() =>
                                        handleUpdateForm(value.keyword)
                                      }
                                    >
                                      <span className="material-icons-outlined">
                                        edit
                                      </span>
                                    </button>
                                    <button
                                      title="Delete"
                                      type="button"
                                      onClick={() =>
                                        handleDeleteSmsTemplate(value.id)
                                      }
                                    >
                                      <span className="material-icons-outlined">
                                        delete
                                      </span>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <div className="noEvent">
                            Not Found Any Sms Templates
                          </div>
                        )}
                      </tbody>
                    </>
                  )}
                </table>
              </div>
            </div>
            {!isEdit ? (
              <div className="stRight whtBox">
                <div className="dbSubHd">Create SMS Template</div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <ul className="flexBox editForm full">
                    <li>
                      <span className="fieldHd">Subject</span>
                      <input
                        type="text"
                        className="formInput"
                        placeholder="Enter Subject"
                        name="subject"
                        {...register("subject", { required: true })}
                      />
                      {errors.subject && (
                        <p className="errorMsg">Please enter subject</p>
                      )}
                    </li>
                    <li>
                      <span className="fieldHd">Title</span>
                      <input
                        type="text"
                        className="formInput"
                        placeholder="Enter Title"
                        name="title"
                        {...register("title", { required: true })}
                      />
                      {errors.title && (
                        <p className="errorMsg">Please enter title</p>
                      )}
                    </li>
                    <li>
                      <span className="fieldHd">Keyword </span>
                      <input
                        type="text"
                        className="formInput"
                        placeholder="Enter Keyword "
                        name="keyword"
                        {...register("keyword", { required: true })}
                      />
                      {errors.keyword && (
                        <p className="errorMsg">Please enter keyword</p>
                      )}
                    </li>
                    <li>
                      <span className="fieldHd">Content </span>
                      {/* <RichTextEditor
                        className="formInput"
                        value={contentDetails}
                        onChange={handleChangeContent}
                      /> */}
                      <RichTextEditorComponent
                        value={contentDetails}
                        change={handleChangeContent}
                        insertImageSettings={insertImageSettings}
                        imageUploadSuccess={imageUploadHandler}
                      >
                        <Inject
                          services={[
                            Toolbar,
                            Image,
                            Link,
                            HtmlEditor,
                            QuickToolbar,
                          ]}
                        />
                      </RichTextEditorComponent>
                    </li>
                    <li>
                      <span className="fieldHd">Variable </span>
                      <input
                        type="text"
                        className="formInput"
                        placeholder="Enter Variable"
                        name="variable"
                        {...register("variable", { required: true })}
                      />
                      {errors.variable && (
                        <p className="errorMsg">Please enter variable</p>
                      )}
                    </li>
                    <li>
                      <div className="flexBox itemCenter">
                        <span className="fieldHd">Template Staus</span>
                        <div className="toggleSwitch ml10">
                          <Switch
                            Name="react-switch1"
                            offColor="#e70000"
                            onChange={handleFormStatus}
                            checked={statusCheck}
                            onColor="#1ce10d"
                            onHandleColor="#fff"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={14}
                            height={22}
                            width={48}
                            className="react-switch"
                          />
                        </div>
                      </div>
                    </li>
                  </ul>
                  {isHideSubmitButton ? (
                    " "
                  ) : (
                    <div className="tar">
                      <button type="submit" className="formBtn full">
                        Create
                      </button>
                    </div>
                  )}
                </form>
              </div>
            ) : (
              <div className="stRight whtBox">
                <div className="dbSubHd">Update SMS Template</div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <ul className="flexBox editForm full">
                    <li>
                      <span className="fieldHd">Subject</span>
                      <input
                        type="text"
                        className="formInput"
                        placeholder="Enter Subject"
                        name="subject"
                        defaultValue={smsDetails.subject}
                        {...register("subject", { required: true })}
                      />
                    </li>
                    <li>
                      <span className="fieldHd">Title</span>
                      <input
                        type="text"
                        className="formInput"
                        placeholder="Enter Title"
                        name="title"
                        defaultValue={smsDetails.title}
                        {...register("title")}
                      />
                    </li>
                    <li>
                      <span className="fieldHd">Keyword </span>
                      <input
                        type="text"
                        className="formInput"
                        placeholder="Enter Keyword "
                        name="keyword"
                        {...register("keyword")}
                        defaultValue={smsDetails.keyword}
                      />
                    </li>
                    <li>
                      <span className="fieldHd">Content </span>

                      {/* <RichTextEditor className="formInput" value={contentDetails} onChange={handleChangeContent} /> */}

                      {/* adding the new text editor */}
                      <RichTextEditorComponent
                        value={contentDetails}
                        change={handleChangeContent}
                        imageUploadSuccess={imageUploadHandler}
                        insertImageSettings={insertImageSettings}
                      >
                        <Inject
                          services={[
                            Toolbar,
                            Image,
                            Link,
                            HtmlEditor,
                            QuickToolbar,
                          ]}
                        />
                      </RichTextEditorComponent>
                    </li>
                    <li>
                      <span className="fieldHd">Variable </span>
                      <input
                        type="text"
                        className="formInput"
                        placeholder="Enter Variable"
                        name="variable"
                        {...register("variable")}
                        defaultValue={smsDetails.special_variables}
                      />
                    </li>
                  </ul>
                  {isHideSubmitButton ? (
                    " "
                  ) : (
                    <div className="tar">
                      <button type="submit" className="formBtn full">
                        Update
                      </button>
                    </div>
                  )}
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminDashboard;
