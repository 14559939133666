import Header from "../../Components/Layout/Header/Header";
import Footer from "../../Components/Layout/Footer/Footer";
import combinedServices from "../../shared/services/user-service";
import Pagination from "react-js-pagination";
import "./Event.scss";
import React, { useState, useEffect, useReducer } from "react";
import moment from "moment";
import getLocalData from "../../shared/localStorage/getData";
import EventTab from "../../Components/EventTab/EventTab";
import { useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";

const Event = () => {
  const navigate = useNavigate();
  const [eventDetails, setEventDetails] = useState([]);
  const [distric, setDistric] = useState([]);
  const [pageRecord, setPageRecord] = useState(10);
  const [page, setPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState("");
  const [currentDistric, setCurrentDistric] = useState("");
  const [hostClubIds, setHostClubIds] = useState("");
  const [isLoder, setIsLoder] = useState(true);

  useEffect(async () => {
    if (getLocalData("user_info", "isLogin")) {
      var userDistricId = getLocalData("user_info", "all").userData
        .login_user_detail.data.user.district.uzip_namba_district;
      var userId = getLocalData("user_info", "all").userData.login_user_detail
        .data.user.id;
      setCurrentDistric(userDistricId);

      getAllEventDetails(userDistricId);
    } else {
      getAllEventDetails(currentDistric);
    }

    let response = await combinedServices.getAllDistric();
    setDistric(response.data);
  }, []);

  const getAllEventDetails = async (dist_id) => {
    var eventArr = [];
    var data = {
      page: 1,
      records_per_page: pageRecord,
      event_status: "completed",
      sort_by: "event_start",
      order: "ASC",
    };
    if (dist_id) {
      data.district_id = dist_id;
    }
    let eventResponse = await combinedServices.getAllEventForHomePage(data);
    if (eventResponse.status == 200) {
      setTotalRecord(eventResponse.totalCount);
      eventResponse.data.map((events) => {
        if (events.event_is_published) {
          var data = {
            boatsCount: events.boatsCount,
            district_id: events.event_district_id,
            event_drivers_meeting_time: events.event_drivers_meeting_time,
            event_event_start: events.event_event_start,
            event_event_close: events.event_event_end,
            full_title: events.event_full_title,
            registration_close: events.event_registration_close,
            registration_open: events.event_registration_open,
            short_tile: events.event_short_tile,
            city: events.venue_clbsite_site_city,
            country: events.venue_clbsite_site_country,
            state: events.venue_clbsite_site_state,
            zipcode: events.venue_clbsite_site_zip,
            venue: events.venue_clbsite_site_name,
            hostClub_club_name: events.hostClub_club_name,
            racersCount: events.racersCount,
            id: events.event_id,
            event_is_published: events.event_is_published,
            time_zone: events.event_time_zone,
          };
          eventArr.push(data);
        }
      });
      var data1 = eventArr.sort(
        (a, b) =>
          new Date(a.event_event_start).getTime() -
          new Date(b.event_event_start).getTime()
      );
      setEventDetails(data1);
      // setEventDetails(eventArr)
      setIsLoder(false);
    } else {
      setIsLoder(false);
    }
  };

  const handlePageChange = async (pageNumber) => {
    setIsLoder(true);
    setPage(pageNumber);
    var eventArr = [];
    var data = {
      district_id: currentDistric,
      page: pageNumber,
      records_per_page: pageRecord,
    };
    let eventResponse = await combinedServices.getAllEvent(data);
    if (eventResponse.status == 200) {
      setTotalRecord(eventResponse.totalCount);

      eventResponse.data.map((events) => {
        if (events.event_is_published) {
          var data = {
            boatsCount: events.boatsCount,
            district_id: events.event_district_id,
            event_drivers_meeting_time: events.event_drivers_meeting_time,
            event_event_start: events.event_event_start,
            event_event_close: events.event_event_end,
            full_title: events.event_full_title,
            registration_close: events.event_registration_close,
            registration_open: events.event_registration_open,
            short_tile: events.event_short_tile,
            city: events.venue_clbsite_site_city,
            country: events.venue_clbsite_site_country,
            state: events.venue_clbsite_site_state,
            zipcode: events.venue_clbsite_site_zip,
            venue: events.venue_clbsite_site_name,
            hostClub_club_name: events.hostClub_club_name,
            racersCount: events.racersCount,
            id: events.event_id,
            event_is_published: events.event_is_published,
            time_zone: events.event_time_zone,
          };

          eventArr.push(data);
        }
      });
      var data1 = eventArr.sort(
        (a, b) =>
          new Date(a.event_event_start).getTime() -
          new Date(b.event_event_start).getTime()
      );

      setEventDetails(data1);
      // setEventDetails(eventArr);
      setIsLoder(false);
    } else {
      setIsLoder(false);
    }
  };

  const handleDistricFilter = (e) => {
    getAllEventDetails(e.target.value);

    setCurrentDistric(e.target.value);
  };

  const HandleEventDetails = (id) => {
    navigate("/event-detail/" + id);
  };
  const calculateDaysBetweenTwoDate = (startDate, endDate) => {
    var future = moment(endDate);
    var start = moment(startDate);
    var d = future.diff(start, "days") + 1; // 9

    //var d = Math.ceil(Math.abs(startDate - endDate) / (1000 * 60 * 60 * 24));

    return d;
  };

  const timeaccotolocation2 = (x, timeZone) => {
    const utcMoment = moment.utc(x);
    const localMoment = utcMoment.tz(timeZone);
    const timeZoneOffset = localMoment.format("Z");
    return localMoment.format("ddd MMM DD YYYY HH:mm:ss");

  };
  return (
    <>
      <div className="mainCon">
        <Header />
        <div className="innerBanner event">
          <div className="container flexBox">
            <div>
              <div className="innerHd">Events</div>
              <div className="bradcrumb">
                <a href="/">Home</a>
                <a href="/event-management">Events</a>
              </div>
            </div>
          </div>
        </div>

        <div className="pb50 grayBg">
          <div className="container">
            <div className="whtBox">
              <EventTab />
              <div className="eventDetCon">
                <p className="eventContent">
                  The complete detailsss of all events, including upcoming
                  events and clubs, with race results, all put together for you
                  for easy availability. You can book your racing event
                  participation, or for the National Championships, so that you
                  will never miss any NAMBA event.{" "}
                </p>
                <div className="flexBox nowrap itemCenter districtSelectBox">
                  District
                  <select onChange={handleDistricFilter}>
                    <option value="">All District</option>
                    {distric.map((distVal) => {
                      return (
                        <option
                          key={distVal.district_id}
                          selected={
                            distVal.district_id == currentDistric
                              ? "selected"
                              : ""
                          }
                          value={distVal.district_id}
                        >
                          D{distVal.district_id}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {isLoder ? (
                  <div className="text-center mt-4 loadMore">
                    <Spinner color="primary" />
                  </div>
                ) : (
                  <>
                    <ul className="upcomingEventList">
                      {eventDetails.length > 0 ? (
                        eventDetails.map((event, index) => {
                          return (
                            <li
                              key={event.id}
                              className="blue"
                              onClick={() => HandleEventDetails(event.id)}
                            >
                              <div className="name">
                                {event.hostClub_club_name
                                  ? event.hostClub_club_name
                                  : ""}
                              </div>
                              <div className="Info">
                                {event.full_title ? event.full_title : ""}
                                <br />
                                Entries:{" "}
                                {event.racersCount ? event.racersCount : 0}{" "}
                                racers,{" "}
                                {event.boatsCount ? event.boatsCount : 0} boats.
                              </div>
                              <div className="date">
                                {moment(
                                  timeaccotolocation2(
                                    event.event_event_start,
                                    event.time_zone
                                  )
                                ).format("MMM D")}{" "}
                                -{" "}
                                {moment(
                                  timeaccotolocation2(
                                    event.event_event_close,
                                    event.time_zone
                                  )
                                ).format("MMM D")}
                                ({event.time_zone=='HST'?'Hawaiin':event.time_zone.split('/')[1]})
                                {moment()
                                  .tz(event.time_zone)
                                  .format("YYYY-MM-DD HH:mm:ss") >
                                  moment(
                                    timeaccotolocation2(
                                      event.registration_close,
                                      event.time_zone
                                    )
                                  )
                                    .tz(event.time_zone)
                                    .format("YYYY-MM-DD HH:mm:ss") && (
                                  <span>
                                    Closed{" "}
                                    {moment(
                                      timeaccotolocation2(
                                        event.registration_close,
                                        event.time_zone
                                      )
                                    ).format("ddd , MMM D , YYYY")}
                                  </span>
                                )}
                                {moment()
                                  .tz(event.time_zone)
                                  .format("YYYY-MM-DD HH:mm:ss") <
                                  moment(
                                    timeaccotolocation2(
                                      event.registration_open,
                                      event.time_zone
                                    )
                                  ).format("YYYY-MM-DD HH:mm:ss") && (
                                  <span>
                                    Opens{" "}
                                    {moment(
                                      timeaccotolocation2(
                                        event.registration_open,
                                        event.time_zone
                                      )
                                    ).format("ddd , MMM D , YYYY")}
                                  </span>
                                )}
                                {(() => {
                                  if (
                                    moment(
                                      timeaccotolocation2(
                                        event.registration_open,
                                        event.time_zone
                                      )
                                    ).format("YYYY-MM-DD HH:mm:ss") <=
                                      moment()
                                        .tz(event.time_zone)
                                        .format("YYYY-MM-DD HH:mm:ss") &&
                                    moment()
                                      .tz(event.time_zone)
                                      .format("YYYY-MM-DD HH:mm:ss") <=
                                      moment(
                                        timeaccotolocation2(
                                          event.registration_close,
                                          event.time_zone
                                        )
                                      ).format("YYYY-MM-DD HH:mm:ss")
                                  ) {
                                    return (
                                      <span>
                                        (Closes{" "}
                                        {moment(
                                          timeaccotolocation2(
                                            event.registration_close,
                                            event.time_zone
                                          )
                                        ).format("ddd , MMM D , YYYY")}
                                        )
                                      </span>
                                    );
                                  }

                                  return null;
                                })()}
                              </div>
                              <div className="location">
                                D{event.district_id}{" "}
                                {event.city ? " , " + event.city : ""}
                              </div>
                            </li>
                          );
                        })
                      ) : (
                        <div className="noEvent">
                          Not Found Any Upcoming Event.
                        </div>
                      )}
                    </ul>
                    {totalRecord > 10 && eventDetails.length > 0 && (
                      <Pagination
                        activePage={page}
                        totalItemsCount={totalRecord}
                        onChange={handlePageChange}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default Event;
