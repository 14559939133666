import Header from "../../../Components/clubDashboard/Header/Header";
const Membership = () =>{

    return (
        <>
        <Header/>
        <div className="dbRight">
            <div className="dbMid dbInner">
                <div className="flexBox spacebetween itemCenter">
                    <div className="searchBox flexBox spacebetween itemCenter nowrap">
                        <input type="text" className="searchInput" placeholder="Search"/>
                        <button className="searchBtn"><span className="material-icons">search</span></button>
                    </div>
                    <ul className="flexBox topLink">
                        <li><a href="#"><span className="material-icons">mail_outline</span></a></li>
                        <li><a href="#"><span className="material-icons-outlined">notifications</span></a></li>
                    </ul>
                </div>
                <div className="flexBox spacebetween topHdBox">
                    <div className="left">
                        <div className="dbMainHd">NAMBA MEMBERSHIP</div>
                        <div className="text">Manage My Memberships 2022</div>
                    </div>
                    <div className="right tar"><a href="/apply-new-member" className="blueBtn">apply for a new membership</a></div>
                </div>
                <div className="dbWhtBox">
                    <div className="dbTable">
                        <table cellpadding="0" cellspacing="0">
                            <tr>
                                <th>NAMBA Number</th>
                                <th>Member Summary</th>
                                <th>Membership Type</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                            <tr>
                                <td><div className="hd">1</div></td>
                                <td>
                                    <div className="hd">David Santistevan</div>
                                    <ul className="summarylist">
                                        <li><span>Username:</span> daves@concentricsinc.com</li>
                                        <li><span>Member Since:</span> December 01, 2016</li>
                                        <li><span>Club Member:</span> Rocky Mountain Marine Modelers, Throttle Up Utah
                                            Boaters</li>
                                        <li><span>Interests:</span> Fast Electric, Nitro, Gas</li>
                                    </ul>
                                </td>
                                <td>
                                    <div className="hd">Primary Member</div>
                                </td>
                                <td>
                                    <div className="status active">Active</div>
                                </td>
                                <td>
                                    <div className="actionBox flexBox nowrap">
                                        <a href="#"><span className="material-icons">visibility</span></a>
                                        <a href="#"><span className="material-icons">drive_file_rename_outline</span></a>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="hd">105</div>
                                    <div className="reqLink"><a href="#">Request a New NAMBA Number from the Site Admin</a> </div>
                                </td>
                                <td>
                                    <div className="hd">Valerie Santistevan</div>
                                    <ul className="summarylist">
                                        <li><span>Member Since:</span> December 01, 2016</li>
                                        <li><span>Club Member:</span> Rocky Mountain Marine Modelers, Throttle Up Utah
                                            Boaters</li>
                                        <li><span>Interests:</span> Fast Electric, Nitro, Gas</li>
                                    </ul>
                                </td>
                                <td>
                                    <div className="hd">Adult Member <span>(Same Address)</span></div>
                                </td>
                                <td>
                                    <div className="status active">Active</div>
                                </td>
                                <td>
                                    <div className="actionBox flexBox nowrap">
                                        <a href="#"><span className="material-icons">visibility</span></a>
                                        <a href="#"><span className="material-icons">drive_file_rename_outline</span></a>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="hd">105</div>
                                    <div className="reqLink"><a href="#">Request a New NAMBA Number from the Site Admin</a> </div>
                                </td>
                                <td>
                                    <div className="hd">Valerie Santistevan</div>
                                    <ul className="summarylist">
                                        <li><span>Member Since:</span> December 01, 2016</li>
                                        <li><span>Club Member:</span> Rocky Mountain Marine Modelers, Throttle Up Utah
                                            Boaters</li>
                                        <li><span>Interests:</span> Fast Electric, Nitro, Gas</li>
                                    </ul>
                                </td>
                                <td>
                                    <div className="hd">Adult Member <span>(Same Address)</span></div>
                                </td>
                                <td>
                                    <div className="status active">Active</div>
                                </td>
                                <td>
                                    <div className="actionBox flexBox nowrap">
                                        <a href="#"><span className="material-icons">visibility</span></a>
                                        <a href="#"><span className="material-icons">drive_file_rename_outline</span></a>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="hd">105</div>
                                    <div className="reqLink"><a href="#">Request a New NAMBA Number from the Site Admin</a> </div>
                                </td>
                                <td>
                                    <div className="hd">Valerie Santistevan</div>
                                    <ul className="summarylist">
                                        <li><span>Member Since:</span> December 01, 2016</li>
                                        <li><span>Club Member:</span> Rocky Mountain Marine Modelers, Throttle Up Utah
                                            Boaters</li>
                                        <li><span>Interests:</span> Fast Electric, Nitro, Gas</li>
                                    </ul>
                                </td>
                                <td>
                                    <div className="hd">Adult Member <span>(Same Address)</span></div>
                                </td>
                                <td>
                                    <div className="status active">Active</div>
                                </td>
                                <td>
                                    <div className="actionBox flexBox nowrap">
                                        <a href="#"><span className="material-icons">visibility</span></a>
                                        <a href="#"><span className="material-icons">drive_file_rename_outline</span></a>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </>


    )
}
export default Membership ;