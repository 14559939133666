import Header from "../../../Components/clubDashboard/Header/Header";
import React, { useState, useEffect } from "react";
import moment from "moment";
import getLocalData from "../../../shared/localStorage/getData";
import SearchPage from "../../../Components/clubDashboard/Header/SearchPage";
import { useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import getRemoveData from "../../../shared/localStorage/removeData";
import { StyledEngineProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { Country, State, City } from "country-state-city";
import combinedServices from "../../../shared/services/user-service";
import Multiselect from "multiselect-react-dropdown";
import dayjs, { Dayjs } from "dayjs";
import MaskedInput from 'react-text-mask';

const UpdateProfile = () => {

  const phoneMask = ['(',/\d/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/,/\d/]; // to mask the number

  const [userId, setUserId] = React.useState("");
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCountry1, setSelectedCountry1] = useState("");
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedState1, setSelectedState1] = useState("");

  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [userDetails, setUserDetails] = useState([]);
  const [clubUserDetails, setUserClubDetail] = useState([]);
  const [isLoder, setIsLoder] = useState(true);
  const [countryiso, setCountryiso] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [areacodemobile, setAreacodemobile] = useState("");
  const [areacodephone, setAreacodephone] = useState("");
  const [mobile, setMobile] = useState("");
  const [phone, setPhone] = useState("");
  const [sex, setSex] = useState("");
  const [addr1, setAddr1] = useState("");
  const [addr2, setAddr2] = useState("");
  const [play_video, setPlay_video] = useState("");
  const [email, setEmail] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [emailnoti, setEmailnoti] = useState("");
  const [dob, setDob] = React.useState(
    dayjs(new Date())
  );
  const [selectedinter, setSelectedinter] = useState([]);
  const [retypeEmail, setRetypeEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [error, setError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [retypeNewPassword, setRetypeNewPassword] = useState("");
  const [error2, setError2] = useState("");
  const [error3, setError3] = useState("");
  const [error4, setError4] = useState("");
  const [error5, setError5] = useState("");
  const [unable, setUnable] = useState(false);
  const areaofinterest = [
    { name: "Fast Electric", id: "4" },
    { name: "Nitro", id: "11" },
    { name: "Gas", id: "12" },
    { name: "Combat", id: "17" },
  ];
  useEffect(async () => {
    var userId = "";

    if (getLocalData("user_info", "isLogin")) {
      userId = getLocalData("user_info", "all").userData.login_user_detail.data
        .user.id;
    }
    setUserId(userId);
    var data = {
      user_id: userId,
      clubs: true,
      events: true,
      district: true,
    };

    let profileResponse = await combinedServices.getProfileDetails(data);
    if (profileResponse.status == 401) {
      swal(profileResponse.msg, "Please Re-login", "warning").then(() => {
        getRemoveData("user_info");
        localStorage.clear();
        window.location.href = "/login";
      });
    }
    let userinteresr = await combinedServices.getUserInterest(data.user_id);

    const matchedInterests = areaofinterest.filter((interest) =>
      userinteresr.data.some(
        (item) => item.intuser_interestid_fkey == interest.id
      )
    );
    setSelectedinter(matchedInterests);

    if (profileResponse?.status == 200) {
      setIsLoder(false);
      var userDetailData = profileResponse.data;
      setUserDetails(userDetailData);
      setUserClubDetail(userDetailData?.club_members);
      setSelectedCountry1(userDetailData?.user_country);
      setSelectedState1(userDetailData?.user_state);
      setSelectedCity(userDetailData?.user_city);
      setFirstname(userDetailData?.user_firstname);
      setLastname(userDetailData?.user_lastname);
      setAddr1(userDetailData?.user_addr1);
      setAddr2(userDetailData?.user_addr2);
      setDob(dayjs(userDetailData?.user_dob));

      setAreacodemobile(userDetailData?.user_areacodemobile);
      setAreacodephone(userDetailData?.user_areacodephone);
      const mobileNumber = formattedPhone(userDetailData.user_mobile)
      setMobile(mobileNumber);
      setPhone(userDetailData?.user_phone);
      setSex(userDetailData?.user_sex);
      setPlay_video(userDetailData?.play_video);
      setEmail(userDetailData.user_email);
      setZipcode(userDetailData.user_zip);
      setEmailnoti(userDetailData?.email_notifiaction);
      setPassword1(userDetailData?.user_pass);
      // if (userDetailData.length != 0) {
      //   setUserData((prevUserData) => ({
      //     ...prevUserData,
      //     firstname: userDetails.user_firstname || "",
      //     lastname: userDetails.user_lastname || "",
      //     dob: userDetails.user_dob || "",
      //     areacodemobile: userDetails.user_areacodemobile || "",
      //     areacodephone: userDetails.user_areacodephone || "",
      //     mobile: userDetails.user_mobile || "",
      //     phone: userDetails.user_phone || "",
      //     sex: userDetails.user_sex || "",
      //     country: userDetails.user_country || "",
      //     state: userDetails.user_state || "",
      //     city: userDetails.user_city || "",
      //     play_video: userDetails.play_video || "",
      //     addr1: userDetails.user_addr1 || "",
      //     addr2: userDetails.user_addr2 || "",
      //     email: userDetails.user_email || "",
      //     zipcode: userDetails.user_zip || "",
      //     // Add other fields as needed
      //   }));
      // }
    }

    const allCountries = Country.getAllCountries();
    setCountries(allCountries);
  }, []);

  const handleCountry = (isoCode) => {
    setSelectedCountry({
      // isoCode,
      name: countries.find((country) => country.isoCode === isoCode).name,
    });
    setSelectedCountry1(selectedCountry.name);
    const allstate = State.getAllStates();
    const stater = allstate.filter((x) => x.countryCode === isoCode);
    setCountryiso(isoCode);
    setStates(stater);
  };

  const handleState = (isoCode) => {
    setSelectedState({
      // isoCode,
      name: states.find((state) => state.isoCode === isoCode).name,
    });
    setSelectedState1(selectedState.name);
    const allcities = City.getAllCities();
    const cities = allcities.filter(
      (x) => x.stateCode == isoCode && x.countryCode == countryiso
    );
    setCities(cities);
  };

  const handleCity = (val) => {
    setSelectedCity(val);

  };
  const handlemail = (value) => {
  setEmail(value)
  };
  const handleRetypeEmail = (value) => {
    // setError('');
    setRetypeEmail(value);

    // Check if the retype email matches the entered email
    if (email != "" && value != email) {
      setError("Emails Not Matched");
      setUnable(true);
    } else {
      setError("");
      setUnable(false);
    }
  };
  const handlefirstname = (val) => {
    setFirstname(val);
  };
  const handlelastname = (val) => {
    setLastname(val);
  };
  const handlephone = (val) => {
    setPhone(val);
  };
  const handlareacodeephone = (val) => {
    setAreacodephone(val);
  };
  const handlemobile = (val) => {
    setMobile(val);
  };
  const handleInputAdd1 = (val) => {
    setAddr1(val);
  };
  const handleInputAdd2 = (val) => {
    setAddr2(val);
  };
  const handleZipcode = (val) => {
    setZipcode(val);
  };
  const handlePassword = (value) => {
    
    // Check if the entered password matches the state obtained from the API
    if (value !== password1) {
      setError2("Incorrect Password");
      setUnable(true);
    } else {
      setError2("");
      setUnable(false);
    }
  };
  const handleNewPassword = (value) => {
    setNewPassword(value);

    const strongPasswordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

    // Check if the new password matches the strong password criteria
    if (!strongPasswordRegex.test(value)) {
      setError4(
        "New password must be at least 8 characters with letters, numbers, and special characters"
      );
      setUnable(true);
    } else if (retypeNewPassword !== "" && value !== retypeNewPassword) {
      setError4("New Password Not Matched");
      setUnable(true);
    } else {
      setError4("");
      setUnable(false);
    }
  };
  const handleEmailNotiChange = (value) => {
    setEmailnoti(value);
  };

  const handlePlayVideoChange = (value) => {
    setPlay_video(value);
  };

  const handleRetypeNewPassword = (value) => {
    setRetypeNewPassword(value);

    // Check if the retype new password matches the entered new password
    if (newPassword !== "" && value !== newPassword) {
      setError3("New Passwords Not Matched");
      setUnable(true);
    } else {
      setError3("");
      setUnable(false);
    }
  };
  const onSelectInterest = (selectedList, selectedItem) => {
    
    setSelectedinter(selectedList)
    // setSelectedClass(selectedList);
  };

  const onRemoveInterest = (selectedList, removedItem) => {
   
    setSelectedinter(selectedList)
    // setSelectedClass(selectedList);
  };
  const handleUpdateClick = async() => {
    const data2={
      user_id:userId,
      x:selectedinter

    }
   
    const res1=await combinedServices.UpdateInterest(data2)
   
    const data = {
      user_id: userId,
      firstname: firstname,
      lastname: lastname,
      dob: dob.toDate().toLocaleDateString().split('/')[2]+'/'+dob.toDate().toLocaleDateString().split('/')[0]+'/'+dob.toDate().toLocaleDateString().split('/')[1],
      areacodemobile: areacodemobile,
      areacodephone: areacodephone,
      mobile: mobile,
      phone: phone,
      sex: sex,
      country: selectedCountry1,
      state: selectedState1,
      city: selectedCity,
      play_video: play_video,
      addr1: addr1,
      addr2: addr2,
      email_notification: emailnoti,
      newpassword: newPassword==""?password1:newPassword,
      zipcode: zipcode,
      usermail: email,
    };
    const res= await combinedServices.UpdateProfile(data)
   
   if(res.data=='Successfully Updated'){
    swal("Profile Updated Successfully")
                    // .then((value) => {

                    //     navigate("../payment-gateway", { replace: true });

                    // })
   }
  };

  const formattedPhone=(phoneNumber)=>{
    
  const pattern = /^\(\d{3}\)\d{3}-\d{4}$/; // Define a regular expression pattern for the desired format
  if(pattern.test(phoneNumber)){
    return phoneNumber
  }else{
    // Remove any non-numeric characters from the phone number
    const numericPhoneNumber = phoneNumber.replace(/\D/g, '');
    
    // Format the numeric phone number as (xxx)xxx-xxxx
    return numericPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3');
  }
  }


  return (
    <>
      <Header />
      <div className="dbRight">
        <div className="dbMid dbInner">
          <div className="">
            <div className="dbMainHd">Edit Profile</div>
            <div className="editSubHd">Personal Information</div>
            <ul className="editForm">
              <li className="half">
                <label>First Name</label>
                <input
                  type="text"
                  className="formInput"
                  value={firstname}
                  onChange={(e) => handlefirstname(e.target.value)}
                />
              </li>
              <li className="half">
                <label>Last Name</label>
                <input
                  type="text"
                  className="formInput"
                  value={lastname}
                  onChange={(e) => handlelastname(e.target.value)}
                />
              </li>
              <li className="half">
                <label>Home Phone include area code</label>
                <div className="phoneBox">
                  <select className="formSelect" value={areacodephone}>
                    <option value="+1">USA(+1)</option>
                    <option value="+1">CA(+1)</option>
                  </select>
                  <MaskedInput
                    type="text"
                    className="formInput"
                    value={phone}
                    mask={phoneMask}
                    onChange={(e) => handlephone(e.target.value)}
                  />
                </div>
              </li>
              <li className="half">
                <label>Cell Phone include area code</label>
                <div className="phoneBox">
                  <select
                    className="formSelect"
                    value={areacodemobile}
                    onChange={(e) => handlareacodeephone(e.target.value)}
                  >
                    <option value="+1">USA(+1)</option>
                    <option value="+1">CA(+1)</option>
                  </select>
                  <MaskedInput
                    mask={phoneMask}
                    type="text"
                    className="formInput"
                    value={mobile}
                    onChange={(e) => handlemobile(e.target.value)}
                  />
                </div>
              </li>
              <li className="half">
                <label>Specific Areas of Interest - Optional</label>
                <Multiselect
                  options={areaofinterest}
                   onSelect={onSelectInterest}
                  onRemove={onRemoveInterest}
                  selectedValues={selectedinter}
                  displayValue="name"
                  showCheckbox
                />
                {/* <select className="formSelect">
                  <option>Select</option>
                </select> */}
              </li>
              <li className="half">
                <label>Member's Date of Birth (mm-dd-yy)</label>
                <div className="dobBox">
                  <StyledEngineProvider injectFirst>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker", "DatePicker", "DatePicker"]}
                      >
                        <DatePicker
                          // label={'Select DOB'}
                          value={dob}
                          views={["month", "day", "year"]}
                          onChange={(newValue) => setDob(newValue)}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </StyledEngineProvider>
                </div>
              </li>
            </ul>
            <div className="editSubHd">Location Information</div>
            <ul className="editForm">
              <li className="half">
                <label>Street Address Line 1</label>
                <input
                  type="text"
                  className="formInput"
                  value={addr1}
                  onChange={(e) => handleInputAdd1(e.target.value)}
                />
              </li>
              <li className="half">
                <label>Address Line 2</label>
                <input
                  type="text"
                  className="formInput"
                  value={addr2}
                  onChange={(e) => handleInputAdd2(e.target.value)}
                />
              </li>
              <li className="half">
                <label>Zip</label>
                <input
                  type="text"
                  className="formInput"
                  value={zipcode}
                  onChange={(e) => handleZipcode(e.target.value)}
                />
              </li>

              <li className="half">
                <label>Country</label>
                <select
                  onChange={(e) => handleCountry(e.target.value)}
                  value={selectedCountry.isoCode}
                  // value={selectedCountry.isoCode} // Use isoCode for the value
                  className="formSelect"
                >
                  <option value="">
                    {" "}
                    {typeof selectedCountry == "string"
                      ? selectedCountry1
                      : "Select Country"}
                  </option>
                  {countries.map((country) => (
                    <option key={country.isoCode} value={country.isoCode}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </li>
              <li className="half">
                <label>State</label>
                <select
                  onChange={(e) => handleState(e.target.value)}
                  value={selectedState.isoCode}
                  className="formSelect"
                >
                  <option value="">
                    {" "}
                    {typeof selectedState == "string"
                      ? selectedState1
                      : "Select State"}
                  </option>

                  {states.map((state) => (
                    <option key={state.isoCode} value={state.isoCode}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </li>
              <li className="half">
                <label>City</label>

                <select
                  onChange={(e) => handleCity(e.target.value)}
                  value={selectedCity}
                  className="formSelect"
                >
                  <option value="">
                    {" "}
                    {typeof selectedCity == "string"
                      ? selectedCity
                      : "Select City"}
                  </option>
                  {/* <option value="">Select City</option> */}
                  {cities.map((city) => (
                    <option key={city.name} value={city.name}>
                      {city.name}
                    </option>
                  ))}
                </select>
              </li>
              {/* <li className="half">
                <label>Club Affiliation</label>
                <select className="formSelect">
                  <option>Select</option>
                </select>
              </li> */}
            </ul>
            <div className="editSubHd">Account Information</div>
            <ul className="editForm">
              <li className="half">
                <label>Email Address</label>
                <input
                  type="email"
                  className="formInput"
                  value={email}
                  onChange={(e) => handlemail(e.target.value)}
                />
                <span style={{ color: "red" }}>{error5}</span>
              </li>
              <li className="half">
                <label>Retype Email Address</label>
                <input
                  type="email"
                  className="formInput"
                  autoComplete="off"
                  value={retypeEmail}
                  onChange={(e) => handleRetypeEmail(e.target.value)}
                />
                <span style={{ color: "red" }}>{error}</span>
              </li>
              <li className="half">
                <label>Password</label>
                <input
                  type="password"
                  className="formInput"
                  autoComplete="new-password"
                  onChange={(e) => handlePassword(e.target.value)}
                />
                <span style={{ color: "red" }}>{error2}</span>
              </li>
              <li></li>
              <li className="half">
                <label>New Password</label>
                <input
                  type="password"
                  className="formInput"
                  onChange={(e) => handleNewPassword(e.target.value)}
                />
                <span style={{ color: "red" }}>{error4}</span>
              </li>
              <li className="half">
                <label>Retype Password</label>
                <input
                  type="password"
                  className="formInput"
                  onChange={(e) => handleRetypeNewPassword(e.target.value)}
                />
                <span style={{ color: "red" }}>{error3}</span>
              </li>
              <li className="half">
                <label>Send email Notification</label>
                <div className="optBox">
                  <label>
                    <span className="radioWrapper">
                      <input
                        type="radio"
                        className="radioInpt"
                        name="notification"
                        checked={emailnoti == 1}
                        onChange={() => handleEmailNotiChange(1)}
                      />
                      <i className="bg"></i>
                    </span>{" "}
                    Yes
                  </label>
                  <label>
                    <span className="radioWrapper">
                      <input
                        type="radio"
                        className="radioInpt"
                        name="notification"
                        checked={emailnoti == 0}
                        onChange={() => handleEmailNotiChange(0)}
                      />
                      <i className="bg"></i>
                    </span>{" "}
                    No
                  </label>
                </div>
              </li>
              <li className="half">
                <label>Video default play</label>
                <div className="optBox">
                  <label>
                    <span className="radioWrapper">
                      <input
                        type="radio"
                        className="radioInpt"
                        name="video"
                        checked={play_video == 1}
                        onChange={() => handlePlayVideoChange(1)}
                      />
                      <i className="bg"></i>
                    </span>{" "}
                    Yes
                  </label>
                  <label>
                    <span className="radioWrapper">
                      <input
                        type="radio"
                        className="radioInpt"
                        name="video"
                        checked={play_video == 0}
                        onChange={() => handlePlayVideoChange(0)}
                      />
                      <i className="bg"></i>
                    </span>{" "}
                    No
                  </label>
                </div>
              </li>
            </ul>
            <div>
              <button
                className="formBtn"
                type="button"
                disabled={unable}
                onClick={handleUpdateClick}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UpdateProfile;
