import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ModalHeatTab from './model-heat-tab'

class ModalExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    return (
      <div>
        <Button color="danger" onClick={this.toggle}>Score Heat</Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className="modalOuterBx">
            <Button className='modalCloseBtn' onClick={this.toggle}>X</Button>
            <ModalBody>
                <div className='tabposition'>
                    <div className='tabpos_Left'>Position Entrant</div>
                    <div className='tabpos_Right' >Finish</div>
                </div>
                <ModalHeatTab scoreheat={this.props.scoreheat}/>
            </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default ModalExample;