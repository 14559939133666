
import React, { useState, useEffect } from 'react';
import "./PaymentGateway.scss";
import Header from "../../../Components/adminDashboard/Header/Header";
import Switch from "react-switch";
import combinedServices from "../../../shared/services/user-service";
import getLocalData from "../../../shared/localStorage/getData";
import {  NavLink, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import { Spinner } from 'reactstrap';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import getRemoveData from '../../../shared/localStorage/removeData'

const PaymentGateway = () => {
    let navigate = useNavigate();
    const [allClubDetails,setAllClubDetails] = useState([]);
    const [isEdit,setIsEdit]= useState(false) ;
    const [paymentGatewayId,setPaymentGatewayId]= useState("");
    const [isLoder, setIsLoder] = useState(true) ;
    const [allUsers,setAllUser] = useState([]);
    const [allEventDetails,setAllEventDetails] = useState([]);
    const [userId,setUserId] = useState("");
   
   

    

    useEffect(async () => {
        if (getLocalData("admin_info", "isLogin")) {
        
        
            let usersResponse = await combinedServices.getAllUser();
            if(usersResponse.status==401){
                swal(usersResponse.msg, "Please Re-login", "warning").then(() => {
                  getRemoveData("user_info");
                  localStorage.clear();
                  window.location.href = "/login";
                });
              }
            var userArr = [] ;
            setUserId(usersResponse.data[0].id)

            usersResponse?.data.map((user)=>{

                var data = {
                    name : user.user_firstname+" "+user.user_lastname ,
                    value : user.id


                }
                userArr.push(data)
            
            })

            setAllUser(userArr) ;
            handleSearchBox(usersResponse.data[0].id)
    
        }
    }, [])
 
    
   
    const handleSearchBox = async(e) =>{
       
        setUserId(e)
       
        var upcomingRaceData = {
            participation_status : "upcoming",
            user_id: e

        }
        let upcomingRacesResponse = await combinedServices.getMyRaceDetailsForAdmin(upcomingRaceData);
       
        setAllEventDetails(upcomingRacesResponse.data)
        

    }

    const handleUpdateMyRace = async(event_id)=>{
      
        var data = {

            event_id :event_id ,
            user_id :userId
        }

        let upcomingRacesResponse = await combinedServices.checkIfUserJoinedAdmin(data);


        if(upcomingRacesResponse.status == 200){

            
            var id = upcomingRacesResponse.data.id ;

            
            navigate("../update-join-event/"+id, { replace: true });


        }
        



    }

    
    return (
        <>
            <Header />
           

            <div className="dbAdminRight">
                <div className="dbMid dbInner">
                    <div className="flexBox spacebetween topHdBox">
                        <div className="left">
                            <div className="dbMainHd">Add / Cancel Class
                            
                        
                            </div>
                            
                        
                        </div>
                        <div className='right select-search'>
                            
                            <SelectSearch
          options={allUsers}
          value={userId}
          
          onChange={handleSearchBox}
          search
          filterOptions={fuzzySearch}
          placeholder="Search something"
          className="select-search"
        />
                               
                                
                               
                            
                        </div>
                    </div>
                    <div className='flexBox spacebetween'>
                        <div className='pgBox whtBox'>
                            <div className="interfaceTable">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>Event Name</th>
                                          
                                            
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    
                                   
                                    <tbody>
                                    
                                        {allEventDetails.length > 0  ? allEventDetails.map((clubDetails,index)=>{
                                            return(
                                                <tr>
                                            <td>{index+1}</td>
                                            <td>{clubDetails.event_full_title}</td>
                                            
                                            
                                            
                                            <td>
                                                <div className='actionBtn flexBox nowrap'>
                                                    <button title='Edit' type='button' onClick={()=>handleUpdateMyRace(clubDetails.event_id)}><span className="material-icons-outlined">edit</span></button>
                                                </div>
                                            </td>
                                           </tr>


                                            )}): <tr className='noEvent'>
                                            <td></td>
                                            <td>Not Found Any Event Of This Event</td>
                                            
                                            
                                            
                                            <td>
                                               
                                            </td>
                                           </tr> }



                        
                                       
                                    </tbody>
                                    
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )

}
export default PaymentGateway;