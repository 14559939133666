import React, { useState, useEffect } from 'react';

import Header from "../../../Components/adminDashboard/Header/Header";
import Switch from "react-switch";
import combinedServices from "../../../shared/services/user-service";
import getLocalData from "../../../shared/localStorage/getData";
import { useForm } from 'react-hook-form';
import swal from 'sweetalert';
import { useNavigate } from "react-router-dom";
import { NavLink, useParams } from "react-router-dom";


const AdminDashboard = () => {


    let navigate = useNavigate();
    const params = useParams();


    const [isEdit, setIsEdit] = useState(false);
    const [allClub, setAllClub] = useState([]);
    const [paymentGatewayId, setPaymentGatewayId] = useState(params.id)
    const [paymentGatewayDetails, setPaymentGatewayDetails] = useState([])

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();



    useEffect(async () => {
        if (getLocalData("admin_info", "isLogin")) {
            var data = {
                page: 0,
                records_per_page: 0,
                district_id: 0

            }
            let allClubDetails = await combinedServices.getAllClub(data);
            setAllClub(allClubDetails.data);
            if (paymentGatewayId) {
                let paymentGatewayDetails = await combinedServices.getClubPaypalById(paymentGatewayId);
                setPaymentGatewayDetails(paymentGatewayDetails.data);
            }
        }
    }, [])



    const onSubmit = async (data) => {
       
        if (paymentGatewayId) {

            var updateformData = {

                club_id:data.club_id ? data.club_id : paymentGatewayId,
                paypal_client_id: data.client_key ? data.client_key : paymentGatewayId.paypal_client_id,
                paypal_client_secret: data.secret_key ? data.secret_key : paymentGatewayId.paypal_client_secret


            }
         

            let smsResponse = await combinedServices.updateClubPaypal(updateformData);
            if (smsResponse.status == 200) {
                swal("Yes", "Sms Update Successfully", "success")
                    .then((value) => {

                        navigate("../payment-gateway", { replace: true });

                    })


            } else {

                swal("!Opps", smsResponse.msg, "error")
            }
        } else {

            var formData = {
                club_id: data.club_id,
                paypal_client_id: data.client_key,
                paypal_client_secret: data.secret_key


            }

            let smsResponse = await combinedServices.addClubPaypal(formData);
            if (smsResponse.status == 200) {
                swal("Yes", "Paypal Details Add Successfully", "success")
                    .then((value) => {

                        navigate("../payment-gateway", { replace: true });

                    })


            } else {

                swal("!Opps", smsResponse.msg, "error")
            }
        }


    }
    const handleDeleteSmsTemplate = async (id) => {


        let smsResponse = await combinedServices.deleteSmsTemplate(id);
        if (smsResponse.status == 200) {
            swal("Yes", "Sms Delete Successfully", "success")
                .then((value) => {

                    //getALlSmsTemplate();

                })


        }

    }


    return (
        <>
            <Header />
          
                <div className="dbAdminRight">
                    <div className="dbMid dbInner">
                        <div className="flexBox spacebetween topHdBox">
                            <div className="left">
                                <div className="dbMainHd"> Paypal Gateway</div>
                            </div>

                        </div>
                        <div className='flexBox spacebetween'>

                            {paymentGatewayId ?
                                <div className=' whtBox pgBox'>
                                    <div className='dbSubHd'>Update Paypal Gateway</div>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <ul className="flexBox editForm full">
                                            <li>
                                                <span className="fieldHd">All Club</span>
                                                <select name="club_id" className='formInput' {...register('club_id')}>
                                                    <option value="">Select Club</option>
                                                    {allClub.map((club) => {
                                                        return (
                                                            <option selected={paymentGatewayId == club.id ? "selected" : ""} value={club.id}>{club.club_name}</option>
                                                        )


                                                    })}

                                                </select>

                                            </li>
                                            <li>
                                                <span className="fieldHd">Client Key</span>
                                                <input type="text" className='formInput' placeholder='Client Key' name="client_key"  {...register('client_key')}  defaultValue={paymentGatewayDetails.paypal_client_id}/>
                                                
                                            </li>
                                            <li>
                                                <span className="fieldHd">Secret Key </span>
                                                <input type="text" className='formInput' placeholder='Secret Key ' name="secret_key"  {...register('secret_key')} defaultValue={paymentGatewayDetails.paypal_client_secret}/>
                                               
                                            </li>

                                        </ul>
                                        <div className="tar"><button type="submit" className="formBtn full">Update</button></div>
                                    </form>
                                </div>


                                : <div className=' whtBox pgBox'>
                                    <div className='dbSubHd'>Add Paypal Gateway</div>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <ul className="flexBox editForm full">
                                            <li>
                                                <span className="fieldHd">All Club</span>
                                                <select name="club_id" className='formInput' {...register('club_id')}>
                                                    <option value="">Select Club</option>
                                                    {allClub.map((club) => {
                                                        return (
                                                            <option  value={club.id}>{club.club_name}</option>
                                                        )


                                                    })}

                                                </select>

                                            </li>
                                            <li>
                                                <span className="fieldHd">Client Key</span>
                                                <input type="text" className='formInput' placeholder='Client Key' name="client_key" {...register('client_key', { required: true })} />
                                                {errors.client_key && <p className="errorMsg">Please enter client key</p>}
                                            </li>
                                            <li>
                                                <span className="fieldHd">Secret Key </span>
                                                <input type="text" className='formInput' placeholder='Secret Key ' name="secret_key"  {...register('secret_key', { required: true })} />
                                                {errors.secret_key && <p className="errorMsg">Please enter secret key</p>}
                                            </li>

                                        </ul>
                                        <div className="tar"><button type="submit" className="formBtn full">Create</button></div>
                                    </form>
                                </div>
                            }
                        </div>
                    </div>
                </div>
        
        </>

    )

}
export default AdminDashboard;