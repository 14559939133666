import Header from "../../Components/Layout/Header/Header";
import Footer from "../../Components/Layout/Footer/Footer";
import "./AddEvent.scss";
import React, { useState, useEffect, useReducer } from 'react';
import moment from "moment"
import { Tooltip } from 'reactstrap';
import { NavLink, Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import combinedServices from "../../shared/services/user-service";
import getLocalData from "../../shared/localStorage/getData";
import userConstants from "../../shared/constants/user-constants";
import { useForm } from "react-hook-form";
import swal from 'sweetalert';



const AddEventStep2 = () => {
    const params = useParams();
    const [eventId, setEventId] = useState('');
    const [eventDetails, setEventDetails] = useState([]);
    const [eventClassDetails, setEventClassDetails] = useState([])
    const [tooltipOpen, setTooltipOpen] = React.useState(false);
    const [tooltipOpen2, setTooltipOpen2] = React.useState(false);
    const [userDetail, setUserDetail] = useState([]);
    const [baseURL, setBaseUrl] = useState(userConstants.baseURL);
    const [availableFrequency, setAvailableFrequency] = useState([]);
    const [participantId, setParticipateId] = useState('');
    const [primaryFreqArr, setPrimaryFreqArr] = useState([])
    const [alternateFreqArr, setAlternateFreqArr] = useState([])
    const [error, setError] = useState(false)
    const [primaryFreqArrValue, setPrimaryFreqArrValue] = useState([])
    const [alternateFreqArrValue, setAlternateFreqArrValue] = useState([])
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();

    useEffect(async () => {
        if (getLocalData("user_info", "isLogin")) {
            var userDetails = getLocalData("user_info", "all").userData.login_user_detail.data.user;
            setUserDetail(userDetails)
        }
        setEventId(params.id);
        setParticipateId(params.id)
        let eventClassResponse = await combinedServices.getEventParticipateClass(params.id);
        // let availableFrequency = await combinedServices.getAvailableFrequency(params.id);
        setEventId(eventClassResponse.data.event.id);
        setEventDetails(eventClassResponse.data.event);
        setEventClassDetails(eventClassResponse.data.eventParticipantClasses);

    }, [])

    const handleFrequency = (e, selectFrq) => {


    }


    const onSubmit = (data) => {


    }

    const handlePrimaryFreqyency = (e, id, classId, className) => {

        //const isFrequency = primaryFreqArr.find(element => element.class_id == classId);
        var primaryfreqData = primaryFreqArrValue;
        var alterFreqData = alternateFreqArrValue;

        primaryfreqData = {
            id: id,
            event_id: eventId,
            event_participant_id: participantId,
            class_id: classId,
            primary_frequency: e.target.value,
            class_name: className

        }

        // frequencyArr.push(data);
        setPrimaryFreqArrValue(primaryfreqData);

        combineTwoFrequency(primaryfreqData, alterFreqData);


    }



    const handleAlternateFreqyency = (e, id, classId, className) => {
        //var frequencyArr = alternateFreqArr;
        var primaryfreqData = primaryFreqArrValue;
        var alterFreqData = alternateFreqArrValue;


        alterFreqData = {
            id: id,
            event_id: eventId,
            event_participant_id: participantId,
            class_id: classId,
            alternate_frequency: e.target.value,
            class_name: className

        }
        setAlternateFreqArrValue(alterFreqData);

        //frequencyArr.push(data);
        // var found = primaryFreqArr.find((val, index) => {
        // Stops at 0
        //return data.includes(val)
        //})



        //setAlternateFreqArr(frequencyArr);
        combineTwoFrequency(primaryfreqData, alterFreqData)



    }
    const combineTwoFrequency = (data1, data2) => {
        var primaryFreq = primaryFreqArr;
        var alternateFreq = alternateFreqArr;


        if (data1.class_id == data2.class_id) {
            if (data1.primary_frequency != data2.alternate_frequency) {

                //primaryFreq.push(data1)
                //setPrimaryFreqArr(primaryFreq)
                //alternateFreq.push(data2);
                //setAlternateFreqArr(alternateFreq)

            } else {

                swal("Opps", "Primary and alternate frequency is not same ", "error")

            }





        }




    }

    const handleAddEventStep2 = async () => {
        const freqArr = primaryFreqArr.map(itm => ({
            ...alternateFreqArr.find((item) => (item.class_id === itm.class_id) && item),
            ...itm
        }));



        let eventClassResponse = await combinedServices.addEventParticipateStep3();
        window.location.href = "/event/add-event-step3/" + participantId;

    }


    return (
        <>
            <div className="mainCon">
                <Header />
                <div className="innerBanner event">
                    <div className="container flexBox">
                        <div>
                            <div className="innerHd">Events</div>
                            <div className="bradcrumb"><a href="/">Home</a><a href="/event-management">Events</a></div>
                        </div>
                    </div>
                </div>

                <div className="pb50 grayBg">
                    <div className="container">

                        <div className="whtBox">
                            {eventDetails.length > 0 &&
                                <div className="addEventTop flexBox spacebetween itemCenter">
                                    <div className="left flexBox itemCenter nowrap">
                                        <img src={
                                        eventDetails.image.slice(0, 16) === "https://nambaweb" ? eventDetails.image :
                                            baseURL + "images/" == eventDetails.image
                                                ? "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/logo.png"
                                                : eventDetails.image
                                    } alt="" />
                                        {eventDetails.full_title}<br />
                                        {eventDetails.host_club.club_name}
                                    </div>
                                    <div className="right">
                                        {moment(eventDetails.registration_open).format('LL')} - {moment(eventDetails.registration_close).format('LL')}<br />
                                        {eventDetails.full_title}
                                    </div>
                                </div>
                            }
                            <div className="addEventMid">
                                <div className="addEventTable">
                                    <table>
                                        <tr>
                                            <th>CLASSES ENTERING</th>
                                            <th>COST</th>
                                        </tr>

                                        {eventClassDetails.map((className, index) => {

                                            return (

                                                <tr>
                                                    <td>{className.class.name}</td>
                                                    <td>{Number(eventDetails.boat_charge).toFixed(2)}</td>
                                                </tr>


                                            )


                                        })}


                                        <tr>
                                            <td><strong>Total Amount Due</strong></td>
                                            <td><strong> {(Number(eventClassDetails.length) * Number(eventDetails.registration_charge) + Number(eventDetails.boat_charge)).toFixed(2)}</strong></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div className="addEventMid">
                                <div className="paymentMetHd">REGISTRATION INFORMATION</div>
                                <div className="eventInner">
                                    <div className="pb20">Please complete the information below. All items in RED are required fields.</div>
                                    <ul className="flexBox eventForm">
                                        <li>
                                            <div className="lft">First Name Only:</div>
                                            <div className="rgt d-flex itemCenter">
                                                <input type="text" className="formInput" value={userDetail.user_firstname} />
                                                <span className="material-icons-outlined infoIcon" id="FirstName">info</span>
                                                <Tooltip isOpen={tooltipOpen} placement="left" target="FirstName" toggle={() => { setTooltipOpen(!tooltipOpen) }}>Message Goes Here</Tooltip>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="lft">Last Name Only:</div>
                                            <div className="rgt d-flex itemCenter">
                                                <input type="text" className="formInput" value={userDetail.user_lastname} />
                                                <span className="material-icons-outlined infoIcon" id="LastName">info</span>
                                                <Tooltip isOpen={tooltipOpen2} placement="left" target="LastName" toggle={() => { setTooltipOpen2(!tooltipOpen2) }}>Message Goes Here</Tooltip>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="lft">Telephone:</div>
                                            <div className="rgt"><input type="text" className="formInput" value={userDetail.user_phone} /></div>
                                        </li>
                                        <li>
                                            <div className="lft">Handicapped Pit Space <span>(Documentation Required Upon Arrival):</span></div>
                                            <div className="rgt">
                                                <select className="formSelect">
                                                    <option>I do not require handicapped pit space</option>
                                                </select>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>



                            {eventClassDetails.map((className, index) => {

                                return (
                                    <div className="addEventMid">
                                        <div className="paymentMetHd">{className.class.name}</div>
                                        <div className="eventInner">


                                            <ul className="flexBox eventForm">
                                                <li>
                                                    <div className="lft">Available Primary Frequencies:</div>
                                                    <div className="rgt">
                                                        <select className="formSelect" name="availbalePrimaryFrequency" onChange={(e) => handlePrimaryFreqyency(e, className.id, className.class_id, className.class.name)}>
                                                            <option>Please select your association</option>
                                                            {className.available_frequencies ? className.available_frequencies.map((frequency, index) => {
                                                                return (
                                                                    <option value={frequency}>{frequency}</option>
                                                                )
                                                            }) : ''}
                                                        </select>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="lft">Available Alternate Frequencies:</div>
                                                    <div className="rgt">
                                                        <select className="formSelect" name="availbaleAlternateFrequency" onChange={(e) => handleAlternateFreqyency(e, className.id, className.class_id, className.class.name)}>
                                                            <option>Please select your association</option>

                                                            {className.available_frequencies ? className.available_frequencies.map((frequency, index) => {
                                                                return (
                                                                    <option value={frequency}>{frequency}</option>
                                                                )

                                                            }) : ''}
                                                        </select>
                                                    </div>
                                                </li>
                                            </ul>

                                        </div>
                                    </div>

                                )

                            })}


                            <div className="addEventBot">
                                <div className="paymentType pad0">
                                    <button type="submit" className="continueBtn" onClick={handleAddEventStep2}>Continue</button>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>

    )

}
export default AddEventStep2;