import React, { useState, useEffect } from "react";
import combinedServices from "../../../shared/services/user-service";
import getLocalData from "../../../shared/localStorage/getData";
import { useParams } from "react-router-dom";
import "./UserResult.scss";
import Header from "../../../Components/clubDashboard/Header/Header";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import getRemoveData from "../../../shared/localStorage/removeData";


const UserResult = () => {
  const [userId, setUserId] = useState("");
  const [myResultDetails, setMyResultDetails] = useState([]);
  const [scoreRacer, setScoreRacer] = useState([]);
  const params = useParams();
  const [eventId, setEventId] = useState(params.id);
  const [myClass, setMyClass] = useState([]);
  const [eventName, setEventName] = useState("");
  const [isLoader, setIsLoader] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setEventId(params.id);
      var myResultData = [];

      if (getLocalData("user_info", "isLogin")) {
        var user_id = getLocalData("user_info", "all").userData.login_user_detail
          .data.user.id;
        setUserId(user_id);
        let myResultResponse = await combinedServices.getMyResultDetails(user_id);
        if(myResultResponse.status==401){
          swal(myResultResponse.msg, "Please Re-login", "warning").then(() => {
            getRemoveData("user_info");
            localStorage.clear();
            window.location.href = "/login";
          });
        }

        myResultResponse.data.map((myResult, index) => {
          var data = {
            full_title: myResult.full_title,
            event_start: myResult.event_start,
            district_id: myResult.district_id,
            id: myResult.id,
          };

          var event_classes = myResult.event_participants[0].classes;
          data.event_classes = event_classes;
          myResultData.push(data);
        });

        setMyResultDetails(myResultData);
      }

      const event = myResultData?.find((event) => event.id == eventId);
      setEventName(event.full_title);

      var score = await combinedServices.getScore(eventId);
      let val = score;
      const sortedData = score?.sort((a, b) => {
        if (a.class_Name === b.class_Name) {
          return b.total - a.total;
        }
        return a.class_Name.localeCompare(b.class_Name);
      });

      setScoreRacer(sortedData);
      setIsLoader(false);
    };

    fetchData();
  }, []);

  return (
    <>
      <Header />
      <div className="dbRight">
      <div className="flexBox resultMid">
        <div className="resultMidLeft ">
        <div className="flexBox spacebetween itemCenter"></div>
          <div
            className="dbMainHd"
            style={{color: "#014d9b" }}
          >
            {eventName}
          </div>
          <div className="dbWhtBox">
            {isLoader ? (
             <div className="text-center mt-4 loadMore">
             <Spinner color="primary" />
           </div>
            ) : scoreRacer.length > 0 ? (
              <>
              <div className="resultTable">
              <table>
                <thead>
                  <tr>
                    <th style={{ fontSize: "16px" }}>Class</th>
                    <th style={{ fontSize: "16px" }}>Round 1</th>
                    <th style={{ fontSize: "16px" }}>Round 2</th>
                    <th style={{ fontSize: "16px" }}>Round 3</th>
                    <th style={{ fontSize: "16px" }}>Round 4</th>
                    <th style={{ fontSize: "16px" }}>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {scoreRacer
                    ?.filter((scr) => scr.participant_Id == userId)
                    .map((x, index) => (
                      <tr key={index}>
                        <td style={{ fontSize: "14px" }}>{x.class_Name}</td>
                        <td style={{ fontSize: "14px" }}>{x.round1_Score}</td>
                        <td style={{ fontSize: "14px" }}>{x.round2_Score}</td>
                        <td style={{ fontSize: "14px" }}>{x.round3_Score}</td>
                        <td style={{ fontSize: "14px" }}>{x.round4_Score}</td>
                        <td style={{ fontSize: "14px",fontWeight:"600" }}>{x.total}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
              </div>
              </>
            ) : (
              <div>
                <a href="#" className="showResult">
                  <span className="name">Not Found Any My Result List</span>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default UserResult;
