import React, { useCallback, useEffect, useState } from "react";
import { PDFExport } from "@progress/kendo-react-pdf";
import combinedServices from "../../shared/services/user-service";
import { useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";

function DownloadScorePdf() {
  const pdfExportComponent = React.useRef(null);
  const location = useLocation();
  const eventId = location.pathname.split("/")[2];
  const page = location.pathname.split("/")[1];
  const [scoreData, setScoreData] = useState([]);
  const [rounds, setRounds] = useState(0);
  const [eventTitle, setEventTitle] = useState("");
  const driverWidth = "180px";
  const roundWidth = rounds <= 4 ? "50px" : "40px";

  useEffect(async () => {
    const eventDetails = await combinedServices.getClassByEventId(eventId);
    const totalRounds = eventDetails.data.rounds_per_class;
    setEventTitle(eventDetails.data.full_title);
    setRounds(totalRounds);

    //getting the participant of the events
    let eventParticipantResponses =
      await combinedServices.getEventParticipantForHeat({ event_id: eventId });
    // console.log("eventparticipantresponses", eventParticipantResponses.data);

    //getting classes only
    let eventClassesDataResponse = (
      await combinedServices.countByClasses(eventId)
    ).data.participants;

    //sorting this eventclasses data according to run date
    eventClassesDataResponse = eventClassesDataResponse
      .filter((cls) => cls.class_status == "MADE")
      .sort((a, b) => {
        const dateA = new Date(a.run_date);
        const dateB = new Date(b.run_date);
        if (dateA.getTime() === dateB.getTime()) {
          return a.order_key - b.order_key;
        } else {
          return dateA - dateB;
        }
      });

    //fetching the heatsheet
    let heatsheetResponse = await combinedServices.getAllHeatSheet({
      event_id: eventId,
    });

    //fetching all the scores of the event
    let eventScoreRespone = await combinedServices.getScoreByEventID({
      event_id: eventId,
    });

    //creating the array to bind the final data
    let scoreArray = [];

    //adding the classes to the array
    await eventClassesDataResponse.forEach((eventClass) => {
      scoreArray.push({
        class_name: eventClass.class_name,
        class_id: eventClass.class_id,
        participants: [],
      });
    });

    //adding the participant
    heatsheetResponse.data.forEach((heat) => {
      for (let participant of heat.participants) {
        //finding the participant's name and his id
        const foundClassIndex = scoreArray.findIndex(
          (item) =>
            item.class_id == participant.event_participant_class.class.id
        );

        const participantExists = scoreArray[
          foundClassIndex
        ]?.participants.find(
          (p) =>
            p.participantId ===
            participant.event_participant_class.event_participant.user.id
        );

        if (!participantExists) {
          // finding the score item to set the score from round1 to round7
          let foundScoreItem = eventScoreRespone.data.find(
            (item) =>
              item.participant_Id ==
                participant.event_participant_class.event_participant.user.id &&
              item.class_Id == participant.event_participant_class.class.id
          );

          //here we creating the object which stores the participant details and score and pushing to the array
          scoreArray[foundClassIndex]?.participants.push({
            participantName:
              participant.event_participant_class.event_participant.user
                .user_firstname +
              " " +
              participant.event_participant_class.event_participant.user
                .user_lastname,
            participantId:
              participant.event_participant_class.event_participant.user.id,
            round1: foundScoreItem ? foundScoreItem.round1_Score : 0,
            round2: foundScoreItem ? foundScoreItem.round2_Score : 0,
            round3: foundScoreItem ? foundScoreItem.round3_Score : 0,
            round4: foundScoreItem ? foundScoreItem.round4_Score : 0,
            round5: foundScoreItem ? foundScoreItem.round5_Score : 0,
            round6: foundScoreItem ? foundScoreItem.round6_Score : 0,
            round7: foundScoreItem ? foundScoreItem.round7_Score : 0,
            totalScore: foundScoreItem ? foundScoreItem.total : 0,
          });
        }
      }
    });

    //sorting the scores according to totalscore in asc order within in each class

    scoreArray.forEach((item) => {
      item.participants.sort((a, b) => {
        return b.totalScore - a.totalScore;
      });
    });

    setScoreData(scoreArray);
  }, [eventId]);

  const renderTableHeaders = useCallback(() => {
    const headers = [];
    for (let i = 1; i <= rounds; i++) {
      headers.push(
        <td
          key={i}
          style={{
            fontWeight: "500",
            width: roundWidth,
            fontSize: "13px",
          }}
        >
          R{i}
        </td>
      );
    }
    return headers;
  }, [rounds]);

  return (
    <div>
      <div className="example-config">
        <button
          style={{
            backgroundColor: "#0095fd",
            color: "white",
            padding: "10px",
            borderRadius: "8px",
          }}
          onClick={() => {
            if (pdfExportComponent.current) {
              pdfExportComponent.current.save();
            }
          }}
        >
          Download PDF
        </button>
      </div>

      <div
        style={
          page == "download-pdf-score"
            ? {}
            : { position: "absolute", left: "-1000px", top: 0 }
        }
      >
        <PDFExport
          paperSize="A4"
          margin={{
            top: "0.4in",
            left: "0.4in",
            right: "0.4in",
            bottom: "0.4in",
          }}
          ref={pdfExportComponent}
          fileName={`${eventTitle}_scores.pdf`}
          repeatHeaders={true} // Add repeatHeaders property
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <img src={logo} style={{ height: "100px", width: "100px" }} />
            <p style={{ margin: "20px", textAlign: "center" }}>{eventTitle}</p>
            <img src={logo} style={{ visibility: "hidden" }} />
          </div>

          <table style={{ width: "100%" }}>
            <thead>
              <tr
                style={{
                  backgroundColor: "",
                  color: "white",
                  width: "100%",
                }}
              >
                <th
                  style={{
                    fontWeight: "500",
                    padding: "5px",
                    width: driverWidth,
                    fontSize: "13px",
                  }}
                ></th>
                <th
                  style={{
                    fontWeight: "500",
                    padding: "5px",
                    textAlign: "center",
                    fontSize: "13px",
                  }}
                >
                  Total
                </th>
                {renderTableHeaders()}
              </tr>
            </thead>
            <tbody>
              {scoreData?.map((score, index) => (
                <React.Fragment key={index}>
                  <tr
                    style={{
                      backgroundColor: "gray",
                      color: "white",
                      width: "100%",
                    }}
                  >
                    <td
                      style={{
                        fontWeight: "500",
                        padding: "5px",
                        width: driverWidth,
                        fontSize: "13px",
                      }}
                    >
                      {score.class_name}
                    </td>
                    <td
                      style={{
                        fontWeight: "500",
                        padding: "5px",
                        textAlign: "center",
                        fontSize: "13px",
                      }}
                    >
                      Total
                    </td>
                    {renderTableHeaders()}
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "12px",
                        width: driverWidth,
                      }}
                    ></td>
                  </tr>
                  {score.participants.map((item, index) => (
                    <tr key={index}>
                      <td style={{ fontSize: "12px", width: driverWidth }}>
                        {item.participantName}
                      </td>
                      <td style={{ fontSize: "12px", textAlign: "center" }}>
                        {item.totalScore}
                      </td>
                      <td
                        style={{
                          width: roundWidth,
                          fontSize: "12px",
                        }}
                      >
                        {item.round1}
                      </td>
                      <td
                        style={{
                          width: roundWidth,
                          fontSize: "12px",
                        }}
                      >
                        {item.round2}
                      </td>
                      {rounds >= 3 ? (
                        <td
                          style={{
                            width: roundWidth,
                            fontSize: "12px",
                          }}
                        >
                          {item.round3}
                        </td>
                      ) : (
                        <></>
                      )}
                      {rounds >= 4 ? (
                        <td
                          style={{
                            width: roundWidth,
                            fontSize: "12px",
                          }}
                        >
                          {item.round4}
                        </td>
                      ) : (
                        <></>
                      )}
                      {rounds >= 5 ? (
                        <td
                          style={{
                            width: roundWidth,
                            fontSize: "12px",
                          }}
                        >
                          {item.round5}
                        </td>
                      ) : (
                        <></>
                      )}
                      {rounds >= 6 ? (
                        <td
                          style={{
                            width: roundWidth,
                            fontSize: "12px",
                          }}
                        >
                          {item.round6}
                        </td>
                      ) : (
                        <></>
                      )}
                      {rounds >= 7 ? (
                        <td
                          style={{
                            width: roundWidth,
                            fontSize: "12px",
                          }}
                        >
                          {item.round7}
                        </td>
                      ) : (
                        <></>
                      )}
                    </tr>
                  ))}
                  <tr>
                    <td>
                      <br />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </PDFExport>
      </div>
    </div>
  );
}

export default DownloadScorePdf;
