import React, { useState, useEffect } from "react";
//import "./PaymentGateway.scss";
import Header from "../../../Components/adminDashboard/Header/Header";
import Switch from "react-switch";
import combinedServices from "../../../shared/services/user-service";
import getLocalData from "../../../shared/localStorage/getData";
import getRemoveData from "../../../shared/localStorage/removeData";
import { NavLink, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { Spinner } from "reactstrap";

const PaymentGateway = () => {
  let navigate = useNavigate();
  const [allEvent, setAllEvent] = useState([]);

  useEffect(async () => {
    if (getLocalData("admin_info", "isLogin")) {
      var data = {
        page: 1,
        records_per_page: 1000,
      };

      let eventResponse = await combinedServices.getAllEventForAdmin(data);
      if (eventResponse.status == 401) {
        swal(eventResponse.msg, "Please Re-login", "warning").then(() => {
          getRemoveData("user_info");
          localStorage.clear();
          window.location.href = "/login";
        });
      }
      if (eventResponse.status == 200) {
        setAllEvent(eventResponse.data);
      }
    }
  }, []);

  const handleJoinEventStep1 = (id) => {
    //participants

    navigate("../join-event-step1/" + id, { replace: true });
  };

  const handleParticipants = (id) => {
    navigate("../participants/" + id, { replace: true });
  };

  return (
    <>
      <Header />

      <div className="dbAdminRight">
        <div className="dbMid dbInner">
          <div className="flexBox spacebetween topHdBox"></div>
          <div className="flexBox spacebetween">
            <div className="pgBox whtBox">
              <div className="interfaceTable">
                <table>
                  <thead>
                    <tr>
                      <th>Sr. No</th>
                      <th>Event Name</th>
                      <th>Event Host Club Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allEvent.map((value, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{value.event_full_title}</td>
                        <td>{value.hostClub_club_name}</td>

                        <td>
                          <div>
                            <button
                              title="Edit"
                              type="button"
                              className="button-event"
                              onClick={() =>
                                handleJoinEventStep1(value.event_id)
                              }
                            >
                              Join Event
                            </button>
                            <button
                              title="Edit"
                              type="button"
                              className="button-event"
                              onClick={() => handleParticipants(value.event_id)}
                            >
                              Participants
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PaymentGateway;
