import React, { useState, useEffect } from 'react';
import Header from "../../../Components/clubDashboard/Header/Header";
import Switch from "react-switch";
import combinedServices from "../../../shared/services/user-service";
import getLocalData from "../../../shared/localStorage/getData";
import { useForm } from 'react-hook-form';
import swal from 'sweetalert';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";



const AdminDashboard = () => {


    let navigate = useNavigate();
    const params = useParams();






    const [images, setImages] = useState([]);
    const [imageURLS, setImageURLs] = useState([]);
    const [imagePreviewURLS, setImagePreviewURLs] = useState([]);
    const [points, setPoints] = useState("");
    const [allPoints, setAllPoints] = useState([]);
    const [eventId, setEventId] = useState(params.id);

    useEffect(() => {
        if (images.length < 1) return;
        const newImageUrls = [];
        const newImagePreviewUrls = [];
        images.forEach((image) => {

            let img = URL.createObjectURL(image);

            newImagePreviewUrls.push(img)
        })

        setImagePreviewURLs(newImagePreviewUrls)
        //setImageURLs(newImageUrls);

    }, [images]);

    function onImageChange(e) {

        setImages([...e.target.files]);
    }

    const onPointsChange = (e) => {
       
            setPoints(e.target.value)
        

        
    }


    const getAllPoints = () => {
        if(points){
            let pointArr = [...allPoints];
            pointArr.push(points)
    
            setAllPoints(pointArr)
            setPoints("")


        }
       
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (images.length == 0) {

            swal("!Opps", "Please Upload Image First", "error")
        } else if (allPoints.length == 0 ) {

            swal("!Opps", "Please Enter Highlight Point", "error")
        } else {




            const formdata = new FormData();
            let uploadFiles = [];
            var data = {};
            data.points = allPoints ;
            data.event_id = Number(eventId);
            


            images.map((image) => {

                formdata.append('images', image)
            })


            let imageUploadRes = await combinedServices.uploadMultipleImage(formdata);
            if (imageUploadRes.status == 200) {
                //setImageURLs(response.data.file_name)
                
                data.image_url = imageUploadRes.data.data.file_name ;

            } else {

                swal("!Opps", imageUploadRes.msg, "error")
            }

            //console.log(uploadFiles)


            let response = await combinedServices.addEvnetHighlights(data);
            if(response.status == 200){
                swal("Successfully Create Highlight For This Event").then(()=>{
                    navigate("../highlights/"+eventId, { replace: true });

                })

            }else{
                swal("!Opps", response.msg,"error")
            }

            

        }


    }
    const handleDeletePoints = (value) => {


        //var points = points.filter((item) => item != value);
        var arr = allPoints.filter(item => item !== value)
        setAllPoints(arr)
        
    }



    


    return (
        <>
            <Header />

            <div className="dbAdminRight">
                <div className="dbMid dbInner">
                    <div className="flexBox spacebetween topHdBox">
                        <div className="left">
                            <div className="dbMainHd"> Highlights</div>
                        </div>

                    </div>
                    <div className='flexBox spacebetween'>

                        <div className=' whtBox pgBox'>
                            <div className='dbSubHd'>Add Event Highlight</div>
                            <form>
                                <ul className="flexBox editForm full">

                                    <li>
                                        <span className="fieldHd">Highlight Images</span>
                                        <input type="file" className='formInput' multiple onChange={onImageChange} />

                                    </li>
                                    <li>
                                        <span className="fieldHd">Highlight Points</span>
                                        <input type="text" className='formInput' onChange={onPointsChange} placeholder='Highlight Point' value={points} name="points" />
                                        <div>
                                            {allPoints.length > 0 ? allPoints.map((value, index) => {
                                                return (
                                                    <>
                                                        {index == 0 ? value : value}  <span className="close-btn" onClick={() => handleDeletePoints(value)}>&times;</span>

                                                    </>)


                                            }) : ""}
                                        </div>
                                    </li>
                                    <li>
                                        <span className="fieldHd"> </span>
                                        <button type="button" className='blueBtn' style={{ "margin-top": "20px" }} onClick={getAllPoints}>Add Points</button></li>



                                </ul>
                                <div className="tar"><button type="submit" className="formBtn full" onClick={handleSubmit}>Create</button></div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>

        </>

    )

}
export default AdminDashboard;