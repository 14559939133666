
import React, { useState, useEffect, PropTypes } from 'react';
import Header from "../../../Components/clubDashboard/Header/Header";
import "./CreateEvent.scss";
import DatePicker from "react-datepicker";
import { set, useForm } from "react-hook-form";
import combinedServices from "../../../shared/services/user-service";
import getLocalData from "../../../shared/localStorage/getData";
import "react-datepicker/dist/react-datepicker.css";
import Multiselect from 'multiselect-react-dropdown';
//import moment from "moment";
import RichTextEditor from 'react-rte';
import swal from 'sweetalert';
import userConstants from "../../../shared/constants/user-constants";
import { useNavigate } from "react-router-dom";
import { Spinner } from 'reactstrap';
import SearchPage from "../../../Components/clubDashboard/Header/SearchPage";
import {Editor, EditorState} from 'draft-js';
import moment from 'moment-timezone';
import getRemoveData from "../../../shared/localStorage/removeData";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';

moment.tz.setDefault("timeZone");
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

const CreateEvent = () => {
    let navigate = useNavigate();
    
    const [errorMsg, setErrorMsg] = useState([]);
    const [errorMessage2, setErrorMessage2] = useState('');
    const [errorMessage1, setErrorMessage1] = useState('');
    const [error, setError] = useState('');
    const[errorimg,setErrorImg]=useState('')
    const [successMsg, setSuccessMsg] = useState("");
    const [distric, setDistric] = useState([]);
    const [location, setLocation] = useState([]);
    const [classes, setClass] = useState([]);
    const [currentDistric, setCurrentDistric] = useState('');
    const [selectedClasses, setSelectedClass] = useState([]);
    const [selectedShootOutClasses, setSelectedShootOutClasses] = useState([]);
    const [locationAccordingToDistric, setLocationAccordingToDistric] = useState('')
    const [hotelCount, setHotelCount] = useState('1');
    const [hotelDetailForm, setHotelDetailForm] = useState([{ name: "", url: "" }]);
    const [districId, setDistricId] = useState(0.00);
    const [venueId, setVenueId] = useState(0.00);
    const [venueName, setVanueName] = useState('');
    //const [editorData, editorData] = useState(RichTextEditor.createEmptyValue());
    const [eventText, setEventDetail] = useState('');

    const [clubMember, setClubMember] = useState([]);
    const [exbitionClasses, setExbitionClasses] = useState('');
    const [minimumBoatError, setMinimumBoatError] = useState('');
    const [roundsError, setRoundsError] = useState('');
    const [clubDetail, setClubDetails] = useState([]);
    const [editorData, setEditorData] = useState('');

    const [uploadImageUrl, setUploadImageUrl] = useState('');
    const[eventTemplateName,setEventTemplateName]=useState('')
    const[clubSelect,setClubSelect]=useState('')
    const[templateOption,setTemplateOption]=useState([])
    const [eventName, setEventName] = useState('')
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [openingDate, setOpeningDate] = useState(new Date());
    const [closingDate, setClosingDate] = useState(new Date());
    const [firstBoatFee, setFirstBoatFee] = useState(0);
    const [firstBoaEntrytFee, setFirstBoatEntryFee] = useState(0);
    const [allSelectedClass, setAllSelectedClasses] = useState([]);
    const [allSelectedShootOutClass, setAllSelectedShootOUtClasses] = useState([]);

    const [minimumBoatToMakeAClass, setMinimumBoatToMakeAClass] = useState(0);
    const [maximumBoatToHeat, setMaximumBoatToHeat] = useState(0);
    const [numberOfRound, setNumberOfRound] = useState(0);
    const [memberOfShootoutRound, setMemberOfShootoutRound] = useState(0);
    const [contactDetails, setContactDetails] = useState('');
    const [mailInPayments, setMailInPayment] = useState(false);
    const [paypalEntries, setPaypalEntries] = useState(false);
    const [payAtRace, setPayAtRace] = useState(false);
    const [eventImage, setEventImage] = useState('');
    const [formValue, setFormValues] = useState({})
    const [baseURL, setBaseUrl] = useState(userConstants.baseURL);
    const [smsNotification, setSmsNotification] = useState(false);
    const [sactions, setSaction] = useState('');
    const [mailCountry, setMailCountry] = useState('');
    const [mailState, setMailState] = useState('');
    const [mailCity, setMailCity] = useState('');
    const [mailAddress, setMailAddress] = useState('');
    const [hostClubId, setHostClubId] = useState('');
    const [deltrue,setDeltrue]=useState(false)


    const [mailZipcode, setMailZipcode] = useState('');
    const [preview, setPreview] = useState();
    const [isLoder, setIsLoder] = useState(true);
    const [isPaypal, setIsPaypal] = useState(false);
    const [numberForMinimumHeat,setNumberforMinimumHeat] = useState(["1","2","3","4","5","6","7","8"]);
    const [numberForMaximumHeat,setNumberforMaximumHeat] = useState(["1","2","3","4","5","6","7","8"]);

    const eventImg = "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/event.jpg"

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();

    useEffect(async () => {


        if (getLocalData("user_info", "isLogin")) {
            setIsLoder(false)
            var userDistricId = getLocalData("user_info", "all").userData.login_user_detail.data.user.district.uzip_namba_district;
            var userId = getLocalData("user_info", "all").userData.login_user_detail.data.user.id;
            var userClubDetails = getLocalData("user_info", "all").userData.login_user_detail.data.user.clubOfficerIn;
            //setDistricId(userDistricId)
            // getHostClubDetails(userClubDetails[0].id)

            setCurrentDistric(userDistricId);
            handleLocationByDistric(userDistricId);
            ///setClubMember(userClubDetails)
            setHostClubId(userClubDetails[0].id)

            checkPaypalCredential(userClubDetails[0].id)


        


        let response = await combinedServices.getAllDistric();
        if (response.status == 401) {
            swal(response.msg, "Please Re-login", "warning").then(() => {
              getRemoveData("user_info");
              localStorage.clear();
              window.location.href = "/login";
            });
          }
        let classResponse = await combinedServices.getAllClasses(userDistricId);

        const clsDropdown = [];
        classResponse.data.filter((x)=>x.status==="active").map((cls) => {
            var categoryName = cls.class_category_id == "6" ? "EX-" + cls.name : cls.name ;
            var options = {
                name:categoryName,
                id:cls.id,
            }
            clsDropdown.push(options)

        })
        setClass(clsDropdown)
        setDistric(response.data);
    }
   
    }, [])

    const checkPaypalCredential = async(club_id) =>{
        let dropdwnOption= await combinedServices.getAllEventTemplateDetails(club_id);
        setTemplateOption(dropdwnOption)
      
        let paymentGatewayDetails = await combinedServices.getClubPaypalByIdForUser(club_id);
        
        if(paymentGatewayDetails.status == 200){

            setIsPaypal(true)
        }else{
            setIsPaypal(false)
        }

        
    }

    const handleSMSNotification = (e) => {

        setSmsNotification(e.target.checked)
        document.getElementById("enable_sms_notification").checked = e.target.checked;

    }
    const handleSactions = (e) => {

        setSaction(e.target.value)
        if (e.target.value == "namba") {
            document.getElementById("namba").checked = true;
            document.getElementById("unsaction").checked = false;
        } else {
            document.getElementById("namba").checked = false;
            document.getElementById("unsaction").checked = true;
        }

    }

    const getHostClubDetails = async (id,template_name) => {

        let clubResponse = await combinedServices.getEventTemplateDetails(id,template_name);
        // if (clubResponse.status == 401) {
        //     swal(clubResponse.msg, "Please Re-login", "warning").then(() => {
        //       getRemoveData("user_info");
        //       localStorage.clear();
        //       window.location.href = "/login";
        //     });
        //   }
        if (clubResponse.status == 200) {
            setIsLoder(false)
            let clubDetails = clubResponse.data;
            let clubRichText = clubDetails.detail;
            setVenueId(clubDetails.venue_id)
            setEventImage(clubDetails.image)
            setEventName(clubDetails.full_title);
            setFirstBoatFee(clubDetails.registration_charge);
            setFirstBoatEntryFee(clubDetails.boat_charge);
            setHotelDetailForm(clubDetails.hotels);
            setEditorData(clubRichText);
            setSmsNotification(clubDetails.enable_sms_notification)
            setSaction(clubDetails.sanctioned_by)
            setAllSelectedClasses(clubDetails.classes)
            setAllSelectedShootOUtClasses(clubDetails.shootout_classes)
            setMinimumBoatToMakeAClass(clubDetails.min_boats_per_class);
            setMailCountry(clubDetails.mail_country)
            setMailState(clubDetails.mail_state)
            setMailCity(clubDetails.mail_city)
            setMailAddress(clubDetails.mail_address)
            setMailZipcode(clubDetails.mail_zip)
            setMinimumBoatToMakeAClass(clubDetails.min_boats_per_class);
            setMaximumBoatToHeat(clubDetails.max_boats_per_heat)
            setNumberOfRound(clubDetails.rounds_per_class)
            setMemberOfShootoutRound(clubDetails.members_in_shootout)
            setContactDetails(clubDetails.contacts)
            setMailInPayment(clubDetails.allow_mail_ins);
            setPaypalEntries(clubDetails.allow_paypal_entries);
            setPayAtRace(clubDetails.allow_pay_at_race_entries);
            document.getElementById("mailInEntries").checked = clubDetails.allow_mail_ins;
      
            document.getElementById("payAtRace").checked = clubDetails.allow_pay_at_race_entries;
            document.getElementById("paypal").checked = clubDetails.allow_paypal_entries;
            document.getElementById("enable_sms_notification").checked = clubDetails.enable_sms_notification;
            if (clubDetails.sanctioned_by == "namba") {
                document.getElementById("namba").checked = true;
            } else {
                document.getElementById("unsaction").checked = true;
            }

            
        }  
            
        // }else{

        //     setIsLoder(false)  
        // }

        


    }
   


    const onSelectClass = (selectedList, selectedItem) => {
        var classIds = [];

        selectedList.map((value) => {

            classIds.push(value.id)
        })

        setSelectedClass(selectedList)

    }

    const onRemoveClass = (selectedList, removedItem) => {
        setSelectedClass(selectedList)
    }

    const onSelectShootOutClass = (selectedList, selectedItem) => {
        var classIds = [];

        selectedList.map((value) => {

            classIds.push(value.id)
        })


        setSelectedShootOutClasses(selectedList)
    }
    const onRemoveShootOutClass = (selectedList, removedItem) => {
        setSelectedShootOutClasses(selectedList)

    }
    // const handleUploadImage = (event) => {


    //     if (event.target.files && event.target.files[0]) {
    //         let img = event.target.files[0];
    //         const objectUrl = URL.createObjectURL(img);
    //         setPreview(objectUrl)
    //         const formdata = new FormData();
    //         formdata.append('image', img)
    //         setUploadImageUrl(formdata);
    //     }
    // }
    const handleUploadImage = (event) => {
        if (event.target.files && event.target.files[0]) {
          const img = event.target.files[0];
      
          // Create an Image object to check the dimensions
          const image = new Image();
          image.src = URL.createObjectURL(img);
      
          image.onload = () => {
            const width = image.width;
            const height = image.height;
      
            if (width >= 1200 && height >= 600) {
                setErrorImg('')
              // Image dimensions are valid, you can proceed
              const objectUrl = URL.createObjectURL(img);
              setPreview(objectUrl);
              const formdata = new FormData();
              formdata.append('image', img);
              setUploadImageUrl(formdata);
            } else {
              // Image dimensions are not valid, display an error message or take appropriate action
            //   alert('Image dimensions must be at least 1200px x 600px');
              setErrorImg('Image dimensions must be at least 1200px x 600px')
              // Clear the file input if needed
              event.target.value = null;
            }
          };
        }
      };
      


    const uploadImage = async (formdata) => {
        let response = await combinedServices.uploadImage(formdata);
        if (response.status == 200) {
            setUploadImageUrl(response.data.file_name)

        }


    }

    const handleLocationByDistric = async (districId) => {

        var locationArr = [];
        var response = await combinedServices.getMyClubSite(districId);

        response.data.map((value) => {

            var data = {
                id: value.id,
                venue: value.clbsite_site_name,
                address: value.clbsite_site_address1 + "," + value.clbsite_site_city + "," + value.clbsite_site_state + "," + value.clbsite_site_country + "," + value.clbsite_site_zip,
            }

            locationArr.push(data)

        })

        setLocation(locationArr)
    }


    const handleLocation = (event) => {
        var address = event.target.options[event.target.selectedIndex].dataset.address;
        var venueName = event.target.options[event.target.selectedIndex].dataset.name;

        setVenueId(event.target.value);
        setVanueName(venueName)
        setLocationAccordingToDistric(address)
    }

    let handleChange = (i, e) => {
        let newFormValues = [...hotelDetailForm];
        newFormValues[i][e.target.name] = e.target.value;
        setHotelDetailForm(newFormValues);
    }

    let addFormFields = () => {
        setHotelDetailForm([...hotelDetailForm, { name: "", url: "" }])
    }

    let removeFormFields = (i) => {
        let newFormValues = [...hotelDetailForm];
        newFormValues.splice(i, 1);
        setHotelDetailForm(newFormValues)
    }
    const handleChangeReportPeragraph = (value) => {


        //setEventDetail(value)
        setEventDetail(value)




    }
    const handleMailInPayment = (e) => {
     
        document.getElementById("mailInEntries").checked = e.target.checked;
        setMailInPayment(e.target.checked)
    }


    const handlePaypalEntries = (e) => {


        setPaypalEntries(e.target.checked)

        document.getElementById("paypal").checked = e.target.checked;


    }
    const handlePayAtRace = (e) => {
        document.getElementById("payAtRace").checked = e.target.checked;
        setPayAtRace(e.target.checked)

    }
    const handleAddExbition = (e) => {


        setExbitionClasses(e.target.value);

    }
    const handleExbitionClass = async () => {
        if (exbitionClasses) {


            var data = {
                "name": exbitionClasses,
                "exhibition_class": true,
                "district_id":currentDistric

            }


            let response = await combinedServices.addExbitionClasses(data);
           
            if (response.status !=="") {
                

                swal("Add Exbition Class Successfully")
                .then(async(value)=>{
                    setExbitionClasses("");
                    const clsDropdown = [];
                    let classResponse = await combinedServices.getAllClasses(currentDistric);
                    
                    classResponse.data.filter((x)=>x.status==="active").map((cls) => {
                        var categoryName = cls.class_category_id == "6" ? "EX-" + cls.name : cls.name ;
            
                        var options = {
            
                            name:categoryName,
                            id: cls.id
            
                        }
                        clsDropdown.push(options)
            
                    })
                    setClass(clsDropdown)
                    
                    
                   }

                )
            

            }else{

                swal("Opps!",response.msg,"error"); 
            }
        } else {
            swal("Opps!", "Please Enter Exhibition Class", "error");

        }


    }




    const addTemplateForm = async () => {
       
    if (eventTemplateName.trim() === '') {
      setError('Please enter a Template Name.');
    } else {
      // Handle the form submission
    
       
        var uploadImageText = uploadImageUrl ? uploadImageUrl : eventImage;
       
            var image = uploadImageText;
            var regularClass = [];
            var shootOutclassIds = [];
            if (uploadImageUrl) {
                let response = await combinedServices.uploadImage(uploadImageUrl);

                if (response.status == 200) {
                    image = response.data.data.file_name
                }

            }

            
            var classDetails = selectedClasses.length > 0 ? selectedClasses : allSelectedClass;

            classDetails.map((value) => {

                regularClass.push(value.id)
            })

            var shootOutClassDetails = selectedShootOutClasses.length > 0 ? selectedShootOutClasses : allSelectedShootOutClass;

            shootOutClassDetails.map((value) => {

                shootOutclassIds.push(value.id)
            })



            var location = locationAccordingToDistric.split(',');

            var data = {
                template_name:eventTemplateName,
                full_title: eventName,
                event_start: startDate,
                event_end: endDate,
                registration_open: openingDate,
                registration_close: closingDate,
                image: image,
                contacts: contactDetails,
                enable_sms_notification: smsNotification,
                classIds: regularClass,
                shootout_classes: shootOutclassIds,
                min_boats_per_class: minimumBoatToMakeAClass,
                rounds_per_class: numberOfRound,
               
                registration_charge: firstBoatFee ? firstBoatFee :0.00,
                boat_charge: firstBoaEntrytFee ? firstBoaEntrytFee :0.00,
                zip: location[4],
                country: location[3],
                state: location[2],
                city: location[1],
                address: location[0],
                hotels: hotelDetailForm,

                frequency_dsm: true,
                detail: editorData.toString('html'),
                members_in_shootout: memberOfShootoutRound,
                alternate_frequency_required: true,
                max_boat_limit: 150,
                district_id: currentDistric,
                host_club_id: hostClubId,
                max_boats_per_heat: maximumBoatToHeat,
                allow_paypal_entries: Boolean(paypalEntries? true :false),
                allow_mail_ins: Boolean(mailInPayments? true :false),
                allow_pay_at_race_entries: Boolean(payAtRace? true :false),
                sanctioned_by: sactions ? sactions :"namba",
                venue_id: Number(venueId),



            }

            if (mailInPayments) {

                data.mail_zip = mailZipcode;
                data.mail_country = mailCountry;
                data.mail_state = mailState;
                data.mail_city = mailCity;
                data.mail_address = mailAddress;

            } else {

                data.mail_zip = "";
                data.mail_country = "";
                data.mail_state = "";
                data.mail_city = "";
                data.mail_address = "";




            }



            let eventResponse = await combinedServices.addTemplateDetails(data);
           
            if (eventResponse.status == 200) {
                setIsLoder(false)

                swal("Event Template Created Successfully.")
                    .then((value) => {
                        navigate("../event-management/create-event", { replace: true });
                    });

            } else {
                swal(eventResponse.msg).then(()=> setIsLoder(false))
            }
    }
    }
    const deleteTemplate=async()=>{
        let deltemp=await combinedServices.deleteEventTemplate(hostClubId,clubSelect)
      
            swal("Event Template Deleted Successfully.")
                .then((value) => {
                    window.location.reload(false);
                });

    }
    const handleFormData = (e) => {

        e.preventDefault();


        setFormValues([e.target.name] = e.target.value)



    }
    const handleContactDetail = (e) => {
        setContactDetails(e.target.value)

    }
    const handleFormDataEventTemplateName=(e)=>{
        setError('');
        setEventTemplateName(e.target.value)
    }
    const handleFormDataEventName = (e) => {
        setEventName(e.target.value)

    }
    const handleFormDataMailCountry = e => {

        setMailCountry(e.target.value)
    }
    const handleFormDataMailState = e => {

        setMailState(e.target.value)
    }
    const handleFormDataMailCity = e => {

        setMailCity(e.target.value)
    }

    const handleFormDataMailAddress = e => {

        setMailAddress(e.target.value)
    }

    const handleFormDataMailZip = e => {

        setMailZipcode(e.target.value)
    }
    const handleFirstBoatEntryFee = (event) => {
        const inputValue = event.target.value;
        setFirstBoatEntryFee(inputValue);
    
        // Check if the entered value is 0
        // if (parseFloat(inputValue) === 0) {
        //   setErrorMessage2('Value cannot be 0');
        // } else {
        //   setErrorMessage2('');
        // }
      };
      const handleFirstBoatFee = (event) => {
        const inputValue = event.target.value;
        setFirstBoatFee(inputValue);
    
        // Check if the entered value is 0
        // if (parseFloat(inputValue) === 0) {
        //   setErrorMessage1('Value cannot be 0');
        // } else {
        //   setErrorMessage1('');
        // }
      };
      const handleTemplate=async(e,hostClubId)=>{
        setDeltrue(true)
        setError('');
        setEventTemplateName(e.target.value)
        setClubSelect(e.target.value)
        const selectedClub=e.target.value
        getHostClubDetails(hostClubId,selectedClub)
    //   let clubResponse = await combinedServices.getEventTemplateDetails(id,selectedClub);

      }
    const handleMinimumBOatToMakeAClass = (e) => {
        setMinimumBoatToMakeAClass(e.target.value);
        var new_arr = numberForMaximumHeat.filter(function(x) {
            return x > e.target.value;
        });
        setNumberforMaximumHeat(new_arr)
    }
    const handleMemberOfShootoutRound = (e) => {
        setMemberOfShootoutRound(e.target.value);
    }
    const handleMaximumBoatFotHeat = (e) => {
        setMaximumBoatToHeat(e.target.value)
    }
    const handleNumberOfRound = (e) => {
        setNumberOfRound(e.target.value)
    }

    const handleEditHotel = (index) =>{
        document.getElementById('hotelDetailsDiv'+index).focus();

    }
const handleImageUpload = async (file) => {
        try {
          const formData = new FormData();
          formData.append('image', file);
      const response = await combinedServices.uploadImage(formData);
      const imageUrl = await baseURL + "images/" + response.data.data.file_name;
          const editor = document.querySelector('#ckeditor');
          const imageElement = document.createElement('img');
          imageElement.setAttribute('src', imageUrl);
          return imageUrl
          editor.appendChild(imageElement);
        } catch (error) {
          console.error(error);
        }  
      };
    return (
        <>
    
            <Header />
            

            <div className="dbRight">
                <div className="dbMid dbInner">
                   
                    <div className="flexBox spacebetween topHdBox">
                        <div className="left ecTop">
                            <div className="dbMainHd">Create Race</div>
                            <div className="text">NOTE: Only 'Mail In' transactions can be processed until you provide a PayPal email address to receive funds.
                            </div>
                        </div>
                    </div>
    
                    {isLoder ?  <div className="text-center mt-4 loadMore"><Spinner color="primary"/></div>  :

                    <div className="dbWhtBox">
                        <div className="memberOptionBox d-flex align-items-end justify-content-between mb30">
                        <div className="d-flex align-items-end ">
                            {eventImage ? 
                            <div className="uploadImg"><img src={preview ? preview : baseURL + "images/" + eventImage} alt="" className="icon" /></div>
                            :<div className="uploadImg"><img src={preview ? preview : eventImg} alt="" className="icon" /></div>}
                            <div><span className="uploadBtn">Upload Image <input type="file" className="fileInput" name="uploadImage" onChange={handleUploadImage} /></span>
                            {errorimg && <span className="error-message" style={{marginLeft
                            :"9px"}}>{errorimg}</span>}</div>
                            </div>
                            <div className='xyz'>
                            <ul className="flexBox createEventForm">
                                <li className="full">
                                    <span className="fieldHd">Select Template</span>
                                        <select className="formSelect" name="location" value={clubSelect} onChange={(e)=>(handleTemplate(e,hostClubId))}>
                                            <option value="0">Select Template</option>
                                            {templateOption.map((val) => {
                                                return (
                                                    <option key={val} value={val}>
                                                    {val}
                                                </option>
                                                    )


                                            })}
                                        </select>
                                    </li>
                                    </ul>
                                    </div>
                            </div>
                            
                        
                        <div className="d-flex flex-wrap topColumn">
                            <div className="topColumn1">
                                
                                <ul className="flexBox createEventForm">
                                <li className="full">
                                    <span className="fieldHd">Template Name</span>
                                        <input type="text"
                                            className="formInput"
                                            placeholder="Enter Template Name"
                                            name="full_title"
                                            value={eventTemplateName}
                                            onChange={handleFormDataEventTemplateName}
                                            required
                                        />
 {error && <div className="error-message">{error}</div>}

                                    </li>
                                    <li className="full">
                                    <span className="fieldHd">Event Name</span>
                                        <input type="text"
                                            className="formInput"
                                            placeholder="Enter Event Name"
                                            name="full_title"
                                            value={eventName}
                                            onChange={handleFormDataEventName}

                                        />


                                    </li>

                                </ul>
                            </div>

                            <div className="topColumn2">
                                
                                <ul className="flexBox createEventForm">
                                <li className="full">
                                    <span className="fieldHd">Event Locations</span>
                                        <select className="formSelect" name="location" value={venueId} onChange={handleLocation}>
                                            <option value="0">Select Location</option>
                                            {location.map((val) => {
                                                return (
                                                    <option data-address={val.address} data-name={val.venue} value={val.id}>{val.address}</option>
                                                )


                                            })}
                                        </select>
                                    </li>
                                    {/* <li>
                                        <span className="fieldHd">Start Date</span>
                                        <div className="pr">
                                            <DatePicker className="formInput datepicker"
                                                selected={startDate} onChange={(date) => setStartDate(date)}
                                                minDate={new Date()} />

                                        </div>
                                    </li>
                                    <li>
                                        <span className="fieldHd">End Date</span>
                                        <div className="pr">
                                            <DatePicker className="formInput datepicker"
                                                selected={endDate} onChange={(date) => setEndDate(date)} minDate={new Date()} />

                                        </div>
                                    </li> */}
                                </ul>
                            </div>

                            <div className="topColumn3">
                                
                                <ul className="flexBox createEventForm">
                                    {/* <li>
                                        <span className="fieldHd">District</span>


                                        <input type="text"
                                            className="formInput"
                                            placeholder="Enter Event Distric"
                                            name="districId"
                                            value={`D${currentDistric}`}

                                        />
                                      
                                    </li>
                                   */}
                                  
                                </ul>
                            </div>
                        </div>
                        {/* <div className="dbSubHd">Event Registration</div> */}
                        {/* <ul className="flexBox createEventForm smallForm">
                            <li>
                                <span className="fieldHd">Opening Date</span>
                                <div className="pr">
                                    <DatePicker className="formInput datepicker" minDate={new Date()} selected={openingDate} onChange={(date) => setOpeningDate(date)} />

                                </div>
                            </li>
                            <li>
                                <span className="fieldHd">Closing Date</span>
                                <div className="pr">
                                    <DatePicker className="formInput datepicker" minDate={new Date()} selected={closingDate} onChange={(date) => setClosingDate(date)} />
                                </div>
                            </li>
                        </ul> */}
                        <div className="dbSubHd">Entry Fees</div>
                        <ul className="flexBox createEventForm smallForm">
                            <li>
                                <span className="fieldHd">Entry Fee</span>
                                <div className="feeBox d-flex align-items-center">
                                    <span>$</span>
                                    <input type="text"
                                        className="formInput"
                                        name="boat_charge"
                                        value={firstBoatFee}
                                        onChange={handleFirstBoatFee}
                                        placeholder = "15"

                                    />


                                </div>
                                {errorMessage1 && (
              <div className="errorContainer">
                 {/* <FontAwesomeIcon icon={faInfoCircle} /> */}
                <span className="errorText">{errorMessage1}</span>
               
              </div>
            )}

                            </li>
                            <li>
                                <span className="fieldHd">Addition Boat</span>
                                <div className="feeBox d-flex align-items-center">
                                    <span>$</span>
                                    <input type="text"
                                        className="formInput"
                                        name="registration_charge"
                                        value={firstBoaEntrytFee}
                                        onChange={handleFirstBoatEntryFee}
                                        placeholder = "10"


                                    />
                                </div>  {errorMessage2 && (
              <div className="errorContainer">
                {/* <FontAwesomeIcon icon={faInfoCircle} /> */}
                <span className="errorText">{errorMessage2}</span>
              </div>
            )}

                            </li>

                        </ul>
                        {/* <div className="dbSubHd">Sanctions</div>
                        <ul className="flexBox createEventForm smallForm">
                            <li className="full maxWidth100">
                                <div className="memberOptionBox">
                                    <label className="labelText"><input type="radio" name="sanctioned_by" id="namba" className="radioInpt" value="namba"  checked={sactions === 'namba'} onClick={handleSactions} /> Namba</label>
                                    <label className="labelText"><input type="radio" name="sanctioned_by" id="unsaction" className="radioInpt" value="unsanctioned"  checked={sactions === 'unsanctioned'}onClick={handleSactions} />  Unsanction race</label>
                                </div>
                            </li>
                        </ul> */}
                        <div className="dbSubHd">Classes</div>
                        <ul className="flexBox createEventForm smallForm">
                            <li className="full">
                                
                                <Multiselect
                                    options={classes}
                                    onSelect={onSelectClass}
                                    onRemove={onRemoveClass}
                                    selectedValues={allSelectedClass}
                                    displayValue="name"

                                />


                            </li>
                        </ul>
                        <div className="dbSubHd">Exhibition Classes </div>
                        <ul className="flexBox createEventForm smallForm">
                            <li className="full">
                               

                                <div className="flexBox itemCenter nowrap">

                                    <input type="text"
                                        className="formInput"
                                        name="exhibitionClass"
                                        onChange={handleAddExbition}
                                        value={exbitionClasses}
                                    />
                                    <div className="addBtnNew"><button type="button" onClick={handleExbitionClass}>Add</button></div>
                                </div>

                            </li>






                        </ul>
                        {/*<div className="dbSubHd">Host Club Id</div>
                        <ul className="flexBox createEventForm smallForm">


                            <input type="text"
                                className="formInput"
                                name="host_club_id"
                                value={hostClubId}
                                
                            />


                                        </ul>*/}



                        <div className="dbSubHd">Boat Per Heat </div>
                        <ul className="flexBox createEventForm smallForm">
                            <li >
                                <span className="fieldHd">Minimum Entries</span>
                                <select className="formSelect"
                                    name="min_boats_per_class"
                                    value={minimumBoatToMakeAClass}
                                    onChange={handleMinimumBOatToMakeAClass}
                                >
                                    <option value="">Select</option>
                                    {numberForMinimumHeat.map((value)=> <option value={value}>{value}</option>)}
                                </select>


                            </li>

                            <li className="">
                                <span className="fieldHd">Maximum Boats Per Heat</span>
                                <select className="formSelect"
                                    name="max_boats_per_heat"

                                    value={maximumBoatToHeat}
                                    onChange={handleMaximumBoatFotHeat}
                                >
                                    <option value="">Select</option>
                                    {numberForMaximumHeat.map((value)=> <option value={value}>{value}</option>)}

                                </select>
                            </li>




                        </ul>

                        <div className="dbSubHd">No. of Rounds</div>
                        <ul className="flexBox createEventForm smallForm">
                            <li className="full">
                                <select className="formSelect"
                                    name="rounds_per_class" value={numberOfRound} onChange={handleNumberOfRound}
                                >
                                    <option value="">Select</option>
                                   
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                </select>

                            </li>
                        </ul>
                        <div className="dbSubHd">Shootout Classes</div>
                        <ul className="flexBox createEventForm smallForm">
                            <li className="full">
                                

                                <Multiselect
                                    options={classes}
                                    onSelect={onSelectShootOutClass}
                                    onRemove={onRemoveShootOutClass}
                                    selectedValues={allSelectedShootOutClass}
                                    displayValue="name"

                                />


                            </li>
                        </ul>
                        {(() => {
                            if (selectedShootOutClasses.length > 0 || allSelectedShootOutClass.length > 0) {
                                return (<>
                                    <div className="dbSubHd">No. of Candidates are eligible for Shootout round</div>
                                    <ul className="flexBox createEventForm smallForm">
                                        <li className="full">
                                            <select className="formSelect"
                                                name="members_in_shootout" value={memberOfShootoutRound} onChange={handleMemberOfShootoutRound}
                                            >
                                                <option value="0">Select</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                            </select>

                                        </li>
                                    </ul>
                                </>)
                            }

                            return null;
                        })()}

                        <div className="dbSubHd">Contacts</div>
                        <ul className="flexBox createEventForm smallForm">
                            <li className="full">
                                
                                <textarea rows="4" cols="4" className="formInput"
                                    name="contacts" value={contactDetails} onChange={handleContactDetail}

                                ></textarea>

                                <div className="exText">Please enter only one contact name and number per line.</div>

                            </li>
                        </ul>

                        <div className="dbSubHd">Payment Type</div>
                        <ul className="flexBox createEventForm smallForm">
                            <li className="full maxWidth100">
                                <div className="memberOptionBox">
                                    {isPaypal && 
                                    <label className="labelText"><input type="checkbox" name="allow_paypal_entries" id="paypal" onClick={handlePaypalEntries} /> Paypal</label>
                                    
                                    }<label className="labelText"><input type="checkbox" name="allow_mail_ins" id="mailInEntries" onClick={handleMailInPayment} /> Mail in entries</label>
                                    <label className="labelText"><input type="checkbox" name="pay_at_race" id="payAtRace" onClick={handlePayAtRace} /> Pay at Race</label>
                                </div>
                            </li>
                        </ul>
                        {/*} <div className="dbSubHd">Paypal Entries</div>
                        <ul className="flexBox createEventForm smallForm">
                            <li className="full">
                                <span className="fieldHd">Allow PayPal Entries:</span>
                                <select className="formSelect"
                                    name="allow_paypal_entries"
                                    value={paypalEntries}
                                    onChange={handlePaypalEntries}
                                >
                                    <option value="">Select</option>
                                    <option value="1">True</option>
                                    <option value="0">False</option>
                                </select>

                            </li>
                        </ul>


                        <div className="dbSubHd">Mail In Payments</div>
                        <ul className="flexBox createEventForm smallForm">
                            <li className="full">
                                <span className="fieldHd">Allow Mail In Payments</span>
                                <select className="formSelect"
                                    name="allow_mail_ins"
                                    value={mailInPayments}
                                    onChange={handleMailInPayment}
                                >
                                    <option value="">Select</option>
                                    <option value="1">True</option>
                                    <option value="0">False</option>
                                </select>

                            </li>
                                        </ul> */}




                        {mailInPayments == true && <>

                            <ul className="flexBox createEventForm">
                                {/*<li className="entriesField">
                                    <span className="fieldHd">Country</span>
                                    <input type="text" className="formInput"
                                        name="mail_country"
                                        value={mailCountry}
                                        onChange={handleFormDataMailCountry}
                                    />

                        </li>*/}
                                <li className="entriesField">
                                    <span className="fieldHd">Address</span>
                                    <input type="text" className="formInput"
                                        name="mail_address" value={mailAddress} onChange={handleFormDataMailAddress}
                                    />

                                </li>
                                <li className="entriesField">
                                    <span className="fieldHd">City</span>
                                    <input type="text" className="formInput"
                                        name="mail_city" value={mailCity} onChange={handleFormDataMailCity}
                                    />

                                </li>
                                <li className="entriesField">
                                    <span className="fieldHd">State</span>
                                    <input type="text" className="formInput"
                                        name="mail_state" value={mailState} onChange={handleFormDataMailState}
                                    />

                                </li>
                                <li className="entriesField">
                                    <span className="fieldHd">Zipcode</span>
                                    <input type="text" className="formInput"
                                        name="mail_zip" value={mailZipcode} onChange={handleFormDataMailZip}

                                    />

                                </li>
                            </ul>
                        </>}



                        <div className="dbSubHd">Event Details</div>
                        <ul className="flexBox createEventForm smallForm">
                            <li className="full">
                                <CKEditor
        editor={ClassicEditor}
        data={editorData}
        onReady={(editor) => {
          editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return {
              upload: async () => {
                const file = await loader.file;
                const imageUrl = await handleImageUpload(file);
                return { default: imageUrl };
              }
            };
          };
        }}
        onChange={(event, editor) => {
          setEditorData(editor.getData());
        }}
      />
                                <div className="exText">Please enter only one event detail per line.</div>

                            </li>

                        </ul>
                        {/* <div className="dbSubHd">Enable SMS Notification</div>
                        <ul className="flexBox createEventForm smallForm">
                            <li className="full">
                                <div className="freqBox">
                                    <label><input type="checkbox"
                                        className="checkInpt"
                                        name="enable_sms_notification"
                                        id="enable_sms_notification"
                                        onClick={handleSMSNotification}
                                    /> Enable</label>
                                </div>

                            </li>

                        </ul> */}
                        <div className="dbSubHd">Hotel Reservation</div>
                        <ul className="hotelResList d-flex flex-wrap">
                            {hotelDetailForm.map((value, index) => value.name != '' && <>


                                <li>
                                    <div className="inner">
                                        <div className="top">
                                            {value.name}
                                        </div>
                                        <div className="bot d-flex">
                                            <a  onClick={()=>handleEditHotel(index)}>Edit</a>
                                            <a className="remove" onClick={() => removeFormFields(index)}>Remove</a>
                                        </div>
                                    </div>
                                </li>


                            </>
                            )}

                        </ul>
                        {hotelDetailForm.map((element, index) => (
                            <ul className="flexBox createEventForm smallForm" >


                                <li className="full">
                                    <span className="fieldHd">Hotel Name</span>
                                    <input type="text" className="formInput" id={"hotelDetailsDiv"+index} name="name" value={element.name} onChange={e => handleChange(index, e)} />
                                </li>
                                <li className="full">
                                    <span className="fieldHd">Enter URL</span>
                                    <input type="text" className="formInput" name="url" value={element.url} onChange={e => handleChange(index, e)} />
                                    {
                                        index ?
                                            <div className="addBtn"><button type="button" onClick={() => removeFormFields(index)}>Remove</button></div>

                                            : null
                                    }
                                </li>

                            </ul>
                        ))}
                        <div className="addBtn"><button type="button" onClick={() => addFormFields()}>Add New</button></div>

                        <div><button type="button" className="formBtn" onClick={addTemplateForm}>Save</button>
                        {deltrue?<button type="button" className="formBtn" style={{marginLeft:"12px"}} onClick={deleteTemplate}>Delete</button>:null}
                        </div>
                        
                        
                    </div>
}
                </div>
            </div>
        </>
    )
}
export default CreateEvent;