export default function getLocalData(key, type = "all") {
  // console.log(key);
  // console.log(type)
  var responseObject = null;

  try {
    if (key) {
      var response;

      response = JSON.parse(atob(localStorage.getItem(key)));
      // console.log("loca res--->>>>", response);

      let USERDATA;
      // if(key === 'user_info'){
      //     USERDATA = response.userData
      // }
      // else{
      //     USERDATA = response.userData.result1
      // }
      USERDATA = response.userData;
      // console.log("****>>>>>", USERDATA);

      switch (type) {
        case "all":
          responseObject = response ? response : null;
          break;

        case "isLogin":
          responseObject = response ? response.isLogin : false;
          break;

        case "getToken":
          responseObject = response ? USERDATA.token : null;
          break;

        case "getId":
          responseObject = response ? USERDATA._id : null;
          break;

        case "registrationData":
          responseObject = response ? USERDATA : null;
          break;

        default:
          responseObject = null;
          break;
      }
    }
  } catch (e) {
    responseObject = null;
  }

  return responseObject;
}
