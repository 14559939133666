import React, { useEffect, useState } from "react";
import Header from "../../../Components/adminDashboard/Header/Header";
import "../PaymentGateway/PaymentGateway.scss";
import "./Audio.scss";
import combinedServices from "../../../shared/services/user-service";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { Spinner } from "reactstrap";

function Audios() {
  const [uploadAudioUrl, setUploadAudioUrl] = useState("");
  const [audios, setAudios] = useState([]);
  const [deleted, setDeleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [durationOptions, setDurationOptions] = useState([
    "0:30",
    "1:30",
    "2:00",
    "2:30",
  ]);
  const [duration, setDuration] = useState("");
  const [isError, setIsError] = useState(false);

  const audioStyle = {
    display: "flex",
    justifyContent: "space-between",
    padding: "40px",
  };
  const buttonStyle = {
    width: "fit-content",
    color: "white",
    fontSize: "12px",
    borderRadius: "3px",
    backgroundColor: "#01458b",
    padding: "5px",
    alignItems: "center",
    border: "none",
  };

  useEffect(async () => {
    getAllAudios();
  }, [deleted]);

  const getAllAudios = async () => {
    let response = await combinedServices.getAudios();
    setAudios(response.data);
  };

  const handleUploadAudio = (e) => {
    if (e.target.files && e.target.files[0]) {
      let audio = e.target.files[0];
      const objectUrl = URL.createObjectURL(audio);
      console.log("url", objectUrl);
      const formData = new FormData();
      formData.append("image", audio);
      console.log("formdata", formData);
      setUploadAudioUrl(formData);
    }
  };

  const handleUploadToBucket = async () => {
    let audio = "";

    if (uploadAudioUrl && duration) {
      setIsLoading(true);
      let response = await combinedServices.uploadImage(uploadAudioUrl);
      if (response.status == 200) {
        audio = response.data.location;
        let data = {
          audio_url: audio,
          status: "inactive",
          audio_len: duration,
          audio_class: "electric",
        };
        const added = await combinedServices.addAudio(data);
        setIsLoading(false);
        getAllAudios();
        setIsError(false);
      }
    } else {
      setIsError(true);
    }
  };

  const handleDeleteAudio = async (e, id) => {
    const data = {
      id: id,
    };
    console.log("data,", data);
    const response = await combinedServices.deleteAudio(data);
    if (response.status == 200) {
      getAllAudios();
    }
  };

  const handleUpdateStatus = async (e, id) => {
    let data;
    if (e.target.checked) {
      data = {
        status: "active",
        id: id,
      };
    } else {
      data = {
        status: "inactive",
        id: id,
      };
    }
    let response = await combinedServices.updateAudioStatus(data);
    getAllAudios();
    console.log("updated", response);
  };

  const handleDurationChange = (e) => {
    setDuration(e.target.value);
  };

  return (
    <>
      <Header />

      <div className="dbAdminRight">
        <div className="dbMid dbInner">
          <div className="flexBox spacebetween topHdBox">
            <h3>Audio</h3>
          </div>
          <div className="flexBox spacebetween">
            <div className="pgBox whtBox">
              <div>
                <input
                  type="file"
                  name="imageUpload"
                  onChange={handleUploadAudio}
                />
                <div style={{ padding: "10px 0" }}>
                  <label for="cars">Select the duration:</label>

                  <select
                    name="cars"
                    id="cars"
                    onChange={handleDurationChange}
                    value={duration}
                    style={{
                      width: "20%",
                      textAlign: "center",
                      borderRadius: "4px",
                      margin: "10px",
                    }}
                  >
                    <option value="" disabled selected>
                      select duration
                    </option>
                    {durationOptions.map((item) => {
                      return <option>{item}</option>;
                    })}
                  </select>
                </div>
                {!isLoading ? (
                  <button onClick={handleUploadToBucket} style={buttonStyle}>
                    upload
                  </button>
                ) : (
                  <div className="text-center mt-4 loadMore">
                    <Spinner color="primary" />
                  </div>
                )}
                {isError ? (
                  <p style={{ color: "red" }}>Select Duration and Audio</p>
                ) : (
                  ""
                )}
              </div>

              <table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  marginTop: "20px",
                }}
              >
                <thead>
                  <tr style={{ backgroundColor: "lightgray", height: "40px" }}>
                    <th
                      style={{
                        textAlign: "center",
                        fontWeight: "400",
                        padding: "10px",
                      }}
                    >
                      {/* Month */}
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontWeight: "400",
                        padding: "10px",
                      }}
                    >
                      Audio
                    </th>
                    <th
                      style={{
                        textAlign: "left",
                        fontWeight: "400",
                      }}
                    >
                      Duration
                    </th>
                    <th
                      style={{
                        textAlign: "left",
                        fontWeight: "400",
                      }}
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {audios.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="checkbox"
                          style={{ height: "20px", width: "20px" }}
                          checked={item.status == "active" ? true : false}
                          onChange={(e) => handleUpdateStatus(e, item.id)}
                        />
                      </td>
                      <td
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          margin: "10px 0",
                        }}
                      >
                        <audio controls>
                          <source src={item.audio_url} type="audio/mpeg" />
                        </audio>
                        <td>
                          <IconButton aria-label="delete">
                            <DeleteIcon
                              onClick={(e) => handleDeleteAudio(e, item.id)}
                            />
                          </IconButton>
                        </td>
                      </td>

                      <td>
                        <span>{item.audio_len}</span>
                      </td>
                      <td>
                        <span>{item.status}</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Audios;
