import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import combinedServices from "../../../shared/services/user-service";
import userConstants from "../../../shared/constants/user-constants";
import "./ManageHeatsheet.scss";
// import PdfContainer from "./PdfContainer";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";

export default function DownloadExcelHeatsheet() {
  // get event id from params
  let eventId = useParams().id;
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [rounds, setRounds] = useState([]);
  const [classes, setClasses] = useState([]);
  const [eventParticipant, setEventParticipant] = useState([]);
  const [eventDetails, setEventDetails] = useState([]);
  const [prevHeat, setPrevHeat] = useState("");
  const [round2Data, setRound2Data] = useState(false);
  const [round3Data, setRound3Data] = useState(false);
  const [round4Data, setRound4Data] = useState(false);
  const [dates, setDates] = useState([]);
  const [allinfo, setAllinfo] = useState([]);
  const [eventname, setEventname] = useState("");
  const [users, setUsers] = useState();
  const [fees, setFees] = useState("");
  const [regischarge, setRegischarge] = useState("");
  const [imagestream, setImagestream] = useState("");
  const [baseURL, setBaseUrl] = useState(userConstants.baseURL);
  const [masterarr, setMasterarr] = useState({});
  const [participantarr, setParticipantarr] = useState({});
  const [scoret, setScoret] = useState("");
  const[timezone,setTimezone]=useState("")
  const AltImg = "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/logo.png"
  useEffect(async () => {
    let finalArr = [];
    let classArr = [];
    let dateArr = [];
    await combinedServices
      .getClassByEventId(eventId)
      .then((res) => {
        setImagestream(
          res.data.image.length > 0
            ? baseURL + "images/" + res.data.image
            : AltImg
        );
        setEventDetails(res.data);
        setTimezone(res.data.time_zone)

        setRegischarge(res.data.registration_charge);
        setFees(res.data.boat_charge);
        setEventname(res.data.full_title);

        let round = [];
        for (var i = 1; i <= res.data.rounds_per_class; i++) {
          round.push(i);
        }
        setRounds(round);
      })
      .catch((err) => {
        alert(err);
      });
    // getting all the data
    await combinedServices
      .getAllHeatSheet({ event_id: eventId })
      .then(async (res) => {
        res.data.map((val, i) => {
          return val.participants.map((itm, i) => {
            return finalArr.push({
              class: itm.event_participant_class.class.name,
              heat: val.heat_number,
              name:
                itm.event_participant_class.event_participant.user
                  .user_firstname +
                " " +
                itm.event_participant_class.event_participant.user
                  .user_lastname,
              score: "",
              round: itm.round,
              id: itm.event_participant_class.event_participant.user.id,
            });
          });
        });
        let uniqueChars = [...new Set(finalArr)];
        finalArr?.map((val) => {
          if (val.round == 2) return setRound2Data(true);
          if (val.round == 3) return setRound3Data(true);
          if (val.round == 4) return setRound4Data(true);
        });
      finalArr.sort((a,b)=>a.heat-b.heat)
        setData(finalArr);
      })

      .catch((err) => {
        alert(err);
      });

    var eventClassesData1 = await combinedServices.countByClasses(eventId);
    var participantClassData = eventClassesData1.data.participants;
    if (participantClassData) {
      participantClassData.map((classVal) => {
        if (classVal.run_date != null) {
          var data = {
            id: classVal.class_id,
            name: classVal.class_name,
            rundate: classVal.run_date,
            event_class_id: classVal.event_class_id,
            order_key: classVal.order_key,
          };
          classArr.push(data);
          classArr.sort((a, b) => {
            const dateA = new Date(a.run_date);
            const dateB = new Date(b.run_date);

            if (dateA.getTime() === dateB.getTime()) {
              return a.order_key - b.order_key;
            } else {
              return dateA - dateB;
            }
          });
        }
        if (classVal.run_date != null) {
          var data1 = {
            run_date: classVal.run_date,
            class: classVal.class_name,
          };
          dateArr.push(data1);
        }
      });
      classArr.sort((a, b) => {
        if (a.rundate === b.rundate) {
          return a.order_key - b.order_key;
        }
        return new Date(a.rundate) - new Date(b.rundate);
      });
      setClasses(classArr);
      const formattedResponse = {};

      for (const item of dateArr) {
        const runDate = item.run_date;
        const classType = item.class;

        if (runDate in formattedResponse) {
          formattedResponse[runDate].push(classType);
        } else {
          formattedResponse[runDate] = [classType];
        }
      }

      setDates(formattedResponse);
    }

    setTimeout(() => {
      if (exportRef.current) {
        exportRef.current.save()
        navigate("../manage-heatsheet/" + eventId, { replace: true }); 
      }
    }, 500);
  
  }, []);

  const exportRef = React.useRef();

  const exportData = [];

  const headerRow = {
    Date: "Date",
    Round: "Round",
    Heat: "Heat",
    Name: "Name",
    Class: "Class",
    Score:"Score"
    
  };
  exportData.push(headerRow);
  const timeaccotolocation2 = (x) => {
    const utcMoment = moment.utc(x);
    const localMoment = utcMoment.tz(timezone);
    
    // const timeZoneAbbreviation = localMoment.format('z');
    const timeZoneOffset = localMoment.format('Z');
    return localMoment.format('MMM DD, YYYY');;
  }
  data.map((x) => {
    const rowData = {
      Round: x.round,
      Heat: Number(x.heat),
      Name: x.name,
      Class: x.class,
      Date:timeaccotolocation2(
        classes?.filter((cls) => cls.name == x.class)[0]?.rundate
      )
    };
    if(exportData[exportData.length-1].Heat!=Number(x.heat) && exportData.length>1){
      exportData.push({
        Round:"",
        Heat:"",
        Name:"",
        Class:"",
        Date:""
      })
    }
    exportData.push(rowData);
  });

  return (
    
    <div>
      {/* <button onClick={handleExport}>Export to Excel</button> */}
      <ExcelExport
        data={exportData.slice(1)} // Exclude the first row (fixed row)
        ref={exportRef}
        fileName={`${eventname}.xlsx`}
        filterable={true}
        groupable={true}
      >
         <ExcelExportColumn field="Date" title="Date" width={100} />
        <ExcelExportColumn field="Round" title="Round" width={100} />
        <ExcelExportColumn field="Heat" title="Heat #" width={100} />
        <ExcelExportColumn field="Name" title="Name" width={100} />
        <ExcelExportColumn field="Class" title="Class" width={100} />
       <ExcelExportColumn field="Score" title="Score" width={100}/>
      </ExcelExport>
    </div>
  );
}
