import React, { useState, useEffect } from 'react'
import "./deak-heat.scss"
import ScoreHeatModal from './score-heat-modal'
import Header from "../../Components/clubDashboard/Header/Header";
import { Link, useParams } from 'react-router-dom';
import combinedServices from '../../shared/services/user-service';
import { TabContent, ModalBody, Modal, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import scoreData from './score.json'
import swal from 'sweetalert';



export default function Deakheat() {
  const [id, setId] = useState(useParams().id);
  const [data, setData] = useState([]);
  const [rounds, setRounds] = useState([]);
  const [eventParticipant, setEventParticipant] = useState([]);
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(false)
  const [participant, setParticipant] = useState([])
  const [selectRound, setSelectRound] = useState(1);
  const [selectedClass, setSelectClass] = useState('');
  const [selectedHeat, setSelectHeat] = useState('');
  const [heatNo, setHeatNo] = useState([]);
  const [pause, setPause] = useState(true)
  const [modal, setModal] = useState(false);
  const [activeTab, setActiveTab] = useState('0')
  const [refresh, setRefresh] = useState('')
  const audio = "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/Track01.mp3"




  useEffect(async () => {
    await combinedServices.getClassByEventId(id).then(res => {
      // setEventDetails(res.data)
      let round = [];
      for (var i = 1; i <= res.data.rounds_per_class; i++) {
        round.push(i)
      }
      setRounds(round);

    }).catch(err => {
      alert(err);
    })
    var heat_Data = {
      event_id: id,
      round: selectRound,
    }
    var finalArr = [];
    var tempClass = [];
    var tempHeat = [];
    await combinedServices.getHeatSheet(heat_Data).then(res => {

      res.data.map((val, i) => {
        tempHeat.push(val.heat_number)
        setHeatNo(tempHeat)
        insertClass().then(finalArr => {
        })
        function insertClass() {
          return new Promise((resolve, reject) => {
            val.participants.map(item => {
              //  if(item.round==1){
              if (tempClass.indexOf(item.event_participant_class.class.name) == -1) {
                tempClass.push(item.event_participant_class.class.name)
                finalArr.push({
                  class: item.event_participant_class.class.name, heat: val.heat_number, heatId: item.id, userId: item.event_participant_class.event_participant.user.id,
                  name: item.event_participant_class.event_participant.user.user_firstname + " " + item.event_participant_class.event_participant.user.user_lastname, score: item.score, round: item.round, scratch: item.scratch, finish: item.finish, scratchUser: item.event_participant_class.event_participant.user.scratchUser
                })
              } else {
                finalArr.push({
                  class: item.event_participant_class.class.name, heat: val.heat_number, heatId: item.id, userId: item.event_participant_class.event_participant.user.id,
                  name: item.event_participant_class.event_participant.user.user_firstname + " " + item.event_participant_class.event_participant.user.user_lastname, score: item.score, round: item.round, scratch: item.scratch, finish: item.finish, scratchUser: item.event_participant_class.event_participant.user.scratchUser
                })
              }
            })
            resolve(finalArr)
          })
        }
      })
      setEventParticipant(finalArr);


    }).catch(err => {
      alert(err)
    })

    // get classes
    var classArr = [];
    await combinedServices.countByClasses(id).then(res => {
      var participantClassData = res.data.participants;
      if (participantClassData) {
        participantClassData.map((classVal) => {
          if (classVal.run_date != null) {
            var data = {
              id: classVal.class_id,
              name: classVal.class_name
            }
            classArr.push(data)
          }
        })
        setClasses(classArr);

      }
    }).catch(err => {
      alert(err)
    })
    //////

  }, [selectedHeat, refresh])
  const playAudio = () => {
    new Audio(audio).play();
    setPause(false)


  }
  const pauseAudio = () => {
    new Audio(audio).pause();
    setPause(true)


  }
  const getAll = async (key) => {
    var heat_Data = {
      event_id: id,
      round: key,
      class_id: 18
    }

    await combinedServices.getHeatSheet(heat_Data).then(res => {

      //  setEventParticipant(res.data);
    }).catch(err => {
      alert(err)
    })
  }
  const handleRound = (e) => {
    //console.log("round e-------",e.target.value)
    setSelectRound(e.target.value)
    getAll(e.target.value)
  }

  const handleClass = (e) => {

    setSelectClass(e.target.value)
    // getAll(e.target.value)

  }
  const handleHeat = (e) => {
    //console.log("round e-------",e.target.value)
    setSelectHeat(e.target.value)
  }

  const handleModal = () => {

    if (selectedClass.length == 0 && selectedHeat.length == 0) {
      swal("Please select Class & Heat first!", "", "warning")
    }
    if (selectedClass.length != 0 && selectedHeat.length != 0) {
      setModal(!modal)
    }
  }
  const handlePtab = (tab, val) => {

    setParticipant([{ name: val.name, id: val.heatId }])
    if (activeTab !== tab) {
      setActiveTab(tab)
    }

  }



  const handleScore = (val) => {


    //setScore(val)

    combinedServices.heatScore([{ id: participant[0].id, score: val }]);
    setRefresh(`${val}`)
  }
  const handleFinish = async () => {
    var OdParticipant = [];
    eventParticipant.map(itm => {
      if (itm.round == selectRound && itm.class == selectedClass && itm.heat == selectedHeat) {
        return OdParticipant.push({ heatId: itm.heatId })
      }
    })
    var data = [];
    OdParticipant.map(val => {
      return (data.push({ id: val.heatId, finish: true }))
    })
    await combinedServices.finishStatus(data).then(res => {
      swal("Completed!", `race completed for heat `, "success")
      setModal(!modal)
      setSelectHeat('')
      setRefresh(data)
    }).catch(err => {
      alert(err)
      setModal(!modal)

    })
  }
  const handleRadio = (e, val) => {
    let check = e.target.checked
    let heatId = [];
    data.map((item) => {
      if (item.userId == val.userId)
        return (heatId.push({ heatId: item.heatId }))
    })
    console.log("firstdata", heatId)
    if (check == true) {
      swal("Scratch participant from?", "", "warning", {
        buttons: {
          cancel: "Cancel",
          current: {
            text: "Current heat only!",
            value: "current",
          },
          all: {
            text: "All heats!",
            value: "all",
          }
        },
      })
        .then((value) => {
          switch (value) {

            case "all":
              let data = [];
              heatId.map(val => {
                return (data.push({ id: val.heatId, scratch: true }))
              })

              combinedServices.updateScoreStatus(data).then(res => {
                swal("Done!", `${val.name} removed from all heats!`, "success");
                setRefresh(Math.random(6))
              }).catch(err => {
                alert(err)
              })

              break;

            case "current":
              combinedServices.updateScoreStatus([{ id: val.heatId, scratch: true }]).then(res => {
                swal("Done!", `${val.name} can not participate in the race`, "success")
                setRefresh(Math.random(6))
              }).catch(err => {
                alert(err)
              })
              break;

            default:
          }
        });
    }
    if (check == false) {

      {/* add participant*/ }
      swal("Allow participant to join-", "", "warning", {
        buttons: {
          cancel: "Cancel",
          current: {
            text: "Current heat only!",
            value: "current",
          },
          all: {
            text: "All heats!",
            value: "all",
          }
        },
      })
        .then((value) => {
          switch (value) {

            case "all":
              var data = [];
              heatId.map(val => {
                return (data.push({ id: val.heatId, scratch: false }))
              })
              combinedServices.updateScoreStatus(data).then(res => {
                swal("Done!", `${val.name} allowed joined the all heats`, "success");
                setRefresh(Math.random(6))
              }).catch(err => {
                alert(err)
              })

              break;

            case "current":
              combinedServices.updateScoreStatus([{ id: val.heatId, scratch: false }]).then(res => {
                swal("Done!", `${val.name} allowed joined the current heat`, "success")
                setRefresh(Math.random(6))
              }).catch(err => {
                alert(err)
              })
              break;

            default:
          }
        });
    }

  }
  return (
    <>
      <Header />
      <div className='deakHeatOuter'>

        <div>
          <h3 >Select Custom Heat
            <span>
              <select className="eventDropdown" onChange={handleRound} >
                {rounds.map((roundVal, r) => {
                  return <option value={roundVal} >Round - {roundVal} </option>
                })}
              </select>
            </span>
            <span>
              <select className="eventDropdown" onChange={handleClass} >
                <option>Select Class</option>
                {classes.length > 0 ? classes.map((classVal, c) => {
                  return (<option value={classVal.name}>Class - {classVal.name}</option>)
                }) : null}
              </select>
            </span> <span>
              <select className="eventDropdown" onChange={handleHeat} >
                <option>Select Heat</option>
                {heatNo.length > 0 ? heatNo.map((heatVal, h) => {
                  return <option value={heatVal}>Heat Number- {heatVal}</option>
                }) : null}
              </select>
            </span>
          </h3>
          <div className='heatTitle'>
            {selectedClass.length == 0 ? "Please Select Class from Dropdown" : selectedHeat.length == 0 ? "Please Select Heat Number from Dropdown" : "You have selected heat No.- " + selectedHeat + " Class-" + selectedClass + " Round-" + selectRound}
          </div>
          <div className='deakOuter'>
            <div className='heatLeftBx'>
              <Button>Heat {selectedHeat} {selectedClass}<br />Call Heat </Button>
              {pause ? <Button onClick={playAudio}>Start Heat</Button> :
                <Button onClick={pauseAudio}>Stop Heat</Button>}
              <div>
                <Button color="danger" onClick={handleModal}>Score Heat</Button>
                <Modal isOpen={modal} toggle={handleModal} className="modalOuterBx">
                  <Button className='modalCloseBtn' onClick={handleModal}>X</Button>
                  <ModalBody>
                    <div className='tabposition'>
                      <div className='tabpos_Left'>Position Entrant</div>
                      <div className='tabpos_Right' >Finish</div>
                    </div>
                    {/*   <ModalHeatTab scoreheat={props.scoreheat}/>*/}
                    <div className='ModtabOuter'>
                      <Nav tabs>
                        {eventParticipant.length > 0 ? eventParticipant.map((itm, i) => {
                          if (itm.round == selectRound && itm.class == selectedClass && itm.heat == selectedHeat)
                            return (
                              <NavItem>
                                <NavLink
                                  className={classnames({ active: activeTab === `${1 + i}` })}
                                  onClick={() => { handlePtab(`${1 + i}`, itm); }}
                                >
                                  {itm.name}<span>{itm.score}</span>
                                </NavLink>
                              </NavItem>
                            )
                        }) : null}
                      </Nav>
                      <TabContent activeTab={activeTab}>
                        {scoreData.map((item, i) => {
                          return <TabPane tabId={`${i + 1}`}>
                            <div className='dnfOuter'>
                              <div className='dnfLeft'>
                                {scoreData.map((val, i) => {
                                  return <ul>
                                    <li>
                                      <input type="radio" onChange={() => handleScore(val.score)} name='radio' />
                                      <label>
                                        <span>{val.position}</span>
                                        <span>{val.score}</span>
                                      </label>
                                    </li>
                                  </ul>
                                })}
                              </div>

                            </div>
                            <div className='nextPrevBtn'>

                              <Button className='nextBtn' onClick={handleFinish} >Finish</Button>

                            </div>
                          </TabPane>
                        })}
                      </TabContent>
                    </div>
                  </ModalBody>
                </Modal>
              </div>
            </div>

            <div className='heatMidBx'>

              {eventParticipant.length > 0 ? eventParticipant.map((itm, i) => {
                if (itm.round == selectRound && itm.class == selectedClass && itm.heat == selectedHeat)
                  if (itm.scratch == true) {
                    return (<ul className='listcolor'>
                      <li > &nbsp; <span className='hideparticipant'>{itm.name}</span>
                        <div className='notifiRight '>
                          <input type="checkbox" checked={itm.scratch} onChange={(e) => handleRadio(e, itm)} />
                          <span>
                            <i></i>
                          </span>
                        </div>
                      </li>
                    </ul>)
                  } else {
                    return (<ul >
                      <li > &nbsp; {itm.name}
                        <div className='notifiRight '>
                          <input type="checkbox" checked={itm.scratch} onChange={(e) => handleRadio(e, itm)} />
                          <span>
                            <i></i>
                          </span>
                        </div>
                      </li>
                    </ul>)
                  }
              }) : null}
            </div>
            <div className='heatRightBx'>
              <Link to={"/manage-heatsheet/" + id}><Button >Edit Heats</Button></Link>
              <Button>Send Message</Button>
              <Button>Send Message All</Button>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
