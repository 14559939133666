import React, { useEffect, useState, useRef } from "react";
import combinedServices from "../../../shared/services/user-service";
import userConstants from "../../../shared/constants/user-constants";
import "./ManageHeatsheet.scss";
import moment from "moment-timezone";
// import PdfContainer from "./PdfContainer";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { useNavigate, useLocation } from "react-router-dom";
import { ButtonBase } from "@mui/material";

export default function DownloadTransponderExcel(props) {
  const { eventId, runDates, timeZone } = props;
  // get event id from params
  const downloaderButtonRef = useRef(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // let eventId = useParams().id;

  const [data, setData] = useState([]);
  const [rounds, setRounds] = useState([]);
  const [classes, setClasses] = useState([]);
  const [eventParticipant, setEventParticipant] = useState([]);
  const [eventDetails, setEventDetails] = useState([]);
  const [prevHeat, setPrevHeat] = useState("");
  const [round2Data, setRound2Data] = useState(false);
  const [round3Data, setRound3Data] = useState(false);
  const [round4Data, setRound4Data] = useState(false);
  const [dates, setDates] = useState([]);
  const [allinfo, setAllinfo] = useState([]);
  const [eventname, setEventname] = useState("");
  const [users, setUsers] = useState();
  const [fees, setFees] = useState("");
  const [regischarge, setRegischarge] = useState("");
  const [imagestream, setImagestream] = useState("");
  const [baseURL, setBaseUrl] = useState(userConstants.baseURL);
  const [masterarr, setMasterarr] = useState({});
  const [participantarr, setParticipantarr] = useState({});
  const [scoret, setScoret] = useState("");
  const AltImg =
    "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/logo.png";

  useEffect(async () => {
    let finalArr = [];
    let classArr = [];
    let dateArr = [];
    await combinedServices
      .getClassByEventId(eventId)
      .then((res) => {
        setImagestream(
          res.data.image.length > 0
            ? baseURL + "images/" + res.data.image
            : AltImg
        );
        setEventDetails(res.data);

        setRegischarge(res.data.registration_charge);
        setFees(res.data.boat_charge);
        setEventname(res.data.full_title);

        let round = [];
        for (var i = 1; i <= res.data.rounds_per_class; i++) {
          round.push(i);
        }
        setRounds(round);
      })
      .catch((err) => {
        alert(err);
      });
    // getting all the data
    await combinedServices
      .getAllHeatSheet({ event_id: eventId })
      .then(async (res) => {
        res.data.map((val, i) => {
          return val.participants.map((itm, i) => {
            return finalArr.push({
              class: itm.event_participant_class.class.name,
              heat: val.heat_number,
              name:
                itm.event_participant_class.event_participant.user
                  .user_firstname +
                " " +
                itm.event_participant_class.event_participant.user
                  .user_lastname,
              firstname:
                itm.event_participant_class.event_participant.user
                  .user_firstname,
              lastname:
                itm.event_participant_class.event_participant.user
                  .user_lastname,
              score: "",
              round: itm.round,
              id: itm.event_participant_class.event_participant.user.id,
              transponder_id: itm.event_participant_class.transponder_id,
              transponder_name: itm.event_participant_class.transponder_name,
              email:
                itm.event_participant_class.event_participant.user.user_email,
              phone:
                itm.event_participant_class.event_participant.user.user_mobile,
              birthday:
                itm.event_participant_class.event_participant.user.user_dob,
              gender:
                itm.event_participant_class.event_participant.user.user_sex,
              address1:
                itm.event_participant_class.event_participant.user.user_addr1,
              address2:
                itm.event_participant_class.event_participant.user.user_addr2,
              city: itm.event_participant_class.event_participant.user
                .user_city,
              state:
                itm.event_participant_class.event_participant.user.user_state,
              zipcode:
                itm.event_participant_class.event_participant.user.user_zip,
              country:
                itm.event_participant_class.event_participant.user.user_country,
            });
          });
        });
        let uniqueChars = [...new Set(finalArr)];
        finalArr?.map((val) => {
          if (val.round == 2) return setRound2Data(true);
          if (val.round == 3) return setRound3Data(true);
          if (val.round == 4) return setRound4Data(true);
        });
        finalArr.sort((a, b) => a.heat - b.heat);
        setData(finalArr);
      })

      .catch((err) => {
        alert(err);
      });

    var eventClassesData1 = await combinedServices.countByClasses(eventId);
    var participantClassData = eventClassesData1.data.participants;
    if (participantClassData) {
      participantClassData.map((classVal) => {
        if (classVal.run_date != null) {
          var data = {
            id: classVal.class_id,
            name: classVal.class_name,
            rundate: classVal.run_date,
            event_class_id: classVal.event_class_id,
            order_key: classVal.order_key,
          };
          classArr.push(data);
          classArr.sort((a, b) => {
            const dateA = new Date(a.run_date);
            const dateB = new Date(b.run_date);

            if (dateA.getTime() === dateB.getTime()) {
              return a.order_key - b.order_key;
            } else {
              return dateA - dateB;
            }
          });
        }
        if (classVal.run_date != null) {
          var data1 = {
            run_date: classVal.run_date,
            class: classVal.class_name,
          };
          dateArr.push(data1);
        }
      });
      classArr.sort((a, b) => {
        if (a.rundate === b.rundate) {
          return a.order_key - b.order_key;
        }
        return new Date(a.rundate) - new Date(b.rundate);
      });
      setClasses(classArr);
      const formattedResponse = {};

      for (const item of dateArr) {
        const runDate = item.run_date;
        const classType = item.class;

        if (runDate in formattedResponse) {
          formattedResponse[runDate].push(classType);
        } else {
          formattedResponse[runDate] = [classType];
        }
      }

      setDates(formattedResponse);
    }
    var data = {
      event_id: eventId,
      classes: true,
    };

    let eventResponse = await combinedServices.getEventParticipate(data);
    setEventParticipant(eventResponse);
    // setTimeout(() => {
    //   if (exportRef.current) {
    //     exportRef.current.save();
    //     navigate("../manage-heatsheet/" + eventId, { replace: true });
    //   }
    // }, 500);
  }, []);

  const exportRef = React.useRef();

  const exportData = [];

  const headerRow = {
    // Date: "Date",
    // Round: "Round",
    FisrtName: "FisrtName",
    LastName: "LastName",
    Heat: "Heat",
    Name: "Name",
    Class: "Class",
    TransponderNumber: " TransponderNumber",
    Email: "Email",
    PhoneNumber: "PhoneNumber",
    Birthday: "Birthday",
    Gender: "Gender",
    City: "City",
    State: "State",
    ZipCode: "ZipCode",
    Country: "Country",
  };
  exportData.push(headerRow);

  const timeaccotolocation2 = (x, timeZone) => {
    const utcMoment = moment.utc(x);
    const localMoment = utcMoment.tz(timeZone);
    return localMoment.format("MMM DD YYYY");
  };

  data.map((x) => {
    const rowData = {
      FirstName: x.firstname,
      LastName: x.lastname,
      Heat: Number(x.heat),
      TransponderNumber: x.transponder_id,
      ClassName: x.class,
      Email: x.email,
      PhoneNumber: x.phone,
      Birthday: x.birthday,
      Gender: x.gender,
      Address1: x.address1,
      Address2: x.address2,
      City: x.city,
      State: x.state,
      ZipCode: x.zipcode,
      Country: x.country,
      Date: timeaccotolocation2(
        classes?.filter((cls) => cls.name == x.class)[0]?.rundate,
        timeZone
      ),
    };

    exportData.push(rowData);
  });

  const excelData = exportData.slice(1);

  const handleDownload = (filename, data) => {
    const blob = new Blob([data], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url);
  };

  const simulateButtonClicks = () => {
    for (let i = 0; i < runDates.length; i++) {
      const formattedDate = moment(runDates[i], "MMM DD, YYYY").format(
        "MMM DD YYYY"
      );
      const dateFilteredData = excelData.filter(
        (data) => data.Date == formattedDate
      );
      const data = [
        [
          // "Date",
          "FirstName",
          "LastName",
          "NickName",
          "PhoneticName",
          
          
          
          "Ability",
          "ClassName",
          
          "IsPaid",
          "PillNumber",
          "LocalRegisteredDateTime",
          "RegistrationNumber",
          "FrameNumber",
          "PermanentNumber",
          "PrimaryColor",
          "SecondaryColor",
          "Manufacturer",
          "ChassisManufacturer",
          "TransponderNumber",
          "CarID",
          "GPSTransponderNumber",
          "SponsorName",
          "Email",
          "PhoneNumber",
          "Birthday",
          "Gender",
          "Address1",
          "Address2",
          "City",
          "State",
          "ZipCode",
          "Country",
          "Hometown",
          "ClubName",
          "ClubRegion",
          "AmericanMotorcyclistAssociationNumber",
          "AmericanMotorcyclistAssociationExpirationDate",
          "AcademyOfModelAeronauticsNumber",
          "AcademyOfModelAeronauticsExpirationDate",
          "HAMCallSign",
          "FAANumber",
          "LocalMembershipType",
          "LocalMembershipCode",
          "LocalMembershipExpirationDate"
        ],
        ...dateFilteredData.map((item) => [
          // item.Date,
          item.FirstName,
          item.LastName,
          "","","",
          item.ClassName,
          "","","","","","","","","","",
          item.TransponderNumber,
        ]),
      ]
        .map((e) => e.join(","))
        .join("\n");
      const filename = `${eventname} ${runDates[i]}`;
      handleDownload(filename, data);
    }
  };

  return (
    <>
      <ButtonBase
        disableRipple
        sx={{
          marginLeft: "10px",
          display: "inline-block",
          background: "#0095fd",
          fontSize: "16px",
          color: "#fff",
          fontWeight: 600,
          padding: "10px 35px",
          "&:hover": {
            backgroundColor: "black",
          },
        }}
        onClick={simulateButtonClicks}
      >
        Transponders
      </ButtonBase>
    </>

    // <div>

    //   {/* <button onClick={handleExport}>Export to Excel</button> */}
    //   <ExcelExport
    //     data={dateFilteredData} // Exclude the first row (fixed row)
    //     ref={exportRef}
    //     fileName={`${eventname} ${runDate}.xlsx`}
    //     filterable={true}
    //     groupable={true}
    //   >

    //     <ExcelExportColumn field="Date" title="Date" width={100} />
    //     {/* <ExcelExportColumn field="Round" title="Round" width={100} /> */}
    //     {/* <ExcelExportColumn field="Heat" title="Heat #" width={100} /> */}

    //     <ExcelExportColumn field="FirstName" title="FirstName" width={100} />
    //     <ExcelExportColumn field="LastName" title="LastName" width={100} />
    //     <ExcelExportColumn field="NickName" title="NickName" width={100} />
    //     <ExcelExportColumn field="PhoneticName" title="PhoneticName" width={100} />
    //     <ExcelExportColumn field="Ability" title="Ability" width={100} />
    //     <ExcelExportColumn field="Class" title="ClassName" width={100} />
    //     <ExcelExportColumn field="IsPaid" title="IsPaid" width={100} />
    //     <ExcelExportColumn field="PillNumber" title="PillNumber" width={100} />
    //     <ExcelExportColumn field="LocalRegisteredDateTime" title="LocalRegisteredDateTime" width={100} />
    //     <ExcelExportColumn field="RegistrationNumber" title="RegistrationNumber" width={100} />
    //     <ExcelExportColumn field="FrameNumber" title="FrameNumber" width={100} />
    //     <ExcelExportColumn field="PermanentNumber" title="PermanentNumber" width={100} />
    //     <ExcelExportColumn field="PrimaryColor" title="PrimaryColor" width={100} />
    //     <ExcelExportColumn field="SecondaryColor" title="SecondaryColor" width={100} />
    //     <ExcelExportColumn field="Manufacturer" title="Manufacturer" width={100} />
    //     <ExcelExportColumn field="ChassisManufacturer" title="ChassisManufacturer" width={100} />
    //     <ExcelExportColumn field="TransponderNumber" title="TransponderNumber" width={100} />
    //     <ExcelExportColumn field="CarID" title="CarID" width={100} />
    //     <ExcelExportColumn field="GPSTransponderNumber" title="GPSTransponderNumber" width={100} />
    //     <ExcelExportColumn field="SponsorName" title="SponsorName" width={100} />
    //     <ExcelExportColumn field="Email" title="Email" width={100} />
    //     <ExcelExportColumn field="PhoneNumber" title="PhoneNumber" width={100} />
    //     <ExcelExportColumn field="Birthday" title="Birthday" width={100} />
    //     <ExcelExportColumn field="Gender" title="Gender" width={100} />
    //     <ExcelExportColumn field="Address1" title="Address1" width={100} />
    //     <ExcelExportColumn field="Address2" title="Address2" width={100} />
    //     <ExcelExportColumn field="City" title="City" width={100} />
    //     <ExcelExportColumn field="State" title="State" width={100} />
    //     <ExcelExportColumn field="ZipCode" title="ZipCode" width={100} />
    //     <ExcelExportColumn field="Country" title="Country" width={100} />
    //     <ExcelExportColumn field="Hometown" title="Hometown" width={100} />
    //     <ExcelExportColumn field="ClubName" title="ClubName" width={100} />
    //     <ExcelExportColumn field="ClubRegion" title="ClubRegion" width={100} />
    //     <ExcelExportColumn field="AmericanMotorcyclistAssociationNumber" title="AmericanMotorcyclistAssociationNumber" width={100} />
    //     <ExcelExportColumn field="AmericanMotorcyclistAssociationExpirationDate" title="AmericanMotorcyclistAssociationExpirationDate" width={100} />
    //     <ExcelExportColumn field="AcademyOfModelAeronauticsNumber" title="AcademyOfModelAeronauticsNumber" width={100} />
    //     <ExcelExportColumn field="AcademyOfModelAeronauticsExpirationDate" title="AcademyOfModelAeronauticsExpirationDate" width={100} />
    //     <ExcelExportColumn field="HAMCallSign" title="HAMCallSign" width={100} />
    //     <ExcelExportColumn field="FAANumber" title="FAANumber" width={100} />
    //     <ExcelExportColumn field="LocalMembershipType" title="LocalMembershipType" width={100} />
    //     <ExcelExportColumn field="LocalMembershipCode" title="LocalMembershipCode" width={100} />
    //     <ExcelExportColumn field="LocalMembershipExpirationDate" title="LocalMembershipExpirationDate" width={100} />
    //   </ExcelExport>
    // </div>
  );
}
