import Header from "../../Components/Layout/Header/Header";
import Footer from "../../Components/Layout/Footer/Footer";
import combinedServices from "../../shared/services/user-service";
import { useParams } from "react-router-dom";
import { NavLink, Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useReducer } from 'react';
import userConstants from "../../shared/constants/user-constants";

const Highlights = () => {
    const params = useParams();
    let navigate = useNavigate();
    const [heatPoints, setHeatPoints] = useState([])
    const [imageUrlsPoints, setImageUrls] = useState([])
    const [baseURL, setBaseUrl] = useState(userConstants.baseURL);


    useEffect(async () => {



        let heatResponse = await combinedServices.EvnetHighlightsById(params.id);
        if (heatResponse.status == 200) {
            setHeatPoints(heatResponse.data.points)
            setImageUrls(heatResponse.data.image_url)
        }





    }, [])


    return (
        <>
            <div className="mainCon">
                <Header />

                <div className="innerBanner">
                    <div className="container flexBox">
                        <div>
                            <div className="innerHd">Highlights</div>
                            <div className="bradcrumb"><a href="/">Home</a>Highlights</div>
                        </div>
                    </div>
                </div>
                <div className="pb50 pt50 whtBg">
                    <div className="container">
                        <div className="highlightBox" >
                            <div className="points">
                                <ul>
                                    {heatPoints.map((value, index) =>

                                        <li key={index}>{value}</li>

                                    )}


                                </ul>
                            </div>
                            <div className="highlightImg">
                                {imageUrlsPoints.map((value) =>
                                    <div className="img">
                                        <img src={baseURL + "images/" + value} alt="" />
                                    </div>

                                )}

                            </div>
                        </div>









                    </div>
                </div>

                <Footer />
            </div>
        </>


    )


}
export default Highlights;