import Header from "../../Components/Layout/Header/Header";
import Footer from "../../Components/Layout/Footer/Footer";
import "./EventDetail.scss";
import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import EventTab from "../../Components/EventTab/EventTab";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import combinedServices from "../../shared/services/user-service";
import { useLocation, useParams } from "react-router-dom";
import { NavLink, Link, useNavigate } from "react-router-dom";
import userConstants from "../../shared/constants/user-constants";
import Login from "../../Pages/Login/Login";
import getLocalData from "../../shared/localStorage/getData";
import { Facebook } from "react-social-sharing";
import { RiTwitterXLine } from "react-icons/ri";
import swal from "sweetalert";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  RedditShareCount,
  TumblrShareCount,
  VKShareCount,
  FacebookShareCount,
  FacebookMessengerShareButton,
  OKShareButton,
} from "react-share";
import MetaDecorator from "../../utils/metaDecorator";
import MapContainer from "./MapContainer";

//map dialog box imports
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

//dialog box
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const EventDetail = (props) => {
  const params = useParams();

  let navigate = useNavigate();

  const [eventDetails, setEventDetails] = useState();
  const [eventImg, setEventImg] = useState();
  const [eventId, setEventId] = useState("");
  const [driverData, setDriverData] = useState([]);
  const [classData, setClassData] = useState([]);
  const [driverModal, setDriverModal] = useState(false);
  const [classModal, setClassModal] = useState(false);
  const [userID, setUserId] = useState("");
  const [baseURL, setBaseUrl] = useState(userConstants.baseURL);
  const [isLogin, setIsLogin] = useState(false);
  const [isJointEvent, setIsJointEvent] = useState(false);
  const [userStatus, setUserStatus] = useState("");
  const [urls, setUrls] = useState("");
  const [descriptions, setDescriptions] = useState("");
  const [headtitle, setHeadTitle] = useState("");
  const [iconshow, setIconshow] = useState("");
  const [driverCount, setDriverCount] = useState("");
  const location = useLocation();
  const [idOfEvent, setIdOfEvent] = useState(
    parseInt(location.pathname.split("/")[2])
  );

  const [userDistrict, setUserDistrict] = useState(0);
  const [venueId, setVenueId] = useState(0);
  const [siteAddress, setSiteAddress] = useState("");

  const shareButtonRef = useRef();
  const twitterButtonRef = useRef();

  // to open map dialog box states and its handlers

  const [open, setOpen] = React.useState(false);
  const [mapLocation, setMapLocation] = useState("");
  const [mapDirections, setMapDirections] = useState("");

  const handleClickOpen1 = () => {
    setMapDirections("place");
    setOpen(true);
  };
  const handleClickOpen2 = () => {
    setMapDirections("directions");
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // useEffect(async ()=>{
  //   const scrappedResponse = await combinedServices.clearFacebookCache({
  //     requestUrl: location.pathname,
  //   });
  //   console.log("scrappedResponse",scrappedResponse);
  // },[])

  useEffect(async () => {
    var data = {
      event_id: idOfEvent,
      classes: true,
    };

    let driverResponse = await combinedServices.getEventParticipate(data);

    setDriverData(driverResponse.data);
    const count = driverData.filter((value) => {
      const allClassesCancelled = value.classes?.every(
        (cls) => cls.participation_status === "CANCELLED"
      );
      return !allClassesCancelled;
    }).length;

    setDriverCount(count);
  }, []);

  useEffect(async () => {
    if (getLocalData("user_info", "isLogin")) {
      var userId = getLocalData("user_info", "all").userData.login_user_detail
        .data.user.id;

      setUserId(userId);
      
      setUserStatus(
        getLocalData("user_info", "all").userData.login_user_detail.data.user
          .user_status
      );
    }
    //setUserStatus("inactive")
    setEventId(params.id);

    setUrls("https://race.namba.com/event-detail/" + params.id);
    let eventResponse = await combinedServices.getEventDetail(params.id);

    let eventDetail = eventResponse.data;

    //setting clubsite id
    setUserDistrict(eventDetail.event_district_id);

    //setting venue id
    setVenueId(eventDetail.venue_clbsite_id);

    if (eventDetail.event_image.startsWith("https://nambaweb.s3")) {
      setEventImg(eventDetail.event_image);
    } else if (eventDetail.event_image != "") {
      setEventImg(baseURL + "images/" + eventDetail.event_image);
    } else {
      setEventImg(
        "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/db_logo.png"
      );
    }
    var event_contact = eventDetail.event_contacts.split(",");

    var data = {
      boatsCount: eventDetail.boatsCount,
      contacts: event_contact,
      status1: eventDetail.event_event_status,
      contact_user_areacodemobile: eventDetail.contact_user_areacodemobile,
      contact_user_areacodephone: eventDetail.contact_user_areacodephone,
      contact_user_email: eventDetail.contact_user_email,
      contact_user_firstname: eventDetail.contact_user_firstname,
      contact_user_lastname: eventDetail.contact_user_lastname,
      contact_user_mobile: eventDetail.contact_user_mobile,
      contact_user_phone: eventDetail.contact_user_phone,
      event_additional_boat_charge: eventDetail.event_additional_boat_charge,
      event_address: eventDetail.event_address,
      event_alternate_frequency_required:
        eventDetail.event_alternate_frequency_required,
      event_city: eventDetail.venue_clbsite_site_city,
      event_country: eventDetail.venue_clbsite_site_country,
      event_created_at: eventDetail.event_created_at,
      event_district_id: eventDetail.event_district_id,
      event_drivers_meeting_time: eventDetail.event_drivers_meeting_time,
      event_event_start: eventDetail.event_event_start,
      event_event_end: eventDetail.event_event_end,
      event_event_type: eventDetail.event_event_type,
      event_extra_rounds_per_class: eventDetail.event_extra_rounds_per_class,
      event_first_boat_charge: eventDetail.event_first_boat_charge,
      event_full_title: eventDetail.event_full_title,
      event_host_club_id: eventDetail.event_host_club_id,
      event_id: eventDetail.event_id,
      event_max_boat_limit: eventDetail.event_max_boat_limit,
      event_min_boats_per_class: eventDetail.event_min_boats_per_class,
      event_registration_close: eventDetail.event_registration_close,
      event_registration_open: eventDetail.event_registration_open,
      event_rounds_per_class: eventDetail.event_rounds_per_class,
      event_short_tile: eventDetail.event_short_tile,
      event_state: eventDetail.venue_clbsite_site_state,
      event_tent_camping: eventDetail.event_tent_camping,
      event_total_days: eventDetail.event_total_days,
      event_updated_at: eventDetail.event_updated_at,
      event_venue: eventDetail.venue_clbsite_site_name,
      event_zip: eventDetail.venue_clbsite_site_zip,
      hostClub_club_name: eventDetail.hostClub_club_name,
      racersCount: eventDetail.racersCount,
      event_image: baseURL + "images/" + eventDetail.event_image,
      event_detail: eventDetail.event_detail,
      hotels: eventDetail.event_hotels,
      time_zone: eventDetail.event_time_zone,
    };
    setEventDetails(data);
    setIconshow(data.event_event_start);
    // setDescriptions("happy"+data.event_venue)
    setHeadTitle(data.event_full_title);
    handleAttendees(params.id);

    // setUserDistrict(eventDetail.)

    {
      /*let registeredUser = await combinedServices.checkIfUserJoined(params.id);

        if (registeredUser.status == 200) {
            if(registeredUser.data.payment){
                setIsJointEvent(false)
            }else{
                setIsJointEvent(true)
            }
       
            

        } else {
            setIsJointEvent(true)

        }*/
    }
    const searchParams = new URLSearchParams(window.location.search);
    const facebookparams = searchParams.get("facebook");
    const twitterparams = searchParams.get("twitter");
    // Check if the 'facebook' parameter is true
    // var facebookParam = getParameterByName("facebook");
    if (facebookparams == "true") {
      if (shareButtonRef.current) {
        shareButtonRef.current.click();
      }
    }
    if (twitterparams == "true") {
      if (twitterButtonRef.current) {
        twitterButtonRef.current.click();
      }
    }

    // finding driver count
  }, []);

  useEffect(async () => {
    const handleLocationByDistric = async (districId) => {
      var response = await combinedServices.getMyClubSite(districId);
      console.log("repsonsee", response);
      console.log("vennnnn", venueId);
      for (let item of response.data) {
        if (item.id == venueId) {
          console.log("itemmmmm", item);
          setSiteAddress(
            `${item.clbsite_site_address1}<br/>${item.clbsite_site_city}, ${item.clbsite_site_state} ${item.clbsite_site_zip}`
          );
          const address = {
            siteName: item.clbsite_site_name,
            address1: item.clbsite_site_address1,
            city: item.clbsite_site_city,
            state: item.clbsite_site_state,
            zip: item.clbsite_site_zip,
            country: item.clbsite_site_country,
          };
          console.log("adresssssss", address);
          setMapLocation(address);
        }
      }
    };

    handleLocationByDistric(userDistrict);
  }, [userDistrict, venueId]);

  const handleAttendeesOnChange = async (e) => {
    var data = {
      event_id: eventId,
    };
    if (e.target.value == 1) {
      data.classes = true;
      let eventResponse = await combinedServices.getEventParticipate(data);
      setDriverData(eventResponse.data);
      setDriverModal(true);
      setClassModal(false);
    } else if (e.target.value == 2) {
      let eventResponse = await combinedServices.getEventParticipateByClasses(
        data
      );
      setClassData(eventResponse.data);
      setClassModal(true);
      setDriverModal(false);
    }
  };
  const handleAttendees = async (id) => {
    setDriverModal(true);
    var data = {
      event_id: id,
      classes: true,
    };

    let eventResponse = await combinedServices.getEventParticipate(data);
    setDriverData(eventResponse.data);
  };

  const checkLoginForJoinEvent = async () => {
    if (userID) {
      if (userStatus == "active") {
        let registeredUser = await combinedServices.checkIfUserJoined(eventId);

        if (registeredUser.status == 200) {
          if (!registeredUser.data.payment) {
            navigate("../event/add-event-step3/" + registeredUser.data.id, {
              replace: true,
            });
          }
        } else {
          window.location.href = "/event/add-event/" + eventId;
        }
      } else {
        swal("Opps", "User status is inactive");
      }
    } else {
      setIsLogin(true);
    }
  };
  const handleEnterEvent = () => {
    if (userID > 0) {
      navigate("../event/add-event/" + eventId, { replace: true });
    } else {
      setIsLogin(true);
    }
  };
  const handleHotelRedirection = (url) => {
    window.location.href = "/event/add-event/" + eventId;
  };

  const checkEndRegistrationDate = (date, timeZone) => {
    var str = "";

    if (
      moment(date).format("YYYY-MM-DD HH:mm:ss") >=
      moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
    ) {
      str = "Closes";
      console.log(moment(date).format("YYYY-MM-DD"), "eventdate---------");
      console.log(moment(new Date()).format("YYYY-MM-DD"));
    } else if (
      moment(date).format("YYYY-MM-DD HH:mm:ss") <
      moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
    ) {
      str = "Closed";
      console.log(moment(date).format("YYYY-MM-DD"), "eventdate---------");
      console.log(moment(new Date()).format("YYYY-MM-DD"));
    }

    return str;
  };
  function compareDates(givenDate) {
    // Parse the given date string to a Date object
    const givenDateTime = new Date(givenDate);

    // Get the current date and time
    const currentDate = new Date();

    // Compare the two dates
    if (givenDateTime > currentDate) {
      return true;
    } else {
      return false;
    }
  }
  const timeaccotolocation = (x) => {
    const utcMoment = moment.utc(x);
    const localMoment = utcMoment.local();
    return localMoment.toString();
  };
  const timeaccotolocation2 = (x, timeZone) => {
    if (timeZone == "") {
      timeZone = "Canada/Eastern";
    }
    const utcMoment = moment.utc(x);
    const localMoment = utcMoment.tz(timeZone);

    // const timeZoneAbbreviation = localMoment.format('z');
    const timeZoneOffset = localMoment.format("Z");
    return localMoment.format("ddd MMM DD YYYY HH:mm:ss");
  };

  return (
    <>
      <div className="mainCon">
        <Header />

        {isLogin ? (
          <Login isLogin={isLogin} path={"/event/add-event/" + eventId} />
        ) : (
          ""
        )}

        <div className="innerBanner event">
          <div className="container flexBox">
            <div>
              <div className="innerHd">Events</div>
              <div className="bradcrumb">
                <a href="/">Home</a>
                <a href="/event-management">Events</a>
              </div>
            </div>
          </div>
        </div>

        <div className="pb50 grayBg">
          <div className="container">
            <div className="whtBox">
              <EventTab />
              {eventDetails && (
                <>
                  <div className="tabCover">
                    {/* 
                    {(() => {
                      if (
                        moment(eventDetails.event_registration_open).format(
                          "YYYY-MM-DD"
                        ) <= moment().format("YYYY-MM-DD") &&
                        moment().format("YYYY-MM-DD") <
                          moment(eventDetails.event_registration_close).format(
                            "YYYY-MM-DD"
                          )
                      ) 
                      {
                        return (
                          <a
                            href="#"
                            onClick={handleEnterEvent}
                            className="blueBtn"
                          >
                            Enter Event
                          </a>
                        );
                      }
                    })()} */}
                    {(() => {
                      if (
                        moment()
                          .tz(
                            eventDetails.time_zone == ""
                              ? "Canada/Eastern"
                              : eventDetails.time_zone
                          )
                          .isSameOrAfter(
                            moment(
                              timeaccotolocation2(
                                eventDetails.event_registration_open,
                                eventDetails.time_zone
                              )
                            ).format("ddd MMM DD YYYY HH:mm:ss")
                          ) &&
                        moment()
                          .tz(
                            eventDetails.time_zone == ""
                              ? "Canada/Eastern"
                              : eventDetails.time_zone
                          )
                          .isSameOrBefore(
                            moment(
                              timeaccotolocation2(
                                eventDetails.event_registration_close,
                                eventDetails.time_zone
                              )
                            ).format("ddd MMM DD YYYY HH:mm:ss")
                          )
                      ) {
                        return (
                          <a
                            href="#"
                            onClick={handleEnterEvent}
                            className="blueBtn"
                          >
                            Enter Event
                          </a>
                        );
                      }
                    })()}
                    {console.log("evvvv", eventImg)}
                    <MetaDecorator
                      title={eventDetails.event_full_title}
                      description={moment(
                        timeaccotolocation2(
                          eventDetails.event_event_start,
                          eventDetails.time_zone
                        )
                      ).format("YYYY-MM-DD")}
                      description1={moment(
                        timeaccotolocation2(
                          eventDetails.event_event_end,
                          eventDetails.time_zone
                        )
                      ).format("YYYY-MM-DD")}
                      description3={moment(
                        timeaccotolocation2(
                          eventDetails.event_registration_open,
                          eventDetails.time_zone
                        )
                      ).format("YYYY-MM-DD")}
                      description4={eventDetails.event_venue}
                      description5={eventDetails.event_state}
                      description6={eventDetails.event_zip}
                      keywords={""}
                      image={eventImg}
                      id={eventId}
                    />
                    {/* <Facebook solid small link="https://concentricsinc.flexsin.org/event-detail/56"/> */}
                    {compareDates(iconshow) ? (
                      <>
                        <FacebookShareButton
                          url={"https://race.namba.com/event-detail/" + eventId}
                          ref={shareButtonRef}
                          quote={"racenamba"}
                          hashtag="#namba"
                          description={
                            "Event Start" +
                            " | " +
                            timeaccotolocation2(
                              eventDetails.event_event_start,
                              eventDetails.time_zone
                            ) +
                            " | " +
                            "Registration Start" +
                            " | " +
                            timeaccotolocation2(
                              eventDetails.event_registration_open,
                              eventDetails.time_zone
                            ) +
                            " | " +
                            "Venue" +
                            " | " +
                            eventDetails.event_venue +
                            " | " +
                            eventDetails.event_state +
                            " | " +
                            eventDetails.event_zip
                          }
                          title={headtitle}
                          image={eventImg}
                          className="m-2"
                        >
                          <FacebookIcon size={32} round={true} />
                        </FacebookShareButton>

                        <TwitterShareButton
                          ref={twitterButtonRef}
                          url={"https://race.namba.com/event-detail/" + eventId}
                          title={eventDetails.event_full_title}
                          description={
                            "Event Start" +
                            " | " +
                            timeaccotolocation2(
                              eventDetails.event_event_start,
                              eventDetails.time_zone
                            ) +
                            " | " +
                            "Registration Start" +
                            " | " +
                            timeaccotolocation2(
                              eventDetails.event_registration_open,
                              eventDetails.time_zone
                            ) +
                            " | " +
                            "Venue" +
                            " | " +
                            eventDetails.event_venue +
                            " | " +
                            eventDetails.event_state +
                            " | " +
                            eventDetails.event_zip
                          }
                          className="m-2"
                        >
                          <RiTwitterXLine size={32} round={true} />
                        </TwitterShareButton>
                      </>
                    ) : null}
                  </div>

                  <div className="d-flex flex-wrap">
                    <div className="eventDetLeft">
                      <div className="inner evdetTop text-center">
                        <div className="img">
                          {eventImg.slice(0, 16) === "https://nambaweb" ? (
                            <img src={eventImg} />
                          ) : eventDetails.event_image ==
                            `${baseURL}` + "images/" ? (
                            <img
                              src={`/static/media/logo.d22ca8f16fd8e85ccbd9.png`}
                            />
                          ) : (
                            <img src={`${eventDetails.event_image}`} />
                          )}
                        </div>
                        <div className="text">
                          <span>{eventDetails.event_full_title}</span>
                          {moment(
                            timeaccotolocation2(
                              eventDetails.event_event_start,
                              eventDetails.time_zone
                            )
                          ).format("ddd MMM DD YYYY")}
                          {eventDetails.event_event_start !=
                            eventDetails.event_event_end &&
                            ` - ${moment(
                              timeaccotolocation2(
                                eventDetails.event_event_end,
                                eventDetails.time_zone
                              )
                            ).format("ddd MMM DD YYYY")} ${
                              eventDetails.time_zone == "HST"
                                ? "(Hawaiin)"
                                : eventDetails.time_zone == ""
                                ? ""
                                : `(${eventDetails.time_zone.split("/")[1]})`
                            }`}
                          <br />
                          Entries:{" "}
                          {
                            driverData.filter((value) => {
                              const allClassesCancelled = value.classes?.every(
                                (cls) =>
                                  cls.participation_status === "CANCELLED"
                              );
                              return !allClassesCancelled;
                            }).length
                          }{" "}
                          drivers, {eventDetails.boatsCount} boats
                        </div>
                      </div>
                      <div className="inner location">
                        <span>Location</span>
                        {eventDetails.event_venue}
                        <br />
                        <p dangerouslySetInnerHTML={{ __html: siteAddress }} />
                        {/* {eventDetails.event_city} , {eventDetails.event_state} ,{" "}
                        {eventDetails.event_zip}.<br /> */}
                        <button onClick={handleClickOpen1}>Map</button> |{" "}
                        <button onClick={handleClickOpen2}>Directions</button>
                        <BootstrapDialog
                          onClose={handleClose}
                          aria-labelledby="customized-dialog-title"
                          open={open}
                        >
                          <DialogTitle
                            sx={{ m: 0, p: 2 }}
                            id="customized-dialog-title"
                          >
                            Event Location
                          </DialogTitle>
                          <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                              position: "absolute",
                              right: 8,
                              top: 8,
                              color: (theme) => theme.palette.grey[500],
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                          <DialogContent dividers>
                            <Typography
                              gutterBottom
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <MapContainer
                                address={mapLocation}
                                mapDirections={mapDirections}
                              />
                            </Typography>
                          </DialogContent>
                          <DialogActions></DialogActions>
                        </BootstrapDialog>
                      </div>
                      <div className="inner location">
                        <span>
                          Registration Open (
                          {moment(
                            timeaccotolocation2(
                              eventDetails.event_registration_open,
                              eventDetails.time_zone
                            )
                          ).format("ddd MMM DD YYYY")}
                          {/* {moment(timeaccotolocation(eventDetails.event_registration_open)).format(
                            "ddd , MMM D , YYYY"
                          )} */}
                          )
                        </span>
                        <span>
                          {/* Registration{" "}
                          {checkEndRegistrationDate(
                            moment(timeaccotolocation(
                              eventDetails.event_registration_close
                            )).format("YYYY-MM-DD")
                          )}{" "} */}
                          Registration{" "}
                          {checkEndRegistrationDate(
                            moment(
                              timeaccotolocation2(
                                eventDetails.event_registration_close,
                                eventDetails.time_zone
                              )
                            ).format("YYYY-MM-DD")
                          )}{" "}
                          (
                          {moment(
                            timeaccotolocation2(
                              eventDetails.event_registration_close,
                              eventDetails.time_zone
                            )
                          ).format("ddd , MMM D , YYYY")}
                          )
                        </span>
                      </div>

                      <div className="inner location">
                        {eventDetails.hotels && (
                          <>
                            <span>Hotel Reservations</span>
                            {eventDetails.hotels.map((value, index) => {
                              return (
                                <>
                                  <a href={`${value.url}`} target="_blank">
                                    {value.name}
                                  </a>{" "}
                                  {eventDetails.hotels.length == index + 1
                                    ? ""
                                    : "|"}
                                </>
                              );
                            })}
                          </>
                        )}

                        <br />
                        <br />
                        <span>Hosting Club</span>
                        <a href="#">{eventDetails.hostClub_club_name}</a>

                        {eventDetails.contacts.map((cont_details) => {
                          return (
                            <>
                              <p className="contactInfo">{cont_details}</p>
                            </>
                          );
                        })}
                      </div>
                    </div>
                    <div className="eventDetRight">
                      <span className="hd">Event Details</span>
                      <ul className="list">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: eventDetails.event_detail,
                          }}
                        />
                        {/* <img src={eventDetails.event_detail} /> */}
                      </ul>
                    </div>
                  </div>
                </>
              )}
              <div className="eventDetBot">
                <div className="hd">Entrants</div>
                <div className="flexBox nowrap itemCenter districtSelectBox">
                  View by:
                  <select onChange={handleAttendeesOnChange}>
                    <option value="1">Driver</option>
                    <option value="2">Classes</option>
                  </select>
                </div>

                {driverModal && (
                  <Accordion allowZeroExpanded="true" preExpanded={[0]}>
                    {driverData.length > 0
                      ? driverData
                          .filter((driver) => {
                            for (let item of driver.classes) {
                              if (item.participation_status == "ENTERED") {
                                return true;
                              }
                            }
                          })
                          .map((value, index) => {
                            // ? driverData.map((value, index) => {
                            //     const allClassesCancelled = value.classes.every(
                            //       (cls) => cls.participation_status === "CANCELLED"
                            //     );
                            return (
                              <>
                                <>
                                  <AccordionItem uuid={index}>
                                    <AccordionItemHeading>
                                      <AccordionItemButton>
                                        {value.user && (
                                          <div className="accQ d-flex justify-content-between">
                                            <div>
                                              <NavLink
                                                to={
                                                  "/race-result-profile/" +
                                                  value.user.id
                                                }
                                              >
                                                {value.user.user_firstname +
                                                  " " +
                                                  value.user.user_lastname}
                                              </NavLink>
                                            </div>
                                          </div>
                                        )}
                                      </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                      <div className="acctable">
                                        <table>
                                          {value.classes
                                            ?.filter((singleClass) => {
                                              if (
                                                singleClass.participation_status ==
                                                "ENTERED"
                                              ) {
                                                return true;
                                              }
                                            })
                                            .map((cls, index) => (
                                              <tr key={cls.transponder_id}>
                                                {cls.transponder_id !== 0 ? (
                                                  <table className="userinfo">
                                                    {index === 0 && (
                                                      <thead>
                                                        <tr>
                                                          <th>Class</th>
                                                          <th>
                                                            Transponder Id
                                                          </th>
                                                          <th>
                                                            Transponder Name
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                    )}
                                                    <tbody>
                                                      <tr>
                                                        <td>
                                                          {cls.class?.name}
                                                        </td>
                                                        <td>
                                                          {cls.transponder_id}
                                                        </td>
                                                        <td>
                                                          {cls.transponder_name}
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                ) : (
                                                  <td>{cls.class?.name}</td>
                                                )}
                                              </tr>
                                            ))}
                                        </table>
                                      </div>
                                    </AccordionItemPanel>
                                  </AccordionItem>
                                </>
                              </>
                            );
                          })
                      : ""}
                  </Accordion>
                )}
                {classModal && (
                  <Accordion allowZeroExpanded="true" preExpanded={[0]}>
                    {classData.length > 0
                      ? classData.map((value, classIndex) => {
                          return (
                            <>
                              <AccordionItem uuid={classIndex}>
                                <AccordionItemHeading>
                                  <AccordionItemButton>
                                    <div className="accQ d-flex justify-content-between">
                                      <div>{value.class_name}</div>
                                    </div>
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  <div className="acctable">
                                    <table className="userinfo">
                                      {value.participants.map(
                                        (participate, index) =>
                                          participate.eventParticipantClass_participation_status !=
                                            "CANCELLED" && (
                                            <>
                                              {participate.transponder_id !==
                                                0 &&
                                                index === 0 && (
                                                  <thead>
                                                    <tr>
                                                      <th>Driver</th>
                                                      <th>Transponder Id</th>
                                                      <th>Transponder Name</th>
                                                    </tr>
                                                  </thead>
                                                )}

                                              <tr>
                                                {participate.transponder_id !==
                                                0 ? (
                                                  <td>
                                                    <NavLink
                                                      to={
                                                        "/race-result-profile/" +
                                                        participate.user_user_id
                                                      }
                                                    >
                                                      {participate.user_user_firstname +
                                                        " " +
                                                        participate.user_user_lastname}
                                                    </NavLink>
                                                  </td>
                                                ) : (
                                                  <div>
                                                    <NavLink
                                                      to={
                                                        "/race-result-profile/" +
                                                        participate.user_user_id
                                                      }
                                                    >
                                                      {participate.user_user_firstname +
                                                        " " +
                                                        participate.user_user_lastname}
                                                    </NavLink>
                                                  </div>
                                                )}

                                                {participate.transponder_id !==
                                                0 ? (
                                                  <td>
                                                    {participate.transponder_id}
                                                  </td>
                                                ) : (
                                                  ""
                                                )}
                                                {participate.transponder_id !==
                                                0 ? (
                                                  <td>
                                                    {
                                                      participate.transponder_name
                                                    }
                                                  </td>
                                                ) : (
                                                  ""
                                                )}
                                              </tr>
                                            </>
                                          )
                                      )}
                                    </table>
                                  </div>
                                </AccordionItemPanel>
                              </AccordionItem>
                            </>
                          );
                        })
                      : ""}
                  </Accordion>
                )}
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default EventDetail;
