import { savePDF } from "@progress/kendo-react-pdf";
import logo from '../../../assets/images/logo.png'

class DocService {
  createPdf = (html,eventTitle,eventDate) => {
    // const logo = "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/logo.png"
    let pageNumber = 1; // initialize the page number
    savePDF(html, {
      paperSize: "Letter",
      fileName: "payments.pdf",
      margin: { top: "0.6in", left: "0.6in", right: "0.6in", bottom: "0.6in" },
      orientation: "landscape",
      pageTemplate: ({ totalPages }) => (
        <>
          <div style={{ position: "absolute", top: "20px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingBottom: "30px",
                width:'100%',
                opacity: "0.7",
              }}
            >
              <img src={logo} style={{ width: "120px", height: "120px" }} />

              
             
            </div>
          </div>

          <div style={{position:'absolute',top:'50px',textAlign:'center',left:'38%'}}><span
                style={{
                  textAlign: "center",
                  color: "gray",
                  fontWeight: "600",
                  
                }}
              >
               {eventTitle},{eventDate}<br />
                Driver Check-in
              </span></div>
          <div
            style={{
              position: "absolute",
              bottom: 0,
              textAlign: "center",
              left:'-3%',
              width: "100%",
            }}
          >
            <span>
              Page {pageNumber++} of {totalPages}
            </span>
          </div>
          <style>
            {` 
              
               table tr td:nth-child(5) {
                width: 6%;
              }
              table tr td:nth-child(1) {
                width: 21%;
              }
              table tr td:nth-child(2) {
                width: 16%;
              }
              table tr td:nth-child(3) {
                width: 16%;
              }
              table tr td:nth-child(4) {
                width: 16%;
              }
              table tr td:nth-child(6) {
                width: 25%;
              }
              
            `}
          </style>
        </>
      ),
      scale: 0.8, // optional, use if you want to scale down the content to fit on the page

      // Add this style to your root element to break pages as needed
      style: {
        "@media print": {
          // add a page break before and after each element with class "break-page"
          ".break-page": {
            pageBreakBefore: "always",
            pageBreakAfter: "always",
          },
          
        },
      },
    });
  };
}

const Doc = new DocService();
export default Doc;
