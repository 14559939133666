import React, { useState, useEffect } from 'react';
import { NavLink, Link } from "react-router-dom";
import getLocalData from "../../../shared/localStorage/getData";
import getRemoveData from "../../../shared/localStorage/removeData";
import combinedServices from "../../../shared/services/user-service";

const Header = () => {

    const [isLogin, setIsLogin] = useState(false);
    const [isEventManagement, setIsManagement] = useState(false)
    const[isEventManage,setIsEventManage]=useState(true)
    const db_logo = "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/db_logo.png"
    const user_img = "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/user_img.jpg"


    useEffect(async() => {
        if (getLocalData("user_info", "isLogin")) {
            setIsLogin(getLocalData("user_info", "isLogin"));
            var loginUserData = getLocalData("user_info", "all").userData.login_user_detail.data.user;
          
          
        
            var data = {
                user_id: loginUserData.id,
                clubs: true,
                events: true,
                district: true,
              };
              let profileResponse = await combinedServices.getProfileDetails(data);
              if(profileResponse.data.resban=="yes"){
                setIsEventManage(false)
              }
            //   setIsEventManage(profileResponse.data.resban)
              
              if (loginUserData.clubOfficerIn.length > 0 || loginUserData.district!=null) {
                setIsManagement(true)
            }


        }


    }, [])

    const handleLogout = () => {
        
        getRemoveData("user_info");
        localStorage.clear();
        window.location.href = "/";
    }

    const [isActive, setActive] = useState(false);

    const toggleClass = () => {
        setActive(!isActive);
    };



    return (

        <>
            <a className={isActive ? 'showDBMenu active' : 'showDBMenu'}
                onClick={toggleClass} ><span><i></i><i></i><i></i></span></a>
            <div className={isActive ? 'dbLeft active' : 'dbLeft'}>
                <div className="dbLogo"><NavLink to="/"><img src={db_logo} alt="" /></NavLink></div>
                <div className="dbMenu">
                    <ul>
                        {isEventManagement ?
                            <li key="home"><NavLink to="/club-dashboard"><span className="icon"><span className="material-icons">home</span></span>
                               Dashboard Home</NavLink></li>
                            : <li key="user-dashboard"><NavLink to="/user-dashboard">
                                <span className="icon"><span className="material-icons">home</span></span>
                                Home</NavLink></li>}

                        <li key="my-race"><NavLink to="/my-race"><span className="icon"><span className="material-icons">directions_boat</span></span> My
                            Races</NavLink></li>
                        <li key="my-result" ><NavLink to="/my-result"><span className="icon"><span className="material-icons">leaderboard</span></span> My
                            Results</NavLink></li>
                        {isEventManagement && isEventManage &&
                            <li key="event-management"><NavLink to="/event-management"><span className="icon"><span
                            className="material-icons">date_range</span></span> Event Management</NavLink></li>
                        }
                        {isEventManagement && isEventManage &&
                            <li key="paypal-credentail"><NavLink to="/paypal-credentail"><span className="icon"><span
                            className="material-icons">date_range</span></span> Paypal Credential</NavLink></li>
                        }


                        <li key="payment"><NavLink to="/payment"><span className="icon"><span className="material-icons">directions_boat</span></span> Payments
                        </NavLink></li>
                       


                        {/* <li key="club-setting"><NavLink to="/club-setting"><span className="icon"><span className="material-icons">settings</span></span> Settings</NavLink>
                        </li> */}

                        <li key="logout"><NavLink to="#" onClick={handleLogout}><span className="icon"><span className="material-icons">logout</span></span> Logout</NavLink>
                        </li>
                    </ul>
                </div>
                <div className="userImg"><NavLink to="/club-profile"><img src={user_img} alt="" /></NavLink></div>

            </div>


        </>



    )


}
export default Header;