import React, { useState, useEffect } from "react";
import Header from "../../../Components/clubDashboard/Header/Header";
import Switch from "react-switch";
import combinedServices from "../../../shared/services/user-service";
import getLocalData from "../../../shared/localStorage/getData";
import { NavLink, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { Spinner } from "reactstrap";
import moment from "moment-timezone";
import getRemoveData from "../../../shared/localStorage/removeData";
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
moment.tz.setDefault("timeZone");

const PaymentGateway = () => {
  let navigate = useNavigate();
  const params = useParams();
  const [isLoder, setIsLoder] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  const [eventId, setEventId] = useState(params.id);

  useEffect(async () => {
    if (getLocalData("user_info", "isLogin")) {
      let paymentResponse = await combinedServices.getAllUserPaymentStatus(
        params.id
      );
      if (paymentResponse.status == 401) {
        swal(paymentResponse.msg, "Please Re-login", "warning").then(() => {
          getRemoveData("user_info");
          localStorage.clear();
          window.location.href = "/login";
        });
      }
      if (paymentResponse.status == 200) {
        setIsLoder(false);
        setPaymentData(paymentResponse.data);
      }
    }
  }, []);

  const getAllPaymentDetails = async () => {
    if (getLocalData("user_info", "isLogin")) {
      let paymentResponse = await combinedServices.getAllUserPaymentStatus(
        eventId
      );
      if (paymentResponse.status == 401) {
        swal(paymentResponse.msg, "Please Re-login", "warning").then(() => {
          getRemoveData("user_info");
          localStorage.clear();
          window.location.href = "/login";
        });
      }
      if (paymentResponse.status == 200) {
        setIsLoder(false);
        setPaymentData(paymentResponse.data);
      }
    }
  };

  const handleUpdatePaymentStatus = async (e, id) => {
 

    var data = {
      id: id,
      payment_status: e.target.value,
    };

    let paymentResponse = await combinedServices.updatePaymentUserStatus(data);

    if (paymentResponse.status == 200) {
      swal({ title: "Payment status update successfully" }).then(() => {
        getAllPaymentDetails();
      });
    } else if (paymentResponse.status == 401) {
      swal("!Opps", PaymentResponse.msg, "error").then(async (value) => {
        window.location.href = "/";
      });
    }
  };

  return (
    <>
      <Header />

      <div className="dbAdminRight">
        <div className="dbMid dbInner">
          <div className="flexBox spacebetween topHdBox">
            <div className="left">
              <div className="dbMainHd">PAYMENT DETAILS</div>
            </div>
          </div>
          <div className="flexBox spacebetween">
            <div className="pgBox whtBox">
              <div className="interfaceTable">
                <table>
                  <thead>
                    <tr>
                      <th>Sr. No</th>
                      <th>User Name</th>
                      <th>Payment Type</th>
                      <th>Payment Status</th>
                      <th>Update Payment Status</th>
                    </tr>
                  </thead>
                  {isLoder ? (
                    <div className="text-center mt-4 loadMore">
                      <Spinner color="primary" />
                    </div>
                  ) : (
                    <>
                      <tbody>
                        {paymentData.length > 0
                          ? paymentData.map((paymentDetails, index) => {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>
                                    {paymentDetails.user
                                      ? paymentDetails.user.user_firstname +
                                        " " +
                                        paymentDetails.user.user_lastname
                                      : ""}
                                  </td>
                                  <td>
                                    {paymentDetails.payment_type ==
                                    "PAY_AT_RACE"
                                      ? "PAY AT RACE"
                                      : ""}{" "}
                                    {paymentDetails.payment_type == "MAIL_IN"
                                      ? "MAIN IN"
                                      : ""}{" "}
                                    {paymentDetails.payment_type == "PAYPAL"
                                      ? "PAYPAL"
                                      : ""}{" "}
                                  </td>
                                  <td>{paymentDetails.payment_status}</td>
                                  <td>
                                    <select
                                      className="custom-select"
                                      value={paymentDetails.payment_status}
                                      onChange={(e) =>
                                        handleUpdatePaymentStatus(
                                          e,
                                          paymentDetails.id
                                        )
                                      }
                                    >
                                      <option value="PENDING">Pending</option>
                                      <option value="SUCCESS">Success</option>
                                    </select>
                                  </td>
                                </tr>
                              );
                            })
                          : ""}
                      </tbody>
                    </>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PaymentGateway;
