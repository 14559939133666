import React from "react";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
const ReadOnlyRow = ({ contact, handleEditClick, handleDeleteClick }) => {
  return (
    <tr>
      <td>{contact.id}</td>
      <td>{contact.transponder_name}</td>
      {/* <td>{contact.address}</td>
      <td>{contact.phoneNumber}</td>
      <td>{contact.email}</td> */}
      <td>
        <IconButton
          aria-label="delete"
          onClick={(event) => handleEditClick(event, contact)}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          aria-label="delete"
          onClick={() => handleDeleteClick(contact.id)}
        >
          <DeleteIcon />
        </IconButton>
      </td>
    </tr>
  );
};

export default ReadOnlyRow;
