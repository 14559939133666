
import React, { useState, useEffect } from 'react';
import './Chat.scss';

export default function Chat() {
    const [isActive, setActive] = useState(false);
const chat = "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/chat-icon.png"
    const toggleClass = () => {
        setActive(!isActive);
    };
    return (
        <div>
            <div className={isActive ? 'chatBox active' : 'chatBox'}>
                <div className="chatTop">
                    <div className="d-flex">
                        <div className="chatLeft">
                            <div className="hd">User List</div>
                            <ul className="chatUserList">
                                <li><i style={{ background: '#16d6d7' }}>J</i> John Smith</li>
                                <li><i style={{ background: '#fe7446' }}>B</i> Batuhan Kara</li>
                                <li><i style={{ background: '#dfb606' }}>C</i> Cuberto</li>
                                <li><i style={{ background: '#876fd1' }}>M</i> Marina Zakharava</li>
                                <li><i style={{ background: '#11d9d9' }}>R</i> RoomainTrystram</li>
                                <li><i style={{ background: '#fe7348' }}>I</i> Irakli Meskhi</li>
                                <li><i style={{ background: '#e2b400' }}>N</i> Nikitin</li>
                                <li><i style={{ background: '#7153c5' }}>A</i> Aleksandar Savic</li>
                            </ul>
                        </div>
                        <div className="chatRight">
                            <div className="hd d-flex align-items-center justify-content-between">
                                <div>John Smith +2</div>
                                <div className="d-flex align-items-center actionBtns">
                                    <button type="button" onClick={toggleClass}><span class="material-icons">remove</span></button>
                                    <button type="button" onClick={toggleClass}><span class="material-icons">close</span></button>
                                </div>
                            </div>
                            <div className="messageList">
                                <ul>
                                    <li className="right">
                                        <div className="inner">Hello! We're one step closer to connecting you and a guide. Please tell us your first name to get started:</div>
                                    </li>
                                    <li className="left">
                                        <div className="userIcon">J</div>
                                        <div className="inner">
                                            <div className="top d-flex justify-content-between">
                                                <span className="name">Jhon</span>
                                                <span className="date">You at 15:58, Dec 23</span>
                                            </div>
                                            <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit</div>
                                            <div className='moreLink'><a><span class="material-icons">more_vert</span></a></div>
                                        </div>
                                    </li>
                                    <li className="left">
                                        <div className="userIcon">J</div>
                                        <div className="inner">
                                            <div className="top d-flex justify-content-between">
                                                <span className="name">Jhon</span>
                                                <span className="date">You at 15:58, Dec 23</span>
                                            </div>
                                            <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit</div>
                                            <div className='moreLink'><a><span class="material-icons">more_vert</span></a></div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="chatMessage">
                                <input type="text" className="input" placeholder="Type your message here..." />
                                <button type="button" className="sendBtn">Send</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div><a onClick={toggleClass}><img src={chat} alt="" /></a></div>
            </div>
        </div>
    )
}
