import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import combinedServices from "../../shared/services/user-service";
import userConstants from "../../shared/constants/user-constants";
import swal from "sweetalert";
import getRemoveData from "../../shared/localStorage/removeData";

// import PdfContainer from "./PdfContainer";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";

export default function DownloadScoreExcel() {
  // get event id from params
  let eventId = useParams().id;
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [classes, setClasses] = useState([]);

  const [eventname, setEventname] = useState("");
  const [baseURL, setBaseUrl] = useState(userConstants.baseURL);
  const [timezone, setTimezone] = useState("");
  const AltImg =
    "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/logo.png";
  const [eventDetails, setEventDetails] = useState([]);
  const [totalRounds, setTotalRounds] = useState(4);
  const [regischarge, setRegischarge] = useState("");
  const [fees, setFees] = useState("");
  const [rounds, setRounds] = useState([]);
  const [eventParticipant, setEventParticipant] = useState([]);
  const [dates, setDates] = useState([]);
  const [round2Data, setRound2Data] = useState(false);
  const [round3Data, setRound3Data] = useState(false);
  const [round4Data, setRound4Data] = useState(false);
  const [masterarr, setMasterarr] = useState({});
  const [scoredata1, setScoredata1] = useState([]);
  const [scoredata3, setScoredata3] = useState([]);
  const [arrayScr, setArrayScr] = useState([]);

  useEffect(async () => {
    let finalArr = [];
    let classArr = [];
    let dateArr = [];
    await combinedServices
      .getClassByEventId(eventId)
      .then((res) => {
        if (res.status == 401) {
          swal(res.msg, "Please Re-login", "warning").then(() => {
            getRemoveData("user_info");
            localStorage.clear();
            window.location.href = "/login";
          });
        }
        setEventDetails(res.data);

        //to set total number of rounds

        setTotalRounds(res.data.rounds_per_class);

        setRegischarge(res.data.registration_charge);
        setFees(res.data.boat_charge);
        setEventname(res.data.full_title);

        let round = [];
        for (var i = 1; i <= res.data.rounds_per_class; i++) {
          round.push(i);
        }
        setRounds(round);
      })
      .catch((err) => {
        alert(err);
      });

    await combinedServices
      .getEventParticipantForHeat({ event_id: eventId })
      .then((res) => {
        if (res.status == 401) {
          swal(res.msg, "Please Re-login", "warning").then(() => {
            getRemoveData("user_info");
            localStorage.clear();
            window.location.href = "/login";
          });
        }
        setEventParticipant(res.data);
      })
      .catch((err) => {
        alert(err);
      });
    //getting class only
    var eventClassesData1 = await combinedServices.countByClasses(eventId);
    var participantClassData = eventClassesData1.data.participants;
    if (participantClassData) {
      participantClassData.map((classVal) => {
        if (classVal.run_date != null) {
          var data = {
            id: classVal.class_id,
            name: classVal.class_name,
            rundate: classVal.run_date,
            event_class_id: classVal.event_class_id,
            order_key: classVal.order_key,
          };
          classArr.push(data);
          classArr.sort((a, b) => {
            const dateA = new Date(a.run_date);
            const dateB = new Date(b.run_date);

            if (dateA.getTime() === dateB.getTime()) {
              return a.order_key - b.order_key;
            } else {
              return dateA - dateB;
            }
          });
        }
        if (classVal.run_date != null) {
          var data1 = {
            run_date: classVal.run_date,
            class: classVal.class_name,
          };
          dateArr.push(data1);
        }
      });
      classArr.sort((a, b) => {
        if (a.rundate === b.rundate) {
          return a.order_key - b.order_key;
        }
        return new Date(a.rundate) - new Date(b.rundate);
      });
      setClasses(classArr);
      const uniqueArr = [];
      dateArr.forEach((obj) => {
        if (!uniqueArr.some((item) => item.run_date === obj.run_date)) {
          uniqueArr.push(obj);
        }
      });
      setDates(uniqueArr);
    }
    await combinedServices
      .getAllHeatSheet({ event_id: eventId })
      .then(async (res) => {
        if (res.status == 401) {
          swal(res.msg, "Please Re-login", "warning").then(() => {
            getRemoveData("user_info");
            localStorage.clear();
            window.location.href = "/login";
          });
        }
        res.data.map((val, i) => {
          return val.participants.map((itm, i) => {
            return finalArr.push({
              class_id: itm.event_participant_class.class.id,
              class: itm.event_participant_class.class.name,
              heat: val.heat_number,
              name:
                itm.event_participant_class.event_participant.user
                  .user_firstname +
                " " +
                itm.event_participant_class.event_participant.user
                  .user_lastname,
              score: "",
              round: itm.round,
              id: itm.event_participant_class.event_participant.user.id,
              scratch: itm.scratch,
            });
          });
        });
        let uniqueChars = [...new Set(finalArr)];
        finalArr?.map((val) => {
          if (val.round == 2) return setRound2Data(true);
          if (val.round == 3) return setRound3Data(true);
          if (val.round == 4) return setRound4Data(true);
        });
        setData(finalArr);
      })

      .catch((err) => {
        alert(err);
      });
  }, []);

  useEffect(async () => {
    const result2 = {};

    data.forEach((item) => {
      const {
        class: className,
        heat,
        name,
        round,
        id,
        class_id,
        isInvalid,
        scratch,
      } = item;

      if (!result2[className]) {
        result2[className] = [];
      }

      const existingEntry = result2[className].find(
        (entry) => entry.username === name
      );

      if (existingEntry) {
        existingEntry[`Round${round}`] = heat;
      } else {
        const newEntry = {
          username: name,
          [`Round${round}`]: heat,
          id: id,
          class_id: class_id,
          isInvalid: false,
          scratch: scratch,
        };
        result2[className].push(newEntry);
      }
    });

    setMasterarr(result2);

    const classNames = classes.map((cls) => cls.name);

    const filteredarray = Object.keys(result2)
      .filter((className) => classNames.includes(className))
      .reduce((obj, className) => {
        obj[className] = result2[className];
        return obj;
      }, {});

    const transformedData = Object.values(filteredarray).flatMap(
      (participants) =>
        participants.map((participant, i) => ({
          class_Id: participant.class_id,
          participant_Id: participant.id,
          total: 0, // Placeholder, you'll need to calculate the total score
          round1_Score: 0,
          round2_Score: 0,
          round3_Score: 0,
          round4_Score: 0,
          round5_Score: 0,
          round6_Score: 0,
          round7_Score: 0,
          isInvalid: false,
          key: i + 1,
        }))
    );
    const transformedData1 = transformedData.map((obj, index) => ({
      ...obj,
      key: index,
    }));

    setScoredata3(transformedData1);

    // setScoredata(scoreres.data);
    let scoreres = await combinedServices.getScoreByEventID({
      event_id: eventId,
    });

    if (scoreres.status == "200") {
      setArrayScr(scoreres.data);
    }

    if (Object.keys(scoreres.data).length !== 0) {
      for (var key in scoreres.data) {
        const participant_Id = scoreres.data[key].participant_Id;
        const class_Id = scoreres.data[key].class_Id;

        for (var transformedKey in transformedData) {
          if (
            transformedData[transformedKey].participant_Id === participant_Id &&
            transformedData[transformedKey].class_Id === class_Id
          ) {
            transformedData[transformedKey] = scoreres.data[key];
            break; // Exit the loop once the match is found
          }
        }
      }
      const transformedData1 = transformedData.map((obj, index) => ({
        ...obj,
        key: index,
      }));

      // console.log(dataArrayWithKey);
      setScoredata1(transformedData1);
    } else {
      setScoredata1(transformedData1);
    }
  }, [data]);

  const exportRef = React.useRef();

  const exportData = [];

  useEffect(async () => {});

  const headerRow = {
    Driver: "Driver",
    Round1: "R1",
    Round2: "R2",
    Round3: "R3",
    Round4: "R4",
    Round5: "R5",
    Round6: "R6",
    Round7: "R7",
    Total: "Total",
  };
  exportData.push(headerRow);

  if (masterarr) {
    for (let cls in masterarr) {
      const data = {
        Driver: cls,
        Round1: "",
        Round2: "",
        Round3: "",
        Round4: "",
        Round5: "",
        Round6: "",
        Round7: "",
        Total: "",
      };
      exportData.push(data);
      for (let participant of masterarr[String(cls)]) {
        const foundParticipant = scoredata1.find(
          (obj) =>
            obj.participant_Id == participant.id &&
            obj.class_Id == participant.class_id
        );
        if (foundParticipant) {
          const data = {
            Driver: participant.username,
            Round1: foundParticipant.round1_Score,
            Round2: foundParticipant.round2_Score,
            Round3: foundParticipant.round3_Score,
            Round4: foundParticipant.round4_Score,
            Round5: foundParticipant.round5_Score,
            Round6: foundParticipant.round6_Score,
            Round7: foundParticipant.round7_Score,
            Total: foundParticipant.total,
          };
          exportData.push(data);
        }
      }

      const space = {
        Driver: "",
        Round1: "",
        Round2: "",
        Round3: "",
        Round4: "",
        Round5: "",
        Round6: "",
        Round7: "",
        Total: "",
      };
      exportData.push(space);
    }
  }
  const handleDownload = () => {
    exportRef.current.save();
  };

  return (
    <div>
      <button
        onClick={handleDownload}
        style={{
          backgroundColor: "#0095fd",
          color: "white",
          padding: "10px",
          borderRadius: "8px",
        }}
      >
        Download Excel
      </button>
      <ExcelExport
        data={exportData.slice(1)} // Exclude the first row (fixed row)
        ref={exportRef}
        fileName={`${eventname}_score.xlsx`}
        filterable={true}
        groupable={true}
      >
        <ExcelExportColumn
          field="Driver"
          title="Driver"
          width={200}
          
          // cellOptions={{
          //   bold: true, // Apply bold formatting to the Driver column cells
          // }}
        />

        <ExcelExportColumn field="Round1" title="R1" width={100} headerCellOptions={{
            textAlign: "right",
          }}/>
        <ExcelExportColumn field="Round2" title="R2" width={100} headerCellOptions={{
            textAlign: "right",
          }}/>
        <ExcelExportColumn field="Round3" title="R3" width={100} headerCellOptions={{
            textAlign: "right",
          }}/>
        <ExcelExportColumn field="Round4" title="R4" width={100} headerCellOptions={{
            textAlign: "right",
          }}/>
        {totalRounds >= 5 ? (
          <ExcelExportColumn field="Round5" title="R5" width={100} headerCellOptions={{
            textAlign: "right",
          }}/>
        ) : (
          ""
        )}
        {totalRounds >= 6 ? (
          <ExcelExportColumn field="Round6" title="R6" width={100} headerCellOptions={{
            textAlign: "right",
          }}/>
        ) : (
          ""
        )}
        {totalRounds >= 7 ? (
          <ExcelExportColumn field="Round7" title="R7" width={100} headerCellOptions={{
            textAlign: "right",
          }} />
        ) : (
          ""
        )}
        <ExcelExportColumn field="Total" title="Total" width={100} />
      </ExcelExport>
    </div>
  );
}
