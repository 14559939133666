import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import combinedServices from "../../../shared/services/user-service";
import userConstants from "../../../shared/constants/user-constants";
import "./ManageHeatsheet.scss";
import Doc from "./DocService";
// import PdfContainer from "./PdfContainer";
import { set } from "react-hook-form";
import swal from "sweetalert";
import { PDFExport, PDFPage } from "@progress/kendo-react-pdf";
import { saveAs } from "@progress/kendo-file-saver";
import jsPDF from "jspdf";
import getRemoveData from "../../../shared/localStorage/removeData";
import { Spinner } from "reactstrap";

var showedheat = [];
let hjk = 9,
  sumik,
  gjk;
var convertedObject;
var tables = [];
export default function DownloadHeatsheet() {
  // get event id from params
  let eventId = useParams().id;
  const [data, setData] = useState([]);
  const [rounds, setRounds] = useState([]);
  const [classes, setClasses] = useState([]);
  const [eventParticipant, setEventParticipant] = useState([]);
  const [eventDetails, setEventDetails] = useState([]);
  const [prevHeat, setPrevHeat] = useState("");
  const [round2Data, setRound2Data] = useState(false);
  const [round3Data, setRound3Data] = useState(false);
  const [round4Data, setRound4Data] = useState(false);
  const [dates, setDates] = useState([]);
  const [allinfo, setAllinfo] = useState([]);
  const [eventname, setEventname] = useState("");
  const [users, setUsers] = useState();
  const [fees, setFees] = useState("");
  const [regischarge, setRegischarge] = useState("");
  const [imagestream, setImagestream] = useState("");
  const [baseURL, setBaseUrl] = useState(userConstants.baseURL);
  const [masterarr, setMasterarr] = useState({});
  const [participantarr, setParticipantarr] = useState({});
  const [scoret, setScoret] = useState("");
  const [isLoader, setIsLoader] = useState(true);
  const [successMessage, setSuccessMessage] = useState(false);
  const[statusCode,setStatusCode]=useState(0)
  const AltImg = "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/logo.png"


  const pdfExportComponent = React.useRef(null);
  const pdfDoc = new jsPDF();
  var result;
  useEffect(async () => {
    let finalArr = [];
    let classArr = [];
    let dateArr = [];
    await combinedServices
      .getClassByEventId(eventId)
      .then((res) => {
        if (res.status == 401) {
          swal(res.msg, "Please Re-login", "warning").then(() => {
            getRemoveData("user_info");
            localStorage.clear();
            window.location.href = "/login";
          });
        }
        setImagestream(
          res.data.image.length > 0
            ? baseURL + "images/" + res.data.image
            : AltImg
        );
        setEventDetails(res.data);

        setRegischarge(res.data.registration_charge);
        setFees(res.data.boat_charge);
        setEventname(res.data.full_title);

        let round = [];
        for (var i = 1; i <= res.data.rounds_per_class; i++) {
          round.push(i);
        }
        setRounds(round);
      })
      .catch((err) => {
        alert(err);
      });
    // setImagestream(eventDetails.image)
    await combinedServices
      .getEventParticipantForHeat({ event_id: eventId })
      .then((res) => {
        if (res.status == 401) {
          swal(res.msg, "Please Re-login", "warning").then(() => {
            getRemoveData("user_info");
            localStorage.clear();
            window.location.href = "/login";
          });
        }
        const sortedArray = res.data.sort((a, b) => {
          const nameA = a.user.user_firstname.toUpperCase();
          const nameB = b.user.user_firstname.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

        setEventParticipant(sortedArray);
      })
      .catch((err) => {
        alert(err);
      });
    //getting class only
    var eventClassesData1 = await combinedServices.countByClasses(eventId);
    var participantClassData = eventClassesData1.data.participants;
    if (participantClassData) {
      participantClassData.map((classVal) => {
        if (classVal.run_date != null) {
          var data = {
            id: classVal.class_id,
            name: classVal.class_name,
            rundate: classVal.run_date,
            event_class_id: classVal.event_class_id,
            order_key: classVal.order_key,
          };
          classArr.push(data);
          classArr.sort((a, b) => {
            const dateA = new Date(a.run_date);
            const dateB = new Date(b.run_date);

            if (dateA.getTime() === dateB.getTime()) {
              return a.order_key - b.order_key;
            } else {
              return dateA - dateB;
            }
          });
        }

        if (classVal.run_date != null) {
          var data1 = {
            run_date: classVal.run_date,
            class: classVal.class_name,
          };
          dateArr.push(data1);
        }
      });
      classArr?.sort((a, b) => {
        if (a.rundate === b.rundate) {
          return a.order_key - b.order_key;
        }
        return new Date(a.rundate) - new Date(b.rundate);
      });
      setClasses(classArr);
      const formattedResponse = {};

      for (const item of dateArr) {
        const runDate = item.run_date;
        const classType = item.class;

        if (runDate in formattedResponse) {
          formattedResponse[runDate].push(classType);
        } else {
          formattedResponse[runDate] = [classType];
        }
      }

      setDates(formattedResponse);
    }

    // getting all the data
    await combinedServices
      .getAllHeatSheet({ event_id: eventId})
      .then(async (res) => {
        res.data.map((val, i) => {
          return val.participants.map((itm, i) => {
            return finalArr.push({
              class: itm.event_participant_class.class.name,
              heat: val.heat_number,
              name:
                itm.event_participant_class.event_participant.user
                  .user_firstname +
                " " +
                itm.event_participant_class.event_participant.user
                  .user_lastname,
              score: "",
              round: itm.round,
              id: itm.event_participant_class.event_participant.user.id,
              scratch: itm.scratch,
              usernemail:
                itm.event_participant_class.event_participant.user
                  .user_username,
              userprimarykey:
                itm.event_participant_class.event_participant.user
                  .user_primarymember_id_fkey,
            });
          });
        });
        let uniqueChars = [...new Set(finalArr)];
        finalArr?.map((val) => {
          if (val.round == 2) return setRound2Data(true);
          if (val.round == 3) return setRound3Data(true);
          if (val.round == 4) return setRound4Data(true);
        });
        setData(finalArr);
      })

      .catch((err) => {
        alert(err);
      });
  }, []);

  useEffect(() => {
    const uniqueNames = [...new Set(data.map((item) => item.id))];

    result = uniqueNames.map((id) => {
      return {
        // name: name,
        id: id,
        AllRunDates: [
          ...new Set(
            data
              .filter((item) => item.id == id)
              .map((item) => item.rundate)
              .sort()
          ),
        ],
      };
    });
    setUsers(result);

    setUsers(result);

   
    // setUsers(result);
  }, [data]);
  useEffect(() => {
    convertedObject = {};

    data?.forEach((item) => {
      const className = item.class;
      const roundKey = `Round${item.round}`;
      const heatKey = `heat_round${item.round}`;
      const usernameKey = `username_round${item.round}`;
      const scratchKey = `scratch_round${item.round}`;

      if (!convertedObject.hasOwnProperty(className)) {
        convertedObject[className] = {};
      }

      const classObj = convertedObject[className];
      const roundObj = classObj.hasOwnProperty(roundKey)
        ? classObj[roundKey]
        : [];

      roundObj.push({
        [heatKey]: item.heat,
        [usernameKey]: item.name,
        scratchKey: item.scratch,
      });

      // Sort the heats within each round in ascending order
      roundObj.sort((a, b) => {
        const heatA = parseInt(a[heatKey]);
        const heatB = parseInt(b[heatKey]);
        return heatA - heatB;
      });

      classObj[roundKey] = roundObj;
    });

    setMasterarr(convertedObject);
  }, [data]);

  useEffect(() => {
    const convertedArray = data.reduce((result, item) => {
      const { class: classname, name: username, round, heat } = item;

      if (!result[username]) {
        result[username] = {};
      }

      if (!result[username][classname]) {
        result[username][classname] = {};
      }

      result[username][classname][`Round${round}`] = heat;

      return result;
    }, {});

    setParticipantarr(convertedArray);
   
    var x = {
      event_details: eventDetails,
      user_date: result,
      user_class: data,
      user_payment: eventParticipant,
    };
    
    if(x.user_date.length>0){
      var x = combinedServices
      .emailParticipants({ data: x })
      .then(async (res) => {
        setStatusCode(res.status)
        if (res.status == 202) {
          setIsLoader(false);
          setSuccessMessage(true);
        }
      });
    }
    
  }, [data]);

  // classes?.map((cls)=>{

  // })
  classes.sort((a, b) => {
    const dateComparison = new Date(a.rundate) - new Date(b.rundate);
    if (dateComparison === 0) {
      return a.order_key - b.order_key;
    }
    return dateComparison;
  });

  const getUsernameElement = (username, isScratched) => {
    if (isScratched) {
      return <div style={{ textDecoration: "line-through" }}>{username}</div>;
    }
    return username;
  };

  for (let i = 0; i < data.length; i++) {
    const race = data[i];
    const rundate = classes.find((item) => item.name === race.class)?.rundate;
    // use optional chaining to avoid error when no matching item is found
    race.rundate = rundate;
  }

  // Download Pdf file of heatsheet
  useEffect(() => {
    if (successMessage == true) {
      swal("Email Sent Successfully").then(() => {
        window.location.href = "/manage-heatsheet/" + eventId;
      });
    }
    if(statusCode === 500){
      swal({
        text: "Email Not Sent",
        icon: "error",
        dangerMode: true, // Display in red color
      }).then(() => {
        window.location.href = "/manage-heatsheet/" + eventId;
      });
    }
  }, [successMessage, eventId,statusCode]);


  return (
    <>
      <div className="downloadRef">
      
        <React.Fragment>
          <div>
            {/* participant table */}
            {/* <div> */}
            {/* <div className="break-page">  nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn*/}
            {isLoader ? (
              <>
                <div
  className="centerStyle"
  style={{
    display: "flex",
    flexDirection: "column", // This ensures the children are stacked vertically
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  }}
>
  <div
    style={{
      // background: "#f0f0f0",
      padding: "20px",
      borderRadius: "4px",
      // boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
      fontWeight: "600",
      fontSize: "16px",
    }}
  >
    Sending Email<span className="loading-dots">...</span>
  </div>
  <div className="text-center mt-4 loadMore">
    <Spinner color="primary" />
  </div>
</div>

              </>
            ) : (
              <>
                <div style={{ opacity: "0" }}>
                  {eventParticipant.map((ep, k) => {
                    return (
                      <>
                        <div
                          className="masterTable"
                          style={{ position: "relative" }}
                          key={k}
                        >
                          {users
                            ?.filter((item) => item.id == ep.user.id)
                            ?.map((day) => {
                              return day.AllRunDates.map((ele, i) => {
                                return (
                                  <>
                                    <div className="containerz">
                                      <table>
                                        <tr>
                                          <td
                                            height="24"
                                            className="gray centerh"
                                            colSpan="5"
                                            style={{
                                              fontWeight: "700",
                                              fontSmooth: "600",
                                              textAlign: "left",
                                            }}
                                          ></td>
                                        </tr>
                                        <tr>
                                          <td colSpan="5">
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-end",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <div>
                                                <h4
                                                  className="hd"
                                                  style={{
                                                    fontSize: "14px",
                                                    fontWeight: "700",
                                                  }}
                                                >
                                                  {ep.user.user_firstname +
                                                    " " +
                                                    ep.user.user_lastname}
                                                  {""}
                                                  <span>
                                                    (Day {i + 1} of{" "}
                                                    {day.AllRunDates.length})
                                                  </span>
                                                </h4>
                                                <h3
                                                  className="hd few"
                                                  style={{
                                                    fontSize: "14px",
                                                    margin: "0px",
                                                  }}
                                                >
                                                  {eventname}
                                                </h3>
                                              </div>
                                              <img
                                                src={AltImg}
                                                style={{
                                                  width: "50px",
                                                  height: "50px",
                                                }}
                                              />
                                            </div>

                                            <div
                                              style={{
                                                backgroundImage: `url(${imagestream})`,
                                                backgroundRepeat: "no-repeat",
                                                backgroundPosition:
                                                  "center center",
                                                backgroundSize: "cover",
                                                borderRadius: "150px",
                                                position: "absolute",
                                                top: "30%",
                                                left: "50%",
                                                width: "300px",
                                                height: "300px",
                                                zIndex: "100",
                                                opacity: "0.2",
                                                margin: "-150px 0 0 -150px",
                                              }}
                                            ></div>
                                          </td>
                                        </tr>
                                        <tr
                                          className="hd"
                                          style={{ width: "100%" }}
                                        >
                                          <td
                                            height="24"
                                            className="gray"
                                            colSpan="5"
                                            style={{
                                              fontWeight: "700",
                                              fontSmooth: "600",
                                              textAlign: "left",
                                              fontSize: "12px",
                                            }}
                                          >
                                            <span>
                                              {new Date(ele).toLocaleDateString(
                                                "en-us",
                                                {
                                                  weekday: "long",
                                                  year: "numeric",
                                                  month: "short",
                                                  day: "numeric",
                                                }
                                              )}
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              padding: "0",
                                              width: "40%",
                                            }}
                                          >
                                            <table>
                                              <tr>
                                                <th
                                                  style={{
                                                    fontWeight: "400",
                                                    textAlign: "center",
                                                    width: "60%",
                                                  }}
                                                >
                                                  Class
                                                </th>
                                                <th
                                                  style={{
                                                    fontWeight: "400",
                                                    textAlign: "center",
                                                    width: "20%",
                                                  }}
                                                >
                                                  Heat
                                                </th>
                                                <th
                                                  style={{
                                                    fontWeight: "400",
                                                    textAlign: "center",
                                                    width: "20%",
                                                  }}
                                                >
                                                  Score
                                                </th>
                                              </tr>
                                              {/* {classes
                                        .filter(
                                          (item) => item.rundate == day.run_date
                                        )
                                        .map((clas) => {
                                          return (
                                            <>
                                            <tr> <td>{clas.name}</td></tr>
                                            </> */}
                                              {data
                                                .filter(
                                                  (item) =>
                                                    item.id == ep.user.id &&
                                                    item.round == 1 &&
                                                    item.rundate == ele
                                                )
                                                .map((cls, i) => {
                                                  // if(cls.class==clas.name)
                                                  return (
                                                    <>
                                                      <tr key={i}>
                                                        <td>{cls.class}</td>
                                                        <td
                                                          style={{
                                                            textAlign: "center",
                                                            height: "40px",
                                                          }}
                                                        >
                                                          {cls.scratch == false
                                                            ? cls.heat
                                                            : "scratched"}
                                                        </td>

                                                        <td
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          {cls.name ? "" : ""}
                                                        </td>
                                                      </tr>
                                                    </>
                                                  );
                                                })}

                                              {/* ); */}
                                              {/* })} */}
                                            </table>
                                          </td>
                                          {round2Data ? (
                                            <td
                                              style={{
                                                padding: "0",
                                                width: "15%",
                                              }}
                                            >
                                              <table>
                                                <tr>
                                                  <th
                                                    style={{
                                                      fontWeight: "400",
                                                      textAlign: "center",
                                                      width: "50%",
                                                    }}
                                                  >
                                                    Heat
                                                  </th>
                                                  <th
                                                    style={{
                                                      fontWeight: "400",
                                                      textAlign: "center",
                                                      width: "50%",
                                                    }}
                                                  >
                                                    Score
                                                  </th>
                                                </tr>

                                                {data
                                                  .filter(
                                                    (item) =>
                                                      item.id == ep.user.id &&
                                                      item.round == 2 &&
                                                      item.rundate == ele
                                                  )
                                                  .map((cls) => {
                                                    return (
                                                      <tr>
                                                        {/* <td>{cls.class}</td> */}
                                                        <td
                                                          style={{
                                                            textAlign: "center",
                                                            height: "40px",
                                                          }}
                                                        >
                                                          {cls.scratch == false
                                                            ? cls.heat
                                                            : "scratched"}
                                                        </td>

                                                        <td
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          {cls.name ? "" : ""}
                                                        </td>
                                                      </tr>
                                                    );
                                                  })}
                                              </table>
                                            </td>
                                          ) : (
                                            ""
                                          )}
                                          {round3Data ? (
                                            <td
                                              style={{
                                                padding: "0",
                                                width: "15%",
                                              }}
                                            >
                                              <table>
                                                <tr>
                                                  <th
                                                    style={{
                                                      fontWeight: "400",
                                                      textAlign: "center",
                                                      width: "50%",
                                                    }}
                                                  >
                                                    Heat
                                                  </th>
                                                  <th
                                                    style={{
                                                      fontWeight: "400",
                                                      textAlign: "center",
                                                      width: "50%",
                                                    }}
                                                  >
                                                    Score
                                                  </th>
                                                </tr>
                                                {data
                                                  .filter(
                                                    (item) =>
                                                      item.id == ep.user.id &&
                                                      item.round == 3 &&
                                                      item.rundate == ele
                                                  )
                                                  .map((cls) => {
                                                    return cls.round == 3 ? (
                                                      <tr>
                                                        {/* <td>{cls.class}</td> */}
                                                        <td
                                                          style={{
                                                            textAlign: "center",
                                                            height: "40px",
                                                          }}
                                                        >
                                                          {cls.scratch == false
                                                            ? cls.heat
                                                            : "scratched"}
                                                        </td>

                                                        <td
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          {cls.name ? "" : ""}
                                                        </td>
                                                      </tr>
                                                    ) : (
                                                      <tr>
                                                        {/* <td>{cls.class}</td> */}
                                                        <td
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          .
                                                        </td>

                                                        <td
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          {cls.name ? "" : ""}
                                                        </td>
                                                      </tr>
                                                    );
                                                  })}
                                              </table>
                                            </td>
                                          ) : (
                                            ""
                                          )}
                                          {round4Data ? (
                                            <td
                                              style={{
                                                padding: "0",
                                                width: "15%",
                                              }}
                                            >
                                              <table>
                                                <tr>
                                                  <th
                                                    style={{
                                                      fontWeight: "400",
                                                      textAlign: "center",
                                                      width: "50%",
                                                    }}
                                                  >
                                                    Heat
                                                  </th>
                                                  <th
                                                    style={{
                                                      fontWeight: "400",
                                                      textAlign: "center",
                                                      width: "50%",
                                                    }}
                                                  >
                                                    Score
                                                  </th>
                                                </tr>
                                                {data
                                                  .filter(
                                                    (item) =>
                                                      item.id == ep.user.id &&
                                                      item.round == 4 &&
                                                      item.rundate == ele
                                                  )
                                                  .map((cls) => {
                                                    return cls.round == 4 ? (
                                                      <tr>
                                                        {/* <td>{cls.class}</td> */}
                                                        <td
                                                          style={{
                                                            textAlign: "center",
                                                            height: "40px",
                                                          }}
                                                        >
                                                          {cls.scratch == false
                                                            ? cls.heat
                                                            : "scratched"}
                                                        </td>
                                                        <td
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          {cls.name ? "" : ""}
                                                        </td>
                                                        {/* <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      .
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      .
                                                    </td> */}
                                                      </tr>
                                                    ) : (
                                                      <tr>
                                                        {/* <td>{cls.class}</td> */}
                                                        <td
                                                          style={{
                                                            textAlign: "center",
                                                            height: "40px",
                                                          }}
                                                        ></td>
                                                        <td
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          {cls.name ? "" : ""}
                                                        </td>
                                                        {/* <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      .
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      .
                                                    </td> */}
                                                      </tr>
                                                    );
                                                  })}
                                              </table>
                                            </td>
                                          ) : (
                                            ""
                                          )}

                                          <td
                                            style={{
                                              padding: "0",
                                              width: "15%",
                                            }}
                                          >
                                            <table>
                                              <tr>
                                                <th className="centerh">
                                                  Total
                                                </th>
                                                <th className="centerh">
                                                  Fees
                                                </th>
                                              </tr>
                                              {data
                                                .filter(
                                                  (item) =>
                                                    item.id == ep.user.id &&
                                                    item.round == 1 &&
                                                    item.rundate == ele
                                                )

                                                .map((cls, index) => {
                                                  // if(cls.class==clas.name)

                                                  return (
                                                    <>
                                                      <tr key={i}>
                                                        <td
                                                          className="centerh"
                                                          style={{
                                                            height: "40px",
                                                          }}
                                                        ></td>

                                                        <td className="centerh">
                                                          {index === 0 &&
                                                          i === 0
                                                            ? Number(
                                                                regischarge
                                                              ) + Number(fees)
                                                            : Number(fees)}
                                                          {/* { h=index} */}
                                                        </td>
                                                      </tr>
                                                    </>
                                                  );
                                                })}
                                            </table>
                                          </td>
                                        </tr>
                                        {/* <tr>
                                      <td height="24" colSpan="4">ggggggggg</td>
                                    </tr> */}
                                        {/* {setScoret(0)} */}
                                        <tr>
                                          <td
                                            colSpan="5"
                                            className="grayTotal"
                                            style={{
                                              textAlign: "right",
                                              padding: "6px 18px",
                                              fontSize: "10px",
                                            }}
                                          >
                                            {gjk === 0 ? (
                                              <>
                                                Total Due - $
                                                {
                                                  (sumik =
                                                    Number(regischarge) +
                                                    Number(hjk + 1) *
                                                      Number(fees))
                                                }
                                              </>
                                            ) : null}
                                            {gjk > 0 ? (
                                              <>
                                                Total Due - $
                                                {
                                                  (sumik =
                                                    // Number(regischarge) +
                                                    Number(hjk + 1) *
                                                    Number(fees))
                                                }
                                              </>
                                            ) : null}

                                            {/* Total Due - {sum=parseInt(regischarge)+((parseInt(h)) * parseInt(fees))} */}
                                            {/* {index} */}
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                  </>
                                );
                              });
                            })}
                        </div>
                      </>
                    );
                  })}
                </div>
              </>
            )}

            {/* </div> */}
          </div>
        </React.Fragment>
        {/* </PdfContainer> */}
      </div>
    </>
  );
}
