import Header from "../../Components/Layout/Header/Header";
import Footer from "../../Components/Layout/Footer/Footer";
import "./RaceResultProfile.scss";
import React, { useState, useEffect, useReducer } from "react";
import moment from "moment";
import EventTab from "../../Components/EventTab/EventTab";
import getLocalData from "../../shared/localStorage/getData";
import combinedServices from "../../shared/services/user-service";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { useParams } from "react-router-dom";

const RaceResultProfile = () => {
  const params = useParams();
  const [userDetails, setUserDetails] = useState([]);
  const [clubUserDetails, setUserClubDetail] = useState([]);
  const [districDetails, setDistricDetails] = useState({});
  const profile_img = "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/profile_img.jpg"
  useEffect(async () => {
    var data = {
      user_id: params.id,
      clubs: true,
      events: true,
      district: true,
    };
    let profileResponse = await combinedServices.getProfileDetails(data);
    if (profileResponse.status) {
      let userDetailData = profileResponse.data;
      setUserDetails(userDetailData);
      setDistricDetails(userDetailData.district);
      setUserClubDetail(userDetailData.club_members);
    }
  }, []);
 
  return (
    <>
      <div className="mainCon">
        <Header />
        <div className="innerBanner event">
          <div className="container flexBox">
            <div>
              <div className="innerHd">Events</div>
              <div className="bradcrumb">
                <a href="/">Home</a>
                <a href="/event-management">Events</a>
              </div>
            </div>
          </div>
        </div>

        <div className="pb50 grayBg">
          <div className="container">
            <div className="whtBox">
              <div className="tabCover">
                <EventTab />
              </div>
              <div className="d-flex flex-wrap">
                <div className="eventDetLeft">
                  <div className="profileBox text-center">
                    <div className="img">
                      <img src={profile_img} alt="" />
                    </div>
                    <div className="det">
                      <span>
                        {userDetails.user_firstname +
                          " " +
                          userDetails.user_lastname}
                      </span>
                      Location:{" "}
                      <strong>{districDetails.uzip_primary_city}</strong> ,{" "}
                      <strong>{districDetails.uzip_state}</strong>
                    </div>
                  </div>
                  <ul className="userInfo">
                    <li>
                      Age{" "}
                      <span>
                        {moment(new Date()).format("YYYY") -
                          moment(userDetails.user_dob).format("YYYY")}
                      </span>
                    </li>
                    <li>
                      Year Racing{" "}
                      <span>
                        {moment(userDetails.user_createdon).format("YYYY")}
                      </span>
                    </li>
                    <li>
                      Gender <span>{userDetails.user_sex}</span>
                    </li>
                  </ul>
                  <ul className="userInfo">
                    <li>
                      Member Since{" "}
                      <span>
                        {moment(userDetails.user_createdon).format(
                          "ddd , MMM D , YYYY"
                        )}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="eventDetRight">
                  <div className="raceAttended">
                    <div className="hd">Races Attended</div>
                    <ul className="raceAttendedList">
                      {userDetails.event_participants
                        ? userDetails.event_participants.map((raceDetail) => {
                            return (
                              <li>
                                <div className="lft">
                                  {raceDetail.event.full_title}
                                </div>
                              </li>
                            );
                          })
                        : ""}
                    </ul>
                  </div>

                  <div className="resultTable">
                    <Accordion allowZeroExpanded="true" preExpanded={["a"]}>
                      <AccordionItem uuid="a">
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <div className="accQ">Clubs</div>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          {clubUserDetails.length > 0 && (
                            <ul className="rrList">
                              {clubUserDetails.map((club) => {
                                return (
                                  <li>
                                    <span className="left">
                                      {club.club.club_name}
                                    </span>
                                    <span className="right">
                                      since{" "}
                                      {moment(club.club.club_foundedon).format(
                                        "YYYY"
                                      )}
                                    </span>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default RaceResultProfile;
