import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import swal from "sweetalert";
import getRemoveData from "../../../src/shared/localStorage/removeData";

export default function ActivityStatus() {
  const [state, setState] = useState("Active");
  const [remaining, setRemaining] = useState(0);

  const onIdle = () => {
    getRemoveData("user_info");

    swal("You have been logged out").then(() => {
      window.location.href = "/";
    });
    setState("Idle");
  };

  const onActive = () => {
    setState("Active");
  };

  const onAction = () => {
    // setCount(count + 1);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 14400000,
    // timeout: 10000,
  });

 

  return (
    <>
     
    </>
  );
}
