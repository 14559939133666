import React from 'react';

const DocumentView = () => {
  return (
    <div>
      {/* Your component code */}
      <iframe title="Privacy Viewer" src={"https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/privacy.pdf"} width="100%" height="1000px" />
    </div>
  );
};

export default DocumentView;
