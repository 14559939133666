import React, { useState } from 'react';
import { NavLink, Link } from "react-router-dom";

const EventTab = () => {

    return (

        <>
            <ul className="eventTab flexBox">
             
                <li key="upcoming-event1"><NavLink to="/upcoming-event">Upcoming Events</NavLink></li>
                <li key="all-event1"><NavLink to="/event">Completed Events</NavLink></li>
                {/* <li key="club1"><NavLink to="/club">Clubs</NavLink></li>
                <li key="club-result1"><NavLink to="/club-result">Race Results</NavLink></li> */}
            </ul>


        </>



    )


}
export default EventTab;