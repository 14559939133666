import React from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';

export default class Example extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1'
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  scoreheat = ()=>{
    this.props.scoreheat()
    
  }
  render() {
    return (
      <div className='ModtabOuter'>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { this.toggle('1'); }}
            >
              David Santisteven <span>300</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { this.toggle('2'); }}
            >
              Bob Smith 
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '3' })}
              onClick={() => { this.toggle('3'); }}
            >
              Bob Smith
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '4' })}
              onClick={() => { this.toggle('4'); }}
            >
              Mark Day
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <div className='dnfOuter'>
                <div className='dnfLeft'>
                    <ul>
                        <li>
                            <span>1st</span>
                            <span>400</span>
                        </li>
                        <li>
                            <span>2nd</span>
                            <span>300</span>
                        </li>
                        <li>
                            <span>3rd</span>
                            <span>225</span>
                        </li>
                        <li>
                            <span>4th</span>
                            <span>169</span>
                        </li>
                        <li>
                            <span>5th</span>
                            <span>127</span>
                        </li>
                        <li>
                            <span>6th</span>
                            <span>96</span>
                        </li>
                        <li>
                            <span>7th</span>
                            <span>72</span>
                        </li>
                        <li>
                            <span>8th</span>
                            <span>84</span>
                        </li>
                    </ul>
                </div>
                <div className='dnfRight'>
                    <Button>DNF</Button>
                    <Button>DNS</Button>
                </div>
            </div>
              <div className='nextPrevBtn'>
                  <Button className='nextBtn'>Next</Button>
                </div>
          </TabPane>
          <TabPane tabId="2">
            <div className='dnfOuter'>
                <div className='dnfLeft'>
                    <ul>
                        <li>
                            <span>1st</span>
                            <span>400</span>
                        </li>
                        <li>
                            <span>2nd</span>
                            <span>300</span>
                        </li>
                        <li>
                            <span>4th</span>
                            <span>169</span>
                        </li>
                        <li>
                            <span>5th</span>
                            <span>127</span>
                        </li>
                        <li>
                            <span>6th</span>
                            <span>96</span>
                        </li>
                        <li>
                            <span>7th</span>
                            <span>72</span>
                        </li>
                        <li>
                            <span>8th</span>
                            <span>84</span>
                        </li>
                    </ul>
                </div>
                <div className='dnfRight'>
                    <Button>DNF</Button>
                    <Button>DNS</Button>
                </div>
            </div>
            <div className='nextPrevBtn'>
                  <Button className='backBtn'>Back</Button>
                  <Button className='nextBtn'>Next</Button>
                </div>
          </TabPane>
          <TabPane tabId="3">
            <div className='dnfOuter'>
                <div className='dnfLeft'>
                    <ul>
                        <li>
                            <span>2nd</span>
                            <span>300</span>
                        </li>
                        <li>
                            <span>4th</span>
                            <span>169</span>
                        </li>
                        <li>
                            <span>5th</span>
                            <span>127</span>
                        </li>
                        <li>
                            <span>6th</span>
                            <span>96</span>
                        </li>
                        <li>
                            <span>7th</span>
                            <span>72</span>
                        </li>
                        <li>
                            <span>8th</span>
                            <span>84</span>
                        </li>
                    </ul>
                </div>
                <div className='dnfRight'>
                    <Button>DNF</Button>
                    <Button>DNS</Button>
                </div>
            </div>
            <div className='nextPrevBtn'>
                  <Button className='backBtn'>Back</Button>
                  <Button className='nextBtn'>Next</Button>
              </div>
          </TabPane>
          <TabPane tabId="4">
            <div className='dnfOuter'>
                <div className='dnfLeft'>
                    <ul>
                        <li>
                            <span>2nd</span>
                            <span>300</span>
                        </li>
                        <li>
                            <span>5th</span>
                            <span>127</span>
                        </li>
                        <li>
                            <span>6th</span>
                            <span>96</span>
                        </li>
                        <li>
                            <span>7th</span>
                            <span>72</span>
                        </li>
                        <li>
                            <span>8th</span>
                            <span>84</span>
                        </li>
                    </ul>
                </div>
                <div className='dnfRight'>
                    <Button>DNF</Button>
                    <Button>DNS</Button>
                </div>
            </div>
            <div className='nextPrevBtn'>
                  <Button className='backBtn'>Back</Button>
                  <Button className='nextBtn' onClick={this.scoreheat}>Finish</Button>
                </div>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}