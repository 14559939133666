import React, { Component } from "react";
import ReactDOM from "react-dom";
import Header from "../../../Components/clubDashboard/Header/Header";
import "./RaceInterface.scss";
import { NavLink, Link } from "react-router-dom";
import getLocalData from "../../../shared/localStorage/getData";
import combinedServices from "../../../shared/services/user-service";
//import moment from "moment";
import Multiselect from "multiselect-react-dropdown";
import swal from "sweetalert";
import { Spinner } from "reactstrap";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
import history from "../../../shared/history";
import { date } from "yup/lib/locale";
import ReactTooltip from "react-tooltip";
import Modal from "react-modal";
import getRemoveData from "../../../shared/localStorage/removeData";
import Scheduler from './Scheduler'
// import { ListBoxComponent } from '@syncfusion/ej2-react-lists';
import { ListBoxComponent } from '@syncfusion/ej2-react-dropdowns';

import moment from "moment-timezone";
//moment.tz.setDefault("Asia/Kolkata");
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
moment.tz.setDefault("timeZone");

const menu = {
  width: "35px",
  height: "5px",
  backgroundColor: "black",
  margin: "6px 0",
};
// fake data generator
const getItems = (count, offset = 0) =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    id: `item-${k + offset}`,
    content: `item ${k + offset}`,
  }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  width: 250,
});

class UncontrolledBoard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: getItems(5),
      selected: getItems(5, 5),
      testTasks: [],
      tasks: [],
      tasks2: [],
      eventParticipant: [],
      eventDetails: [],
      eventId: "",
      cancelNonQualify: false,
      toggleShow: false,
      isOpen: false,
      open: false,
      dateArray: [],
      selectedClass: [],
      selectedDate: "",
      cancelledClass: [],
      cancelledClassMsg: "",
      showHeatButton: false,
      minClass: [],
      isLoder: true,
      dragItem: null,
      selectedindex: "",
      datearr: [],
      classarray1:{},
      classarray2:{},
      areOrderKeysSame: true,
      parentGenerateHeat:true
    };
  }
  id2List = {
    droppable: "items",
    droppable2: "selected",
  };

  getList = (id) => this.state[this.id2List[id]];

  onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      );

      let state = { items };

      if (source.droppableId === "droppable2") {
        state = { selected: items };
      }

      this.setState(state);
    } else {
      const result = move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      );

      this.setState({
        items: result.droppable,
        selected: result.droppable2,
      });
    }
  };

  componentDidMount = async () => {
    const yourParamName = window.location.pathname;
    const youradminUrl = yourParamName.split("/");

    var participantArray = [];
    this.setState({
      eventId: youradminUrl[2],
    });

    var data = {
      event_id: parseInt(youradminUrl[2]),
    };
    let cancelNonQualifyingClassResponse =
      await combinedServices.cancelNonQualifyingClassOnClick(data);
    await combinedServices
      .getClassWithMinimumEntry(data)
      .then((res) => {
        if (res.status == 401) {
          swal(res.msg, "Please Re-login", "warning").then(() => {
            getRemoveData("user_info");
            window.location.href = "/login";
          });
        }
        this.setState({ minClass: res.data });
        this.setState({ isLoder: false });
      })
      .catch((err) => {
        this.setState({ isLoder: false });
      });
    await combinedServices
      .cancelClassWithZeroEntry(data)
      .then((res) => {})
      .catch((err) => {});

    //let eventClassesData = await combinedServices.getClassByEventId(youradminUrl[2]);
    //let cancelNonQualifyingClassResponse = await combinedServices.cancelNonQualifyingClass(data);

    var eventClassesData = await combinedServices.countByClasses(
      youradminUrl[2]
    );
    this.setState({classarray1:eventClassesData})

    if (!eventClassesData.data.event.cancelled_classes) {
      let cancelNonQualifyingClassResponse =
        await combinedServices.cancelNonQualifyingClass(data);

      if (cancelNonQualifyingClassResponse.status == 200) {
        this.setState({ isOpen: !this.state.isOpen });

        this.setState({
          cancelledClass: cancelNonQualifyingClassResponse.data,
          cancelledClassMsg: cancelNonQualifyingClassResponse.msg,
        });
        this.getAllClassDetails(youradminUrl[2]);
      }
    } else {
      eventClassesData.data.participants.map((value) => {
        var data = {
          class_id: value.class_id,
          class_name: value.class_name,
          class_status: value.class_status,
          participants_count: value.confirmed_participants
            ? value.confirmed_participants
            : 0,
          type: "inProgress",
          run_date: value.run_date,
          event_class_id: value.event_class_id,
          order_key: value.order_key,
        };
        if (eventClassesData.data.participants.order_key >= 1) {
          participantArray.push(data);
          participantArray.sort((a, b) => {
            const dateA = new Date(a.run_date);
            const dateB = new Date(b.run_date);

            if (dateA.getTime() === dateB.getTime()) {
              return a.order_key - b.order_key;
            } else {
              return dateA - dateB;
            }
          });
        } else {
          // console.log("datawwww",data)
          participantArray.push(data);
          participantArray.sort((a, b) => {
            const dateA = new Date(a.run_date);
            const dateB = new Date(b.run_date);

            if (dateA.getTime() === dateB.getTime()) {
              return a.order_key - b.order_key;
            } else {
              return dateA - dateB;
            }
          });
        }
      });
      this.setState({
        testTasks: participantArray,
        tasks: participantArray,
        tasks2: participantArray,
        eventDetails: eventClassesData.data.event,
      });

      var event_start_date = eventClassesData.data.event.event_start;
      var event_end_date = eventClassesData.data.event.event_end;
      this.getTotalDaysAndDateBetweenTwoDate(
        event_start_date,
        event_end_date,
        youradminUrl[2]
      );
      this.checkAllDateAssign(participantArray);
    }
  };
  checkAllDateAssign = (participantArr) => {
    var newArr = [];

    participantArr.map((item, index) => {
      if (item.class_status == "MADE" && item.run_date == null) {
        newArr.push(index);
      }
    });

    if (newArr.length == 0) {
      // this.setState({ showHeatButton: true });
    }
  };
  // unassigne classes
  handleUnassignedClass = async (id) => {
    var data = {
      event_class_ids: [id],
      run_date: "",
      order_key: "",
      status: "Unassigned",
    };

    let eventClassesData = await combinedServices.assignDateForEvent(data);

    // let eventdata=eventClassesData
    if (eventClassesData.status == 200) {
      this.getAllClassDetails(this.state.eventId);
    }
    this.checkAllDateAssign(this.state.tasks);
  };

  toggleModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  toggleModalMin = () => {
    this.setState({ open: !this.state.open });
  };
  getAllClassDetails = async (event_id) => {
    var participantArray = [];
    let eventClassesData = await combinedServices.countByClasses(event_id);
    this.setState({classarray2:eventClassesData})

    eventClassesData.data.participants.map((value) => {
      var data = {
        class_id: value.class_id,
        class_name: value.class_name,
        class_status: value.class_status,
        participants_count: value.confirmed_participants
          ? value.confirmed_participants
          : 0,
        type: "inProgress",
        run_date: value.run_date,
        event_class_id: value.event_class_id,
        order_key: value.order_key,
      };
      if (eventClassesData.data.participants.order_key >= 1) {
        participantArray.push(data);
        participantArray.sort((a, b) => {
          const dateA = new Date(a.run_date);
          const dateB = new Date(b.run_date);

          if (dateA.getTime() === dateB.getTime()) {
            return a.order_key - b.order_key;
          } else {
            return dateA - dateB;
          }
        });
      } else {
        participantArray.push(data);
        participantArray.sort((a, b) => {
          const dateA = new Date(a.run_date);
          const dateB = new Date(b.run_date);

          if (dateA.getTime() === dateB.getTime()) {
            return a.order_key - b.order_key;
          } else {
            return dateA - dateB;
          }
        });
      }
    });
    this.setState({
      tasks: participantArray,
      tasks2: participantArray,
      eventDetails: eventClassesData.data.event,
    });
    var event_start_date = eventClassesData.data.event.event_start;
    var event_end_date = eventClassesData.data.event.event_end;
    this.getTotalDaysAndDateBetweenTwoDate(
      event_start_date,
      event_end_date,
      event_id
    );
    this.compareOrderKeysAndUpdateState();
    //this.handleTotalHeat(this.state.selectedDate);
  };

  getTotalDaysAndDateBetweenTwoDate = async (startDate, stopDate, event_Id) => {
    var dateArray = [];
    var currentDate = moment(startDate);
    var stopDate = moment(stopDate);
    while (currentDate <= stopDate) {
      dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
      currentDate = moment(currentDate).add(1, "days");
    }
    dateArray.map((dateValue, index) => {
      let tasks = this.state.tasks.filter((task) => {
        if (
          moment(dateValue).format("L") == moment(task.run_date).format("L")
        ) {
          task.type = "Date" + index;
        }
        return task;
      });
    });

    this.setState({ dateArray: dateArray });
  };

  calculateDaysBetweenTwoDate = (startDate, endDate) => {
    var future = moment(endDate);
    var start = moment(startDate);
    var d = future.diff(start, "days") + 1; // 9

    //var d = Math.ceil(Math.abs(startDate - endDate) / (1000 * 60 * 60 * 24));

    return d;
  };

  onDragStart = (event, task, index) => {
    this.setState({ selectedClass: [task.event_class_id] });
    event.dataTransfer.setData("taskName", task.class_name);
    //event.dataTransfer.setData("classId", task.class_id);
  };
  onDragOver = async (event, id, index) => {
    event.preventDefault();
    //this.callAssignedClassForDate(id)
    if (id) {
      this.setState({ selectedDate: moment(id).format() });
    }
  };

  onDrop = async (event, cat) => {
    let taskName = event.dataTransfer.getData("taskName");

    if (taskName) {
      let tasks = this.state.tasks.filter((task) => {
        if (task) {
          if (task?.class_name == taskName) {
            task.type = cat;
          }

          return task;
        }
      });

      this.setState({
        ...this.state,
        tasks,
      });
    }
    // this.callAssignedClassForDate();

    let datearr = this.state.tasks.filter((task) => {
      return task?.type === cat;
    });
    this.setState({
      datearr,
    });

    for (let i = 0; i < datearr.length; i++) {
      datearr[i].order_key = i + 1;
      const { event_class_id, run_date } = datearr[i];
      const data = {
        event_class_ids: [event_class_id],
        // run_date: run_date,
        run_date: this.state.selectedDate,
        order_key: datearr[i].order_key,
      };
      try {
        let eventClassesData = await combinedServices.assignDateForEvent(data);
   
      } catch (error) {
  
      }
    }

    this.getAllClassDetails(this.state.eventId);
  };

  callAssignedClassForDate = async () => {
    var data = {
      event_class_ids: this.state.selectedClass,
      run_date: this.state.selectedDate,
      // order_key: "",
    };

    let eventClassesData = await combinedServices.assignDateForEvent(data);

    if (eventClassesData.status == 200) {
      this.getAllClassDetails(this.state.eventId);
    }
    this.checkAllDateAssign(this.state.tasks);
  };


  handleEstimateHeatCount = (eventEntries, maxMinValue) => {
    var number = eventEntries / maxMinValue;

    return Math.ceil(number);
  };

  handleTotalHeat = (date) => {
    // console.log("handle++++++++++++++++++++>",date)
    var totalMaxHeat = 0;
    var totalMinHeat = 0;
    // console.log("tasks++++++++++++++>",this.state.tasks)
    this.state.tasks.filter((task) => {
      if (task) {
       
        if (moment(date).format("L") ==moment(task?.run_date).format("L")) {
          totalMinHeat =
            totalMinHeat +
            Math.ceil(
              task.participants_count /
                this.state.eventDetails.max_boats_per_heat
            );

          totalMaxHeat =
            totalMaxHeat +
            parseInt(
              task.participants_count /
                this.state.eventDetails.min_boats_per_class
            );
        }
       
      }
    });
    return totalMinHeat * this.state.eventDetails.rounds_per_class;
  };
  handleSchedule = () => {
    this.compareOrderKeysAndUpdateState()
    if (this.state.minClass.length > 0) {
      this.setState({ open: true });
    } else {
      window.location.href = `../manage-heatsheet/${this.state.eventId}?regenrate=${this.state.parentState}`
     
    }
  };
  handleYes = async () => {
    const data = {
      event_id: this.state.eventId,
    };
    await combinedServices.allowClassWithMinimumEntry(data).then((res) => {
      this.toggleModalMin();
      swal(
        "Done!",
        "You can schedule classes with minimum entries now",
        "success"
      );
      this.componentDidMount();
    });
  };
  handleNo = () => {
    window.location.href = "../manage-heatsheet/" + this.state.eventId;
  };

  handleDragStart = (i, tasks, item, index) => {
    this.setState({
      dragItem: i,
      dragDate: "Date" + index,
    });
    this.setState({ selectedindex: i });
  };
  handleDragEnter = (e, i, index, tasks) => {
    if (this.state.dragDate === "Date" + index) {
      e.target.style.backgroundColor = "#336699";
      const newList = [...this.state.tasks];

      const filteredArr = newList.filter((obj) => obj.type === "Date" + index);
      const item = filteredArr[this.state.dragItem];
      filteredArr.splice(this.state.dragItem, 1);
      filteredArr.splice(i, 0, item);

      const combinedArr = [...filteredArr, ...this.state.tasks2].reduce(
        (acc, cur) => {
          const isDuplicate = acc.some(
            (item) => item.class_id === cur.class_id
          );
          if (!isDuplicate) {
            acc.push(cur);
          }
          return acc;
        },
        []
      );
      this.setState({
        dragItem: i,
        tasks: combinedArr,
      });
    } else {
      e.preventDefault();
    }
  };

  handleDragLeave = (e, cat) => {
    e.target.style.backgroundColor = "white";
  };

  handleDrop = async (e, i) => {
    e.target.style.backgroundColor = "white";
    this.setState({ selectedindex: i });
  };
  compareOrderKeys=async(classarray1, classarray2) =>{
    if (classarray1 && classarray2) {
      const participants1 = classarray1.data.participants;
      const participants2 = classarray2.data.participants;

      if (participants1.length !== participants2.length) {
        return false;
      }

      for (let i = 0; i < participants1.length; i++) {
        if (participants1[i].order_key !== participants2[i].order_key) {
          return false;
        }
      }

      return true;
    }

    return false;
  }

  compareOrderKeysAndUpdateState=async()=>{
    // console.log("happpppppp",this.state.classarray1,this.state.classarray2)
    const { classarray1, classarray2 } = this.state;

   
    if (classarray1 && classarray2) {
     
      this.compareOrderKeys(classarray1, classarray2)
      .then(result => {
        this.setState({ areOrderKeysSame: result });
       if(result=="false"){
        localStorage.setItem('regkey', "false");
       }
      })
      .catch(error => {
      
      });
    }
  }

   handleGenerateHeatChange = (value) => {
    this.setState({parentGenerateHeat:value});
  };
  render() {

    var tasks = {
      inProgress: [],
    };
    const { areOrderKeysSame } = this.state;

    var newArr = [];
    var showHeatButton = false;
    this.state.tasks.forEach((item, index) => {
      if (item.class_status == "MADE" && item.run_date == null) {
        newArr.push(index);
      }
    });
    if (newArr.length == 0) {
    
      showHeatButton = true;
   
    }
    this.state.dateArray.forEach((date, dateIndex) => {
      tasks["Date" + dateIndex] = [];
    });

    this.state.tasks.forEach((task, index) => {
      if (task && task.type == "inProgress") {
        tasks[task.type].push(
          <tr key={index}>
            <td>{index + 1}</td>

            {task.class_status == "NOT_MADE" ? (
              <td
                className="draggable"
                style={{ backgroundColor: "#fff", color: "black" }}
              >
                {task.class_name}
              </td>
            ) : (
              <td
              
              >
                {task.class_name}
              </td>
            )}

            <td className="name">
              {task.class_status == "NOT_MADE" ? "NOT MADE" : "MADE"}
            </td>
            <td>{task.participants_count}</td>
            <td>
              {task.run_date
                ? moment(task.run_date).format("ddd , MMM D , YYYY")
                : "Unassigned"}
            </td>

            <td>
              {this.handleEstimateHeatCount(
                task.participants_count,
                this.state.eventDetails.max_boats_per_heat
              )}{" "}
              -{" "}
              {this.handleEstimateHeatCount(
                task.participants_count,
                this.state.eventDetails.min_boats_per_class
              )}
            </td>
          </tr>
        );
      } else {
        tasks[task && task.type]?.push(
          <div
            key={task.class_id}
            onDragStart={(event) => this.onDragStart(event, task)}
            draggable
            className="draggable"
            style={{ backgroundColor: task.bgcolor }}
          >
            {task.class_name}
            <a data-tip="Unassign ?">
              <span
                className="material-icons-outlined crossButton"
                onClick={() => this.handleUnassignedClass(task.event_class_id)}
              >
                close
              </span>
            </a>
            <ReactTooltip place="right" type="dark" effect="float" />(
            {this.handleEstimateHeatCount(
              task.participants_count,
              this.state.eventDetails.max_boats_per_heat
            )}
            )
          </div>
        );
      }
    });

    return (
      <>
     
        <div>
          <Header />
          {this.state.isLoder ? (
            <div className="text-center mt-4 loadMore">
              <Spinner color="primary" />
            </div>
          ) : 
          (
            <>
              <div className="dbRight">
                <div className="dbMid dbInner">
                  <div className="flexBox spacebetween topHdBox">
                    <div className="left">
                      <div className="dbMainHd">Race Interface</div>
                      <div className="text flexBox">
                        <div className="eventDate">
                          Event : Start -{" "}
                          {moment(this.state.eventDetails.event_start).format(
                            "ddd , MMM D , YYYY"
                          )}{" "}
                          - End -{" "}
                          {moment(this.state.eventDetails.event_end).format(
                            "ddd , MMM D , YYYY"
                          )}
                        </div>
                        <div className="eventTime">
                          Duration :{" "}
                          {this.calculateDaysBetweenTwoDate(
                            this.state.eventDetails.event_start,
                            this.state.eventDetails.event_end
                          ) > 0
                            ? this.calculateDaysBetweenTwoDate(
                                this.state.eventDetails.event_start,
                                this.state.eventDetails.event_end
                              ) + " days"
                            : ""}{" "}
                        </div>
                      </div>
                      <div
                        className="bradcrumb"
                        style={{ backgroundColor: "#1c60a6" }}
                      >
                        <a href="/">Home</a>
                        <a href="/event-management">Event Management</a>
                        
                      </div>
                    </div>
                    <div className="right tar">
                    

                      {showHeatButton ? (
                        <a
                          className="blueBtn ml10"
                          onClick={() => this.handleSchedule()}
                        >
                           Heats
                        </a>
                      ) : (
                        ""
                      )}
                     
                      <NavLink
                        className="blueBtn ml10"
                        to={"/payment-details/" + this.state.eventId}
                      >
                        Payment Details
                      </NavLink>
                    </div>
                  </div>
                  {this.state.cancelledClass && (
                    <>
                      <Modal
                        style={{ width: "300px" }}
                        isOpen={this.state.isOpen}
                        //onRequestClose={toggleModal}
                        className="mymodal"
                        contentLabel="My dialog"
                      >
                        <div className="cancelledClassMsg">
                          {this.state.cancelledClassMsg}
                        </div>
                        <ol>
                          {this.state.cancelledClass.map((value) => {
                            return <li>{value}</li>;
                          })}{" "}
                        </ol>
                        <button
                          className="cancelledPopup"
                          onClick={this.toggleModal}
                        >
                          Close modal
                        </button>
                      </Modal>
                    </>
                  )}

                  <Modal
                    style={{ width: "300px" }}
                    isOpen={this.state.open}
                    className="mymodal"
                    contentLabel="My dialog"
                  >
                    <div className="cancelledClassMsg">
                      This class has not made, do you still wish to schedule
                      this class ?
                    </div>
                    <ol>
                      {this.state.minClass?.map((value) => {
                        return <li>{value}</li>;
                      })}{" "}
                    </ol>
                    <button
                      className="cancelledPopup"
                      onClick={this.toggleModalMin}
                    >
                      Back
                    </button>
                    <button className="yesPopup" onClick={this.handleYes}>
                      YES
                    </button>
                    <button className="noPopup" onClick={this.handleNo}>
                      NO, Continue
                    </button>
                  </Modal>
                  {/* <Scheduler /> */}
                  <div class="dbWhtBox">
                    <div className="interfaceTable row">
                      {this.state.dateArray.map((value, index) => (
                        <>
                          <div
                            className="droppable column"
                            onDragOver={(event) =>
                              this.onDragOver(event, value)
                            }
                            onDragEnter={(e) =>
                              this.handleDragEnter(e, index, tasks)
                            }
                            onDrop={(event) => {
                              this.setState({ dropIndex: index }, () => {
                                this.onDrop(event, "Date" + index);
                              });
                            }}
                          >
                            <span
                              className="group-header"
                              style={{ color: "#fff" }}
                            >
                              {moment(value).format("ddd , DD MMMM YYYY")}{" "}
                            </span>
                            <p style={{ color: "#fff" }}>
                              Heats : ({this.handleTotalHeat(value)})
                            </p>
                            <ul>
                              {tasks["Date" + index].map((item, i) => (
                                <li
                                  draggable
                                  key={i}
                                  onDragStart={
                                    () =>
                                      this.handleDragStart(
                                        i,
                                        tasks,
                                        item,
                                        index,
                                        tasks
                                      )

                                    
                                  }
                                
                                  onDragEnter={(e) =>
                                    this.handleDragEnter(e, i, index, tasks)
                                  }
                                  onDragLeave={(e) =>
                                    this.handleDragLeave(e, "Date" + index)
                                  }
                                  onDrop={(e) => this.handleDrop(e, i)}
                
                                  onDragOver={(event) =>
                                    this.onDragOver(event, value)
                                  }
                                >
                                  {item}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                  <div class="dbWhtBox">
                    <div class="dbWhtBox">
                      {this.state.tasks?.map((value, index) => {
                        if (value && value.type === "inProgress") {
                          if (value.class_status === "MADE") {
                            return (
                              <Chip
                                key={index}
                                label={value.class_name}
                                onDragStart={(event) =>
                                  this.onDragStart(event, value, index)
                                }
                                draggable
                                className="draggable"
                                style={{
                                  backgroundColor: "#1c60a6",
                                  color: "#fff",
                                }}
                              />
                            );
                          } else if (value.class_status === "NOT_MADE") {
                            return (
                              <Chip
                                key={index}
                                label={value.class_name}
                                onDragStart={(event) =>
                                  this.onDragStart(event, value)
                                }
                                draggable
                                className="draggable"
                                style={{
                                  backgroundColor: "#FFFF00",
                                  color: "#000000",
                                }}
                              />
                            );
                          }
                        }
                        return null;
                      })}
                    </div>

                    <div className="drag-container">
                      <div class="dbWhtBox">
                        <div className="interfaceTable">
                          <table>
                            <thead>
                              <tr>
                                <th>Number</th>
                                <th>Class Name</th>
                                <th>Class Status</th>
                                <th>Entries</th>
                                <th>Event Day</th>
                                <th>Estimate Heat Count</th>
                              </tr>
                            </thead>
                            <tbody>{tasks.inProgress}</tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

export default UncontrolledBoard;
