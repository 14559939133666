import React, { useState, useEffect } from "react";
import Header from "../../../Components/clubDashboard/Header/Header";

import { Modal, ModalBody, ModalHeader, Button, ModalFooter } from 'reactstrap';
import getLocalData from "../../../shared/localStorage/getData";
import combinedServices from "../../../shared/services/user-service";
import userConstants from "../../../shared/constants/user-constants";
import moment from "moment";
import Pagination from "react-js-pagination";
import { useNavigate} from "react-router-dom";
import { NavLink, useParams } from "react-router-dom";
import swal from 'sweetalert';
import { Spinner } from 'reactstrap';
import SearchPage from "../../../Components/clubDashboard/Header/SearchPage";
import getRemoveData from "../../../shared/localStorage/removeData";
import Switch from "react-switch";
const EventManagement = () => {
    // Modal open state
    let navigate = useNavigate();
    const params = useParams();

    const [highlight, setHighlight] = React.useState([]);
    const [baseURL, setBaseUrl] = useState(userConstants.baseURL);
    const [eventId,setEventId] = useState();
    



    useEffect(async () => {

        setEventId(params.id)
        if (getLocalData("user_info", "isLogin")) {

            var userId = getLocalData("user_info", "all").userData.login_user_detail.data.user.id;
            var userDistricId = getLocalData("user_info", "all").userData.login_user_detail.data.user.district.uzip_namba_district;
            var districtDirectors = getLocalData("user_info", "all").userData.login_user_detail.data.user.districtDirector;
           
            var data = {
                limit:4,
                eventId:params.id
            }
        
            let response = await combinedServices.getAllHighlite(data);
            //console.log(response)
            setHighlight(response.data)

        }


    }, [])

    const handleDeleteEvent = (id)=>{

        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover event highlight!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then(async(willDelete) => {
            if (willDelete) {
                let response = await combinedServices.deleteHighLights(id);
                if(response.status == 200){
                    swal("Poof! Your event highlight  has been deleted!", {
                        icon: "success",
                    });

                    var data = {
                        limit:4,
                        eventId:params.id
                    }
        
                    let response = await combinedServices.getAllHighlite(data);
            
                    setHighlight(response.data)

                }else{

                   swal("!Opps",response.msg,"error") 
                }
             
            } else {
              swal("Your event highlight is safe!");
            }
          });


    }





    return (
        <>
            <Header />


                 


            <div className="dbAdminRight">
                <div className="dbMid dbInner">
                    <div className="flexBox spacebetween topHdBox">
                        <div className="left">
                            <div className="dbMainHd">Event Highlights
                        
                            <NavLink to={"../add-highlights/"+eventId} className='blueBtn' style={{"margin-left": "22px"}}>Add Highlights</NavLink>
                            </div>
                            
                        
                        </div>
                       
                    </div>
                    <div className='flexBox spacebetween'>
                        <div className='pgBox whtBox'>
                            <div className="interfaceTable">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>Event Name</th>
                                            <th>Image</th>                                            
                                            
                                            <th>Points</th>
                                            <th>Action</th>

                                            
                                            
                                        </tr>
                                    </thead>
                                   
                                    <tbody>
                                    {highlight.length > 0  ? highlight.map((value,index)=>{
                                        
                                        return(
                                            <tr>
                                                <td>{index+1}</td>

                                                <td>{value.event ? value.event.full_title : ""}</td>

                                                <td><div className="icon highlightImg"><img style={{"height":"55px"}} src={baseURL + "images/" + value.image_url[0]} alt="" /></div></td>
                                                <td><ul>
                                                {value.points.map((point)=>
                                                <li>{point}</li>
                                                    

                                                )}</ul></td>
                                               

                                               <td>
                                                <div className='actionBtn flexBox nowrap'>
                                                   
                                                    <button title='Delete' type='button' onClick={()=>handleDeleteEvent(value.id)}><span className="material-icons-outlined">delete</span></button>
                                                </div>
                                                </td>
                                                   
                                                  
                                            
                                           
                                              </tr>
                                        )


                                        }):""}

                                              

                                       
                                        
                                       
                                    </tbody>
                                   
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    

        </>
    )
}
export default EventManagement;