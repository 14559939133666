import getLocalData from '../localStorage/getData'

export default function authHeader(requesttype) {
  let token_res = null
  let authToken
  let id_res

  let header = {
    _id: '',
    Authorization: '',
  }


  try {
    if (requesttype) {
      switch (requesttype) {
        case 'ADMIN':
          token_res = getLocalData('admin_info', 'all').userData.token;
          
          id_res = getLocalData('admin_info', 'getId')
          authToken = `${token_res}`
          header = {
            authorization: authToken,
          }
          break
        case 'USER':
          token_res = getLocalData('user_info', 'all').userData.login_user_detail.data.token;


          id_res = getLocalData('user_info', 'getId')
          authToken = `${token_res}` 
          
          header = {
            Authorization: authToken,
          }
          break


        default:
          token_res = 'No user found'
          break
      }
    }
  } catch (e) {
    token_res = null
  }
  return header
}
