import { useScrollTrigger } from '@mui/material';
import React, { useEffect, useState } from 'react';

function MapContainer (props) {
  console.log('props',props.address);
  console.log('map',props.mapDirections);
  const [origin,setOrigin] = useState('')
  useEffect(()=>{
    if(navigator.geolocation){
      navigator.geolocation.getCurrentPosition((position)=>{
        const longitude = position.coords.longitude
        const lattitude = position.coords.latitude
        setOrigin(`${lattitude},${longitude}`)
      })
    }
  },[])
  
  return (
    <div>
      <iframe
        width="550"
        height="300"
        frameborder="0"
        
        referrerpolicy="no-referrer-when-downgrade"
        src={props.mapDirections=='place'?`https://www.google.com/maps/embed/v1/place?key=AIzaSyDHwu9MRSEsOXKCwESUM6SsL-241G0Auqc&q=${props.address.siteName},${props.address.address1},${props.address.city},${props.address.state},${props.address.zip},${props.address.country}`:`https://www.google.com/maps/embed/v1/directions?key=AIzaSyDHwu9MRSEsOXKCwESUM6SsL-241G0Auqc&origin=${origin}&destination=${props.address.siteName},${props.address.address1},${props.address.city},${props.address.state},${props.address.zip},${props.address.country}&avoid=tolls|highways`}
        // src={props.mapDirections=='place'?`https://www.google.com/maps/embed/v1/place?key=AIzaSyBfVSh0clIIw275Zhm_rVk30pFXINkO9vw&q=${props.address.siteName},${props.address.address1},${props.address.city},${props.address.state},${props.address.zip},${props.address.country}`:`https://www.google.com/maps/embed/v1/directions?key=AIzaSyBfVSh0clIIw275Zhm_rVk30pFXINkO9vw&origin=${origin}&destination=badarpur&avoid=tolls|highways`}

        // src={`https://www.google.com/maps/embed/v1/directions?key=AIzaSyBfVSh0clIIw275Zhm_rVk30pFXINkO9vw&origin=${origin}&destination=${props.siteName},${props.address1},${props.city},${props.state},${props.zip},${props.country}&avoid=tolls|highways`}
        allowfullscreen
      />
    </div>
  );
}

export default MapContainer;
