import React, { useState, useEffect } from 'react';
//import "./PaymentGateway.scss";
import Header from "../../../Components/adminDashboard/Header/Header";
import combinedServices from "../../../shared/services/user-service";
import getLocalData from "../../../shared/localStorage/getData";
import {  NavLink, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';


const Participant = () => {
    let navigate = useNavigate();
    let params = useParams();
    const [allParticipants,setAllParticipants]= useState([])
    const [eventId,setEventId]= useState(params.id)
   
    useEffect(async () => {
        if (getLocalData("admin_info", "isLogin")) {
            var data = {
                event_id:params.id
               
            }
           
            let participantResponse = await combinedServices.getEventParticipateAdmin(data);
            if(participantResponse.status){

                setAllParticipants(participantResponse.data)
            }
            
           


           
        }
    }, [])

    const handleDeleteParticipant= async(id,user_id)=>{
    

    swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this participant!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    })
        .then(async (willDelete) => {
            if (willDelete) {
                var data ={
                    id:id,
                    user_id:user_id
                }
                let participantResponse = await combinedServices.deleteParticipant(data);
                if (participantResponse.status == 200) {
                    swal("Participant delete successfully")
                        .then(() => {

                            navigate("../all-events", { replace: true });

                        })
                } else {
                    swal("!Opps", participantResponse.msg, "error")
                }

            } else {
                swal("Your participant  is safe!");
            }
        });


    

    }

    
   


    return (
        <>
            <Header />

            <div className="dbAdminRight">
                <div className="dbMid dbInner">
                    <div className="flexBox spacebetween topHdBox">
                      
                    </div>
                    <div className='flexBox spacebetween'>
                        <div className='pgBox whtBox'>
                            <div className="interfaceTable">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>Participant Name</th>
                                            <th>Payment Type</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allParticipants.map((value,index)=>
                                             <tr>
                                                 <td>{index+1}</td>
                                                 <td>{value.user.user_firstname + " " +value.user.user_lastname}</td>
                                                 <td>{value.payment_type}</td>
                                                 
                                                  <td>
                                                <div>
                                                    <button title='delete' type='button' onClick={()=>handleDeleteParticipant(value.id,value.user.id)}><span className="material-icons-outlined"></span>Delete</button>
                                                   
                                                </div>
                                            </td>


                                             </tr>
     


                                        )}
                                       
                                    </tbody>
                                    
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           

        </>

    )

}
export default Participant;