import React, { useState, useEffect } from 'react';
import Header from "../../Components/Layout/Header/Header";
import Footer from "../../Components/Layout/Footer/Footer";
import combinedServices from "../../shared/services/user-service";
import getLocalData from "../../shared/localStorage/getData";
import Pagination from "react-js-pagination";
import EventTab from "../../Components/EventTab/EventTab";
import { NavLink, useParams } from "react-router-dom";
import "./Success.scss";




const Success = (props) => {
    const [isPayment ,setIsPayment] = useState("");
    const [eventId ,setEVentId] = useState(props.eventid)

    
    useEffect(async () => {
       
        
            
        const authResult = new URLSearchParams(window.location.search);
        const paymentId = authResult.get('paymentId');
        const token = authResult.get('token');
        const payerID = authResult.get('PayerID');
        const event_participant_id = authResult.get('event_participant_ids');
      
      
        var data = {
            PayerID: payerID,
            paymentId: paymentId,           
            event_participant_ids: event_participant_id
            
        }
        if(paymentId){
            let paymentResponse = await combinedServices.paymentSuccess(data);
            
            

        }else{
            setIsPayment(false)
            const authResult = new URLSearchParams(window.location.search);
            const  event_id = authResult.get('event_id');
            setEVentId(event_id) 

        }

    }, [])


    

    return (
        <>
            <div className="mainCon">
                <Header />
                <div className="innerBanner event">
                    <div className="container flexBox">
                        <div>
                            <div className="innerHd">Events</div>
                            <div className="bradcrumb"><a href="/">Home</a><a href="/event-management">Events</a></div>
                        </div>
                    </div>
                </div>


                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mx-auto mt-5">
                            <div className="payment">
                                <div className="payment_header">
                                    <div className="check"><i class="checkmark">✓</i></div>
                                </div>
                                {isPayment && 
                               
                                <div className="content">
                                    <h1>Thank you for payment !</h1>
                                    <p>We really appreciate it. Click on link and check your races  </p>
                                    <NavLink to={"/my-race"}>Go To My Races Page</NavLink>
                                </div>
} 
                                
{!isPayment && 
                                <div className="content">
                                    <h1>Thank You For Join Event !</h1>
                                    
                                    <NavLink to={"/my-race"}>Go To My Races Page</NavLink>
                                </div> }

                              

                            </div>
                        </div>
                    </div>
                </div>
            

                <Footer />
            </div>
        </>

    )

}
export default Success;