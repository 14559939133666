import Header from "../../Components/Layout/Header/Header";
import Footer from "../../Components/Layout/Footer/Footer";
import "./AddEvent.scss";
import React, { useState, useEffect, useReducer } from "react";
import moment from "moment";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import combinedServices from "../../shared/services/user-service";
import userConstants from "../../shared/constants/user-constants";
import swal from "sweetalert";
import AddStep3 from "./AddEventStep3";
import getRemoveData from "../../shared/localStorage/removeData";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import EditableForm from "./editTransponders";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Spinner } from "reactstrap";
import { CLS_HIDE, CLS_IMGRIGHT } from "@syncfusion/ej2-react-richtexteditor";

const AddEvent = (props) => {
  const params = useParams().id;
  let navigate = useNavigate();
  const [userID, setUserId] = useState(props.participateId);

  const [eventId, setEventId] = useState("");
  const [eventDetails, setEventDetails] = useState([]);
  const [eventClassDetails, setEventClassDetails] = useState([]);
  const [registrationCount, setRegistrationCount] = useState(0);
  const [classIds, setClassIds] = useState([]);
  const [cancelClassIds, setCancelClassIds] = useState([]);
  const [total, setTotal] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");
  const [editopen, setEditOpen] = React.useState(false);

  const [baseURL, setBaseUrl] = useState(userConstants.baseURL);
  const [hostClubName, setHostClubName] = useState("");
  const [participateIds, setParticipantIds] = useState(props.participateId);
  const [participantClassData, setParticipantClassData] = useState([]);
  const [eventClassData, setEventClassData] = useState([]);
  const [allClassDetails, setAllClassDetails] = useState([]);
  const [isLoder, setIsLoder] = useState(false);
  const [addLoder, setAddLoder] = useState(false);
  const [transponder, setTransponder] = useState(false);
  const [transponders1, setTransponders1] = useState(false);
  const [array, setArray] = useState([]);
  const [transponders, setTransponders] = useState([]);
  const [selectedSubMemberId, setSelectedSubMemberId] = useState();
  const [classSelected,setClassSelected] = useState(false)
  const [transponderSelected,setTransponderSelected] = useState(false)



  
  const [dropdownValues, setDropdownValues] = useState(
    Array(transponders1.length).fill("")
  );
  useEffect(() => {
    //setParticipantIds(participateId)
    calculateEnterClassPrice();
  }, [props.participateId]);

  const calculateEnterClassPrice = async () => {
    let eventParticipateResponse =
      await combinedServices.getEventParticipateClass(props.participateId);
    if (eventParticipateResponse.status == 401) {
      swal(eventParticipateResponse.msg, "Please Re-login", "warning").then(
        () => {
          getRemoveData("user_info");
          localStorage.clear();
          window.location.href = "/login";
        }
      );
    }
    
    let eventParticipateData = eventParticipateResponse.data;
    let eventDetails = eventParticipateData.event;
    setEventDetails(eventDetails);
    let eventParticipateDetails = eventParticipateData.participantsData;

    let response = await combinedServices.getTransponders(
      eventParticipateDetails[0].eventParticipant.user_id
    );
    setSelectedSubMemberId(eventParticipateDetails[0].eventParticipant.user_id);
    setTransponders1(response.data.data);
    //setParticipantIds(eventParticipateDetails);
    let eventClassResponse = await combinedServices.getClassByEvent(
      eventDetails.id
    );
    // bug 58
    

    var selectedParticipantClasses = [];
    var selectedEventClasses = [];

    eventParticipateDetails.map((value) => {
      value.eventParticipantClasses.map((classes) => {
        if (classes.participation_status == "ENTERED") {
          var classData = {
            class_id: classes.class_id,
            id: classes.id,
            class_name: classes.class.name,
            status: classes.participation_status,
            transponder_id: classes.transponder_id,
            transponder_name: classes.transponder_name,
          };
          selectedParticipantClasses.push(classData);
        }
      });
    });
    setParticipantClassData(selectedParticipantClasses);
    // -----------------------------------
    var registeredData = {
      event_id: eventDetails.id,
      user_id: eventParticipateDetails[0].eventParticipant.user_id,
    };
    let registeredUser = await combinedServices.checkIfUserJoined(registeredData);
    // -----------------------------------
    if (
      selectedParticipantClasses[0]?.transponder_id != 0 &&
      selectedParticipantClasses[0]?.transponder_id != "" &&
      selectedParticipantClasses[0]?.transponder_id != undefined
    ) {
      setTransponder(true);
      // -----------------------
    }else if (registeredUser.status==200){
      setTransponder(true)
    }
    // ---------------------
   
    eventClassResponse.data.eventClasses.map((value) => {
      var classData = {
        class_id: value.class_Class_Name_ID,
        class_name: value.class_Class_Name,
        status: "",
        id: "",
      };

      selectedEventClasses.push(classData);
    });

    //fixing bug 58
    if(eventClassResponse.data.event.transponder_allow==false){
      setTransponder(false)
    }

    var filteredArray = selectedEventClasses.filter(function (array_el) {
      return (
        selectedParticipantClasses.filter(function (anotherOne_el) {
          return anotherOne_el.class_id == array_el.class_id;
        }).length == 0
      );
    });
// const  arrry=filteredArray.filter((x)=>x.status!="")
 

    setEventClassData(filteredArray);

    var totalCount = 0;
    var totalCost = 0;
    if (selectedParticipantClasses.length == 0) {
      setTotal(0);
      setRegistrationCount(0);
    }

    selectedParticipantClasses.map((value) => {
      if (value.status == "ENTERED") {
        totalCount = totalCount + 1;

        const totalCost =
          Number(eventDetails.registration_charge) +
          Number(eventDetails.boat_charge * totalCount);

        setTotal(totalCost.toFixed(2));
        setRegistrationCount(totalCount);
      } else {
        totalCount = totalCount - 1;

        const totalCost =
          Number(eventDetails.registration_charge) +
          Number(eventDetails.boat_charge * totalCount);

        setTotal(totalCost.toFixed(2));
        setRegistrationCount(totalCount);
      }
    });
  };

  const handleRegistionCost = async (e, classDetails, index) => {
    var classArr = [];
    var classIdArr = [];
    var classtr = [];

    if (classDetails.status == "ENTERED") {
      classIdArr.push(classDetails.id);
      var data = {
        ids: classIdArr,
        index,
      };
      let eventClassResponse =
        await combinedServices.cancleEventParticipateClassMany(data);
      if (eventClassResponse.status == 200) {
        //getting all the unselected classes
        var selectedEventClasses = [];

        let eventClassResponse = await combinedServices.getClassByEvent(
          eventDetails.id
        );

        if(eventClassResponse.data.event.transponder_allow==false){
          setTransponder(false)
        }
    
        var filteredArray = selectedEventClasses.filter(function (array_el) {
          return (
            selectedParticipantClasses.filter(function (anotherOne_el) {
              return anotherOne_el.class_id == array_el.class_id;
            }).length == 0
          );
        });
    // const  arrry=filteredArray.filter((x)=>x.status!="")
     
    
        setEventClassData(filteredArray);
      } else {
        swal("!Opps", eventClassResponse.data, "error");
      }
        calculateEnterClassPrice();
    }
    else {
      if(e.target.checked==false){
        setClassSelected(false)
      }else if(e.target.checked){
        setClassSelected(true)
      }
      setTransponderSelected(false)

      var data2 = {
        class_ids: classDetails.class_id,
        index,
      };
      classtr.push(data2);

      // Using setArray with a callback to log the updated state
      setArray((prevArray) => {
        var newClasstr = [...prevArray, data2];

        return newClasstr;
      });

      classArr.push(classDetails.class_id);

      var data = {
        event_participant_id: Number(props.participateId),
        class_ids: classArr,

        //participation_status: "CANCELLED"
      };
      if (transponder == false) {
        if(e.target.checked==true){
          //when transponder is now allowed and checking the checkbox to enter the class
        let eventClassResponse =
          await combinedServices.addEventParticipateClassMany(data);
        }else if(e.target.checked==false){

          // getting selected classes from  db 
           
          var selectedParticipantClasses = [];

          //when transponder is now allowed and unchecking the checkbox to unenter the class
          let eventParticipateResponse =
          await combinedServices.getEventParticipateClass(props.participateId);
          
          let eventParticipateDetails = eventParticipateResponse.data.participantsData

          eventParticipateDetails.map((value) => {
            value.eventParticipantClasses.map((classes) => {
              if (classes.participation_status == "ENTERED") {
                var classData = {
                  class_id: classes.class_id,
                  id: classes.id,
                  class_name: classes.class.name,
                  status: classes.participation_status,
                  transponder_id: classes.transponder_id,
                  transponder_name: classes.transponder_name,
                };
                selectedParticipantClasses.push(classData);
              }
            });
          });
          setParticipantClassData(selectedParticipantClasses)
          const class_name=classDetails.class_name
          let passingId=''
          for(let item of selectedParticipantClasses){
            if(item.class_name==class_name){
              passingId=item.id
            }
          }
          classIdArr.push(passingId);
          var data = {
            ids: classIdArr,
            index,
          };
          let eventClassResponse =
            await combinedServices.cancleEventParticipateClassMany(data);
            



          if (eventClassResponse.status == 200) {
            //getting all the unselected classes
            var selectedEventClasses = [];

            let eventClassResponse = await combinedServices.getClassByEvent(
              eventDetails.id
            );

            if(eventClassResponse.data.event.transponder_allow==false){
              setTransponder(false)
            }
        
            var filteredArray = selectedEventClasses.filter(function (array_el) {
              return (
                selectedParticipantClasses.filter(function (anotherOne_el) {
                  return anotherOne_el.class_id == array_el.class_id;
                }).length == 0
              );
            });
        // const  arrry=filteredArray.filter((x)=>x.status!="")
         
        
            setEventClassData(filteredArray);
          } else {
            swal("!Opps", eventClassResponse.data, "error");
          }
            calculateEnterClassPrice();
        }
      }
    }
    //  calculateEnterClassPrice();
  };

  const calculateDaysBetweenTwoDate = () => {
    var future = moment(eventDetails.registration_close);
    var start = moment(eventDetails.registration_open);
    var d = future.diff(start, "days") + 1; // 9

    return d;
  };
  const handleTransponderChange1 = async (e, i, a, b) => {
    const val = e.target.value;
    var data = JSON.parse(val);
    var classids = [];

    // Access the 'id' and 'transponder_name' fields
    var transponderId = data.id;
    var transponderName = data.transponder_name;
    var classdetails = {
      class_id: b,
      transponder_id: transponderId,
      transponder_name: transponderName,
    };
    classids.push(classdetails);
    var data2 = {
      event_participant_id: Number(props.participateId),
      class_ids: classids,
    };
    let eventClassResponse =
      await combinedServices.addEventParticipateClassMany(data2);
      // calculateEnterClassPrice();
  };
  const handleTransponderChange = async (e, i) => {
    const val = e.target.value;
    var data = JSON.parse(val);

    // Access the 'id' and 'transponder_name' fields
    var transponderId = data.id;
    var transponderName = data.transponder_name;

    const newArray = array.map((item) => {
      if (item.index == i) {
        var data = {
          class_id: item.class_ids, // Assuming you want to use class_ids from the original array
          transponder_id: transponderId, // Use an empty string if transponderName is undefined
          transponder_name: transponderName, // Use an empty string if transponderId is undefined
        };
      }
      // calculateEnterClassPrice();
      return data;
      // calculateEnterClassPrice();
    });

    const para = newArray.filter((data) => data !== undefined);
    if (para.length == 0) {
      swal("Select Corresponding Transponder!!");
      setDropdownValues((prevValues) => {
        const newValues = [...prevValues];
        newValues[i] = ""; // Set to an initial value or an empty string
        return newValues;
      });
    } else {
      var data2 = {
        event_participant_id: Number(props.participateId),
        class_ids: para,
        // transponder_id:para.transponder_id,
        // transponder_name:para.transponder_name

        //participation_status: "CANCELLED"
      };
    }
    let eventClassResponse =
      await combinedServices.addEventParticipateClassMany(data2);
      setTransponderSelected(false)
      setClassSelected(false)
  };
  const handleUpdateSendEmail = async () => {
    if(classSelected==false && transponderSelected==false){
      setIsLoder(true)
    let eventClassResponse = await combinedServices.sendEmailForParticipant(
      props.participateId
    );
    if (eventClassResponse.status == 200) {
      setIsLoder(false)
      swal({
        text: "Your entry update successfully. Please check all update details on your mail",
        icon: "success",
      }).then(() => {
        setTransponder(false)
        
          setClassSelected(false)
        window.location.reload();
      });
    }
    }else if(transponder==false){
      setIsLoder(true)
      let eventClassResponse = await combinedServices.sendEmailForParticipant(
        props.participateId
        );
        
      if (eventClassResponse.status == 200) {
        setIsLoder(false)
        swal({
          text: "Your entry update successfully. Please check all update details on your mail",
          icon: "success",
        }).then(() => {
          setTransponder(false)
          
          setClassSelected(false)
          window.location.reload();
        });
      }
    }else if(classSelected==true && transponderSelected==false){
      swal('oops','Select Transponder','error')
    }else if(classSelected==true && transponderSelected==true){
      setIsLoder(true)
      let eventClassResponse = await combinedServices.sendEmailForParticipant(
        props.participateId
      );
      if (eventClassResponse.status == 200) {
        setIsLoder(false)
        swal({
          text: "Your entry update successfully. Please check all update details on your mail",
          icon: "success",
        }).then(() => {
          setTransponder(false)
          setClassSelected(false)
          window.location.reload();
        });
      }
    }
  };
  const handleclickopen1 = () => {
    setEditOpen(true);
  };
  const handleClose2 = async () => {
    setEditOpen(false);
    let response = await combinedServices.getTransponders(selectedSubMemberId);
    setTransponders1(response.data.data);
    // calculateEnterClassPrice()
  };
  
  return (
    <>
      <div className="addEventMid">
        <div className="addEventTable">
          <table>
            <tr>
              <th>CLASS</th>
              {transponder == true ? <th>Transponder</th> : ""}
              <th>Class Status</th>
              <th></th>
            </tr>
            {participantClassData.sort((a,b)=>{
              const nameA = a.class_name.toUpperCase()
              const nameB = b.class_name.toUpperCase()
              if(nameA<nameB){
                return -1
              }else if(nameB<nameA){
                return 1
              }else{
                return 0
              }
            }).map((classes, classIndex) => {
              
              return (
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      checked={classes.status == "ENTERED" ? "true" : ""}
                      className="checkInpt"
                      name="checkInpt"
                      onChange={(e) =>
                      handleRegistionCost(e, classes, classIndex)
                      }
                    />
                    {classes.class_name}
                  </td>
                  {transponder == true ? (
                    <td>
                      <div className="smllInput" style={{ display: "flex" }}>
                        <div
                          className="listLeft"
                          style={{
                            width: "70%",
                            textAlign: "initial",
                          }}
                        >
                          <ul
                            // className="flexBox eventForm"
                            disabled={selectedSubMemberId === 0}
                          >
                            <li>
                              <select
                                className="formSelect"
                                id="sub_member_name"
                                onChange={(e) =>
                                  handleTransponderChange1(
                                    e,
                                    classIndex,
                                    classes.class_name,
                                    classes.class_id
                                  )
                                }
                              >
                                <option value="">Select Transponder</option>
                                {transponders1.length > 0
                                  ? transponders1.map((value) => {
                                      return (
                                        <option
                                          key={value.id}
                                          value={JSON.stringify(value)}
                                          selected={
                                            value.id == classes.transponder_id
                                          }
                                        >
                                          {value.transponder_name +
                                            " - " +
                                            "(" +
                                            value.id +
                                            ")"}{" "}
                                        </option>
                                      );
                                    })
                                  : ""}
                              </select>
                            </li>
                          </ul>
                        </div>
                        <div
                          style={{
                            width: "30%",
                            paddingRight: "5px",
                            textAlign: "initial",
                          }}
                        >
                          <Button variant="outlined" onClick={handleclickopen1}>
                            +
                          </Button>

                          <Dialog open={editopen} onClose={handleClose2}>
                            <DialogTitle>Transponders</DialogTitle>
                            <DialogContent>
                              <EditableForm data={selectedSubMemberId} />
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={handleClose2}>Save</Button>
                            </DialogActions>
                          </Dialog>
                        </div>
                      </div>
                    </td>
                  ) : (
                    ""
                  )}
                  <td>{classes.status}</td>
                  <td></td>
                </tr>
              );
            })}

            {eventClassData.sort((a,b)=>{
              const nameA = a.class_name.toUpperCase()
              const nameB = b.class_name.toUpperCase()
              if(nameA<nameB){
                return -1
              }else if(nameB<nameA){
                return 1
              }else{
                return 0
              }
            }).map((classes, classIndex) => {
              return (
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      // checked={classes.status == "ENTERED"}
                      className="checkInpt"
                      name="checkInpt"
                      onChange={(e) =>
                        handleRegistionCost(e, classes, classIndex)
                      }
                    />
                    {classes.class_name}
                  </td>
                  {transponder == true ? (
                    <td>
                      {" "}
                      <div className="smllInput" style={{ display: "flex" }}>
                        <div
                          className="listLeft"
                          style={{
                            width: "70%",
                            textAlign: "initial",
                          }}
                        >
                          <ul
                          // className="flexBox eventForm"
                          >
                            <li>
                              <select
                                className="formSelect"
                                id="sub_member_name"
                                onChange={(e) =>
                                  handleTransponderChange(e, classIndex)
                                }
                              >
                                <option value="">Select Transponder</option>
                                {transponders1.length > 0
                                  ? transponders1.map((value) => {
                                      return (
                                        <option
                                          key={value.id}
                                          value={JSON.stringify(value)}
                                        >
                                          {value.transponder_name +
                                            " - " +
                                            "(" +
                                            value.id +
                                            ")"}{" "}
                                        </option>
                                      );
                                    })
                                  : ""}
                              </select>
                            </li>
                          </ul>
                        </div>
                        <div
                          style={{
                            width: "30%",
                            paddingRight: "5px",
                            textAlign: "initial",
                          }}
                        >
                          <Button variant="outlined" onClick={handleclickopen1}>
                            +
                          </Button>

                          <Dialog open={editopen} onClose={handleClose2}>
                            <DialogTitle>Transponders</DialogTitle>
                            <DialogContent>
                              <EditableForm data={selectedSubMemberId} />
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={handleClose2}>Save</Button>
                            </DialogActions>
                          </Dialog>
                        </div>
                      </div>
                    </td>
                  ) : (
                    ""
                  )}
                  <td>{classes.status==""?"NOT ENTERED":classes.status}</td>
                  <td></td>
                </tr>
              );
            })}

            <tr className="paymentMetHd">
              <td>Total</td>
              <td></td>

              <td>{total}</td>
            </tr>
          </table>
        </div>
      </div>
      <div className="addEventBot">
        <div className="paymentType">
          <div className="addEventBot">
            <div className="text-center mt-4 loadMore">
             {isLoder ? <div className="text-center mt-4 loadMore">
                    <Spinner color="primary" />
                  </div>: <button
                type="button"
                className="continueBtn"
                onClick={handleUpdateSendEmail}
              >
                Update Entry
              </button>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddEvent;
