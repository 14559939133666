import React from 'react';
import { NavLink } from "react-router-dom";


const Scroll = () => {
    window.scrollTo(0, 0);
}
const Footer = () => {
    const facebook = "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/facebook_wht.png"
    const logo = "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/footer_logo.png"
    const instagram = "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/instagram_wht.png"
    const linkein = "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/linkedin.png"
    const twitter = "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/twitter_wht.png"
    return (

        <footer className="footer">
            <div className="container">
                <div className="footerTop flexBox spacebetween">
                    <div className="footerInfo">
                        <div><a href="#"><img src={logo} alt="Logo" /></a></div>
                        <div className="footerText">
                            The NAMBA Race Entry System was developed and donated to NAMBA by David Santistevan, NAMBA #1
                            {/* If you are looking for the smoothest way to reach the top speed & cruise
                        in front of your competitors, you’re in the right place. Welcome to Namba. */}
                        </div>
                    </div>
                    <div className="quickLinks">
                        <div className="footerHd">Quick Links</div>
                        <div className="flexBox spacebetween">
                            <ul className="footerLinks" >
                                <li key="home1" onClick={Scroll}><NavLink to="/">Home</NavLink></li>
                                {/* <li key="about-us1" onClick={Scroll}><NavLink to="/about-us" > About</NavLink></li> */}
                                {/* <li key="latest-new1"><NavLink to="/">Latest News</NavLink></li> */}
                                <li key="how-it-works"><NavLink to="/how-it-works">How It Works</NavLink></li>
                                <li key="event1" onClick={Scroll}><NavLink to="/event">Events</NavLink></li>

                            </ul>
                            <ul className="footerLinks">

                                <li key="club1" onClick={Scroll}><NavLink to="/club">Club</NavLink></li>
                                <li key="club-result1" onClick={Scroll}><NavLink to="/club-result">Race</NavLink></li>
                                <li key="club-result1" onClick={Scroll}><NavLink to="/privacy-policy">Privacy Policy</NavLink></li>

                            </ul>
                        </div>
                    </div>
                    <div className="contactInfo">
                        {/* <div className="footerHd">OFFICE IN NEW YORK</div> */}
                        <div className="info location">
                            <span className="material-icons icon">location_on</span>
                            NAMBA INTERNATIONAL  162 Avenida Chapala San Marcos, CA 92069</div>
                        <div className="info phone">
                            <span className="material-icons icon">call</span>
                            + (760)522-9138<br />

                        </div>
                        <div className="info mail"><a href="secretary@namba.com"><span className="material-icons icon">mail</span>secretary@namba.com</a></div>
                    </div>
                    <div className="socialMedia">
                        <div className="footerHd">Social Media</div>
                        <ul className="footerSM flexBox">
                            <li key="linkein1"><a href="#"><img src={linkein} alt="Linkedin" /></a></li>
                            <li key="twitter1"><a href="#"><img src={twitter} alt="Twitter" /></a></li>
                            <li key="facebook1"><a href="https://www.facebook.com/NAMBAInt"><img src={facebook} alt="Facebook" /></a></li>
                            <li key="instagram1"><a href="#"><img src={instagram} alt="Instagram" /></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="footerBot tac">&copy; Concentrics, Inc. 2023, All rights reserved</div>
        </footer>


    )
}
export default Footer;