import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import combinedServices from "../../../shared/services/user-service";
import "./ManageHeatsheet.scss";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { useNavigate } from "react-router-dom";

export default function DownloadPaymentExcel() {
  let eventId = useParams().id;

  const navigate = useNavigate();
  const [driverData, setDriverData] = useState([]);
  const [entryFees, setEntryFees] = useState(0);
  const [boatFees, setBoatFees] = useState(0);
  const [eventDetails1, setEventDetails1] = useState([]);
  const [boatCount, setBoatCount] = useState(0);
  const [driverCount, setDriverCount] = useState(0);
  const [totalBoatFees, setTotalBoatFees] = useState(0);
  const [eventTitle, setEventTitle] = useState("");
  const [finalDate, setFinalDate] = useState("");

  var boat_Fees = 0;
  useEffect(async () => {
    //fetching driver data
    var data = {
      event_id: eventId,
      classes: true,
    };

    let driverResponse = await combinedServices.getEventParticipate(data);

    //filtering only those drivers who have entered classes in them
    const filteredDrivers = driverResponse.data
      .filter((driver) => {
        for (let item of driver.classes) {
          if (item.participation_status == "ENTERED") {
            return true;
          }
        }
      })
      .sort((a, b) => {
        if (
          a.user.user_lastname + a.user.user_firstname <
          b.user.user_lastname + b.user.user_firstname
        ) {
          return -1;
        }
        if (
          a.user.user_lastname + a.user.user_firstname >
          b.user.user_lastname + b.user.user_firstname
        ) {
          return 1;
        }
        return 0;
      });
   
    setDriverData(filteredDrivers); //setting filtered driver data
    setDriverCount(filteredDrivers.length); //setting filtered driver length

    //fetching event details
    const eventDetails = await combinedServices.getClassByEventId(eventId);
    setEventDetails1(eventDetails);
    setEntryFees(Number(eventDetails.data.registration_charge)); //setting entry fee
    boat_Fees = eventDetails.data.boat_charge;
    setBoatFees(Number(boat_Fees)); //setting the boat fee
    setEventTitle(eventDetails.data.full_title); // setting the title of the event

    //to calculate total boat fees we need to count all the total entered classes of the participants
    let totalEnteredClasses = 0;
    driverResponse.data.forEach((driver) => {
      for (let item of driver.classes) {
        if (item.participation_status == "ENTERED") {
          totalEnteredClasses += 1;
        }
      }
    });
    setTotalBoatFees(totalEnteredClasses * boat_Fees);

    setTimeout(() => {
      if (exportRef.current) {
        exportRef.current.save();
        navigate("../manage-heatsheet/" + eventId, { replace: true });
      }
    }, 500);
  }, []);

  const exportRef = React.useRef();

  const exportData = [];

  const headerRow = {
    NambaNumber: "Namba",
    Date: "Driver",
    Round: "Fee",
    TotalClasses: "Total Classes",
    Heat: "Notes",
  };
  exportData.push(headerRow);
  var boatsNumber = 0;
  const singleBoatFees = eventDetails1?.data?.boat_charge;

  driverData.map((driver) => {
    let participantBoats = 0;
    let total = entryFees;
    let c = 0;
    for (let cls of driver.classes) {
      if (cls.participation_status == "ENTERED") {
        c += 1;
      }
    }
    const rowData = {
      NambaNumber: driver.user.user_nambanum_fkey,
      Driver: driver.user.user_lastname + ", " + driver.user.user_firstname,
      Fee: entryFees,
      TotalClasses: c,
      Notes: "",
    };
    exportData.push(rowData);

    for (let item of driver.classes) {
      if (item.participation_status == "ENTERED") {
        const rowData = {
          NambaNumber: "",
          Class: item.class.name,
          Fee: boatFees,
          Notes: "",
        };
        boatsNumber += 1;
        participantBoats += 1;
        total = Number(total) + Number(boatFees);
        exportData.push(rowData);
      }
    }

    const totalCostData = {
      NambaNumber: "",

      Total: total,
      Notes: "",
    };
    exportData.push(totalCostData);
    exportData.push({
      NambaNumber: "",
      Driver: "",
      Fee: "",
      Notes: "",
    });
  });

  exportData.push({
    NambaNumber: "",

    Driver: `Entry Fees(${driverData.length} Drivers)`,
    Fee: entryFees * driverData.length,
    Notes: "",
  });

  exportData.push({
    NambaNumber: "",

    Driver: `Boat Fees(${boatsNumber} Boats)`,
    Fee: boatsNumber * singleBoatFees,
    Notes: "",
  });

  exportData.push({
    NambaNumber: "",

    Driver: `TOTAL`,
    Total: boatsNumber * singleBoatFees + driverData.length * Number(entryFees),
    Notes: "",
  });

  return (
    <div>
      <ExcelExport
        data={exportData.slice(1)} // Exclude the first row (fixed row)
        ref={exportRef}
        fileName={`Check-in-sheet.xlsx`}
        filterable={true}
        groupable={true}
      >
        <ExcelExportColumn
          field="NambaNumber"
          title="Namba"
          width={100}
          cellOptions={{
            textAlign: "center",
          }}
        />

        <ExcelExportColumn field="Driver" title="Name" width={200} />
        <ExcelExportColumn field="Class" title="Class" width={200} />
        <ExcelExportColumn
          field="Fee"
          title="Fee"
          width={100}
          cellOptions={{
            format: "$ #,##0.00",
          }}
          groupFooterCellOptions={{
            textAlign: "right",
            format: "$#,##0.00",
            bold: true,
          }}
          footerCellOptions={{
            wrap: true,
            textAlign: "center",
            format: "$#,##0.00",
            bold: true,
          }}
        />
        <ExcelExportColumn
          field="TotalClasses"
          title="Total Classes"
          width={100}
        />
        <ExcelExportColumn
          field="Total"
          title="Total"
          width={100}
          cellOptions={{
            format: "$ #,##0.00",
          }}
          groupFooterCellOptions={{
            textAlign: "right",
            format: "$#,##0.00",
            bold: true,
          }}
          footerCellOptions={{
            wrap: true,
            textAlign: "center",
            format: "$#,##0.00",
            bold: true,
          }}
        />

        <ExcelExportColumn field="Notes" title="Notes" width={150} />
      </ExcelExport>
    </div>
  );
}
