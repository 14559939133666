// export const userConstants = {
//     REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
//     REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
//     REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

//     LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
//     LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
//     LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    
//     LOGOUT: 'USERS_LOGOUT',

//     GETALL_REQUEST: 'USERS_GETALL_REQUEST',
//     GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
//     GETALL_FAILURE: 'USERS_GETALL_FAILURE',
    
// };

export const userConstants = {
//  baseURL: "https://api.race.namba.com/",
 baseURL:"https://api.concentricsinc.flexsin.org/"
//baseURL: "http://100.100.7.56:3002/"
   // baseURL: "http://localhost:3002/"
//  baseURL:"http://localhost:3002/"
   

    
}
export default userConstants
