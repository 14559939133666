import React, { useState ,useEffect } from "react";
import combinedServices from "../../shared/services/user-service";
import setLocalData from "../../shared/localStorage/setData";
import { useForm } from "react-hook-form";
import { Modal, ModalBody, ModalHeader, Button, ModalFooter } from 'reactstrap';
import { useParams } from "react-router-dom";
import "./Login.scss";
import swal from 'sweetalert';
const Login = (props) => {
    useEffect(async () => {
        const authResult = new URLSearchParams(window.location.search);
        const token = authResult.get('token');

        const login_response = await combinedServices.loginWithToken(token);
        var login_data = login_response.data;

        if (login_response.status == 200) {
     
          let login_response_1 = {
           login_user_detail: login_response
         };
     
           if(login_data.user.user_type == "superadmin"){
             window.location.href = "/admin-dashboard" ;
            
             setLocalData("admin_info",login_data);
           }else{
     
             var clubOfficerIn = login_data.user.clubOfficerIn.length ;
            
             setLocalData("user_info", login_response_1);
             if(props.isLogin){
               window.location.href = props.path ; 
               //navigate(props.path, { replace: true });
             }else if(clubOfficerIn > 0 ){
               window.location.href = "/club-dashboard";
               //navigate("../club-dashboard", { replace: true });
             }else{
               window.location.href = "/user-dashboard";
               //navigate("../user-dashboard", { replace: true });
             }
     
         
           }
          
          
         } else {
           swal("!Opps",login_response.msg,"error")
           //setErrorMsg(login_response.msg);
         }

    })
 

  

  return (
    <>

      <div>
       
      </div>
    </>


  )


}

export default Login;