import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import combinedServices from "../../../shared/services/user-service";
import "./ManageHeatsheet.scss";
import Doc from "./DocService";
import PdfContainer from "./PdfContainer";
import moment from "moment";

export default function DownloadPayment2() {
  // get event id from params
  let eventId = useParams().id;

  const [driverData, setDriverData] = useState([]);
  const [entryFees, setEntryFees] = useState(0);
  const [boatFees, setBoatFees] = useState(0);
  const [boatCount, setBoatCount] = useState(0);
  const [driverCount, setDriverCount] = useState(0);
  const [totalBoatFees, setTotalBoatFees] = useState(0);
  const [eventTitle, setEventTitle] = useState("");
  const [finalDate, setFinalDate] = useState("");
  const [finalDriverData, setFinalDriverData] = useState([]);

  useEffect(async () => {
   
    //fetching event details
    const eventDetails = await combinedServices.getClassByEventId(eventId);
    setEntryFees(eventDetails.data.registration_charge); //setting entry fee
    const boat_Fees = eventDetails.data.boat_charge;
    setBoatFees(boat_Fees); //setting the boat fee
    setEventTitle(eventDetails.data.full_title); // setting the title of the event


     //fetching driver data
    var data = {
      event_id: eventId,
      classes: true,
    };

    let driverResponse = await combinedServices.getEventParticipate(data);

    //filtering only those drivers who have entered classes in them

    const filteredDrivers = driverResponse.data.filter((driver) => {
      for (let item of driver.classes) {
        if (item.participation_status == "ENTERED") {
          return true;
        }
      }
    });
    var boat_count=0
    
    const finalDriverData = filteredDrivers.map((driver) => {
      let userEnteredClasses = [];
      for (let item of driver.classes) {
        if (item.participation_status == "ENTERED") {
          userEnteredClasses.push(item.class.name);
          boat_count+=1
        }
      }
      var data = {
        user: driver.user.user_firstname + " " + driver.user.user_lastname,
        classes: userEnteredClasses,
        entryFees: eventDetails.data.registration_charge,
        boatFees: boat_Fees,
      };
      return data;
    });
    setBoatCount(boat_count)
    const sortedDriverData = finalDriverData.sort((a,b)=>a.user.localeCompare(b.user))
    setFinalDriverData(sortedDriverData);
    setDriverData(filteredDrivers); //setting filtered driver data
    setDriverCount(filteredDrivers.length); //setting filtered driver length


    //converting the event start and event end date
    const time_zone = eventDetails.data.time_zone;
    const final_Date =
      timeaccotolocation2(eventDetails.data.event_start, time_zone).split(
        " "
      )[1] +
      " " +
      timeaccotolocation2(eventDetails.data.event_start, time_zone).split(
        " "
      )[2] +
      "-" +
      timeaccotolocation2(eventDetails.data.event_end, time_zone).split(" ")[2];
    setFinalDate(final_Date);

    //to calculate total boat fees we need to count all the total entered classes of the participants
    let totalEnteredClasses = 0;
    driverResponse.data.forEach((driver) => {
      for (let item of driver.classes) {
        if (item.participation_status == "ENTERED") {
          totalEnteredClasses += 1;
        }
      }
    });
    setTotalBoatFees(totalEnteredClasses * boat_Fees);
    
  }, []);

  // Download Pdf file of heatsheet
  const createPdf = (html) => Doc.createPdf(html, eventTitle, finalDate);

  //function to convert the date into respective timezone
  const timeaccotolocation2 = (date, timeZone) => {
    if (timeZone == "") {
      timeZone = "Canada/Eastern";
    }
    const utcMoment = moment.utc(date);
    const localMoment = utcMoment.tz(timeZone);

    // const timeZoneAbbreviation = localMoment.format('z');
    const timeZoneOffset = localMoment.format("Z");
    return localMoment.format("ddd MMM DD YYYY HH:mm:ss");
  };

  return (
    <>
      <div className="downloadRef">
        <PdfContainer createPdf={createPdf} eventId={eventId}>
          <React.Fragment>
            <div className="masterTable2" style={{ marginTop: "100px" }}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  borderBottom: "1px solid black",
                }}
              >
                <p style={{ flex: "4", textAlign: "left", fontWeight: "700" }}>
                  Drivers
                </p>
                <p style={{ flex: "1", textAlign: "left", fontWeight: "700" }}>
                  Fees
                </p>
                <p style={{ flex: "4", textAlign: "left", fontWeight: "700" }}>
                  Notes
                </p>
              </div>

              {finalDriverData.map((driver) => {
                return (
                  <>
                  <tr style={{display:'flex'}}>
                    <td style={{flex:'4'}}>
                      <p style={{marginBottom:'0rem',fontWeight:'700'}}>{driver.user}</p>

                     <div style={{display:'flex',flexDirection:'column'}}>
                     {driver.classes.map((cls)=>{
                        return <p style={{marginBottom:'0rem',lineHeight:'15px',paddingLeft:'40px'}}>{cls}</p>
                      })}
                      </div> 
                    </td>
                    {console.log('driver',driver)}
                    <td style={{flex:'1'}}><p style={{marginBottom:'0rem'}}>$ {driver.entryFees}</p> {driver.classes.map((cls)=>{
                      return <p style={{marginBottom:'0rem',lineHeight:'15px'}}>$ {driver.boatFees}</p>
                    })} </td>
                    
                    <td style={{flex:'4'}}><p style={{marginBottom:'0rem',lineHeight:'15px'}}></p></td>

                  </tr>
                  <tr style={{display:'flex'}}><td style={{fontWeight:'700',fontSize:'14px',flex:'4'}}>TOTAL</td>
                  <td style={{flex:'1',fontWeight:'600',fontWeight:'700'}}>$ {Number(entryFees)+driver.classes.length*Number(boatFees)}.00</td>
                  <td style={{flex:'4'}}></td>

                  </tr>
                  </>
                );
              })}

              {/* {finalDriverData.map((driver)=>{
                return <div style={{display:'flex',width:'100%', borderBottom:'1px solid black'}}>
                <div style={{flex:'4'}}>
                <div style={{display:'flex',flexDirection:'column',marginBottom:'0rem'}}>
                <p style={{textAlign:'left',fontWeight:'700',marginBottom:'0rem'}}>{driver.user}</p>
                  {driver.classes.map((item)=>{
                    return <p style={{marginBottom:'0rem',lineHeight:'16px'}}>{item}</p>
                  })} 
                </div>
                </div>
                <div style={{flex:'1'}}>
                <p style={{textAlign:'left',marginBottom:'0rem'}}>$ {driver.entryFees}</p>
                {driver.classes.map((cls)=>{
                  return <p style={{marginBottom:'0rem',lineHeight:'16px'}}>$ {boatFees}</p>
                })}
                </div>
                <p style={{flex:'4',textAlign:'left',lineHeight:'16px'}}></p>
              </div>
              })} */}
               <div
                style={{
                  display: "flex",
                  width: "100%",
                  borderTop:'1px solid black'
                }}
              >
                <p style={{ flex: "4", textAlign: "left", fontWeight: "700", marginBottom:'0rem' }}>
                  All Drivers
                </p>
                <p style={{ flex: "1", textAlign: "left", fontWeight: "700" , marginBottom:'0rem' }}>
                  
                </p>
                <p style={{ flex: "4", textAlign: "left", fontWeight: "700" , marginBottom:'0rem' }}>
                </p>
              </div> 
              <div
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <p style={{ flex: "4", textAlign: "left", fontWeight: "700", marginBottom:'0rem'  }}>
                  Entry Fees({`${finalDriverData.length}`} Drivers)
                </p>
                <p style={{ flex: "1", textAlign: "left", fontWeight: "700", marginBottom:'0rem'  }}>
                  
                   $ {finalDriverData.length*Number(finalDriverData[0]?.entryFees)}.00
                </p>
                <p style={{ flex: "4", textAlign: "left", fontWeight: "700", marginBottom:'0rem' }}>
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                 
                }}
              >
                <p style={{ flex: "4", textAlign: "left", fontWeight: "700", marginBottom:'0rem'  }}>
                  Boat Fees({`${boatCount}`} count)
                </p>
                <p style={{ flex: "1", textAlign: "left", fontWeight: "700", marginBottom:'0rem'  }}>
                  
                   $ {boatCount*Number(finalDriverData[0]?.boatFees)}.00
                </p>
                <p style={{ flex: "4", textAlign: "left", fontWeight: "700", marginBottom:'0rem'  }}>
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  borderBottom: "1px solid black",
                }}
              >
                <p style={{ flex: "4", textAlign: "left", fontWeight: "700", marginBottom:'0rem'  }}>
                  TOTAL
                </p>
                <p style={{ flex: "1", textAlign: "left", fontWeight: "700", marginBottom:'0rem'  }}>
                  
                   $ {boatCount*Number(finalDriverData[0]?.boatFees)+finalDriverData.length*Number(finalDriverData[0]?.entryFees)}.00
                </p>
                <p style={{ flex: "4", textAlign: "left", fontWeight: "700", marginBottom:'0rem'  }}>
                </p>
              </div>
            </div>
          </React.Fragment>
        </PdfContainer>
      </div>
    </>
  );
}
