import React, { useState, useEffect } from "react";
import getLocalData from "../../../shared/localStorage/getData";
import combinedServices from "../../../shared/services/user-service";
import "./EventManagement.scss";
import swal from 'sweetalert';
import moment from 'moment-timezone';
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
moment.tz.setDefault("timeZone");
const AddScore = (props) => {

    const [eventClassDetails, setClassEventDetails] = useState([]);
    const [eventId, setEventId] = useState("");
    const [userIds, setUserId] = useState("");
    const [addScoreUserData, setAddScoreUserData] = useState([]);
    const [hotelDetailForm, setHotelDetailForm] = useState({});
    const [classIds, setClassIds] = useState("");
    const [roundArr, setRoundArr] = useState([]);
    const [roundId, setRoundId] = useState("");
    const [allHeats, setAllHeats] = useState([]);
    const [value, setValue] = React.useState(0);

    useEffect(async () => {

        if (getLocalData("user_info", "isLogin")) {
            var round = [];

            var userId = getLocalData("user_info", "all").userData.login_user_detail.data.user.id;

            let eventDetails = await combinedServices.getClassByEventId(props.eventid);
            let eventClassDetail = eventDetails.data.classes;
            if (eventDetails.data.rounds_per_class) {
                for (var i = 1; i <= eventDetails.data.rounds_per_class; i++) {
                    round.push(i)
                }

            }

            setRoundArr(round);
            setClassEventDetails(eventClassDetail)
            setEventId(props.eventid)
            setUserId(userId)
            getAllHeat(props.eventid);





        }


    }, [])

    const handleClass = (e) => {
        setClassIds(e.target.value);
        //getAllUserScore(eventId)
    }

    const handleRound = (e) => {
        setRoundId(e.target.value)
        //setAllHeats([])
        getAllHeat(eventId);


    }
    const handleHeat = () => {

        getAllHeat(eventId);
    }

    const getAllHeat = async (event_id) => {
        var heatData = [];
        var class_id = document.getElementById("classId").value;
        var round_id = document.getElementById("roundId").value;

        var data = {

            event_id: event_id ? event_id : eventId,
            class_id: class_id,
            round: round_id,
            heats_cound_only: true

        }


        let heatNumberResponse = await combinedServices.getHeatNumbers(data);


        if (heatNumberResponse.status == 200) {
            var heatDetails = heatNumberResponse.data;
          
            if(heatDetails){
                heatDetails.map((value) => {

                    heatData.push(value);
    
                })

                setAllHeats(heatData);
                getAllUserScore(event_id)
    


            }else{
                setAddScoreUserData([]) 

            }
            

           
        }
        

       

        //console.log("aa",heatData)


    }

    const getAllUserScore = async (event_id) => {

        var class_id = document.getElementById("classId").value;
        var round_id = document.getElementById("roundId").value;
        var heat_id = document.getElementById("heatId").value;


        if (class_id && round_id && heat_id) {



            var data = {
                event_id: event_id,
                class_id: class_id,
                round: round_id,
                class_heat_number: heat_id,


            }


            var addScoreResponse = await combinedServices.getLeaderBoardForAddScore(data);


            if (addScoreResponse.data.leaderboard.length == 0) {
                let newScoreArr = [];
                var eventData = {
                    event_id: Number(event_id),
                    class_id: Number(class_id)
                }

                let addScoreResponse = await combinedServices.addLeaderBoard(eventData);
                getCreatedScore(event_id, newScoreArr);

            } else {

                addScoreResponse.data.leaderboard.map((leaderBoard, leaderIndex) => {


                    leaderBoard.class_score.map((scoreValue, scoreIndex) => {


                        {
                            var totalScore = 0;
                            var newFormValues = hotelDetailForm;
                            scoreValue.rounds.map((round, index) => {

                                totalScore = totalScore + round;

                                newFormValues["roundValue" + scoreIndex + index] = round;




                            })
                            newFormValues["total" + scoreIndex] = totalScore;


                            setHotelDetailForm(newFormValues);
                        }


                    })




                })


                setAddScoreUserData(addScoreResponse.data.leaderboard)


            }

        } else {
            setAddScoreUserData([])

        }


    }
    const getCreatedScore = async (event_id,newScoreArr) => {
        var newArr = [];

        var class_id = document.getElementById("classId").value;
        var round_id = document.getElementById("roundId").value;
        var heat_id = document.getElementById("heatId").value;

        var data = {
            event_id: event_id,
            class_id: class_id,
            round: round_id,
            class_heat_number: heat_id,


        }

        let addScoreResponse = await combinedServices.getLeaderBoardForAddScore(data);

        addScoreResponse.data.leaderboard.map((leaderBoard, leaderIndex) => {

            //newArr.userName =    loaderDetail.user_firstname + " " + loaderDetail.user_lastname  

            leaderBoard.class_score.map((scoreValue, scoreIndex) => {


                {
                    var totalScore = 0;
                    var newFormValues = hotelDetailForm;
                    scoreValue.rounds.map((round, index) => {

                        totalScore = Number(totalScore) + Number(newScoreArr["roundValue" + scoreIndex + index]);

                        newFormValues["roundValue" + scoreIndex + index] = newScoreArr["roundValue" + scoreIndex + index];




                    })
                    newFormValues["total" + scoreIndex] = totalScore;

                    setHotelDetailForm(newFormValues);
                }


            })




        })


        //setAddScoreUserData(addScoreResponse.data.leaderboard)
    }
    

    const handleAddScoreOnClassChange = async (e) => {

        var eventData = {
            event_id: eventId,
            class_id: e.target.value

        }
        let addScoreResponse = await combinedServices.getLeaderBoardForAddScore(eventData);
        addScoreResponse.data.leaderboard.map((leaderBoard, leaderIndex) => {


            leaderBoard.class_score.map((scoreValue, scoreIndex) => {


                {
                    var totalScore = 0;
                    var newFormValues = hotelDetailForm;
                    scoreValue.rounds.map((round, index) => {

                        totalScore = totalScore + round;

                        newFormValues["roundValue" + scoreIndex + index] = round;




                    })
                    newFormValues["total" + scoreIndex] = totalScore;
                    //console.log("aa",totalScore)
                    setHotelDetailForm(newFormValues);
                }


            })




        })


        //setAddScoreUserData(addScoreResponse.data.leaderboard)


    }



    let handleUpdateScore = (i,e) => {
        
            const newTodos = Object.assign({}, hotelDetailForm);
           
            newTodos[e.target.name] = Number(e.target.value);
          


            
            getCreatedScore(eventId, newTodos);
            setHotelDetailForm(newTodos);


        //setHotelDetailForm(newTodos);
        //setHotelDetailForm(newFormValues);
    }

    



    const handleScoreUpdate = () => {
      
        //updateLeaderBoard

        addScoreUserData.map(async (leaderBoard, leaderIndex) => {

            var newScoreArray = [];

            leaderBoard.class_score.map((scoreValue, scoreIndex) => {


                var updateData = {};


                updateData.id = scoreValue.id;
                updateData.event_id = scoreValue.event_id;
                updateData.class_id = scoreValue.class_id;
                updateData.event_participant_id = scoreValue.event_participant_id;


                scoreValue.rounds.map((round, index) => {
                    var startIndex = index + 1;

                    updateData["round" + startIndex + "_score"] = Number(hotelDetailForm["roundValue" + scoreIndex + index] ? hotelDetailForm["roundValue" + scoreIndex + index] : 0);

                })

                //console.log("up",updateData)
                newScoreArray.push(updateData)



            })
            let addScoreResponse = await combinedServices.updateLeaderBoard(newScoreArray);
            if (addScoreResponse.status = 200) {

                swal("!Yes", addScoreResponse.data, "success");
            }




        })

    }



    return (
        <>

            <div>
                <div className="popupHd">Add Score</div>

                <div className="classess">
                    <span>Classes</span>
                    <select className="formSelect" id="classId" onChange={handleClass}>

                        {eventClassDetails.map((classDetail) => {
                            return (
                                <option value={classDetail.id}>{classDetail.name}</option>
                            )


                        })}
                    </select>
                </div>
                <div className="classess">
                    <span>Round</span>
                    <select className="formSelect" id="roundId" onChange={handleRound}>
                         <option>Select Round</option>

                        {roundArr.map((round) => {
                            return (
                                <option value={round}>{round}</option>
                            )


                        })}
                    </select>
                </div>

                <div className="classess">
                    <span>Heat</span>
                    <select className="formSelect" id="heatId" onChange={handleHeat}>
                       <option>Select Heat</option> 

                        {allHeats.map((heatValue) => {
                            return (
                                <option value={heatValue}>{heatValue}</option>
                            )


                        })}
                    </select>
                </div>
                 

{console.log(addScoreUserData)}
                {addScoreUserData.length > 0 ? addScoreUserData.map((classValue) => {

                    return (
                        <>
                            <div className="scoreTable">


                                <table>
                                    <tr>
                                        <th>Name</th>
                                        <th>R1</th>
                                        <th>R2</th>
                                        <th>R3</th>
                                        <th>R4</th>
                                        <th>R5</th>
                                        <th>Total</th>
                                    </tr>
                                    {classValue.class_score.map((loaderDetail, scoreIndex) => {
                                        return (




                                            <tr>
                                                <td>{loaderDetail.user_firstname + " " + loaderDetail.user_lastname}</td>
                                                {loaderDetail.rounds.map((round, index) => {
                                                    return (

                                                        <td><input type="number" min={0}  className="input" name={"roundValue" + scoreIndex + index}  value={hotelDetailForm["roundValue" + scoreIndex + index] && Math.max(0,hotelDetailForm["roundValue" + scoreIndex + index])} 
                                                        
                                                        onChange={e => handleUpdateScore(index, e)} /></td>
                                                    )
                                                })}

                                               


                                                <td>{hotelDetailForm["total" + scoreIndex] ? hotelDetailForm["total" + scoreIndex] : 0}</td>

                                            </tr>
                                        )


                                    })}



                                </table>

                            </div>
                        </>

                    )

                }) : <div className="noEvent">Not Found Any User for Score.</div>}


                {addScoreUserData.length > 0 && <div className="AddBtn"><button type="button" onClick={handleScoreUpdate}>Add</button></div>}
            </div >
        </>
    )
}
export default AddScore;