import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import combinedServices from '../../../shared/services/user-service';
import './ManageHeatsheet.scss'
import Doc from './DocService';
import PdfContainer from './PdfContainer';
import swal from 'sweetalert';
import getRemoveData from '../../../shared/localStorage/removeData'

export default function DownloadFees() {

  // get event id from params
  let eventId = useParams().id

  const [data, setData] = useState([]);
  const [rounds, setRounds] = useState([]);
  const [classes, setClasses] = useState([]);
  const [eventParticipant, setEventParticipant] = useState([]);
  const [eventDetails, setEventDetails] = useState([]);


  // call apis on page load
  useEffect(async () => {
    let finalArr =[];
    let classArr = [];
    await combinedServices.getClassByEventId(eventId).then(res => {
      if (res.status == 401) {
        swal(res.msg, "Please Re-login", "warning").then(() => {
          getRemoveData("user_info");
localStorage.clear();
          window.location.href = "/login";
        });
      }
      // console.log("data===================>",res.data)
      setEventDetails(res.data)
      let round = [];
      for (var i = 1; i <= res.data.rounds_per_class; i++) {
        round.push(i)
      }
      setRounds(round);

    }).catch(err => {
      alert(err);
    })

    await combinedServices.getAllHeatSheet({ event_id: eventId }).then(res => {
      // console.log("heatdata===================>",res.data)
      setEventParticipant(res.data);
    }).catch(err => {
      alert(err);
    })
    //getting class only

    var eventClassesData1 = await combinedServices.countByClasses(eventId);
    if(eventClassesData1.status==401){
      swal(eventClassesData1.msg, "Please Re-login", "warning").then(() => {
        getRemoveData("user_info");
        localStorage.clear();
        window.location.href = "/login";
      });
    }
    var participantClassData = eventClassesData1.data.participants;
    if (participantClassData) {
      participantClassData.map((classVal) => {
        if (classVal.run_date != null) {
          var data = {
            id: classVal.class_id,
            name: classVal.class_name
          }
          classArr.push(data)
        }
      })
      setClasses(classArr);
    }

    // getting all the data
    await combinedServices.getAllHeatSheet({ event_id: eventId }).then(res => {
      //  console.log("data===================>",res.data)
      res.data.map((val, i) => {
        // console.log("val========.",val)
        return val.participants.map((itm, i) => {
          //  console.log("itm===============>",itm)
          return finalArr.push({
            Class: itm.event_participant_class.class.name, Heat: val.heat_number,
            name: itm.event_participant_class.event_participant.user.user_firstname + " " + itm.event_participant_class.event_participant.user.user_lastname, Score: '', round: itm.round
          })
        })
      })
      let uniqueChars = [...new Set(finalArr)];
      setData(finalArr);
    }).catch(err => {
      alert(err);
    })
  }, [])

  // Download Pdf file of heatsheet
  const createPdf = (html) => Doc.createPdf(html);



  return (
    <>

  

      <div className="downloadRef">
      <PdfContainer createPdf={createPdf}>

        <React.Fragment >
        <div className="masterTable">
                        <table>
                            <tr>
                                <th style={{ width:'20%'}}>Saturday</th>
                                <th style={{fontWeight: '400', textAlign: 'center', width:'8%'}}>Heat</th>
                                <th style={{fontWeight: '400', textAlign: 'center', width:'8%'}}>Score</th>
                                <th style={{fontWeight: '400', textAlign: 'center', width:'8%'}}>Heat</th>
                                <th style={{fontWeight: '400', textAlign: 'center', width:'8%'}}>Score</th>
                                <th style={{fontWeight: '400', textAlign: 'center', width:'8%'}}>Heat</th>
                                <th style={{fontWeight: '400', textAlign: 'center', width:'8%'}}>Score</th>
                                <th style={{fontWeight: '400', textAlign: 'center', width:'8%'}}>Heat</th>
                                <th style={{fontWeight: '400', textAlign: 'center', width:'8%'}}>Score</th>
                                <th style={{fontWeight: '400', textAlign: 'center', width:'8%'}}>Total</th>
                                <th style={{textAlign: 'center', width:'8%'}}>FEES</th>
                            </tr>
                            <tr>
                                <td>A Stock OPC</td>
                                <td style={{textAlign: 'center'}}>1</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>12</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>23</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>34</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>$20.00</td>
                            </tr>
                            <tr>
                                <td>A Mono</td>
                                <td style={{textAlign: 'center'}}>2</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>13</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>24</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>35</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>$7.00</td>
                            </tr>
                            <tr>
                                <td>Open Gas Mono	</td>
                                <td style={{textAlign: 'center'}}>4</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>15</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>25</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>37</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>$7.00</td>
                            </tr>
                            <tr>
                                <td>Classic Thunderboat	</td>
                                <td style={{textAlign: 'center'}}>7</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>18</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>29</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>40</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>$7.00</td>
                            </tr>
                            <tr>
                                <td>P Ltd Mono</td>
                                <td style={{textAlign: 'center'}}>11</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>21</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>32</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>44</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>$7.00</td>
                            </tr>
                            <tr>
                                <td colspan="11" style={{ height:'10px'}}></td>
                            </tr>
                            <tr>
                                <th style={{ width:'20%'}}>Sunday</th>
                                <th style={{fontWeight: '400', textAlign: 'center', width:'8%'}}>Heat</th>
                                <th style={{fontWeight: '400', textAlign: 'center', width:'8%'}}>Score</th>
                                <th style={{fontWeight: '400', textAlign: 'center', width:'8%'}}>Heat</th>
                                <th style={{fontWeight: '400', textAlign: 'center', width:'8%'}}>Score</th>
                                <th style={{fontWeight: '400', textAlign: 'center', width:'8%'}}>Heat</th>
                                <th style={{fontWeight: '400', textAlign: 'center', width:'8%'}}>Score</th>
                                <th style={{fontWeight: '400', textAlign: 'center', width:'8%'}}>Heat</th>
                                <th style={{fontWeight: '400', textAlign: 'center', width:'8%'}}>Score</th>
                                <th style={{fontWeight: '400', textAlign: 'center', width:'8%'}}>Total</th>
                                <th style={{textAlign: 'center', width:'8%'}}>FEES</th>
                            </tr>
                            <tr>
                                <td>A Super Stock OPC</td>
                                <td style={{textAlign: 'center'}}>45</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>55</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>65</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>75</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>$7.00</td>
                            </tr>
                            <tr>
                                <td>G Ltd Mono</td>
                                <td style={{textAlign: 'center'}}>48</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>58</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>69</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>79</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>$7.00</td>
                            </tr>
                            <tr>
                                <td>P Mono</td>
                                <td style={{textAlign: 'center'}}>51</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>61</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>71</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>81</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>$7.00</td>
                            </tr>
                            <tr>
                                <td>Open Tunnel</td>
                                <td style={{textAlign: 'center'}}>53</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>63</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>73</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>83</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>$7.00</td>
                            </tr>
                            <tr>
                                <td>Q-Mono/Cat</td>
                                <td style={{textAlign: 'center'}}>54</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>64</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>74</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>84</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}></td>
                                <td style={{textAlign: 'center'}}>$7.00</td>
                            </tr>
                            <tr>
                                <td colspan="9"></td>
                                <td className="gray" style={{textAlign: 'center'}}>Total Due</td>
                                <td className="gray" style={{textAlign: 'center'}}>$83.00 </td>
                            </tr>
                        </table>
                    </div>
        </React.Fragment>
        </PdfContainer>
      </div>
    </>
  )
}
