import Header from "../../../Components/clubDashboard/Header/Header";

const EditClub = () =>{
    return (
        <>
<Header/>
        <div className="dbRight">
        <div className="dbMid dbInner">
            <div className="flexBox spacebetween itemCenter">
                <div className="searchBox flexBox spacebetween itemCenter nowrap">
                    <input type="text" className="searchInput" placeholder="Search"/>
                    <button className="searchBtn"><span className="material-icons">search</span></button>
                </div>
                <ul className="flexBox topLink">
                    <li><a href="#"><span className="material-icons">mail_outline</span></a></li>
                    <li><a href="#"><span className="material-icons-outlined">notifications</span></a></li>
                </ul>
            </div>
            <div className="flexBox spacebetween topHdBox">
                <div className="left ecTop">
                    <div className="dbMainHd">Edit Club "Rocky Mountain Marine Modelers"</div>
                    <div className="text">You are the registrar of this club. <span className="blueClr">Registrar</span> on:
                        Aug 24, 2015</div>
                </div>
            </div>
            <div className="dbWhtBox">
                <div className="fieldHd mb30">Proposed Name of the Club (Please write to NAMBA, if you need to change the name of your club)</div>
                <ul className="flexBox editForm">
                    <li>
                        <span className="fieldHd">Website Url</span>
                        <input type="text" className="formInput" placeholder="Enter Website Url" />
                    </li>
                    <li>
                        <span className="fieldHd">Street Address Line 1</span>
                        <input type="text" className="formInput" placeholder="Enter Street Address Line 1"/>
                    </li>
                    <li>
                        <span className="fieldHd">Address Line 2 (optional)</span>
                        <input type="text" className="formInput" placeholder="Enter Address Line 2 (optional)"/>
                    </li>
                    <li>
                        <span className="fieldHd">Country</span>
                        <select className="formSelect">
                            <option>United state of america</option>
                        </select>
                    </li>
                    <li>
                        <span className="fieldHd">State</span>
                        <select className="formSelect">
                            <option>Colorado</option>
                        </select>
                    </li>
                    <li>
                        <span className="fieldHd">City</span>
                        <select className="formSelect">
                            <option>Littleton</option>
                        </select>
                    </li>
                    <li>
                        <span className="fieldHd">Zip</span>
                        <input type="text" className="formInput" placeholder="80127"/>
                    </li>
                </ul>
                <ul className="flexBox editForm">
                    <li>
                        <span className="fieldHd">Club Meets On Day:</span>
                        <div className="pr">
                            <input type="text" className="formInput datepicker" placeholder="Enter Date"/>
                            <span className="material-icons dateIcon">today</span>
                        </div>
                    </li>
                    <li>
                        <span className="fieldHd">Club Meets On Time:</span>
                        <div className="pr">
                            <input type="text" className="formInput timepicker" placeholder="Enter Time"/>
                            <span className="material-icons dateIcon">schedule</span>
                        </div>
                    </li>
                    <li>
                        <span className="fieldHd">Contact Phone:</span>
                        <div className="phoneBox">
                            <select className="formSelect codeSelect">
                                <option>+91</option>
                            </select>
                            <input type="text" className="formInput" placeholder="Enter Phone Number"/>
                        </div>
                    </li>
                </ul>
                <ul className="flexBox editForm">
                    <li>
                        <span className="fieldHd">Contact Person:</span>
                        <div className="flexBox nowrap itemTop">
                            <input type="text" className="formInput smallInput" value="1"/>
                            <div className="profileInfo">                                    
                                <span className="hd"><span className="material-icons icon">check_circle_outline</span> David Santistevan</span>
                                Reverify NAMBA Number                                    
                            </div>
                        </div>
                    </li>
                    <li>
                        <span className="fieldHd">Commodore:</span>
                        <div className="flexBox nowrap itemTop">
                            <input type="text" className="formInput smallInput" value="1"/>
                            <div className="profileInfo">                                    
                                <span className="hd"><span className="material-icons icon">check_circle_outline</span> David Santistevan</span>
                                Reverify NAMBA Number                                    
                            </div>
                        </div>
                    </li>
                    <li>
                        <span className="fieldHd">Vice-Commodore:</span>
                        <div className="flexBox nowrap itemTop">
                            <input type="text" className="formInput smallInput" value="826" />
                            <div className="profileInfo">                                    
                                <span className="hd"><span className="material-icons icon">check_circle_outline</span> Jesse J Melick</span>
                                Reverify NAMBA Number                                    
                            </div>
                        </div>
                    </li>
                    <li>
                        <span className="fieldHd">Secretary:</span>
                        <div className="flexBox nowrap itemTop">
                            <input type="text" className="formInput smallInput" value="870" />
                            <div className="profileInfo">                                    
                                <span className="hd"><span className="material-icons icon">check_circle_outline</span> Lee Taylor</span>
                                Reverify NAMBA Number                                    
                            </div>
                        </div>
                    </li>
                    <li>
                        <span className="fieldHd">Purser:</span>
                        <div className="flexBox nowrap itemTop">
                            <input type="text" className="formInput smallInput" value="870"/>
                            <div className="profileInfo">                                    
                                <span className="hd"><span className="material-icons icon">check_circle_outline</span> D Lee Taylor</span>
                                Reverify NAMBA Number                                    
                            </div>
                        </div>
                    </li>
                </ul>
                <ul className="flexBox editForm">
                    <li className="full">
                        <span className="fieldHd">Club Information (optional):</span>
                        <textarea rows="5" cols="5" className="formInput"></textarea>
                        <div className="tcText">
                            The CLUB REGISTRATION FEE for the calendar year or any part thereof is $10. Payment of this fee, and the completion of this form will place your club on the NAMBA INTERNATIONAL Roll of Registered Clubs, and will entitle you to all the rights and privileges of club registration.<br/><br/>
                            It is understood that you shall notify the NAMBA office immediately of any change in our club officers or meeting place.
                        </div>
                    </li>
                    <li className="full">
                        <div className="emailNot"><label><input type="checkbox" className="checkInpt"/> I agree to the Terms & Conditions of this site.</label></div>
                    </li>
                </ul>
                <div><button type="button" className="formBtn">Submit</button></div>
            </div>
        </div>
    </div>
    </>
    )

}

export default EditClub ;