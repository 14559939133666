import React, { useState, useEffect } from 'react';
import Header from "../../../Components/adminDashboard/Header/Header";
import Switch from "react-switch";
import combinedServices from "../../../shared/services/user-service";
import getLocalData from "../../../shared/localStorage/getData";
import { useForm } from 'react-hook-form';
import swal from 'sweetalert';



const AdminDashboard = () => {


    const [eventCost,setEventCost] = useState("");
    const [isHideSubmitButton,setIsHideSubmitButton] = useState(false);


    useEffect(async () => {
        if (getLocalData("admin_info", "isLogin")) {
            let response = await combinedServices.getEventCost();
            
            setEventCost(response.data.p_fees_event_registration);

            //setUserId(userId)
        }
    }, [])
  

  
    const handleEventCost = (e) =>{
        setEventCost(e.target.value)
    }
    const handleSaveButton = async()=>{
        setIsHideSubmitButton(true)
        var data = {

            event_charge : eventCost
        }

        let editResponse = await combinedServices.updateEventCost(data);
        
        if(editResponse.status == 200){


            swal("Yes", "Event cost add successfully", "success") ;
            setEventCost(editResponse.data.p_fees_event_registration);
            setIsHideSubmitButton(false)
                

        }else{
            swal("!Opps", editResponse.msg, "error") ;
            setIsHideSubmitButton(false)
        }


       
    }


    return (
        <>
            <Header />

            <div className="dbAdminRight">
                <div className="dbMid dbInner">
                    <div className="flexBox spacebetween topHdBox">
                       
                    </div>
                    <div className='flexBox spacebetween'>
                       
                        
                            <div className='stRight whtBox'>
                                <div className='dbSubHd'>{eventCost ? "Update" : "Add" }  Event Cost</div>
                                
                                    <ul className="flexBox editForm full">
                                        <li>
                                            <span className="fieldHd">Event Cost</span>
                                            <input type="text" className='formInput' placeholder='Enter Cost' value={eventCost} name="cost"  onChange={handleEventCost}/>
                                            
                                        </li>
                                       
                                    </ul>
                                   {isHideSubmitButton ?  " " :  <div className="tar"><button type="submit" className="formBtn full" onClick={handleSaveButton}>{eventCost ? "Update" : "Create"}</button></div>}
                               
                            </div>
                        
                                    

                    </div>
                </div>
            </div>
        </>

    )

}
export default AdminDashboard;