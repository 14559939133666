import Header from "../../Components/clubDashboard/Header/Header";
import { useForm } from "react-hook-form";
import React, { useState, useEffect } from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import combinedServices from "../../shared/services/user-service";
import getRemoveData from "../../shared/localStorage/removeData";
import * as Yup from 'yup';
import { Spinner } from 'reactstrap';
import swal from 'sweetalert';
import SearchPage from "../../Components/clubDashboard/Header/SearchPage";
import getLocalData from "../../shared/localStorage/getData";
import { useNavigate } from "react-router-dom";
const Setting = () => {
    const navigate = useNavigate();
    const [errorMsg, setErrorMsg] = useState("");
    const [isLoder, setIsLoder] = useState(false);
    const [clubList, setClubList] = useState([]);
    const [clubId, setClubId] = useState("");
    const [clientId, setClientId] = useState("");
    const [clientScretId, setClientScretId] = useState("");
    const [updateForm, setUpdateForm] = useState(0);

    useEffect(async () => {


        if (getLocalData("user_info", "isLogin")) {

             //var userDistricId = getLocalData("user_info", "all").userData.login_user_detail.data.user.district.uzip_namba_district;

            var userClubList = getLocalData("user_info", "all").userData.login_user_detail.data.user.clubOfficerIn;
            //console.log(userClubList)
            
            setClubList(userClubList)
            setClubId(userClubList[0].id);
            const clubResponse = await combinedServices.getPaypalCredetial(userClubList[0].id);
            if(clubResponse.status==401){
                swal(clubResponse.msg, "Please Re-login", "warning").then(() => {
                  getRemoveData("user_info");
                  localStorage.clear();
                  window.location.href = "/login";
                });
              }

            if (clubResponse.status == 200) {

                setUpdateForm(1)
            

            setClientId(clubResponse.data.paypal_client_id);
            setClientScretId(clubResponse.data.paypal_client_secret);
            }

        }
    }, [])

const getPaypalCredential = async(club_id) =>{

    const clubResponse = await combinedServices.getPaypalCredetial(club_id);
    if(clubResponse.status==401){
        swal(clubResponse.msg, "Please Re-login", "warning").then(() => {
          getRemoveData("user_info");
          localStorage.clear();
          window.location.href = "/login";
        });
      }
            if (clubResponse.status == 200) {

                setUpdateForm(1)
            

            setClientId(clubResponse.data.paypal_client_id);
            setClientScretId(clubResponse.data.paypal_client_secret);
            }else{
                setClientId("");
                setClientScretId("");
                setUpdateForm(0)

            }


}




    const handleSubmit = async (e) => {
        e.preventDefault();

        if (clubId == "") {

            swal("Please select any club.")
        } else if (clientId == "") {
            swal("Please enter client id.")

        } else if (clientScretId == "") {

            swal("Please enter client secret id.")
        } else {



            setIsLoder(true)
            //e.preventDefault();
            var data = {
                paypal_client_id: clientId,
                paypal_client_secret: clientScretId,
                club_id: Number(clubId)
            };
            var response = "";

            if (updateForm == 1) {
                response = await combinedServices.updatePaypalCredential(data);
            } else {
                response = await combinedServices.addPaypalCredential(data);

            }



            //const response = "";
            if (response.status == 200) {
                setIsLoder(false)
                if (updateForm == 1) {
                    swal("Credential Update Successffully.")
                        .then(async (value) => {
                            // window.location.href = "/paypal-credentail";

                           // navigate("../paypal-credentail", { replace: true });
                           

                        });
                } else {
                    swal("Credential Add Successffully.")
                        .then(async (value) => {
                            // window.location.href = "/paypal-credentail";

                            //navigate("../paypal-credentail", { replace: true });
                            //setClientId("");
                            //setClientScretId("");

                        });
                }

            } else {

                setIsLoder(false);
                swal("!Opps",response.msg,"error")
            }

        }




    };
    const handleClientId = (e) => {

        setClientId(e.target.value)
    }
    const handleClientScretId = (e) => {

        setClientScretId(e.target.value)
    }
    const handleCLubId = (e) => {
        setClubId(e.target.value) ;
        getPaypalCredential(e.target.value)

    }



    return (
        <>
            <Header />
            <div class="dbRight">
                <div class="dbMid dbInner">
                    
                    <div class="">
                        <div class="dbMainHd">{updateForm == 1 ?  "Update Paypal Credential" : "Add Paypal Credential" }</div>
                        <ul class="flexBox profileDet">
                            <li class="full">
                                <div class="">

                                    <ul class="settingForm">
                                        <form onSubmit={handleSubmit}>
                                            {clubList.length > 1 ? (
                                                <li key="club-list">
                                                    <span className="fieldHd">Club List</span>
                                                    <select className="formSelect" name="clubIds" value={clubId} onChange={handleCLubId}>
                                                        <option value="0">Select Club</option>
                                                        {clubList.map((clubDetails) => {
                                                            return(<option key={clubDetails.id} value={clubDetails.id}>{clubDetails.club_name}</option>)
                                                            

                                                        })}

                                                    </select>
                                                </li>
                                            ) : ""}
                                            <li key="list-clinet_id"><input
                                                className="formInput"
                                                type="text"
                                                placeholder="Client ID"
                                                name="client_id"
                                                value={clientId}
                                                onChange={handleClientId}


                                            />

                                            </li>
                                            <li key="client_scret_id"><input
                                                type="text"
                                                placeholder="Client Secret"
                                                name="client_scret"
                                                value={clientScretId}
                                                onChange={handleClientScretId}

                                                className="formInput" />

                                            </li>

                                            {isLoder ? <li key="error-loder" className="text-center mt-4 loadMore"><Spinner color="primary" /></li> : ""}

                                            <li><button type="submit" className="formBtn">Submit</button></li>
                                        </form>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )

}
export default Setting;