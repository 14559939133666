import React, { useCallback, useEffect, useState } from "react";
import { io } from "socket.io-client";
import combinedServices from "../../shared/services/user-service";
import { NavLink, useLocation } from "react-router-dom";
import userConstants from "../../shared/constants/user-constants";
import "./LiveFeed.scss";
import checkPng from "../../../src/assets/images/check.png";
import logo from "./logo.png";
import { useMemo } from "react";
import moment from "moment";
import swal from "sweetalert";
import { Spinner } from "reactstrap";
import Marquee from "react-fast-marquee";
import Slider from "react-slick";

export default function LiveFeed() {
  const [feeds, setFeeds] = useState([]);
  const [scratched, setScratched] = useState(false);
  const [eventTitle, setEventTitle] = useState("");
  const [rounds, setRounds] = useState(0);
  const [scoreData, setScoreData] = useState([]);
  const [eventStart, setEventStart] = useState("");
  const [eventEnd, setEventEnd] = useState("");
  const [dateOptions, setDateOptions] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [classesAndRunDate, setClassesAndRunDate] = useState([]);
  const [eventTimeZone, setEventTimeZone] = useState("");
  const [classIds, setClassIds] = useState([]);
  const [heatSheetGenerated, setHeatSheetGenerated] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const location = useLocation();
  const eventId = location.pathname.split("/")[2];
  const socket = useMemo(() => {
    return io.connect(userConstants.baseURL);
  }, []);
  const settings1 = {
    fade: true,
    autoplay: true,
    infinite: true,
    speed: 2000,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    arrows: false,
    pauseOnHover: false,
    pauseOnFocus: false,
  };
  const settings2 = {
    autoplay: true,
    infinite: true,
    className: "center",
    centerMode: true,

    speed: 2000,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    waitForAnimate: false,
    arrows: false,
    pauseOnHover: false,
    pauseOnFocus: false,
    vertical: true, // Add this setting
    cssEase: "linear",
  };

  useEffect(() => {
    let scoreArrayData = [];
    //function to fetch the data when the page first time reloads and populate the page
    let globalFeedArray=[]
    const fetchData = async () => {
      let response;
      if (selectedDate !== "") {
        let data = {
          eventId: eventId,
          classIds: classIds,
        };
        response = await combinedServices.getFeed(data);
      } else {
        let data = {
          eventId: eventId,
        };
        response = await combinedServices.getFeed(data);
      }
      let eventResponse = await combinedServices.getEventDetail(eventId);
      var eventClassesWithRunDate = await combinedServices.countByClasses(
        eventId
      );

      setClassesAndRunDate(eventClassesWithRunDate.data.participants);
      setEventTitle(eventResponse.data.event_full_title);

      setEventTimeZone(eventResponse.data.event_time_zone);
      setRounds(eventResponse.data.event_rounds_per_class);

      for (let item of response) {
        if (item.case === 7 || item.case === 11 || item.case === 12) {
          let heatsheetResponse = await combinedServices.getHeatByHeatNumber(
            eventId,
            item.heat==0 ? 1 :item.heat
          );
          console.log('heatsheet response is ', heatsheetResponse)
          heatsheetResponse = heatsheetResponse.data[0]?.participants;

          let heatStatusData =
            item.new_feed_text !== "" ? JSON.parse(item.new_feed_text) : {};
          let participants = [];
          for (let participant of heatsheetResponse) {
            let username =
              participant.event_participant_class.event_participant.user
                .user_firstname +
              " " +
              participant.event_participant_class.event_participant.user
                .user_lastname;
            let data = {
              username: username,
              scratch: participant.scratch ? "SCR" : "",
              onSpot: participant.on_spot,
              heatId:participant.id
            };

            participants.push(data);
          }
          heatStatusData["participants"] = participants;
          item.new_feed_text = JSON.stringify(heatStatusData);
        }else if(item.case == 8 && !item.new_feed_text.includes('Round')){
          const heatScoreData = await combinedServices.getHeatScore({eventId:item.event_Id,classId:item.class_Id,heatNumber:item.heat})
          item.new_feed_text=JSON.stringify(heatScoreData.data)
          
          
        }
      }
      setFeeds(response); // Update feeds state with the initial data
      globalFeedArray=response
    };

    fetchData();

    const fetchScore = async () => {
      const eventDetails = await combinedServices.getClassByEventId(eventId);
      const totalRounds = eventDetails.data.rounds_per_class;
      setEventTitle(eventDetails.data.full_title);
      setEventStart(eventDetails.data.event_start);
      setEventEnd(eventDetails.data.event_end);
      setRounds(totalRounds);

      let datesArray = getDatesBetween(
        eventDetails.data.event_start,

        eventDetails.data.event_end,

        eventDetails.data.time_zone
      );
      datesArray.push({ value: "", label: "All Days" });
      setDateOptions(datesArray);

      //getting the participant of the events
      let eventParticipantResponses =
        await combinedServices.getEventParticipantForHeat({
          event_id: eventId,
        });
      // console.log("eventparticipantresponses", eventParticipantResponses.data);

      //getting classes only
      let eventClassesDataResponse = (
        await combinedServices.countByClasses(eventId)
      ).data.participants;

      //sorting this eventclasses data according to run date
      eventClassesDataResponse = eventClassesDataResponse
        .filter((cls) => cls.class_status == "MADE")
        .sort((a, b) => {
          const dateA = new Date(a.run_date);
          const dateB = new Date(b.run_date);
          if (dateA.getTime() === dateB.getTime()) {
            return a.order_key - b.order_key;
          } else {
            return dateA - dateB;
          }
        });

      //fetching the heatsheet
      let heatsheetResponse = await combinedServices.getAllHeatSheet({
        event_id: eventId,
      });

      if (heatsheetResponse.data.length > 0) {
        setHeatSheetGenerated(true);
      }
      //fetching all the scores of the event
      let eventScoreRespone = await combinedServices.getScoreByEventID({
        event_id: eventId,
      });

      if (eventScoreRespone.status == 200) {
        setIsLoader(false);
      }

      //creating the array to bind the final data
      let scoreArray = [];

      //adding the classes to the array
      await eventClassesDataResponse.forEach((eventClass) => {
        scoreArray.push({
          class_name: eventClass.class_name,
          class_id: eventClass.class_id,
          participants: [],
        });
      });

      //adding the participant
     
      heatsheetResponse.data.forEach((heat) => {
        for (let participant of heat.participants) {
          //finding the participant's name and his id
          
          const foundClassIndex = scoreArray.findIndex(
            (item) =>
              item.class_id == participant.event_participant_class.class.id
          );

          const participantExists = scoreArray[
            foundClassIndex
          ]?.participants.find(
            (p) =>
              p.participantId ===
              participant.event_participant_class.event_participant.user.id
          );

          if (!participantExists) {
            // finding the score item to set the score from round1 to round7
            let foundScoreItem = eventScoreRespone.data.find(
              (item) =>
                item.participant_Id ==
                  participant.event_participant_class.event_participant.user
                    .id &&
                item.class_Id == participant.event_participant_class.class.id
            );
            if (
              foundScoreItem?.score_with_reason !== null &&
              foundScoreItem !== undefined
            ) {
              for (let item of foundScoreItem?.score_with_reason) {
                if (
                  item.reason == "DNS" ||
                  // item.reason == "SCR" ||
                  item.reason == "DQ"
                ) {
                  foundScoreItem[item.round] = item.reason;
                }
              }
            }
            //here we creating the object which stores the participant details and score and pushing to the array
            
            scoreArray[foundClassIndex]?.participants.push({
              participantName:
                participant.event_participant_class.event_participant.user
                  .user_firstname +
                " " +
                participant.event_participant_class.event_participant.user
                  .user_lastname,
              participantId:
                participant.event_participant_class.event_participant.user.id,
              round1: {
                Score: foundScoreItem ? foundScoreItem.round1_Score : 0,
                heatNumber: heat.heat_number,
                scratched: participant.scratch,
                heatId:participant.id
              },
              round2: {
                Score: foundScoreItem ? foundScoreItem.round2_Score : 0,
                heatNumber: heat.heat_number,
                scratched: participant.scratch, heatId:participant.id
              },
              round3: {
                Score: foundScoreItem ? foundScoreItem.round3_Score : 0,
                heatNumber: heat.heat_number,
                scratched: participant.scratch, heatId:participant.id
              },
              round4: {
                Score: foundScoreItem ? foundScoreItem.round4_Score : 0,
                heatNumber: heat.heat_number,
                scratched: participant.scratch, heatId:participant.id
              },
              round5: {
                Score: foundScoreItem ? foundScoreItem.round5_Score : 0,
                heatNumber: heat.heat_number,
                scratched: participant.scratch, heatId:participant.id
              },
              round6: {
                Score: foundScoreItem ? foundScoreItem.round6_Score : 0,
                heatNumber: heat.heat_number,
                scratched: participant.scratch, heatId:participant.id
              },
              round7: {
                Score: foundScoreItem ? foundScoreItem.round7_Score : 0,
                heatNumber: heat.heat_number,
                scratched: participant.scratch, heatId:participant.id
              },
              totalScore: foundScoreItem ? foundScoreItem.total : 0,
            });
          } else {
            if (
              foundClassIndex !== -1 &&
              scoreArray[foundClassIndex].participants
            ) {
              const participantIndex = scoreArray[
                foundClassIndex
              ].participants.findIndex(
                (p) =>
                  p.participantId ===
                  participant.event_participant_class.event_participant.user.id
              );
              if (participantIndex !== -1) {
                scoreArray[foundClassIndex].participants[participantIndex][
                  `round${participant.round}`
                ]["heatNumber"] = heat.heat_number;
                scoreArray[foundClassIndex].participants[participantIndex][
                  `round${participant.round}`
                ]["scratched"] = participant.scratch;
                scoreArray[foundClassIndex].participants[participantIndex][
                  `round${participant.round}`
                ][`heatId`] = participant.id;
              }
            }
          }
        }
      });

      //sorting the scores according to totalscore in asc order within in each class

      scoreArray.forEach((item) => {
        item.participants.sort((a, b) => {
          return b.totalScore - a.totalScore;
        });
      });
      if (selectedDate !== "") {
        scoreArray = scoreArray.filter((scores) =>
          classIds.includes(scores.class_id)
        );
      }

      setScoreData(scoreArray);
      scoreArrayData = scoreArray;
      
    };
    fetchScore();

    // Function to handle 'updateFeed' event
    //  Function to update the on the page
    const handleUpdateFeed = async (newFeed) => {
      if (newFeed.event_Id == eventId) {
        if (newFeed.case === 7 || newFeed.case === 11 || newFeed.case === 12) {
          let heatsheetResponse = (
            await combinedServices.getHeatByHeatNumber(eventId, newFeed.heat)
          ).data[0]["participants"];
          let heatStatusData =
            newFeed.new_feed_text !== ""
              ? JSON.parse(newFeed.new_feed_text)
              : {};
          let participants = [];
          for (let participant of heatsheetResponse) {
            let username =
              participant.event_participant_class.event_participant.user
                .user_firstname +
              " " +
              participant.event_participant_class.event_participant.user
                .user_lastname;
            let data = {
              username: username,
              scratch: participant.scratch ? "SCR" : "",
              onSpot: participant.on_spot,
              heatId:participant.id
            };

            participants.push(data);
          }
          heatStatusData["participants"] = participants;
          newFeed.new_feed_text = JSON.stringify(heatStatusData);
        }
        
        setFeeds((prevFeeds) => [newFeed, ...prevFeeds]); // Update feeds state with the new feed
      }
    };

    // Function to handle 'scratched' event
    //function to update the feed when any participant is scratched
    const handleScratched = (scratchedHeatIds) => {
      
      const oldFeeds = [...globalFeedArray]
      const previousScoreData = [...scoreArrayData];
      for(let heatId of scratchedHeatIds){

        // change SCR status in left and right column
        for(let feed of oldFeeds){
          let feedText = JSON.parse(feed.new_feed_text)
          let findIndex = feedText.participants.findIndex((participant)=>participant.heatId==heatId.id)
          if(findIndex!==-1){
            feedText.participants[findIndex]["scratch"]=heatId.scratch
            feed.new_feed_text=JSON.stringify(feedText)
          }
        }

        //change SCR status in middle column
        
        for(let eventClass of previousScoreData){
          for(let participant of eventClass.participants){
            const foundElement = Object.keys(participant).find((key)=>participant[key]["heatId"]==heatId.id)
            console.log('foundeleement',foundElement)
            if(foundElement){
              participant[foundElement]["scratched"] = heatId.scratch
            }
          }
        }
      }
      setScoreData(previousScoreData)
      setFeeds(oldFeeds)
      // setScratched((scratched) => !scratched);
    };

    // Function to handle 'newScore' event
    const handleNewScore = (newScore) => {
      if (newScore[0].event_Id == eventId) {
        if (newScore[0].event_Id == eventId) {
          // Create a copy of the previous score data
          const previousScoreData = [...scoreArrayData];

          // Find the index of the class in the previous score data
          const classIndex = previousScoreData.findIndex(
            (obj) => obj.class_id === newScore[0].class_Id
          );

          if (classIndex !== -1) {
            // Find the index of the participant within the class
            const participantIndex = previousScoreData[classIndex][
              "participants"
            ].findIndex(
              (obj) => obj.participantId === newScore[0].participant_Id
            );

            if (participantIndex !== -1) {
              // Update the participant's scores
              for (let i = 1; i <= 7; i++) {
                previousScoreData[classIndex]["participants"][participantIndex][
                  `round${i}`
                ]["Score"] = newScore[0][`round${i}_Score`];
              }
              previousScoreData[classIndex]["participants"][
                participantIndex
              ].totalScore = newScore[0].total;
            } else {
              console.log("Participant not found");
            }
          } else {
            console.log("Class not found");
          }

          // Update the state with the modified score data
          setScoreData(previousScoreData);
        }
      }
    };

    // Register event listeners
    //whenever the useEffect runs first time these web sockets will be registered to listen for the emitted events from the backend

    socket.on("updateFeed", handleUpdateFeed);
    socket.on("scratched", handleScratched);
    socket.on("newScore", handleNewScore);

    // here we are switching off the sockets so that on every rerender new web sockets don't get registered which can lead to degrading the performance of the site
    return () => {
      // Cleanup event listeners
      socket.off("updateFeed", handleUpdateFeed);
      socket.off("scratched", handleScratched);
      socket.off("newScore", handleNewScore);
    };
  }, [scratched, eventId, selectedDate]); // Add eventId as a dependency if it might change

  useEffect(() => {
    console.log("useEffect triggered");
  }, [scratched]);

  const currentTime = useCallback(() => {
    //this function will give the current local time
    // Create a new Date object
    const now = new Date();

    // Get hours and minutes from the Date object
    let hours = now.getHours();
    let minutes = now.getMinutes();

    // Determine if it's AM or PM
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert hours from 24-hour format to 12-hour format
    hours = hours % 12 || 12;

    // Add leading zero to minutes if needed
    minutes = minutes < 10 ? "0" + minutes : minutes;

    // Combine hours, minutes, and AM/PM into a string
    const currentTime = hours + ":" + minutes + " " + ampm;
    return currentTime;
  }, []);

  const generateRounds = useMemo(() => {
    let arr = [];
    for (let r = 1; r <= rounds; r++) {
      arr.push(<th>{`R${r}`}</th>);
    }
    return arr;
  }, [rounds]);

  const defaultMarqueText = [
    <li style={{ color: "#00abeb" }}>Lorem ipsum dolor sit amet.</li>,
    <li style={{ color: "#00abeb" }}>Lorem ipsum dolor sit amet.</li>,
    <li style={{ color: "#00abeb" }}>Lorem ipsum dolor sit amet.</li>,
    <li style={{ color: "#00abeb" }}>Lorem ipsum dolor sit amet.</li>,
    <li style={{ color: "#00abeb" }}>Lorem ipsum dolor sit amet.</li>,
  ];

  function getDatesBetween(startDate, endDate, timeZone) {
    const start = moment.utc(startDate).add(1, "days");
    const end = moment.utc(endDate).subtract(1, "days");
    const datesArray = [];

    while (start.isSameOrBefore(end)) {
      datesArray.push({
        value: start.format(),
        label: timeaccotolocation2(start, timeZone),
      });
      start.add(1, "days");
    }
    datesArray.unshift({
      value: startDate,
      label: timeaccotolocation2(startDate, timeZone),
    });
    datesArray.push({
      value: endDate,
      label: timeaccotolocation2(endDate, timeZone),
    });

    return datesArray;
  }

  const timeaccotolocation2 = (x, timeZone) => {
    if (timeZone == "") {
      timeZone = "Canada/Eastern";
    }
    const utcMoment = moment.utc(x);
    const localMoment = utcMoment.tz(timeZone);
    const timeZoneOffset = localMoment.format("Z");
    return localMoment.format("MMM DD YYYY");
  };

  const handleDateChange = async (e) => {
    if (e.target.value == "") {
      setSelectedDate("");
      setScoreData([]);
      setFeeds([]);
      setIsLoader(true);
    } else {
      const foundClassOfSelectedDate = classesAndRunDate.filter(
        (item) =>
          timeaccotolocation2(item.run_date, eventTimeZone) ==
          timeaccotolocation2(e.target.value, eventTimeZone)
      );

      if (foundClassOfSelectedDate.length > 0) {
        const filteredClassIds = foundClassOfSelectedDate.map(
          (item) => item.class_id
        );
        setScoreData([]);
        setClassIds(filteredClassIds);
        setFeeds([]);
        setIsLoader(true);
        setSelectedDate(e.target.value);
      } else {
        swal("No class was scheduled on this day");
      }
    }
  };

  return (
    <>
      <div class="containerMid livefeed-container">
        <div class="newHeader">
          <div class="newLogo">
            <NavLink to="/">
              <img src={logo} alt="" />
            </NavLink>
            <div className="topHd"> {eventTitle}</div>
          </div>
          <div class="topRight">
            {currentTime()}
            <select className="dateSelct" onChange={(e) => handleDateChange(e)}>
              <option disabled selected>
                Select Date
              </option>
              {dateOptions.map((date) => (
                <option value={date.value}>{date.label}</option>
              ))}
            </select>
          </div>
        </div>
        <div class="midPart">
          {feeds.length > 0 ? (
            <div class="midLeft">
              {/* <marquee direction="up" className="marqBox" scrollamount="3"> */}
              {feeds.length > 0 ? (
                feeds
                  .filter(
                    (feed) =>
                      feed.case === 8 && !feed.new_feed_text.includes("Round")
                  )
                  .slice(0, 2) // Limit to only 3 feeds for case 8 where `new_feed_text` does not include "Round`
                  .map((feed) => {
                    if (
                      feed.case == 8 &&
                      !feed.new_feed_text.includes("Round")
                    ) {
                      return (
                        <div className="mb-20" key={feed.id}>
                          <div className="heading">
                            Heat <span>{feed.heat}</span>{" "}
                          </div>
                          <div className="whtBox">
                            <ul className="boxList">
                              <li className="hd">
                                <div>
                                  {feed.new_feed_text === ""
                                    ? ""
                                    : JSON.parse(feed.new_feed_text).classname}
                                </div>
                                <div>Score</div>
                              </li>
                              {feed.new_feed_text !== "" ? (
                                JSON.parse(feed.new_feed_text)
                                  ?.participants?.sort(
                                    (a, b) => b.score - a.score
                                  )
                                  .map((participant, index) => {
                                    return (
                                      <li key={index}>
                                        <div>{participant.name}</div>
                                        <div>{participant.scratch ? "SCR" : participant.score}</div>
                                      </li>
                                    );
                                  })
                              ) : (
                                <></>
                              )}
                            </ul>
                          </div>
                        </div>
                      );
                    }
                  })
              ) : (
                <></>
              )}
              {/* </marquee> */}
            </div>
          ) : (
            <></>
          )}

          {isLoader ? (
            <div
              className="text-center loadMore"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              <Spinner color="primary" />
            </div>
          ) : (
            <div class="midCen">
             
              {heatSheetGenerated ? (
                <div direction="up" className="marqBox" scrollamount="3">
                  {scoreData.length > 0 && <div class="heading">Standings</div>}
                  <div class="tableSlider">
                    <Slider {...settings1} className="marqBox" >
                   
                    {scoreData.map((scoreObj) => {
                      return (
                        <div class="item mb-20">
                          <table class="table">
                            <tr>
                              <th style={{ width: "50%" }}>
                                {scoreObj.class_name}
                              </th>
                              <th>Total</th>
                              {generateRounds?.map((round) => round)}
                            </tr>
                            {scoreObj.participants.map((participant) => {
                              return (
                                <tr>
                                  <td>{participant.participantName}</td>
                                  <td> {participant.totalScore}</td>
                                  {generateRounds.map((round, index) => {
                                    return (
                                      <td>
                                        {participant[`round${index + 1}`][
                                          "scratched"
                                        ]
                                          ? "SCR"
                                          : participant[`round${index + 1}`][
                                              "Score"
                                            ]}
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })}
                          </table>
                        </div>
                      );
                    })}
                    </Slider>
                  </div>
                </div>
              ) : (
                <div
                  className="noEvent"
                  style={{
                    border: "none",
                  }}
                >
                  Scores are not available
                  <div className="marqBox"></div>
                </div>
              )}
            </div>
          )}
          {feeds.length > 0 ? (
            <div class="midRight">
              {/* <marquee direction="up" className="marqBox" scrollamount="3"> */}
              {feeds
                ?.filter((feed) => feed.case == 7)
                ?.slice(0, 1)
                ?.map((feed) => {
                  return (
                    <div class="mb-20">
                      <div class="heading">
                        {JSON.parse(feed.new_feed_text).title.split(" ")[0] +
                          " " +
                          JSON.parse(feed.new_feed_text).title.split(" ")[1] +
                          " "}
                        <span>
                          {JSON.parse(feed.new_feed_text).title.split(" ")[2] +
                            " " +
                            JSON.parse(feed.new_feed_text).title.split(" ")[3]}
                        </span>
                      </div>
                      <div class="whtBox">
                        <ul class="boxList">
                          <li class="hd">
                            <div>
                              {JSON.parse(feed.new_feed_text).className}
                            </div>
                          </li>
                          {JSON.parse(feed.new_feed_text).participants.map(
                            (participant) => {
                              return (
                                <li>
                                  <div>{participant.username}</div>
                                  {feed.case == 7 ? (
                                    participant.onSpot ? (
                                      <div>
                                        <img src={checkPng} alt="" />
                                      </div>
                                    ) : (
                                      <div> {participant.scratch ? "SCR" :""}</div>
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    </div>
                  );
                })}
              <Slider {...settings1}>
                {feeds
                  ?.filter((feed) => feed.case == 12)
                  ?.slice(0, 1)
                  ?.map((feed) => {
                    return (
                      <div class="mb-20">
                        <div class="heading">
                          {JSON.parse(feed.new_feed_text).title.split(" ")[0] +
                            " "}
                          <span>
                            {JSON.parse(feed.new_feed_text).title.split(
                              " "
                            )[1] +
                              " " +
                              JSON.parse(feed.new_feed_text).title.split(
                                " "
                              )[2]}
                          </span>
                        </div>
                        <div class="whtBox">
                          <ul class="boxList">
                            <li class="hd">
                              <div>
                                {JSON.parse(feed.new_feed_text).className}
                              </div>
                            </li>
                            {JSON.parse(feed.new_feed_text).participants.map(
                              (participant) => {
                                return (
                                  <li>
                                    <div>{participant.username}</div>
                                    {feed.case == 7 ? (
                                      participant.onSpot ? (
                                        <div>
                                          <img src={checkPng} alt="" />
                                        </div>
                                      ) : (
                                        <div> {participant.scratch}</div>
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </div>
                    );
                  })}
                {feeds
                  ?.filter((feed) => feed.case == 11)
                  ?.slice(0, 1)
                  ?.map((feed) => {
                    return (
                      <div class="mb-20">
                        <div class="heading">
                          {JSON.parse(feed.new_feed_text).title.split(" ")[0] +
                            " " +
                            JSON.parse(feed.new_feed_text).title.split(" ")[1] +
                            " "}
                          <span>
                            {JSON.parse(feed.new_feed_text).title.split(
                              " "
                            )[2] +
                              " " +
                              JSON.parse(feed.new_feed_text).title.split(
                                " "
                              )[3]}
                          </span>
                        </div>
                        <div class="whtBox">
                          <ul class="boxList">
                            <li class="hd">
                              <div>
                                {JSON.parse(feed.new_feed_text).className}
                              </div>
                            </li>
                            {JSON.parse(feed.new_feed_text).participants.map(
                              (participant) => {
                                return (
                                  <li>
                                    <div>{participant.username}</div>
                                    {console.log('partiicpant scratched or not', participant.scratch)} 
                                    {feed.case == 7 ? (
                                      participant.onSpot ? (
                                        <div>
                                          <img src={checkPng} alt="" />
                                        </div>
                                      ) : (
                                        <div>
                                          {participant.scratch}</div>
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </div>
                    );
                  })}
              </Slider>
              {/* </marquee> */}
            </div>
          ) : (
            <></>
          )}
          {isLoader && <div className="marqBox"></div>}
        </div>
        <div class="latestNews">
          <div class="heading">Latest News:</div>
          <div>
            <marquee direction="left">
              <ul class="newsList">
                {feeds.length > 0 ? (
                  feeds
                    .filter((feed) => feed.case == 14)
                    .map((feed) => {
                      return <li>{feed.new_feed_text}</li>;
                    })
                ) : (
                  <>{defaultMarqueText.map((text) => text)}</>
                )}
              </ul>
            </marquee>
          </div>
        </div>
      </div>
    </>
  );
}
