import React, { useState, useEffect } from 'react';
import "./PaymentGateway.scss";
import Header from "../../../Components/adminDashboard/Header/Header";
import Switch from "react-switch";
import combinedServices from "../../../shared/services/user-service";
import getLocalData from "../../../shared/localStorage/getData";
import {  NavLink, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import { Spinner } from 'reactstrap';

const PaymentGateway = () => {
    let navigate = useNavigate();
    const [allClubDetails,setAllClubDetails] = useState([]);
    const [isEdit,setIsEdit]= useState(false) ;
    const [paymentGatewayId,setPaymentGatewayId]= useState("");
    const [isLoder, setIsLoder] = useState(true)
   

    

    useEffect(async () => {
        if (getLocalData("admin_info", "isLogin")) {
            let club_name = "" ;
            let clubResponse = await combinedServices.getAllClubPaypal(club_name);
            //let paymentAll = await combinedServices.getPaymentAdmin(club_name);
            //setAllClubDetails(clubResponse.data);
            if(clubResponse.status  == 200){
                setIsLoder(false)
                setAllClubDetails(clubResponse.data);
               
            }
    
        }
    }, [])
    const handlleUpdatePayment = (id)=>{
        setIsEdit(true);
        setPaymentGatewayId(id);
        navigate("../payment-gateway/edit/"+id, { replace: true });


    }
    const  getALlPaypalDetails = async(club_name) =>{
        let clubResponse = await combinedServices.getAllClubPaypal(club_name);
        if(clubResponse.status  == 200){
            setIsLoder(false)
            setAllClubDetails(clubResponse.data);
           
        }

    }
    const handleDeletePayment = async(id) =>{
       

        swal("Are you sure want to delete this paypal credentials ?", {
            buttons: {
              yes: {
                text: "Yes",
                value: "yes"
              },
              no: {
                text: "No",
                value: "no"
              }
            }
          }).then(async(value) => {
            if (value === "yes") {
                let clubResponse = await combinedServices.deleteClubPaypal(id);

                if (clubResponse.status == 200) {
            
                    swal("Yes", "Paypal Details Delete Successfully", "success")
                    .then((value) => {

                        getALlPaypalDetails();

                    })
                            
    
    
                }
            }
            return false;
          });

        
    }
    const handleSearchBox = (e) =>{
        getALlPaypalDetails(e.target.value)

    }


    return (
        <>
            <Header />
           

            <div className="dbAdminRight">
                <div className="dbMid dbInner">
                    <div className="flexBox spacebetween topHdBox">
                        <div className="left">
                            <div className="dbMainHd">PAYMENT GATEWAY
                            
                            <NavLink to={"../payment-gateway/add"} className='blueBtn' style={{"margin-left": "22px"}}>Add</NavLink>
                            </div>
                            
                        
                        </div>
                        <div className='right pgSearch'>
                            <div className="searchBox flexBox spacebetween itemCenter nowrap">
                                <input type="text" className="searchInput" placeholder="Search"  onChange={handleSearchBox}/>
                                <button className="searchBtn"><span className="material-icons">search</span></button>
                            </div>
                        </div>
                    </div>
                    <div className='flexBox spacebetween'>
                        <div className='pgBox whtBox'>
                            <div className="interfaceTable">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>Club Name</th>
                                            
                                            <th>Client ID</th>
                                            <th>Secret Key</th>
                                            
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    {isLoder ?  <div className="text-center mt-4 loadMore"><Spinner color="primary"/></div>  : <>
                                    <tbody>
                                        {allClubDetails.length > 0 ? allClubDetails.map((clubDetails,index)=>{
                                            return(
                                                <tr>
                                            <td>{index+1}</td>
                                            <td>{clubDetails.club.club_name}</td>
                                            
                                            <td>
                                                <div className='dec'>{clubDetails.paypal_client_id}</div>
                                            </td>
                                            <td>
                                                <div className='dec'>{clubDetails.paypal_client_secret}</div>
                                            </td>
                                            
                                            <td>
                                                <div className='actionBtn flexBox nowrap'>
                                                    <button title='Edit' type='button' onClick={()=>handlleUpdatePayment(clubDetails.club_id)}><span className="material-icons-outlined">edit</span></button>
                                                    <button title='Delete' type='button' onClick={()=>handleDeletePayment(clubDetails.id)}><span className="material-icons-outlined">delete</span></button>
                                                </div>
                                            </td>
                                        </tr>


                                            )


                                        }):<div className="noEvent">Not Found Any Club Paypal Credentials</div>}
                                        
                                       
                                    </tbody>
                                    </>}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )

}
export default PaymentGateway;