import Header from "../../../Components/clubDashboard/Header/Header";
import React, { useState, useEffect } from "react";
import getLocalData from "../../../shared/localStorage/getData";
import combinedServices from "../../../shared/services/user-service";
import userConstants from "../../../shared/constants/user-constants";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { Spinner } from "reactstrap";
import getRemoveData from "../../../shared/localStorage/removeData";
import moment from "moment";
const MyRace = () => {
  let navigate = useNavigate();
  const [upcomingRaces, setUpcomingRaces] = useState([]);
  const [completedRaces, setCompletedRaces] = useState([]);
  const [raceDetails, setRaceDetails] = useState("");
  const [baseURL, setBaseUrl] = useState(userConstants.baseURL);
  const [userId, setUserId] = useState("");
  const [isLoder, setIsLoder] = useState(true);

  useEffect(async () => {
    var completedRacesArr = [];

    if (getLocalData("user_info", "isLogin")) {
      var user_id = getLocalData("user_info", "all").userData.login_user_detail
        .data.user.id;
      setUserId(user_id);
      var completedRaceData = {
        participation_status: "completed",
        user_id: user_id,
      };
      let completedRacesResponse = await combinedServices.getMyRaceDetails(
        completedRaceData
      );
      if (completedRacesResponse.status == 401) {
        swal(completedRacesResponse.msg, "Please Re-login", "warning").then(
          () => {
            getRemoveData("user_info");
            localStorage.clear();
            window.location.href = "/login";
          }
        );
      }

      completedRacesResponse.data.map((events) => {
        var completedData = {
          boatsCount: events.boatsCount,
          city: events.event_city,
          country: events.event_country,
          district_id: events.event_district_id,
          event_drivers_meeting_time: events.event_drivers_meeting_time,
          event_event_start: events.event_event_start,
          full_title: events.event_full_title,
          registration_close: events.event_registration_close,
          registration_open: events.event_registration_open,
          short_tile: events.event_short_tile,
          state: events.event_state,
          venue: events.event_venue,
          hostClub_club_name: events.hostClub_club_name,
          racersCount: events.racersCount,
          id: events.event_id,
          event_image: baseURL + "images/" + events.event_image,
          time_zone:events.event_time_zone
        };

        completedRacesArr.push(completedData);
      });

      setCompletedRaces(completedRacesArr);
      setIsLoder(false);
    }

    getAllUpcomingRaces();
  }, []);

  const getAllUpcomingRaces = async () => {
    var upcomingRaceArr = [];

    if (getLocalData("user_info", "isLogin")) {
      var user_id = getLocalData("user_info", "all").userData.login_user_detail
        .data.user.id;

      var upcomingRaceData = {
        participation_status: "upcoming",
        user_id: user_id,
      };
      let upcomingRacesResponse = await combinedServices.getMyRaceDetails(
        upcomingRaceData
      );

      upcomingRacesResponse.data.map((events, index) => {
        if (index == 0) {
          handleParticularEventDetails(events.event_id);
        }

        var upcomingData = {
          details: events.event_detail,
          boatsCount: events.boatsCount,
          city: events.event_city,
          country: events.event_country,
          district_id: events.event_district_id,
          event_drivers_meeting_time: events.event_drivers_meeting_time,
          event_event_start: events.event_event_start,
          full_title: events.event_full_title,
          registration_close: events.event_registration_close,
          registration_open: events.event_registration_open,
          short_tile: events.event_short_tile,
          state: events.event_state,
          venue: events.event_venue,
          hostClub_club_name: events.hostClub_club_name,
          racersCount: events.racersCount,
          id: events.event_id,
          event_image: baseURL + "images/" + events.event_image,
          time_zone:events.event_time_zone

        };

        upcomingRaceArr.push(upcomingData);
      });
      setUpcomingRaces(upcomingRaceArr);
      setIsLoder(false);
    }
  };

  const handleParticularEventDetails = async (id) => {
    let upcomingRacesResponse = await combinedServices.getEventDetail(id);
    let events = upcomingRacesResponse.data;

    var upcomingData = {
      details: events.event_detail,
      boatsCount: events.boatsCount,
      city: events.event_city,
      country: events.event_country,
      district_id: events.event_district_id,
      event_drivers_meeting_time: events.event_drivers_meeting_time,
      event_event_start: events.event_event_start,
      full_title: events.event_full_title,
      registration_close: events.event_registration_close,
      registration_open: events.event_registration_open,
      short_tile: events.event_short_tile,
      state: events.event_state,
      venue: events.event_venue,
      hostClub_club_name: events.hostClub_club_name,
      racersCount: events.racersCount,
      zip: events.event_zip,
      id: events.event_id,
      hotels: events.event_hotels,
      event_image: baseURL + "images/" + events.event_image,
      time_zone:events.event_time_zone

    };

    setRaceDetails(upcomingData);
  };

  const handleEventDetailPage = (id) => {
    navigate("../event-detail/" + id, { replace: true });
  };
  const handleEventRacePage = (id) => {
    navigate("../my-result/" + id, { replace: true });
  };
  const handleUpdateMyRace = async (event_id) => {
    var data = {
      event_id: event_id,
      user_id: userId,
    };

    let upcomingRacesResponse = await combinedServices.checkIfUserJoined(data);

    if (upcomingRacesResponse.status == 200) {
      var id = upcomingRacesResponse.data.id;

      navigate("../event/update-join-event/" + id, { replace: true });
    }
  };
//   const timeaccotolocation = (x) => {
//     const utcMoment = moment.utc(x);
//     const localMoment = utcMoment.local();
//     return localMoment.toString();
// };
// const timeaccotolocation2 = (x, timeZone) => {
//   const utcMoment = moment.utc(x);
//   const localMoment = utcMoment.tz(timeZone);
  
//   // const timeZoneAbbreviation = localMoment.format('z');
//   const timeZoneOffset = localMoment.format('Z');
  
//   return localMoment.toString();
  
// }
  return (
    <>
      <Header />
      <div className="dbRight">
        <div className="raceMid">
          <div className="flexBox spacebetween itemCenter">
            {/*<div className="searchBox flexBox spacebetween itemCenter nowrap">
                            <input type="text" className="searchInput" placeholder="Search" />
                            <button className="searchBtn"><span className="material-icons">search</span></button>
                        </div>
                        <ul className="flexBox topLink">
                            <li><a href="#"><span className="material-icons">mail_outline</span></a></li>
                            <li><a href="#"><span className="material-icons-outlined">notifications</span></a></li>
    </ul>*/}
          </div>
          <div className="mb30">
            <div className="dbMainHd">Up Coming Races</div>
            {isLoder ? (
              <div className="text-center mt-4 loadMore">
                <Spinner color="primary" />
              </div>
            ) : (
              <>
                {" "}
                {upcomingRaces.length > 0 ? (
                  <ul className="uocomingRaces">
                    {upcomingRaces
                      .sort((a, b) => {
                        const dateA = new Date(a.event_event_start);
                        const dateB = new Date(b.event_event_start);
                        return dateA - dateB;
                      })
                      ?.map((race) => {
                        return (
                          <li className="showRaces">
                            <div className="left">
                              <div className="icon">
                                <img src={race.event_image} alt="" />
                              </div>
                              <div className="det">
                                <a
                                  href="#"
                                  // onClick={() =>
                                  //   handleParticularEventDetails(race.id)
                                  // }
                                  onClick={() => handleEventDetailPage(raceDetails.id)}
                                >
                                  <span className="hd">
                                    {race.full_title}

                                    {moment().format("YYYY-MM-DD") >
                                    moment(race.registration_close).format('YYYY-MM-DD') && (
                                      <span>(Closed {moment(race.registration_close).format('ddd , MMM D , YYYY')})</span>
                                    )}

                                    {moment().format("YYYY-MM-DD") <
                                     moment().format('YYYY-MM-DD') < moment(race.registration_open).format('YYYY-MM-DD') && (
                                      <span className="blue">(open {moment(race.registration_open).format('ddd , MMM D , YYYY')})</span>
                                    )}
                                  </span>
                                </a>
                                {race.hostClub_club_name} |{" "}
                                {moment(race.event_event_start).format('ddd , MMM D , YYYY')}
                              </div>
                            </div>
                            <div className="rgt">
                              <a>{race.racersCount} Racers</a>
                              <a>{race.boatsCount} Boats</a>

                              {moment().format("YYYY-MM-DD") <=
                                moment(race.registration_close).format('YYYY-MM-DD')  && (
                                <a onClick={() => handleUpdateMyRace(race.id)}>
                                  Update
                                </a>
                              )}
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                ) : (
                  <ul className="uocomingRaces">
                    <li className="showRaces">
                      <div className="left">
                        <div className="icon"></div>
                        <div className="det">
                          <span className="hd">
                            <a href="#"></a>
                            <span>No upcoming races found</span>
                          </span>
                        </div>
                      </div>
                      <div className="rgt"></div>
                    </li>
                  </ul>
                )}
              </>
            )}
          </div>
          <div className="">
            <div className="dbMainHd">Completed Races</div>
            {isLoder ? (
              <div className="text-center mt-4 loadMore">
                <Spinner color="primary" />
              </div>
            ) : (
              <>
                {completedRaces?.length > 0 ? (
                  <ul className="uocomingRaces">
                    {completedRaces?.map((race) => {
                      return (
                        <li className="showRaces">
                          <div className="left">
                            <div className="icon">
                              <img src={race.event_image} alt="" />
                            </div>
                            <div className="det">
                              <a
                                href="#"
                                onClick={() => handleEventRacePage(race.id)}
                                // onClick={() =>
                                //   handleParticularEventDetails(race.id)
                                // }
                               
                              >
                               
                                <span className="hd">
                                  {race.full_title}

                                  {moment().format("YYYY-MM-DD") >
                                    moment(race.registration_close).format('YYYY-MM-DD') && (
                                      <span>(Closed {moment(race.registration_close).format('ddd , MMM D , YYYY')})</span>
                                  )}

                                  {moment().format("YYYY-MM-DD") <
                                    moment(race.registration_open).format('YYYY-MM-DD') && (
                                      <span className="blue">(open {moment(raceDetails.registration_open).format('ddd , MMM D , YYYY')})</span>
                                  )}
                                </span>
                              </a>
                              {race.hostClub_club_name} |{" "}
                              {moment(race.event_event_start).format('ddd , MMM D , YYYY')}
                            </div>
                          </div>
                          <div className="rgt">
                            <a>{race.racersCount} Racers</a>
                            <a>{race.boatsCount} Boats</a>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <>
                    <ul className="uocomingRaces">
                      <li className="showRaces">
                        <div className="left">
                          <div className="icon"></div>
                          <div className="det">
                            <span className="hd">
                              <a href="#"></a>
                              <span>Not Found Any Completed Races</span>
                            </span>
                          </div>
                        </div>
                        <div className="rgt"></div>
                      </li>
                    </ul>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {raceDetails && (
        <>
          <div className="raceDetail" id="racedetails">
            <div className="">
              <span className="hideRaces">
                <span className="material-icons">close</span>
              </span>
              <div className="raceDetImg">
                <img src={raceDetails.event_image} alt="" />{" "}
                <span className="icon">
                  <img src={raceDetails.event_image} alt="" />
                </span>
              </div>
              <div className="raceInfo">
                <span className="hd">
                  {raceDetails.full_title}

                  {moment().format('YYYY-MM-DD') > moment(raceDetails.registration_close).format('YYYY-MM-DD') &&
                                <span>(Closed {moment(raceDetails.registration_close).format('ddd , MMM D , YYYY')})</span>
                            }

                            {moment().format('YYYY-MM-DD') < moment(raceDetails.registration_open).format('YYYY-MM-DD') &&
                                <span className="blue">(open {moment(raceDetails.registration_open).format('ddd , MMM D , YYYY')})</span>
                            }
                </span>
                {raceDetails.hostClub_club_name}
                <br />
                {moment(raceDetails.event_event_start).format(
                  "ddd , MMM D , YYYY"
                )}
              </div>
              <div className="raceDet">
                <span className="raceHd">Location</span>
                <div className="locationText">
                  {raceDetails.hostClub_club_name}
                  {raceDetails.city ? "," + raceDetails.city : ""}
                  {raceDetails.state ? "," + raceDetails.state : ""}
                  {raceDetails.zip ? "," + raceDetails.zip : ""}
                  <br />
                  <a href="#">Map</a> <span className="sep">|</span>
                  <a href="#">Direction</a>
                </div>
              </div>
              <div className="raceDet">
                <span className="raceHd">Hotel Reservation</span>
                <div className="locationText">
                  {raceDetails.hotels
                    ? raceDetails.hotels.map((value, index) => {
                        return (
                          <>
                            <a href="#">{value.name}</a>{" "}
                            {raceDetails.hotels.length == index + 1 ? (
                              ""
                            ) : (
                              <span className="sep">|</span>
                            )}
                          </>
                        );
                      })
                    : ""}
                </div>
              </div>
              <div className="raceDet">
                <span className="raceHd">Event Details</span>
                <div className="raceText">
                  <p
                    dangerouslySetInnerHTML={{ __html: raceDetails.details }}
                  />
                </div>
              </div>
              {raceDetails && (
                <div>
                  <a
                    className="viewMoreDet"
                    onClick={() => handleEventDetailPage(raceDetails.id)}
                  >
                    View More Details
                  </a>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default MyRace;
