import React, { useState, useEffect } from 'react';
import "./Event.scss";
import Header from "../../../Components/adminDashboard/Header/Header";
import Switch from "react-switch";
import combinedServices from "../../../shared/services/user-service";
import getLocalData from "../../../shared/localStorage/getData";
import { NavLink, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import EditJoinRace from "./UpdateEventEntry"


const JoinEventStep1 = () => {
    let navigate = useNavigate();
    const params = useParams();
    const [allUser, setAllUser] = useState([]);
    const [searchUser, setSearchUser] = useState([]);
    const [selectUser, setSelectUser] = useState("");
    const [eventId, setEventId] = useState(params.id);
    const [userId, setUserId] = useState("");
    const [userName, setUserName] = useState("");
    const [eventClassDetails, setEventClassDetails] = useState([]);
    const [eventDetails, setEventDetails] = useState([]);
    const [classIds, setClassIds] = useState([])
    const [total, setTotal] = useState('');
    const [registrationCount, setRegistrationCount] = useState(0);
    const [specialNote, setSpecialNote] = useState("");
    const [selectedSubMemberAndClass, setSelectedSubMemberAndClass] = useState([]);
    const [editEntryRaceButton, setEditEntryRaceButton] = useState(false);
    const [participantIds, setParticipantIds] = useState("");

    useEffect(async () => {
        if (getLocalData("admin_info", "isLogin")) {

            var ele = document.getElementById("toggleText");
            ele.style.display = "none";
            let eventClassResponse = await combinedServices.getClassByEvent(params.id);
           
            setEventClassDetails(eventClassResponse.data.eventClasses);
            setEventDetails(eventClassResponse.data.event)
            let userResponse = await combinedServices.getAllUserAdmin();
            //console.log(eventResponse)
            if (userResponse.status == 200) {

                var userLIst = [];
                userResponse.data.map((user) => {

                    var data = {
                        name: user.user_firstname + " " + user.user_lastname,
                        id: user.id


                    }

                    userLIst.push(data)


                })


                setAllUser(userLIst)

            }

        }
    }, [])

    const handleSelectUser = async(user) => {

        setUserName(user.name)
        document.getElementById("searchUser").value = user.name;
        var ele = document.getElementById("toggleText");
        ele.style.display = "none";
        setUserId(user.id)
        var registeredData = {
            event_id: eventId,
            user_id: user.id
        }

        let registeredUser = await combinedServices.checkIfUserJoined(registeredData);
       
        if (registeredUser.status == 200) {
            setParticipantIds(registeredUser.data.id)
            setEditEntryRaceButton(true);
            
            //navigate("../event/update-join-event/" + registeredUser.data.id, { replace: true });
            // swal("!Opps", "Member is already registered in this event", "error") ;
            //setEditEntryRaceButton(true) ;
        } else {
            setEditEntryRaceButton(false);
            setParticipantIds(registeredUser.data.id)

        }

    }

    const handleSearchBox = (e) => {
        setSearchUser(e.target.value);
        var ele = document.getElementById("toggleText");
        ele.style.display = "";

    }
    const handleRegistionCost = (e, class_id, class_name) => {

        var newClassArry = [...classIds];

        var total = registrationCount;

        if (e.target.checked) {
            var data = {
                id: class_id,
                name: class_name
            }
            newClassArry.push(data);
            setClassIds(newClassArry)
            total = total + 1;


            const totalCost =
                Number(eventDetails.registration_charge) +
                Number(
                    eventDetails.boat_charge *
                    (total - 1)
                );

            setTotal(totalCost.toFixed(2))


            setRegistrationCount(total)


        } else {


            var index = newClassArry.indexOf(class_id); // Let's say it's Bob.
            newClassArry.splice(index, 1);
            setClassIds(newClassArry)

            total = total - 1;

            if (total == 0) {

                setTotal('');
                setRegistrationCount(0);
            } else {


                const totalCost =
                    Number(eventDetails.registration_charge) +
                    Number(
                        eventDetails.boat_charge *
                        (total - 1)
                    );
                setTotal(totalCost.toFixed(2))

                setRegistrationCount(total);
            }


        }
    }

    const handleSpecialNotes = (e) => {

        setSpecialNote(e.target.value)
    }


    const handleSubUserAndClass = async () => {



        if (!userId) {

            return (
                swal("!Opps", "Please select user first ", "error")
            )
        }
        var newArr = [...selectedSubMemberAndClass]


        //setEditEntryRaceButton(false);
        var data = {
            user_name: userName,
            selectedClass: classIds,
            user_id: Number(userId),
            classWiseTotal: total,
            pit_space: false,
            special_note: specialNote
        }

        newArr.push(data)

        setSelectedSubMemberAndClass(newArr);
        var inputs = document.querySelectorAll('.checkInpt');
        for (var i = 0; i < inputs.length; i++) {
            inputs[i].checked = false;
        }
        setClassIds([]);
        setTotal(0);
        selectUser("")
        setUserId(0);
        setRegistrationCount(0);
        specialNote("")


        //document.getElementById("myForm").reset();

    }

    const handleStep1Form = async () => {

       
        var newMemberArr = [];
        selectedSubMemberAndClass.map((memberData) => {
            var data = {
                event_id: eventDetails.id,
                pit_space: memberData.pit_space,

            }
            var classes = [];
            data.user_id = Number(memberData.user_id)



            memberData.selectedClass.map((clsVal) => {
                classes.push(clsVal.id)

            })

            data.classIds = classes;
            data.payment_type = " ";
            
            data.special_note = memberData.special_note
            newMemberArr.push(data)

        })

        let eventClassResponse = await combinedServices.addEventParticipateMany(newMemberArr);

        if (eventClassResponse.status == 200) {

            navigate("../join-event-step2/" + eventClassResponse.data, { replace: true });

        } else {

            swal("Oops!", eventClassResponse.msg, "error");
        }
    

}



const handleUserMemberDelete = (id) => {


    var arr = selectedSubMemberAndClass.filter(function (item) {
        return item.user_id !== id
    })


    setSelectedSubMemberAndClass(arr)

}




    

    return (
        <>
            <Header />


            <div className="dbAdminRight">
                <div className="dbMid dbInner">
                    <div className="flexBox spacebetween topHdBox">
                      
                    </div>
                    <div className='flexBox spacebetween'>
                        <div className='pgBox whtBox'>
                            <div className="interfaceTable">

                                <form id="myForm">
                                    <div className="addEventMid">
                                        <div className="paymentMetHd">Members INFORMATION</div>
                                        <div className="eventInner searchBox membersList-box">
                                            <input type="text" className="searchUser" id="searchUser" placeholder='search user' onChange={handleSearchBox} />


                                            <ul className="flexBox eventForm" id="toggleText">
                                                

                                                    
                                                        {allUser.filter((val) => {
                                                            if (searchUser == "") {

                                                                return val;
                                                            } else if (val.name.toLowerCase().includes(searchUser.toLowerCase())) {
                                                                return val;

                                                            }



                                                        }



                                                        ).map((user) =>

                                                            <li onClick={() => handleSelectUser(user)}> {user.name}</li>
                                                        )}


                                                    
                                               

                                            </ul>
                                        </div>
                                    </div>

                                    {editEntryRaceButton ? <EditJoinRace participateId={participantIds}/>:<>


                                    <div className="addEventMid">
                                        <div className="addEventTable">
                                            <table>
                                                <tr>
                                                    <th>CLASS</th>
                                                    <th>ENTRIES COUNT </th>
                                                    <th></th>

                                                </tr>


                                                {eventClassDetails.map((distVal, index) => {
                                                    return (
                                                        <tr index={index}>

                                                            <td><input
                                                                type="checkbox"
                                                                className="checkInpt"
                                                                name="checkInpt"
                                                                onChange={(e) => handleRegistionCost(e, distVal.class_Class_Name_ID, distVal.class_Class_Name)} />{distVal.class_Class_Name}</td>
                                                            <td>{distVal.entriesCount ? distVal.entriesCount : 0}</td>
                                                            <td></td>
                                                        </tr>

                                                    )

                                                })}

                                                <tr className="paymentMetHd">

                                                    <td>Total</td>
                                                    <td></td>

                                                    <td>{total}</td>

                                                </tr>




                                            </table>
                                        </div>

                                    </div>

                                    <div className="eventDetBot">
                                        <div className="paymentMetHd">Special Note BY Member</div>
                                        <div className="eventInner">

                                            <ul className="flexBox eventForm">
                                                <li className="full">
                                                    <textarea rows="4" cols="12" className="formInput"
                                                        name="contacts"
                                                        onChange={handleSpecialNotes}
                                                    ></textarea>
                                                    <div className="exText">Please enter only one contact name and number per line.</div>

                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                    </>
}

                                    {selectedSubMemberAndClass.length > 0 && <>
                                        <div className="eventDetBot">

                                            <div className="paymentMetHd">Users WITH CLASSES INFORMATION</div>
                                            <ul className="flexBox cartList">
                                                {selectedSubMemberAndClass.map((value, index) => {

                                                    return (

                                                        <li>
                                                            <div className="inner">
                                                                <span className="material-icons close" onClick={() => handleUserMemberDelete(value.user_id)}>close</span>
                                                                <div className="hd">{value.user_name}</div>
                                                                <div className="acctable">
                                                                    <table>
                                                                        {value.selectedClass.map((classes, classIndex) => {
                                                                            return (

                                                                                <tr>
                                                                                    <td>{classes.name}</td>
                                                                                </tr>

                                                                            )



                                                                        })}


                                                                    </table>
                                                                </div>
                                                                <div className="specialNote"><strong>Special Note: </strong>{value.special_note}</div>
                                                                <div className="total">
                                                                    <div>Total</div>
                                                                    <div>{value.classWiseTotal}</div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )


                                                })}


                                            </ul>

                                        </div>
                                    </>}
                                    {!editEntryRaceButton && 

                                    <div className="addEventBot">

                                        <div className="paymentType">

                                            <div className="addEventBot">

                                                <button type="button" className="continueBtn" onClick={handleSubUserAndClass}>Add The Entry</button>

                                            </div>
                                        </div>
                                    </div>
}
                                    {selectedSubMemberAndClass.length > 0 &&

                                        <div className="addEventBot">


                                            <div className="paymentType">



                                                <button type="button" className="continueBtn"  onClick={handleStep1Form}>Checkout</button>

                                            </div>

                                        </div>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )

}
export default JoinEventStep1;