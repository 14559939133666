

import React, { useState, useEffect, useReducer } from 'react';
import moment from "moment"
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import combinedServices from "../../../shared/services/user-service";
import userConstants from "../../../shared/constants/user-constants";
import swal from 'sweetalert';


const AddEvent = (props) => {
    const params = useParams();
    let navigate = useNavigate();
    const [eventId, setEventId] = useState('');
    const [eventDetails, setEventDetails] = useState([]);
    const [eventClassDetails, setEventClassDetails] = useState([])
    const [registrationCount, setRegistrationCount] = useState(0)
    const [classIds, setClassIds] = useState([])
    const [cancelClassIds, setCancelClassIds] = useState([])
    const [total, setTotal] = useState(0)
    const [errorMsg, setErrorMsg] = useState('')
    const [baseURL, setBaseUrl] = useState(userConstants.baseURL);
    const [hostClubName, setHostClubName] = useState("");
    const [participantIds, setParticipantIds] = useState(props.participateId);
    const [participantClassData, setParticipantClassData] = useState([]);
    const [eventClassData, setEventClassData] = useState([]);
    const [allClassDetails, setAllClassDetails] = useState([])
    const [isLoder, setIsLoder] = useState(false);
    const [addLoder, setAddLoder] = useState(false);
    useEffect(() => {
    
        //setParticipantIds(participateId)
        calculateEnterClassPrice();




    }, [])

    const calculateEnterClassPrice = async () => {

        let eventParticipateResponse = await combinedServices.getEventParticipateClass(participantIds);
        let eventParticipateData = eventParticipateResponse.data;
        let eventDetails = eventParticipateData.event;
        setEventDetails(eventDetails)
        let eventParticipateDetails = eventParticipateData.participantsData;
        //setParticipantIds(eventParticipateDetails);
        let eventClassResponse = await combinedServices.getClassByEvent(eventDetails.id);
        var selectedParticipantClasses = [];
        var selectedEventClasses = [];




        eventParticipateDetails.map((value) => {

            value.eventParticipantClasses.map((classes) => {
                if (classes.participation_status == "ENTERED") {



                    var classData = {
                        class_id: classes.class_id,
                        id: classes.id,
                        class_name: classes.class.name,
                        status: classes.participation_status

                    }
                    selectedParticipantClasses.push(classData);
                }

            })



        })
        setParticipantClassData(selectedParticipantClasses);

        eventClassResponse.data.eventClasses.map((value) => {


            var classData = {
                class_id: value.class_Class_Name_ID,
                class_name: value.class_Class_Name,
                status: "",
                id: ""

            }

            selectedEventClasses.push(classData)



        })


        var filteredArray = selectedEventClasses.filter(function (array_el) {
            return selectedParticipantClasses.filter(function (anotherOne_el) { return anotherOne_el.class_id == array_el.class_id; }).length == 0
        });

        //console.log("filteredArray",filteredArray)

        setEventClassData(filteredArray);





      


        var totalCount = 0;
        var totalCost = 0;
        if (selectedParticipantClasses.length == 0) {


            setTotal(0);
            setRegistrationCount(0);
        }


        selectedParticipantClasses.map((value) => {


            if (value.status == "ENTERED") {
                totalCount = totalCount + 1;


                const totalCost =
                    Number(eventDetails.registration_charge) +
                    Number(
                        eventDetails.boat_charge *
                        (totalCount - 1)
                    );

          
                setTotal(totalCost.toFixed(2))
                setRegistrationCount(totalCount)



            } else {

                totalCount = totalCount - 1;



                const totalCost =
                    Number(eventDetails.registration_charge) +
                    Number(
                        eventDetails.boat_charge *
                        (totalCount - 1)
                    );

                setTotal(totalCost.toFixed(2))
                setRegistrationCount(totalCount)



            }


        })



    }




    const handleRegistionCost = async (e, classDetails) => {
        var classArr = [];
        var classIdArr = [];

        if (classDetails.status == "ENTERED") {
            classIdArr.push(classDetails.id)
            var data = {
                ids: classIdArr
            }
            let eventClassResponse = await combinedServices.cancleEventParticipateClassMany(data);
            if (eventClassResponse.status == 200) {
            } else {
                swal("!Opps", eventClassResponse.data, "error")
            }

        } else {
            classArr.push(classDetails.class_id)

            var data = {
                event_participant_id: Number(participantIds),
                class_ids: classArr,
                //participation_status: "CANCELLED"

            }

            let eventClassResponse = await combinedServices.addEventParticipateClassMany(data);
        }

        calculateEnterClassPrice();


    }



    const calculateDaysBetweenTwoDate = () => {

        var future = moment(eventDetails.registration_close);
        var start = moment(eventDetails.registration_open);
        var d = future.diff(start, 'days') + 1; // 9

        return d;
    }

    const handleUpdateSendEmail = async () => {

        let eventClassResponse = await combinedServices.sendEmailForParticipant(participantIds);
        if (eventClassResponse.status == 200) {
            //swal("!Yes","Your entry update successfully. Please check all update details on your mail", "success");
            swal("Your entry update successfully. Please check all update details on your mail").then(() => {
              //  navigate("../event", { replace: true });

            })
        }

    }







    return (


        <>

            <div className="addEventMid">
                <div className="addEventTable">

                    <table>
                        <tr>
                            <th>CLASS</th>
                            <th>Class Status </th>
                            <th></th>

                        </tr>

                        {participantClassData.map((classes, classIndex) => {
                            return (
                                <tr>

                                    <td><input type="checkbox" checked={classes.status == "ENTERED" ? "true" : ""} className="checkInpt" name="checkInpt" onChange={(e) => handleRegistionCost(e, classes)} />{classes.class_name}</td>
                                    <td>{classes.status}</td>
                                    <td></td>
                                </tr>

                            )

                        })}

                        {eventClassData.map((classes, classIndex) => {
                            return (
                                <tr>

                                    <td><input type="checkbox" checked={classes.status == "ENTERED" ? "true" : ""} className="checkInpt" name="checkInpt" onChange={(e) => handleRegistionCost(e, classes)} />{classes.class_name}</td>
                                    <td>{classes.status}</td>
                                    <td></td>
                                </tr>

                            )

                        })}




                        <tr className="paymentMetHd">

                            <td>Total</td>
                            <td></td>

                            <td>{total}</td>

                        </tr>




                    </table>
                </div>

            </div>
            <div className="addEventBot">

                <div className="paymentType">

                    <div className="addEventBot">
                        <div className="text-center mt-4 loadMore"><button type="button" className="continueBtn" onClick={handleUpdateSendEmail}>Update Entry</button>
                        </div>
                    </div>
                </div>
            </div>








        </>


    )

}
export default AddEvent;