import React, { useState, useEffect, useRef } from "react";
import combinedServices from "../../shared/services/user-service";
import DownloadScorePdf from "./DownloadScorePdf";
import { useParams } from "react-router-dom";
import MetaDecorator from "../../utils/metaDecorator";
import moment from "moment";
import { FacebookShareButton,FacebookIcon } from "react-share";
import { useLocation } from "react-router-dom";

function ScorePdfPage() {
  const [metaDecoratorData, setMetaDecoratorData] = useState("");
  const shareButtonRef = useRef();
  const location = useLocation();

  let eventId = useParams().id;
  useEffect(async () => {
    let res = await combinedServices.getClassByEventId(eventId);
    setMetaDecoratorData(res.data);
    const searchParams = new URLSearchParams(window.location.search);
    const facebookparams = searchParams.get("facebook");
    console.log("ffff", facebookparams);
    if (facebookparams == "true") {
      console.log("cll");
      if (shareButtonRef.current) {
        console.log("clicked");
        shareButtonRef.current.click();
      }
    }
  }, []);
  const timeaccotolocation2 = (x, timeZone) => {
    if (x == undefined) {
      return;
    }
    if (timeZone == "") {
      timeZone = "Canada/Eastern";
    }
    const utcMoment = moment.utc(x);
    const localMoment = utcMoment.tz(timeZone);

    // const timeZoneAbbreviation = localMoment.format('z');
    const timeZoneOffset = localMoment.format("Z");
    return localMoment.format("ddd MMM DD YYYY HH:mm:ss");
  };
  return (
    <div>
      {metaDecoratorData && (
        <>
          <MetaDecorator
            title={metaDecoratorData?.full_title}
            description={moment(
              timeaccotolocation2(
                metaDecoratorData?.event_start,
                metaDecoratorData?.time_zone
              )
            ).format("YYYY-MM-DD")}
            description1={moment(
              timeaccotolocation2(
                metaDecoratorData?.event_end,
                metaDecoratorData?.time_zone
              )
            ).format("YYYY-MM-DD")}
            description3={moment(
              timeaccotolocation2(
                metaDecoratorData?.registration_open,
                metaDecoratorData?.time_zone
              )
            ).format("YYYY-MM-DD")}
            description4={metaDecoratorData?.venue.clbsite_site_city}
            description5={metaDecoratorData?.venue.clbsite_site_state}
            description6={metaDecoratorData?.venue.clbsite_site_name}
            keywords={""}
            image={metaDecoratorData?.image}
          />
          <FacebookShareButton
            ref={shareButtonRef}
            url={
              "https://concentricsinc.flexsin.org/" +
              "download-pdf-score/" +
              eventId
            }
            quote={"racenamba"}
            hashtag="#namba"
            description={
              "Event Start" +
              " | " +
              timeaccotolocation2(
                metaDecoratorData?.event_start,
                metaDecoratorData?.time_zone
              ) +
              " | " +
              "Registration Start" +
              " | " +
              timeaccotolocation2(
                metaDecoratorData?.registration_open,
                metaDecoratorData?.time_zone
              ) +
              " | " +
              "Venue" +
              " | " +
              metaDecoratorData?.venue +
              " | " +
              metaDecoratorData?.state +
              " | " +
              metaDecoratorData?.zip
            }
            title={metaDecoratorData?.full_title}
            image={metaDecoratorData?.image}
            className="m-2"
          >
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
          <DownloadScorePdf />
        </>
      )}
    </div>
  );
}

export default ScorePdfPage;
