import Header from "../../Components/Layout/Header/Header";
import Footer from "../../Components/Layout/Footer/Footer";
import "./AddEvent.scss";
import React, { useState, useEffect, useReducer } from 'react';
import moment from "moment"
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import combinedServices from "../../shared/services/user-service";
import userConstants from "../../shared/constants/user-constants";
import swal from 'sweetalert';
import AddStep3 from "./AddEventStep3"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { Spinner } from 'reactstrap';




const AddEvent = () => {
    const params = useParams();
    let navigate = useNavigate();
    const [eventId, setEventId] = useState('');
    const [eventDetails, setEventDetails] = useState([]);
    const [eventClassDetails, setEventClassDetails] = useState([])
    const [registrationCount, setRegistrationCount] = useState(0)
    const [paymentMethod, setPaymentMethod] = useState([])
    const [paymentSelectedMethod, setSelectedPaymentMethod] = useState("")
    const [classIds, setClassIds] = useState([])
    const [cancelClassIds, setCancelClassIds] = useState([])
    const [total, setTotal] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const [baseURL, setBaseUrl] = useState(userConstants.baseURL);
    const [handicappedPitSpace, setHandicappedPitSpace] = useState(false)
    const [hostClubName, setHostClubName] = useState("");
    const [subMember, setSubMember] = useState([]);
    const [selectedSubMemberId, setSelectedSubMemberId] = useState(0);
    const [selectedSubMember, setSelectedSubMember] = useState("");
    const [selectedSubMemberAndClass, setSelectedSubMemberAndClass] = useState([]);
    const [redirectionStatus, setRedirectionStatus] = useState(false);
    const [participantIds, setParticipantIds] = useState("");
    const [participantClassData, setParticipantClassData] = useState([]);
    const [isLoder, setIsLoder] = useState(false);
    const [addLoder, setAddLoder] = useState(false);
    useEffect(async () => {
        var paymentArr = [];

        let eventParticipateResponse = await combinedServices.getEventParticipateClass(params.id);
        let eventParticipateData = eventParticipateResponse.data;
        let eventDetails = eventParticipateData.event;
        let eventParticipateDetails = eventParticipateData.participantsData;
        var selectedParticipantClasses = [];

        eventParticipateDetails.map((value) => {

            value.eventParticipantClasses.map((classes) => {

                if (classes.participation_status != "CANCELLED") {
                    selectedParticipantClasses.push(classes.class_id)

                }

            })



        })


        setParticipantClassData(eventParticipateDetails);
        setEventId(eventDetails.id)
        setParticipantIds(params.id);
        let eventClassResponse = await combinedServices.getClassByEvent(eventDetails.id);
        setEventClassDetails(filterByReference(eventClassResponse.data.eventClasses, selectedParticipantClasses))

        setEventDetails(eventClassResponse.data.event);




    }, [])


    const getAllParticipantData = async () => {


        let eventParticipateResponse = await combinedServices.getEventParticipateClass(participantIds);
        let eventParticipateData = eventParticipateResponse.data;
        let eventDetails = eventParticipateData.event;
        let eventParticipateDetails = eventParticipateData.participantsData;
        var selectedParticipantClasses = [];

        eventParticipateDetails.map((value) => {

            value.eventParticipantClasses.map((classes) => {

                if (classes.participation_status != "CANCELLED") {
                    selectedParticipantClasses.push(classes.class_id)

                }

            })



        })

        setParticipantClassData(eventParticipateDetails);


        let eventClassResponse = await combinedServices.getClassByEvent(eventId);

        setEventClassDetails(filterByReference(eventClassResponse.data.eventClasses, selectedParticipantClasses))

        setEventDetails(eventClassResponse.data.event);


    }

    const filterByReference = (arr1, arr2) => {


        let res = [];
        res = arr1.filter(el => {
            return !arr2.find(element => {
                return element === el.class_Class_Name_ID;
            });
        });
        return res;
    }

    const handleRegistionCost = (e, class_id, class_name, type) => {
        var newClassArry = [...classIds];
        var newClassArr2 = [...cancelClassIds]
        var total = registrationCount;
        if (type == "add") {
            if (e.target.checked) {
                var data = {
                    id: class_id,
                    name: class_name
                }
                newClassArry.push(data);
                setClassIds(newClassArry)
                total = total + 1;


                const totalCost =
                    Number(eventDetails.boat_charge) +
                    Number(
                        eventDetails.registration_charge *
                        (total - 1)
                    );



                setTotal(totalCost.toFixed(2))
                setRegistrationCount(total)






            } else {


                var index = newClassArry.indexOf(class_id); // Let's say it's Bob.
                newClassArry.splice(index, 1);
                setClassIds(newClassArry)

                total = total - 1;

                if (total == 0) {

                    setTotal('');
                    setRegistrationCount(0);
                } else {


                    const totalCost =
                        Number(eventDetails.boat_charge) +
                        Number(
                            eventDetails.registration_charge *
                            (total - 1)
                        );


                    setTotal(totalCost.toFixed(2))
                    setRegistrationCount(total)



                }


            }

        } else {
            if (e.target.checked) {
                var data = {
                    id: class_id,
                    name: class_name
                }
                newClassArr2.push(data);
                setCancelClassIds(newClassArr2)






            } else {


                var index = newClassArr2.indexOf(class_id); // Let's say it's Bob.
                newClassArr2.splice(index, 1);
                setCancelClassIds(newClassArr2)



            }


        }



    }



    const calculateDaysBetweenTwoDate = () => {

        var future = moment(eventDetails.registration_close);
        var start = moment(eventDetails.registration_open);
        var d = future.diff(start, 'days') + 1; // 9

        return d;
    }



    const handleSubUserAndClass = async () => {
        setIsLoder(true)
        if (cancelClassIds == 0) {
            setIsLoder(false)
            swal("!Opps", "Please check atleast one class for cancel", "error");
            return true;

        }

        var classes = [];
        cancelClassIds.map((clsVal) => {
            classes.push(clsVal.id)

        })

        //data.classIds = classes;


        var data = {
            //event_participant_id:participantIds,
            ids: classes,
            //participation_status: "CANCELLED"

        }

        let eventClassResponse = await combinedServices.cancleEventParticipateClassManyForAdmin(data);

        if (eventClassResponse.status == 200) {

            setIsLoder(false)
            swal(eventClassResponse.data)
                .then(async (value) => {
                    getAllParticipantData();
                    var inputs = document.querySelectorAll('.checkInpt');
                    setCancelClassIds([])
                    for (var i = 0; i < inputs.length; i++) {
                        inputs[i].checked = false;
                    }
                });




            //navigate("../event/update-join-event/" + participantIds, { replace: true });
        } else {

            setIsLoder(false)
            swal("!Opps", eventClassResponse.data, "error")
        }





    }


    const handleAddSubUserAndClass = async () => {

        setAddLoder(true)
        if (classIds.length == 0) {
            setAddLoder(false)
            swal("!Opps", "Please check atleast one class for cancel", "error");
            return true;

        }

        var classes = [];
        classIds.map((clsVal) => {
            classes.push(clsVal.id)

        })



        var data = {
            event_participant_id: participantIds,
            class_ids: classes,
            //participation_status: "CANCELLED"

        }

        let eventClassResponse = await combinedServices.addEventParticipateClassManyForAdmin(data);





        if (eventClassResponse.status == 200) {

            if (participantClassData[0].eventParticipant.payment_type == "PAYPAL") {

                var participantData = {


                    event_participant_class_ids: eventClassResponse.data


                }
                let eventClassResponse1 = await combinedServices.eventParticipantClassPaymentForAdmin(participantData);
                if (eventClassResponse1.status == 200) {

                    setAddLoder(true)
                    window.location.href = eventClassResponse1.data.payment_link;
                } else {

                    swal(eventClassResponse1.msg).then((value) => {

                        setAddLoder(false)
                    }


                    )
                }



            } else {
                setAddLoder(true)
                swal("Class add successfully ")
                    .then(async (value) => {
                        getAllParticipantData();
                        var inputs = document.querySelectorAll('.checkInpt');
                        setClassIds([])
                        for (var i = 0; i < inputs.length; i++) {
                            inputs[i].checked = false;
                        }
                    });



            }













            //navigate("../event/update-join-event/" + participantIds, { replace: true });
        } else {

            setIsLoder(false)
            swal("!Opps", eventClassResponse.data, "error")
        }






    }




    return (
        <>
            <div className="mainCon">
                <Header />

                <div className="innerBanner event">
                    <div className="container flexBox">
                        <div>
                            <div className="innerHd">Events</div>
                            <div className="bradcrumb"><a href="/">Home</a><a href="/event-management">Events</a></div>
                        </div>
                    </div>
                </div>

                <div className="pb50 grayBg">
                    <div className="container">
                        <div className="whtBox">
                            <div className="addEventTop flexBox spacebetween itemCenter">
                                <div className="left flexBox itemCenter nowrap">
                                    <img src={
                                        eventDetails.image.slice(0, 16) === "https://nambaweb" ? eventDetails.image :
                                            baseURL + "images/" == eventDetails.image
                                                ? "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/logo.png"
                                                : eventDetails.image
                                    } alt="" />
                                    {eventDetails.full_title}<br />
                                    {hostClubName}
                                </div>

                                <div className="right">
                                    {moment(eventDetails.registration_open).format('MMM D')} - {moment(eventDetails.registration_close).format('MMM D')}{calculateDaysBetweenTwoDate() > 0 ? "," + calculateDaysBetweenTwoDate() + " days" : ""}<br />

                                </div>
                            </div>

                            <form id="myForm">

                                {participantClassData.map((value, index) => {
                                    return (
                                        <div className="addEventMid">
                                            <div className="addEventTable">
                                                <table>
                                                    <tr>
                                                        <th>CLASS</th>
                                                        <th>Class Status </th>
                                                        <th></th>

                                                    </tr>
                                                    {value.eventParticipantClasses.map((classes, classIndex) => {
                                                        return (
                                                            <tr>

                                                                <td><input type="checkbox" disabled={classes.participation_status == "CANCELLED" ? "disabled" : ""} className="checkInpt" name="checkInpt" onChange={(e) => handleRegistionCost(e, classes.id, classes.class.name, "cancel")} />{classes.class.name}</td>
                                                                <td>{classes.participation_status}</td>
                                                                <td></td>
                                                            </tr>

                                                        )

                                                    })}


                                                </table>
                                            </div>

                                        </div>
                                    )
                                })}

                                <div className="addEventBot">

                                    <div className="paymentType">

                                        <div className="addEventBot">
                                            {isLoder ? <div className="text-center mt-4 loadMore"><Spinner color="primary" /></div> : <button type="button" className="continueBtn" onClick={handleSubUserAndClass}>Cancel Class</button>}

                                        </div>
                                    </div>
                                </div>



                            </form>
                            {/*
                            {eventClassDetails.length > 0 && <>
                                <div className="addEventMid">
                                    <div className="addEventTable">
                                        <table>
                                            <tr className="paymentMetHd">
                                                <th>CLASS</th>

                                                <th></th>

                                            </tr>
                                            {eventClassDetails.map((classes, classIndex) => {
                                                return (
                                                    <tr>

                                                        <td><input type="checkbox" className="checkInpt" name="checkInpt" onChange={(e) => handleRegistionCost(e, classes.class_Class_Name_ID, classes.class_Class_Name, "add")} />{classes.class_Class_Name}</td>

                                                        <td></td>
                                                    </tr>

                                                )

                                            })}

                                            <tr className="paymentMetHd">

                                                <td>Total</td>


                                                <td>{total}</td>

                                            </tr>


                                        </table>
                                    </div>

                                </div>





                                <div className="addEventBot">

                                    <div className="paymentType">

                                        <div className="addEventBot">
                                            {addLoder ? <div className="text-center mt-4 loadMore"><Spinner color="primary" /></div> : <button type="button" className="continueBtn" onClick={handleAddSubUserAndClass}>Add extra Class</button>}

                                        </div>
                                    </div>
                                </div>

                            </>}
                                        */}

                        </div>
                    </div>
                </div>


                <Footer />
            </div>


        </>

    )

}
export default AddEvent;