import React, { useState, useEffect } from 'react';
import "./AdminDashboard.scss";
import Header from "../../../Components/adminDashboard/Header/Header";
import Switch from "react-switch";
import combinedServices from "../../../shared/services/user-service";
import getLocalData from "../../../shared/localStorage/getData";
import { useForm } from 'react-hook-form';
import swal from 'sweetalert';
import { useNavigate } from "react-router-dom";
import { NavLink, useParams } from "react-router-dom";
import { Spinner } from 'reactstrap';


const AdminDashboard = () => {

    let navigate = useNavigate();
    const params = useParams();

    const [checked, setChecked] = useState(false);
    const [statusCheck, setStatusCheck] = useState(false);
    const [allsmsData, setAllSmsData] = useState([]);
    const [smsDetails, setSmsDetails] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoder, setIsLoder] = useState(true);
    const [isHideSubmitButton,setIsHideSubmitButton] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();


    const handleChange = async (nextChecked, keyword) => {
        let smsResponse = await combinedServices.getSmsTemplate(keyword);
        if (smsResponse.status == 200) {
            let smsDetails = smsResponse.data;
            let smsdata = {

                title: smsDetails.title,
                keyword: smsDetails.keyword,
                content: smsDetails.content,
                variables: smsDetails.variable,
                template_status: nextChecked,



            }

            let apiResponse = await combinedServices.updateSmsTemplate(smsdata, smsDetails.id);

            if (apiResponse.status == 200) {

                getALlSmsTemplate();
            }


        }


        //console.log(smsResponse)
        //setChecked(nextChecked);
    };
    const handleFormStatus = (nextChecked) => {


        setStatusCheck(nextChecked)

    }
   
    const handleUpdateForm = async (keyword) => {
        setIsEdit(true)
        let editResponse = await combinedServices.getSmsTemplate(keyword);
        setSmsDetails(editResponse.data)
    }



    useEffect(async () => {
        if (getLocalData("admin_info", "isLogin")) {
            let smsResponse = await combinedServices.smsTemplateAll();
            if(smsResponse.status  == 200){
                setIsLoder(false)
                setAllSmsData(smsResponse.data);
               
            }
           

            //setUserId(userId)
        }
    }, [])

    const getALlSmsTemplate = async () => {
        setIsLoder(true)
        let smsResponse = await combinedServices.smsTemplateAll();
        if(smsResponse.status  == 200){
            setIsLoder(false)
            setAllSmsData(smsResponse.data);
           
        }

        
    }

    const onSubmit = async (data) => {
        if (isEdit) {
            setIsHideSubmitButton(true)
            var updateformData = {

                title: data.title,
                keyword: data.keyword,
                content: data.content,
                variables: data.variable,



            }

            let smsResponse = await combinedServices.updateSmsTemplate(updateformData, smsDetails.id);
            if (smsResponse.status == 200) {
                
                swal("Yes", "Sms Update Successfully", "success")
                    .then((value) => {
                        setIsHideSubmitButton(false)
                        getALlSmsTemplate();

                    })


            } else {

                swal("!Opps", smsResponse.msg, "error")
                setIsHideSubmitButton(false)
            }
        } else {
            setIsHideSubmitButton(true)
    
            var formData = {

                title: data.title,
                keyword: data.keyword,
                content: data.content,
                variables: data.variable,
                template_status: statusCheck


            }

            let smsResponse = await combinedServices.smsTemplate(formData);
            if (smsResponse.status == 200) {
               
                swal("Yes", "Sms Create Successfully", "success")
                    .then((value) => {
                        setIsHideSubmitButton(false)
                        getALlSmsTemplate();

                    })


            } else {

                swal("!Opps", smsResponse.msg, "error");
                setIsHideSubmitButton(false)
            }
        }


    }
    const handleDeleteSmsTemplate = async (id) => {


        swal("Are you sure want to delete this sms template?", {
            buttons: {
              yes: {
                text: "Yes",
                value: "yes"
              },
              no: {
                text: "No",
                value: "no"
              }
            }
          }).then(async(value) => {
            if (value === "yes") {
                let smsResponse = await combinedServices.deleteSmsTemplate(id);

                if (smsResponse.status == 200) {
            
                    swal("Yes", "Sms Delete Successfully", "success")
                    .then((value) => {

                        getALlSmsTemplate();

                    })
                        
                    
    
    
                }
            }
            return false;
          });


    }


    return (
        <>
            <Header />

            <div className="dbAdminRight">
                <div className="dbMid dbInner">
                    <div className="flexBox spacebetween topHdBox">
                        <div className="left">
                            <div className="dbMainHd">SMS TEMPLATE</div>
                        </div>
                        <div className='right tar'>
                            <button type='button' className='blueBtn'>+ Create SMS Tempalte</button>
                        </div>
                    </div>
                    <div className='flexBox spacebetween'>
                        <div className='stLeft whtBox'>
                            <div className="interfaceTable">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>Title</th>
                                            <th>Description</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    {isLoder ?  <div className="text-center mt-4 loadMore"><Spinner color="primary"/></div>  : <>

                                    <tbody>
                                        {allsmsData.length > 0 ? allsmsData.map((value, index) => {
                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <div className='title'>{value.title}</div></td>
                                                    <td>
                                                        <div className='dec'>{value.keyword}</div>
                                                    </td>

                                                    <td>
                                                        <div className='toggleSwitch'><Switch onChange={(e) => handleChange(e, value.keyword)} checked={value.template_status} Name="react-switch1" offColor="#e70000" onColor="#1ce10d" onHandleColor="#fff" uncheckedIcon={false} checkedIcon={false} handleDiameter={14} height={22} width={48} className="react-switch" /></div>
                                                    </td>
                                                    <td>
                                                        <div className='actionBtn flexBox nowrap'>
                                                            <button title='Delete' type='button' onClick={() => handleDeleteSmsTemplate(value.id)}><span className="material-icons-outlined">delete</span></button>
                                                        </div>
                                                    </td>
                                                </tr>

                                            )
                                        }) : <div className="noEvent">Not Found Any Sms Templates</div>}
                                    </tbody>
                                    </>}
                                </table>
                            </div>
                        </div>
                        {!isEdit ?
                            <div className='stRight whtBox'>
                                <div className='dbSubHd'>Create SMS Template</div>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <ul className="flexBox editForm full">
                                        <li>
                                            <span className="fieldHd">Title</span>
                                            <input type="text" className='formInput' placeholder='Enter Title' name="title"  {...register('title', { required: true })} />
                                            {errors.title && <p className="errorMsg">Please enter title</p>}
                                        </li>
                                        <li>
                                            <span className="fieldHd">Keyword </span>
                                            <input type="text" className='formInput' placeholder='Enter Keyword ' name="keyword" {...register('keyword', { required: true })} />
                                            {errors.keyword && <p className="errorMsg">Please enter keyword</p>}
                                        </li>
                                        <li>
                                            <span className="fieldHd">Content </span>
                                            <textarea rows={3} cols={3} className='formInput' placeholder='Enter Content ' name="content" {...register('content', { required: true })} ></textarea>
                                            {errors.content && <p className="errorMsg">Please enter content</p>}
                                        </li>
                                        <li>
                                            <span className="fieldHd">Variable </span>
                                            <input type="text" className='formInput' placeholder='Enter Variable' name="variable" {...register('variable', { required: true })} />
                                            {errors.variable && <p className="errorMsg">Please enter variable</p>}
                                        </li>
                                        <li>
                                            <div className='flexBox itemCenter'>
                                                <span className="fieldHd">Template Staus</span>
                                                <div className='toggleSwitch ml10'>
                                                    <Switch Name="react-switch1" offColor="#e70000" onChange={handleFormStatus} checked={statusCheck} onColor="#1ce10d" onHandleColor="#fff" uncheckedIcon={false} checkedIcon={false} handleDiameter={14} height={22} width={48} className="react-switch" /></div>
                                            </div>
                                        </li>
                                    </ul>
                                    {isHideSubmitButton ? " " : <div className="tar"><button type="submit" className="formBtn full">Create</button></div>}
                                </form>
                            </div>
                            :

                            <div className='stRight whtBox'>
                                <div className='dbSubHd'>Update SMS Template</div>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <ul className="flexBox editForm full">
                                        <li>
                                            <span className="fieldHd">Title</span>
                                            <input type="text" className='formInput' placeholder='Enter Title' name="title" defaultValue={smsDetails.title} {...register('title')} />

                                        </li>
                                        <li>
                                            <span className="fieldHd">Keyword </span>
                                            <input type="text" className='formInput' placeholder='Enter Keyword ' name="keyword" {...register('keyword')} defaultValue={smsDetails.keyword} />

                                        </li>
                                        <li>
                                            <span className="fieldHd">Content </span>
                                            <textarea rows={3} cols={3} className='formInput' placeholder='Enter Content ' name="content" {...register('content')} defaultValue={smsDetails.content}></textarea>

                                        </li>
                                        <li>
                                            <span className="fieldHd">Variable </span>
                                            <input type="text" className='formInput' placeholder='Enter Variable' name="variable" {...register('variable')} defaultValue={smsDetails.variables} />

                                        </li>

                                    </ul>
                                    {isHideSubmitButton ? " " :<div className="tar"><button type="submit" className="formBtn full">Update</button></div>}
                                </form>
                            </div>


                        }


                    </div>
                </div>
            </div>
        </>

    )

}
export default AdminDashboard;