import React, { useState } from "react";
import combinedServices from "../../shared/services/user-service";
import setLocalData from "../../shared/localStorage/setData";
import { useForm } from "react-hook-form";
import { Modal, ModalBody, ModalHeader, Button, ModalFooter } from 'reactstrap';
import "./Login.scss" ;
import { useNavigate } from "react-router-dom";
const Login = (props) => {
  let navigate = useNavigate();
 
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  // Modal open state
  const [modal, setModal] = React.useState(props.isLogin? props.isLogin :false);
  const logo = "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/logo.png"
  const onSubmit = async (data,e) => {
   
    //e.preventDefault();
    var data = {
      email: data.email,
      password: data.password,
    };

    let login_response = await combinedServices.login(data);
    
    var login_data = login_response.data;

    
    
    
   if (login_response.status == 200) {
     
     let login_response_1 = {
      login_user_detail: login_response
    };

      if(login_data.user.user_type == "superadmin"){
        window.location.href = "/admin-dashboard" ;
       
        setLocalData("admin_info",login_data);
      }else{

        var clubOfficerIn = login_data.user.clubOfficerIn.length ;
       
        setLocalData("user_info", login_response_1);

        localStorage.setItem("parent_token",login_data.token);
        
        if(props.isLogin){
          window.location.href = props.path ; 
          //navigate(props.path, { replace: true });
        }else if(clubOfficerIn > 0 ){
          window.location.href = "/club-dashboard";
          //navigate("../club-dashboard", { replace: true });
        }else{
          //window.location.href = "/user-dashboard";
          window.location.href = "/club-dashboard";
          //navigate("../user-dashboard", { replace: true });
        }

    
      }
     
     
    } else {
      setErrorMsg(login_response.msg);
      e.target.reset();
    }
  };

  

  // Toggle for Modal
  const toggle = () => {
   
    setModal(!modal);
  }
  const registerq = () => {
   window.location.href=" https://member.namba.com/index.php?page=getstarted";
    
  }
  

  return (
    <>

      <div>
        {props.isLogin ? "" : <a onClick={toggle}>Login</a>}
        <Modal isOpen={modal}>
          <ModalHeader className="loginHeader">
            <Button className="close"><span class="material-icons" onClick={toggle}>close</span></Button>
            <div className="popupLogo"><img src={logo} alt="NAMBA" /></div>
          </ModalHeader>
          <ModalBody className="loginInner">
            <form onSubmit={handleSubmit(onSubmit)}>

              <div>

                <ul className="flexBox loginForm">
                  <li>
                    <span className="fieldHd">Email</span>
                    <input
                      type="text"
                      className="formInput"
                      placeholder="Enter your email"
                      autoComplete="email"
                      {...register("email", {
                        required: true,
                      })}
                    />
                    {errors?.email?.type === "required" && (
                      <span className="errorMsg">This field is required</span>
                    )}
                  </li>
                  <li>
                    <span className="fieldHd">Password</span>
                    <input type="password"
                      className="formInput"
                      placeholder="Enter your password"
                      autoComplete="password"
                      {...register("password", {
                        required: true,
                      })}

                    />
                    {errors?.password?.type === "required" && (
                      <span className="errorMsg">This field is required</span>
                    )}
                  </li>
                </ul>
                {errorMsg && <span style={{"text-align":"center"}} className="errorMsg">{errorMsg}</span> }
                <div className="LoginBtn"><Button type="submit" className="formBtn">Submit</Button></div>
              </div>
            </form>
            
            <div className="regText">
              
              <span >Click here if you forgot your Password</span>
              
              <Button onClick={registerq} type="button" className="regbtn">Register</Button>
              
            </div>
            
          </ModalBody>
        </Modal>


      </div>
    </>


  )


}

export default Login;