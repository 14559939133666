import Header from "../../Components/Layout/Header/Header";
import Footer from "../../Components/Layout/Footer/Footer";
import "./AddEvent.scss";
import React, { useState, useEffect, useReducer } from 'react';
import moment from "moment"
import { NavLink, Link } from "react-router-dom";
import combinedServices from "../../shared/services/user-service";
import getLocalData from "../../shared/localStorage/getData";
import getRemoveData from "../../shared/localStorage/getData";
import userConstants from "../../shared/constants/user-constants";
import { useParams } from "react-router-dom";
import SucessPayment from "../../Pages/Payment/Success"
import swal from 'sweetalert';
import { useNavigate } from "react-router-dom";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';



const AddEventStep3 = (props) => {
    const params = useParams();
    let navigate = useNavigate();
    const [eventId, setEventId] = useState('');
    const [eventDetails, setEventDetails] = useState([]);
    const [eventClassDetails, setEventClassDetails] = useState([])
    const [userDetail, setUserDetail] = useState([]);
    const [participantId, setParticipateId] = useState([]);
    const [baseURL, setBaseUrl] = useState(userConstants.baseURL);
    const [successPage, setSuccessPage] = useState(false)
    const [paymentMethod, setPaymentMethod] = useState([])
    const [selectPaymentMethod, setSelectPaymentMethod] = useState("")
    const [handicappedPitSpace, setHandicappedPitSpace] = useState(false)
    const [clubHostName, setClubHostName] = useState("")



    useEffect(async () => {
        var paymentArr = [];
        if (getLocalData("user_info", "isLogin")) {
            var userDetails = getLocalData("user_info", "all").userData.login_user_detail.data.user;
            setUserDetail(userDetails)
        }
        //setEventId(params.id);
        setParticipateId(params.id)
        let eventClassResponse = await combinedServices.getEventParticipateClass(params.id);
        if(eventClassResponse.status==401){
            swal(eventClassResponse.msg, "Please Re-login", "warning").then(() => {
              getRemoveData("user_info");
              localStorage.clear();
              window.location.href = "/login";
            });
          }
        if (eventClassResponse.data.event.allow_mail_ins) {

            paymentArr.push("Payment By Mail")
        }
        if (eventClassResponse.data.event.allow_pay_at_race_entries) {

            paymentArr.push("Payment At Race")
        }
        if (eventClassResponse.data.event.allow_paypal_entries) {

            paymentArr.push("PayPal")
        }
        setPaymentMethod(paymentArr)
        setEventDetails(eventClassResponse.data.event);
        //setPaymentMethod(eventClassResponse.data.participantsData[0].eventParticipant.payment_type)
        setEventId(eventClassResponse.data.event.id);

        setEventClassDetails(eventClassResponse.data.participantsData);

    }, [])


    const handlePaypalChecout = async () => {

        var participantIdsArr = String(participantId).split(",").map((num) => {
            return Number(num)
        })
       
        if (selectPaymentMethod == "PAYPAL") {
            let eventClassResponse = await combinedServices.addPaypalForm(participantIdsArr);
            if (eventClassResponse.status == 200) {
                setSuccessPage(true)
                window.location.href = eventClassResponse.data.payment_link;
            } else {

                swal("Oops!", eventClassResponse.msg, "error");
            }

        } else if (selectPaymentMethod === "PAY_AT_RACE") {
            var paymentDataArr = [];


            participantIdsArr.map((value) => {

                var data = {
                    event_participant_id: value,
                    payment_for: "event_participation",
                    pit_space: handicappedPitSpace,

                    payment_type: "PAY_AT_RACE"
                }

                paymentDataArr.push(data)


            })


            let eventPayAtRace = await combinedServices.addPaymentMany(paymentDataArr);
            if (eventPayAtRace.status == 200) {
                setSuccessPage(true);
                //let emailResponse = await combinedServices.sendEmailForParticipant(value);
                navigate("../manage-entries/" + eventId, { replace: true });
            } else {

                swal("Oops!", eventPayAtRace.msg, "error");
            }

        } else if (selectPaymentMethod === "MAIL_IN") {
            var paymentDataArr = [];


            participantIdsArr.map((value) => {

                var data = {
                    event_participant_id: value,
                    payment_for: "event_participation",
                    pit_space: handicappedPitSpace,

                    payment_type: "MAIL_IN"
                }

                paymentDataArr.push(data)


            })


            let eventMailsResponse = await combinedServices.addPaymentMany(paymentDataArr);
            if (eventMailsResponse.status == 200) {
                setSuccessPage(true)
                navigate("../payment-success?event_id=" + eventId, { replace: true });
            } else {

                swal("Oops!", eventMailsResponse.msg, "error");
            }
        }


    }
    const handlePaymentMethod = (e) => {
        var payment_type = ""
       
       switch(e.target.value) {
        case "Payment At Race":
          // code block
          payment_type = "PAY_AT_RACE";
          break;
        case "Payment By Mail":
          // code block
          payment_type =  "MAIL_IN"
          break;
          case "PayPal":
            // code block
            payment_type =  "PAYPAL" ;
            break;
        default:
            payment_type = ""
        }

        
        
        if(eventClassDetails){

            eventClassDetails.map(async(value)=>{
                var eventParticipantData = value.eventParticipant ;
                var data = {
                    id:eventParticipantData.id,
                    user_id:eventParticipantData.user_id,
                    payment_type:payment_type


                }


              let paymentTypeResponse = await combinedServices.updatePaymentType(data);
              if(paymentTypeResponse.status == 200){
                setSelectPaymentMethod(payment_type);

              }

            
            })


        }
    }

    const handleClassWiseAmount = (classData) => {

        var totalClass = classData.length;



        const totalCost =
            Number(eventDetails.registration_charge) +
            Number(
                eventDetails.boat_charge *
                (totalClass - 1)
            );
        return totalCost.toFixed(2);


    }

    const calculateDaysBetweenTwoDate = () => {

        var future = moment(eventDetails.registration_close);
        var start = moment(eventDetails.registration_open);
        var d = future.diff(start, 'days') + 1; // 9

        return d;
    }




    return (
        <>

            <Header />
            {successPage ? <SucessPayment payment_type={paymentMethod} eventid={eventId} participantId={participantId} /> : ''}
            <div className="innerBanner event">
                <div className="container flexBox">
                    <div>
                        <div className="innerHd">Events</div>
                        <div className="bradcrumb"><a href="/">Home</a><a href="/event-management">Events</a></div>
                    </div>
                </div>
            </div>

            <div className="pb50 grayBg">
                <div className="container">
                    <div className="whtBox">
                        <div className="addEventTop flexBox spacebetween itemCenter">
                            <div className="left flexBox itemCenter nowrap">
                                <img src={
                                        eventDetails.image.slice(0, 16) === "https://nambaweb" ? eventDetails.image :
                                            baseURL + "images/" == eventDetails.image
                                                ? "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/logo.png"
                                                : eventDetails.image
                                    } alt="" />
                                {eventDetails.full_title}<br />

                            </div>
                            <div className="right">
                                Registration In - {moment(eventDetails.registration_open).format('MMM Do')} - {moment(eventDetails.registration_close).format('MMM Do')}{calculateDaysBetweenTwoDate() > 0 ? "," + calculateDaysBetweenTwoDate() + " days" : ""}<br />
                                {clubHostName ? clubHostName : ""}
                            </div>
                        </div>
                        <div className="addEventMid spacebetween">

                            <div className="paymentMetHd">REGISTRATION INFORMATION</div>
                            <ul className="userInfoList">
                                <li key="1">
                                    <div className="left">First Name:</div>
                                    <div className="right">{userDetail.user_firstname}</div>
                                </li>
                                <li key="2">
                                    <div className="left">Last Name:</div>
                                    <div className="right">{userDetail.user_lastname}</div>
                                </li>
                                <li key="3">
                                    <div className="left">Phone No:</div>
                                    <div className="right">{userDetail.user_areacodemobile + userDetail.user_mobile}</div>
                                </li>
                                <li key="4">
                                    <div className="left">Sanctioning body:</div>
                                    <div className="right">{eventDetails.sanctioned_by}</div>
                                </li>
                                <li key="5">
                                    <div className="left">Namba No:</div>
                                    <div className="right">{userDetail.user_nambanum_fkey}</div>
                                </li>

                            </ul>

                            {/*<div className="classApp">
                                <div className="addEventTable">
                                    <table>
                                    <tbody>
                                        <tr>
                                            <th>Classes Applied</th>

                                            <th>Total Cost</th>
                                        </tr>
                                       
                                        {eventClassDetails.map((className, index) => {

                                            return (



                                                <tr>
                                                    <td>{className.class.name}</td>

                                                    <td>-</td>
                                                </tr>


                                            )


                                        })}





                                        <tr className="total">
                                            <td></td>

                                            <td><strong> {(Number(eventClassDetails.length - 1) * Number(eventDetails.registration_charge) + Number(eventDetails.boat_charge)).toFixed(2)}</strong></td>
                                        </tr>
                                        </tbody> 
                                    </table>
                                </div>
                                    </div>*/}


                        </div>




                        {eventClassDetails.length > 0 && <>
                            <div className="eventDetBot">

                                <div className="paymentMetHd">Users WITH CLASSES INFORMATION</div>
                                <ul className="flexBox cartList">
                                    {eventClassDetails.map((value, index) => {

                                        return (

                                            <li>
                                                <div className="inner">

                                                    <div className="hd">{value.eventParticipant.user.user_firstname + " " + value.eventParticipant.user.user_lastname}</div>
                                                    <div className="acctable">
                                                        <table>
                                                            {value.eventParticipantClasses.map((classes, classIndex) => {
                                                                return (

                                                                    <tr>
                                                                        <td>{classes.class.name}</td>
                                                                    </tr>

                                                                )



                                                            })}

                                                            {/* 
                                                      <tr>
                                                          <td> Handicapped Pit Space:  <strong>{value.eventParticipant.pit_space ? "Yes" : "No"}</strong></td>
                                                    </tr>   */}


                                                        </table>
                                                    </div>
                                                    <div className="total">
                                                        <div>Total</div>
                                                        <div>{handleClassWiseAmount(value.eventParticipantClasses)}</div>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })}


                                </ul>

                            </div>
                        </>}


                        <div className="addEventBot">


                            <div className="paymentType">

                                <div className="addEventBot">

                                    <div className="paymentMetHd">PAYMENT METHOD </div>
                                    <div className="paymentType">
                                        <span>Select payment method:</span>
                                        <select className="formSelect" name="payment" onChange={handlePaymentMethod}>
                                            <option>Select</option>
                                            {paymentMethod.length > 0 ? paymentMethod.map((payValue, index) => {
                                                    return (
                                                        <option value={payValue}>{payValue}</option>
                                                    )

                                                }) : ""}
                                            

                                        </select>


                                    </div>

                                </div>

                            

                            </div>

                        </div>

                        <div className="addEventBot">
                            <div className="paymentType pad0">
                                <button type="button" className="continueBtn" onClick={handlePaypalChecout}>Complete Entry</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

        </>

    )

}
export default AddEventStep3;