import React from "react";

const EditableRow = ({
  editFormData,
  handleEditFormChange,
  handleCancelClick,
}) => {
  return (
    <tr>
      {console.log("edit",editFormData)}
      <td>
        <input
          type="number"
          required="required"
          placeholder="Transponder Id..."
          name="id"
          value={editFormData.id}
          onChange={handleEditFormChange}
        ></input>
      </td>
      <td>
        <input
          type="text"
          required="required"
          placeholder="Transponder Name..."
          name="transponder_name"
          value={editFormData.transponder_name}
          onChange={handleEditFormChange}
        ></input>
      </td>
      <td>
        <div className="d-flex actBtn">
        <button type="submit">Save</button>
        <button type="button" className="cancel" onClick={handleCancelClick}>
          Cancel
        </button>
        </div>
      </td>
    </tr>
  );
};

export default EditableRow;