import React, { useState, useEffect } from "react";
import Header from "../../../Components/clubDashboard/Header/Header";
import getLocalData from "../../../shared/localStorage/getData";
import combinedServices from "../../../shared/services/user-service";
import SearchPage from "../../../Components/clubDashboard/Header/SearchPage";
import moment from "moment";
import "./Dashboard.scss";

const Dashboard = () => {
  const [upcomingRaces, setUpcomingRaces] = useState([]);
  const [completedRaces, setCompletedRaces] = useState([]);
const race_icon1 = "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/race_icon1.jpg"
const user_img = "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/user_img.jpg"
  useEffect(async () => {
    var upcomingRaceArr = [];
    var completedRacesArr = [];

    if (getLocalData("user_info", "isLogin")) {
      var user_id = getLocalData("user_info", "all").userData.login_user_detail
        .data.user.id;

      var upcomingRaceData = {
        participation_status: "upcoming",
        user_id: user_id,
      };
      var completedRaceData = {
        participation_status: "completed",
        user_id: user_id,
      };
      let upcomingRacesResponse = await combinedServices.getMyRaceDetails(
        upcomingRaceData
      );
      let completedRacesResponse = await combinedServices.getMyRaceDetails(
        completedRaceData
      );
      upcomingRacesResponse.data.map((events, index) => {
        var upcomingData = {
          boatsCount: events.boatsCount,
          city: events.event_city,
          country: events.event_country,
          district_id: events.event_district_id,
          event_drivers_meeting_time: events.event_drivers_meeting_time,
          event_event_start: events.event_event_start,
          full_title: events.event_full_title,
          registration_close: events.event_registration_close,
          registration_open: events.event_registration_open,
          short_tile: events.event_short_tile,
          state: events.event_state,
          venue: events.event_venue,
          hostClub_club_name: events.hostClub_club_name,
          racersCount: events.racersCount,
          id: events.event_id,
        };

        upcomingRaceArr.push(upcomingData);
      });
      setUpcomingRaces(upcomingRaceArr);

      completedRacesResponse.data.map((events) => {
        var completedData = {
          boatsCount: events.boatsCount,
          city: events.event_city,
          country: events.event_country,
          district_id: events.event_district_id,
          event_drivers_meeting_time: events.event_drivers_meeting_time,
          event_event_start: events.event_event_start,
          full_title: events.event_full_title,
          registration_close: events.event_registration_close,
          registration_open: events.event_registration_open,
          short_tile: events.event_short_tile,
          state: events.event_state,
          venue: events.event_venue,
          hostClub_club_name: events.hostClub_club_name,
          racersCount: events.racersCount,
          id: events.event_id,
        };

        completedRacesArr.push(completedData);
      });

      setCompletedRaces(completedRacesArr);
    }
  }, []);

  return (
    <>
      <Header />

      <div className="dbRight">
        <div className="dbMid">
          <SearchPage />

          {/* <div className="mb30">
                    <div className="dbMainHd">Overview</div>
                    <ul className="dbTop flexBox">
                        <li>
                            <div className="inner" style={{background: "#db402c"}}>
                                <img src={Participate} alt="" className="icon"/>
                                Participates in
                                <span>25</span>
                                Clubs
                            </div>
                        </li>
                        <li>
                            <div className="inner" style={{background: "#525de0"}}>
                                <img src={membership} alt="" className="icon"/>
                                Namba Membership
                                <span>127</span>
                                Primary Member
                            </div>
                        </li>
                        <li>
                            <div className="inner" style={{background: "#e9aa40"}}>
                                <img src={district} alt="" className="icon"/>
                                District
                                <span>19</span>
                                Namba District
                            </div>
                        </li>
                    </ul>
                </div> */}
          <div className="">
            <div className="dbMainHd">{/* Upcoming Races */}</div>
            <ul className="uocomingRaces">
              {upcomingRaces.length > 0 ? (
                upcomingRaces.map((race) => {
                  return (
                    <li className="showRaces">
                      <div className="left">
                        <div className="icon">
                          <img src={race_icon1} alt="" />
                        </div>
                        <div className="det">
                          <span className="hd">
                            <a href="#">{race.full_title}</a>
                            {moment().format("YYYY-MM-DD") >
                              moment(race.registration_close).format(
                                "YYYY-MM-DD"
                              ) && (
                              <span>
                                (Closed{" "}
                                {moment(race.registration_close).format("llll")}
                                )
                              </span>
                            )}

                            {moment().format("YYYY-MM-DD") <
                              moment(race.registration_open).format(
                                "YYYY-MM-DD"
                              ) && (
                              <span className="blue">
                                (open{" "}
                                {moment(race.registration_open).format("llll")})
                              </span>
                            )}
                          </span>
                          {race.hostClub_club_name} |{" "}
                          {moment(race.event_event_start).format("llll")}
                        </div>
                      </div>
                      <div className="rgt">
                        <a>{race.racersCount} Racers</a>
                        <a>{race.boatsCount} Boats</a>
                      </div>
                    </li>
                  );
                })
              ) : (
                <>
                  <ul className="uocomingRaces">
                    <li className="showRaces">
                      <div className="left">
                        <div className="icon"></div>
                        <div className="det">
                          <span className="hd">
                            <a href="#"></a>
                            <span>Not Found Any Upcoimg Races</span>
                          </span>
                        </div>
                      </div>
                      <div className="rgt"></div>
                    </li>
                  </ul>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className="myRace">
        <div className="userName flexBox nowrap itemCenter">
          <img src={user_img} alt="" /> John Smith{" "}
          <span className="showRace">
            <i className="hide">Hide</i> <i className="show">Show</i> My Race
          </span>
        </div>
        <div id="myRaces" className="raceListBox">
          <div className="myRaceHd">My Races</div>
          <ul className="raceList">
            {completedRaces.map((race) => {
              return (
                <li>
                  <a href="#" className="flexBox nowrap itemCenter">
                    <span className="date">
                      Nov 19,2021 <span>at 7:30 AM</span>
                    </span>
                    <span className="name">Woodoo World championships </span>
                    <span className="material-icons-outlined icon">
                      navigate_next
                    </span>
                  </a>
                </li>
              );
            })}

            <li>
              <a href="#" className="flexBox nowrap itemCenter">
                <span className="date">
                  Nov 19,2021 <span>at 7:30 AM</span>
                </span>
                <span className="name">Woodoo World championships </span>
                <span className="material-icons-outlined icon">
                  navigate_next
                </span>
              </a>
            </li>
            <li>
              <a href="#" className="flexBox nowrap itemCenter">
                <span className="date">
                  Nov 19,2021 <span>at 7:30 AM</span>
                </span>
                <span className="name">Woodoo World championships </span>
                <span className="material-icons-outlined icon">
                  navigate_next
                </span>
              </a>
            </li>
            <li>
              <a href="#" className="flexBox nowrap itemCenter">
                <span className="date">
                  Nov 19,2021 <span>at 7:30 AM</span>
                </span>
                <span className="name">Woodoo World championships </span>
                <span className="material-icons-outlined icon">
                  navigate_next
                </span>
              </a>
            </li>
            <li>
              <a href="#" className="flexBox nowrap itemCenter">
                <span className="date">
                  Nov 19,2021 <span>at 7:30 AM</span>
                </span>
                <span className="name">Woodoo World championships </span>
                <span className="material-icons-outlined icon">
                  navigate_next
                </span>
              </a>
            </li>
            <li>
              <a href="#" className="flexBox nowrap itemCenter">
                <span className="date">
                  Nov 19,2021 <span>at 7:30 AM</span>
                </span>
                <span className="name">Woodoo World championships </span>
                <span className="material-icons-outlined icon">
                  navigate_next
                </span>
              </a>
            </li>
            <li>
              <a href="#" className="flexBox nowrap itemCenter">
                <span className="date">
                  Nov 19,2021 <span>at 7:30 AM</span>
                </span>
                <span className="name">Woodoo World championships </span>
                <span className="material-icons-outlined icon">
                  navigate_next
                </span>
              </a>
            </li>
            <li>
              <a href="#" className="flexBox nowrap itemCenter">
                <span className="date">
                  Nov 19,2021 <span>at 7:30 AM</span>
                </span>
                <span className="name">Woodoo World championships </span>
                <span className="material-icons-outlined icon">
                  navigate_next
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default Dashboard;
