import { useState, useEffect, useRef } from "react";
import Header from "../../../Components/clubDashboard/Header/Header";
import getLocalData from "../../../shared/localStorage/getData";
import combinedServices from "../../../shared/services/user-service";
import moment from "moment";
import { useReactToPrint, PrintContextConsumer } from "react-to-print";
import { useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import getRemoveData from "../../../shared/localStorage/removeData";


const MyResult = () => {
  const componentRef = useRef(null);
  const [userId, setUserId] = useState("");
  const [myResultDetails, setMyResultDetails] = useState([]);
  const [leaderLoaderDetails, setLeaderLoaderDetails] = useState([]);
  const [classList, setClassList] = useState([]);
  const [title, setTitle] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [startDate, setStartDate] = useState("");
  const navigate = useNavigate();
  const [isLoder, setIsLoder] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      var myResultData = [];
  
      if (getLocalData("user_info", "isLogin")) {
        var user_id = getLocalData("user_info", "all").userData.login_user_detail
          .data.user.id;
        setUserId(user_id);
  
        try {
          let myResultResponse = await combinedServices.getMyResultDetails(user_id);
          if(myResultResponse.status==401){
            swal(myResultResponse.msg, "Please Re-login", "warning").then(() => {
              getRemoveData("user_info");
              localStorage.clear();
              window.location.href = "/login";
            });
          }
  
          myResultResponse.data.filter((x)=>x.event_status=="completed").map((myResult, index) => {
            var data = {
              full_title: myResult.full_title,
              event_start: myResult.event_start,
              district_id: myResult.district_id,
              id: myResult.id,
            };
  
            var event_classes = myResult.event_participants[0].classes;
            data.event_classes = event_classes;
            if (index === 0) {
              handleMyResult(
                myResult.id,
                myResult.full_title,
                myResult.district_id,
                myResult.event_start
              );
            }
            myResultData.push(data);
          });
  
          setMyResultDetails(myResultData);
        } catch (error) {
          // Handle error
        } finally {
          setIsLoder(false);
        }
      }
    };
  
    fetchData();
  }, []);
  

  const handleMyResult = async (event_id, title, distric_id, start_date) => {
    //  navigate('/my-score/' + event_id);
    //setClassList(event_Class)
    setTitle(title);
    setDistrictId(distric_id);
    setStartDate(moment(start_date).format("ddd , MMM D , YYYY"));

    var data = {
      event_id: event_id,
      user_id: userId,
    };

    let myLeaderBoardResponse = await combinedServices.getLeaderBoardDetails(
      data
    );
   

    setLeaderLoaderDetails(myLeaderBoardResponse.data);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const handleMyResult1=(event_id, title, distric_id, start_date)=>{
  navigate('/my-result/' + event_id);
  }
  const timeaccotolocation = (x) => {
    const utcMoment = moment.utc(x);
    const localMoment = utcMoment.local();
    return localMoment.toString();
};
  return (
    <>
      <Header />
      <div className="dbRight">
        <div className="flexBox resultMid">
        <div className="resultMidLeft">
      <div className="flexBox spacebetween itemCenter"></div>
      <div>
        <div className="dbMainHd">My Results</div>
        {isLoder ? (
            <div className="text-center mt-4 loadMore">
              <Spinner color="primary" />
            </div>
          ): 
          (
          <ul className="resultList">
            {myResultDetails.length > 0 ? (
              myResultDetails.map((value) => (
                <li key={value.id}>
                  <a href="#" className="showResult">
                    <span className="name">{value.full_title}</span>
                    <span className="date">
                      D{value.district_id} District Race #{value.district_id}{' '}
                      {moment(timeaccotolocation(value.event_start)).format('ddd, MMM D, YYYY')}
                    </span>
                    <span
                      className="material-icons-outlined icon"
                      onClick={() =>
                        handleMyResult1(
                          value.id,
                          value.full_title,
                          value.district_id,
                          value.event_start
                        )
                      }
                    >
                      navigate_next
                    </span>
                  </a>
                </li>
              ))
            ) : (
              <ul className="resultList">
                <li>
                  <a href="#" className="showResult">
                    <span className="name">Not Found Any My Result List</span>
                  </a>
                </li>
              </ul>
            )}
          </ul>
        )}
      </div>
    </div>
          {leaderLoaderDetails.length > 0 && (
            <>
              <div className="resultMidRight" id="myResult" ref={componentRef}>
                <div className="resultHd">
                  Results{" "}
                  <span className="hideResult">
                    <span className="material-icons">close</span>
                  </span>
                </div>
                <div className="flexBox nowrap spacebetween resultTop">
                  <div className="hd">
                    {title}{" "}
                    <span>
                      D{districtId} District Race #{districtId} {startDate}
                    </span>
                  </div>
                  <div onClick={handlePrint}>
                    <a href="#" className="printBtn">
                      <span className="material-icons-outlined">print</span>{" "}
                      Print
                    </a>
                  </div>
                </div>

                {leaderLoaderDetails.map((classValue) => {
                  return (
                    <>
                      <div className="rsultTbalehd">
                        {classValue.class_name}
                      </div>

                      <div className="rsultTable">
                        <table cellpadding="0" cellspacing="0">
                          <tr>
                            <th className="tal">Name</th>
                            <th className="tar">R1</th>
                            <th className="tar">R2</th>
                            <th className="tar">R3</th>
                            <th className="tar">R4</th>
                            <th className="tar">R5</th>
                            <th className="tar">R6</th>
                            <th className="tar">Total</th>
                          </tr>

                          {classValue.class_score.map((loaderDetail, index) => (
                            <tr>
                              <td className="blueClr">
                                {" "}
                                0{loaderDetail.event_district_id},{" "}
                                {loaderDetail.user_user_firstname},{" "}
                                {loaderDetail.user_user_lastname}
                              </td>
                              <td className="tar">
                                {loaderDetail.leaderboard_round1_score}
                              </td>
                              <td className="tar">
                                {loaderDetail.leaderboard_round2_score}
                              </td>
                              <td className="tar">
                                {loaderDetail.leaderboard_round3_score}
                              </td>
                              <td className="tar">
                                {loaderDetail.leaderboard_round4_score}
                              </td>
                              <td className="tar">
                                {loaderDetail.leaderboard_round5_score}
                              </td>
                              <td className="tar">
                                {loaderDetail.leaderboard_round6_score}
                              </td>
                              <td className="tar">{loaderDetail.total}</td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default MyResult;
