import React, { useState, useEffect } from 'react';
import Header from "../../Components/Layout/Header/Header";
import Footer from "../../Components/Layout/Footer/Footer";
import combinedServices from "../../shared/services/user-service";
import getLocalData from "../../shared/localStorage/getData";
import Pagination from "react-js-pagination";
import EventTab from "../../Components/EventTab/EventTab";
import { Spinner } from 'reactstrap';

const Club = () => {
    const [clubDetails, setClubDetails] = useState([])
    const [distric, setDistric] = useState([])
    const [clubData, setClubData] = useState('')
    const [pageRecord, setPageRecord] = useState(10)
    const [page, setPage] = useState(1)
    const [totalRecord, setTotalRecord] = useState('');
    const [currentDistric, setCurrentDistric] = useState('')
    const [isLoder, setIsLoder] = useState(true)
    const club_logo = "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/logo.png"

    useEffect(async () => {
        var data = {
            page: page,
            records_per_page: pageRecord
        }
        if (getLocalData("user_info", "isLogin")) {
            var user_dist_id = getLocalData("user_info", "all").userData.login_user_detail.data.user.district.uzip_namba_district;
            data.district_id = user_dist_id ;
            setCurrentDistric(user_dist_id) ;  
        } 
        let clubResponse = await combinedServices.getAllClub(data);
        if(clubResponse.status == 200){
            setIsLoder(false)
            setClubDetails(clubResponse.data);
            setTotalRecord(clubResponse.totalCount);
    
            clubResponse.data.map(async (value, index) => {
                if (index == 0) {
                    let clubResponse = await combinedServices.getClubDetails(value.id);
                    setClubData(clubResponse.data);
                }
    
    
            })
    


        }else{
            setIsLoder(false)
           
        }
       
        
        getAllDistricList();


    }, [])

         
    const getAllDistricList = async () => {
        let response = await combinedServices.getAllDistric();
        setDistric(response.data)
    }


    const handlePageChange = async (pageNumber) => {
        setPage(pageNumber)
        setIsLoder(true)

        var data = {
            page: pageNumber,
            records_per_page: pageRecord

        }
        if (currentDistric) {
            data.district_id = currentDistric;
        }
        
        let clubResponse = await combinedServices.getAllClub(data);
        if(clubResponse.status == 200){
            setIsLoder(false)
            setClubDetails(clubResponse.data);
            setTotalRecord(clubResponse.totalCount);
            clubResponse.data.map(async (value, index) => {
                if (index == 0) {
                    let clubResponse = await combinedServices.getClubDetails(value.id);
                    setClubData(clubResponse.data);
                }
    
    
            })


        }else{
            setIsLoder(false);
            
        }
       



    }

    const handleClubDetails = async (id) => {
        let clubResponse = await combinedServices.getClubDetails(id);
        setClubData(clubResponse.data);

    }
    const handleDistricFilter = async (e) => {
        setIsLoder(true)
        var clubDetails = [];

        var data = {
            page: 1,
            records_per_page: pageRecord

        }
        if (e.target.value) {
            data.district_id = e.target.value
        }

        setCurrentDistric(e.target.value)

        let clubResponse = await combinedServices.getAllClub(data);
        if(clubResponse.status == 200){
            clubDetails = clubResponse.data;
            setIsLoder(false)
            setClubDetails(clubResponse.data);
            setTotalRecord(clubResponse.totalCount);
    
            { clubDetails.length > 0  ? clubDetails.map(async (value, index) => {
                if (index == 0) {
                    let clubResponse = await combinedServices.getClubDetails(value.id);
                    setClubData(clubResponse.data);
                }
    
    
            }):
               setClubData("")
             }


        }else{
            setIsLoder(false)
            
        }
       



    }


    return (
        <>
            <div className="mainCon">
                <Header />
                <div className="innerBanner event">
                    <div className="container flexBox">
                        <div>
                            <div className="innerHd">Events</div>
                            <div className="bradcrumb"><a href="/">Home</a><a href="/event-management">Events</a></div>
                        </div>
                    </div>
                </div>

                <div className="pb50 grayBg">
                    <div className="container">
                        <div className="whtBox">

                            {/* <EventTab /> */}
                            <div>
                                <div className="flexBox">
                                    <div className="clubLft">
                                    {/* <p className="eventContent">Here are complete details that you wanted to know about various NAMBA Clubs that you can become a member of. You have complete details of all the club events in all districts, so you will never miss a club event. </p> */}
                                        <div className="flexBox nowrap itemCenter districtSelectBox">
                                            District
                                        <select onChange={handleDistricFilter} defaultValue="">
                                                <option value="">All District</option>
                                                {distric.map((distVal) => {
                                                    return (
                                                        <option key={distVal.district_id} selected={currentDistric == distVal.district_id ? "selected" : ''} value={distVal.district_id}>D{distVal.district_id}</option>
                                                    )


                                                })}

                                            </select>
                                        </div>
                                        {isLoder ?  <div className="text-center mt-4 loadMore"><Spinner color="primary"/></div>  : <>

                                        <ul className="clubList">
                                            {clubDetails.length > 0 ? clubDetails.map((club) => {
                                                return (
                                                    <li key={club.id} onClick={() => handleClubDetails(club.id)}><a href="#">{club.club_name}<span>D{club.club_district}</span></a></li>
                                                )
                                            }) : <div className="noEvent">Not Found Any Club.</div>}

                                        </ul>

                                        {totalRecord > 10 &&

                                            <Pagination
                                                activePage={page}

                                                totalItemsCount={totalRecord}

                                                onChange={handlePageChange}
                                            />
                                        }
                                        </>}
                                    </div>
                                    <div className="clubRgt">
                                        {clubData ? <>
                                        <div><img src={club_logo} alt="" /></div>
                                        <div className="clubHd">{clubData.club_name}</div>
                                        <ul className="clubDet">
                                            <li key="address">
                                                <span>Address</span>
                                                {clubData.club_city}<br />
                                                {clubData.club_state}, {clubData.club_country}, {clubData.club_zip}
                                            </li>
                                            <li key="contact">
                                            <span>Contact</span> 
                                            Contact Name : {clubData.contactUser.user_firstname} {clubData.contactUser.user_lastname}<br/>
                                            Telephone : ({clubData.club_contact_areacode}){clubData.club_contact_phone}<br />
                                            Email: {clubData.contactUser.user_email}
                                                                         </li>
                                            <li key="website">
                                                <span>Website</span>
                                                <a href="#">{clubData.club_website ? clubData.club_website : ''}</a>
                                            </li>
                                        </ul>
                                        </>: ""}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>

    )

}
export default Club;