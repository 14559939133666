import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import moment from "moment";
import combinedServices from '../shared/services/user-service';


const MetaDecorator = ({ title, description,description1,description3,description4,description5,description6, keywords,image,id }) => {
  var eventdate= moment(description).format(
    "ddd , MMM D , YYYY"
  )
  var regisdate=moment(description3).format("ddd , MMM D , YYYY")
  var description3=moment(description3).format("ddd , MMM D , YYYY")
  var imageType;
  useEffect(() => {
    if (image!=" ") {
      const fileExtension = image.toLowerCase().split('.').pop();
      imageType = fileExtension === 'png' ? 'image/png' : fileExtension === 'jpeg' || fileExtension==='jpg'? 'image/jpeg': "";
  //  imageType = image?.target?.files[0]?.name?.match(/\.(png|jpe?g)$/i) ? 'image/jpeg' : 'image/png';

    }
    else{
      image="https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/db_logo.png";
      imageType = 'image/jpeg'
    }

    // Make a GET request to the server with the title and description data
    fetch(`/api/meta-data?title=${encodeURIComponent(title)}&description=${encodeURIComponent(eventdate)}&description1=${encodeURIComponent(regisdate)}&description3=${encodeURIComponent(description3)}&description4=${encodeURIComponent(description4)}&description5=${encodeURIComponent(description5)}&description6=${encodeURIComponent(description6)}&image=${encodeURIComponent(image)}&imagetype=${encodeURIComponent(imageType)}`)
      .then(async (response) => {
        // Handle the response if needed
        const scrappedResponse = await combinedServices.clearFacebookCache({
          requestUrl: `/event-detail/${id}`,
        });
        console.log("scrappedResponse",scrappedResponse);
      })
      .catch((error) => {
        console.error('Error sending data to server:', error);
      });
    
  }, [title, description,image,description1,imageType]);

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="description" key="description" content={description} />
      <meta name="title" key="title" content={title} />
      <meta property="og:title" key="og:title" content={title} />
      <meta property="og:locale" key="og:locale" content="en_US" />
      <meta charSet="utf-8" />
      <meta property="og:image:width" content="800"/>
      <meta property="og:image:height" content="630"/>

      <meta property="og:type" key="og:type" content="website" />
      
      <meta
        property="og:description"
        key="og:description"
        content={description1}
      />
      <meta
        property="og:image"
        key="og:image"
        content={`${process.env.BASE_URL}/images/frontend.jpeg`}
      />  
    </Helmet>
  );
};

MetaDecorator.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  keywords: PropTypes.string.isRequired,
};

export default MetaDecorator;
