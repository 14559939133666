import Header from "../../../Components/clubDashboard/Header/Header";
import combinedServices from "../../../shared/services/user-service";
import React, { useState, useEffect } from "react";
import moment from "moment";
import getLocalData from "../../../shared/localStorage/getData";
import SearchPage from "../../../Components/clubDashboard/Header/SearchPage";
import Pagination from "react-js-pagination";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import getRemoveData from "../../../shared/localStorage/removeData";

const Dashboard = () => {
  const [userDetails, setUserDetails] = useState([]);
  const [clubUserDetails, setUserClubDetail] = useState([]);
  const [clubMemberDetails, setClubMemberDetails] = useState([]);
  const [pageRecord, setPageRecord] = useState(10);
  const [page, setPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState("");
  const [totalClubMember, setTotalClubMember] = useState(0);
  const [isLoder, setIsLoder] = useState(true);
  const [user, setUser] = useState("active");
  const[numnumber,setNumnumber]=useState([])
  const[datatopass,setDatatopass]=useState([])

  const race_icon1 = "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/logo.png"
  const handleLogout = () => {
    getRemoveData("user_info");
    window.location.href = "/";
  };
  useEffect(async () => {
    var userId = "";
    if (getLocalData("user_info", "isLogin")) {
      userId = getLocalData("user_info", "all").userData.login_user_detail.data
        .user.id;
    }
    var data = {
      user_id: userId,
      clubs: true,
      events: true,
      district: true,
    };

    let profileResponse = await combinedServices.getProfileDetails(data);
    let totalClubMember = await combinedServices.tokenMemberCount();
    if(totalClubMember.status==401){
      swal(totalClubMember.msg, "Please Re-login", "warning").then(() => {
        getRemoveData("user_info");
        localStorage.clear();
        window.location.href = "/login";
      });
    }

    setTotalClubMember(totalClubMember.data.clubMembersTotal);
    if(totalClubMember.status==401){
      swal(profileResponse.msg, "Please Re-login", "warning").then(() => {
        getRemoveData("user_info");
        localStorage.clear();
        window.location.href = "/login";
      });
    }
    if (profileResponse.status == 200) {
      let userDetailData = profileResponse.data;
      setIsLoder(false);
      setUserClubDetail(userDetailData.club_members);

      userDetailData.club_members.map(async (value, index) => {
        if (index == 0) {
          let clubResponse = await combinedServices.getClubMembers(
            value.club.id
          );
      
          setClubMemberDetails(clubResponse.data);
        }
      });
    } else {
      setIsLoder(false);
    }
   
  }, []);

  const handleClubMember = async (value) => {
    let clubResponse = await combinedServices.getClubMembers(value);
    

    setClubMemberDetails(clubResponse.data);
  };

  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };
  const handleMemberFilter = (e) => {
    setUser(e.target.value);
  };
// const getnum_number=(val)=>{
//   var y=numnumber.filter((x)=>x.num_id===val)
//   return y
// }
  return (
    <>
      <Header />
      <div className="dbRight">
        <div className="dbMid">
          <SearchPage/>
          {/* <div className="mb30">
                        <div className="dbMainHd">Overview</div>
                        <ul className="dbTop flexBox">
                            <li key="1">
                                <div className="inner" style={{ background: "#db402c" }}>
                                    <img src={Participate} alt="" className="icon" />
                                    Total Clubs
                                    <span>{clubUserDetails.length}</span>
                                </div>
                            </li>
                            <li key="2">
                                <div className="inner" style={{ background: "#525de0" }}>
                                    <img src={membership} alt="" className="icon" />
                                    Total Members
                                    <span>{totalClubMember}</span>
                                </div>
                            </li>
                           
                        </ul>
                    </div> */}
          <div className="">
            <div className="dbMainHd">My Clubs</div>
            {isLoder ? (
              <div className="text-center mt-4 loadMore">
                <Spinner color="primary" />
              </div>
            ) : (
              <>
                <ul className="myClubList" style={{ display: "contents" }}>
                  {clubUserDetails?.length > 0 ? (
                    clubUserDetails?.map((clubInfo) => {
                      return (
                        <li
                          key={clubInfo.club.id}
                          onClick={() => handleClubMember(clubInfo.club.id)}
                        >
                          <div className="left">
                            <a href="#">
                              <img src={race_icon1} alt="" />
                              {clubInfo.club.club_name}
                            </a>
                          </div>
                          <div className="rgt">
                            <div className="district">
                              District:{clubInfo.club.club_district}{" "}
                            </div>
                            {clubInfo.club.founder && (
                              <div className="name">
                                <span>
                                  {clubInfo.club.founder.user_firstname +
                                    " " +
                                    clubInfo.club.founder.user_lastname}
                                </span>{" "}
                                ({clubInfo.club.founder.user_areacodemobile}){" "}
                                {clubInfo.club.founder.user_mobile}
                              </div>
                            )}
                            <div className="btn">
                              {clubInfo.club.club_status}
                            </div>
                            <div className="viewDet">
                              <a href="#">
                                View Detail{" "}
                                <span className="material-icons">
                                  chevron_right
                                </span>
                              </a>
                            </div>
                          </div>
                        </li>
                      );
                    })
                  ) : (
                    <li key="error">
                      <div className="left">Not found any club</div>
                      <div className="rgt"></div>
                    </li>
                  )}
                </ul>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="myRace">
        <div className="userName member">
          <span
            className={isActive ? "showRace active" : "showRace"}
            onClick={toggleClass}
          >
            <i className="hide">Hide</i> <i className="show">Show</i> My Members
          </span>
        </div>
        <div
          id="myRaces"
          className={isActive ? "raceListBox active" : "raceListBox"}
        >
          <div className="myRaceHd">My Members  </div>
          <div className="flexBox nowrap itemCenter districtSelectBox">
            <select onChange={handleMemberFilter} name="user" value={user}>
              <option key="1" value="active">
                Active
              </option>
              <option key="2" value="inactive">
                Inactive
              </option>
            </select>
          </div>
          {user === "active" && clubMemberDetails && clubMemberDetails.length > 0 ? (
  <div>
    {clubMemberDetails
      .sort((a, b) => {
        if (!a.user || !a.user.user_lastname) {
          return -1;
        }
        if (!b.user || !b.user.user_lastname) {
          return 1;
        }
        return a.user.user_lastname.localeCompare(b.user.user_lastname);
      })
      .map((member) => (
        <React.Fragment key={member.user ? member.user.id : null}>
          {member.user && member.user.user_status === "active" && member.nambanumber ? (
            <ul className="memberList" style={{ display: "contents" }}>
              <li>
                <a href="#" className="flexBox nowrap itemCenter spacebetween">
                  <span className="name">
                    {member.user.user_firstname + " " + member.user.user_lastname}
                  </span>
                  <span className="count">
                    {member.nambanumber.num_number}
                  </span>
                </a>
              </li>
            </ul>
          ) : (
            ""
          )}
        </React.Fragment>
      ))}
  </div>
) : (
  <>
    {clubMemberDetails && clubMemberDetails.length > 0 ?
      clubMemberDetails
        .sort((a, b) => {
          if (!a.user || !a.user.user_lastname) {
            return -1;
          }
          if (!b.user || !b.user.user_lastname) {
            return 1;
          }
          return a.user.user_lastname.localeCompare(b.user.user_lastname);
        })
        .map((member) => (
          <React.Fragment key={member.user ? member.user.id : null}>
            {member.user && member.user.user_status === "inactive" && member.nambanumber ? (
              <ul className="memberList" style={{ display: "contents" }}>
                <li key={member.user.id}>
                  <a href="#" className="flexBox nowrap itemCenter spacebetween">
                    <span className="name">
                      {member.user.user_firstname + " " + member.user.user_lastname}
                    </span>
                    <span className="count">
                      {member.nambanumber.num_number}
                    </span>
                  </a>
                </li>
              </ul>
            ) : (
              ""
            )}
          </React.Fragment>
        ))
      : "No club members available"
    }
  </>
)}

        </div>
      </div>
    </>
  );
};
export default Dashboard;
