import React, { useState, useEffect } from "react";
import "./HomeContent.scss";
import Header from "../../../Components/adminDashboard/Header/Header";
import Switch from "react-switch";
import combinedServices from "../../../shared/services/user-service";
import getLocalData from "../../../shared/localStorage/getData";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { NavLink, useParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import RichTextEditor from "react-rte";
import userConstants from "../../../shared/constants/user-constants";
import {
  RichTextEditorComponent,
  Toolbar,
  Link,
  Table,
  QuickToolbar,
  Image,
  HtmlEditor,
  Inject,
} from "@syncfusion/ej2-react-richtexteditor";
const AdminDashboard = () => {
  let navigate = useNavigate();
  const params = useParams();

  const [allsmsData, setAllSmsData] = useState([]);
  const[buttons,setButtons]=useState("Create")
  const[allbannerImages,setAllbannerImages]=useState([])
  const [isLoder, setIsLoder] = useState(true);
  const [isHideSubmitButton, setIsHideSubmitButton] = useState(false);
  const [contentDetails, setContentDetails] = useState(
    RichTextEditor.createEmptyValue()
  );
  const [uploadImageUrl, setUploadImageUrl] = useState();
  const [imageError, setImageError] = useState("");
  const [htmlError, setHtmlError] = useState("");
  const[orderError,setOrderError]=useState("")
  const[orderContent,setOrderContent]=useState("")
  const [baseURL, setBaseUrl] = useState(userConstants.baseURL);
  const [url , setUrl] = useState('')
  const [editorData,setEditorData] = useState('')
  const [imageUrl,setImageUrl] = useState('')

  useEffect(async () => {
    if (getLocalData("admin_info", "isLogin")) {
      let smsResponse = await combinedServices.homeContentDataAll();
      if (smsResponse.status == 200) {
        setIsLoder(false);
        setAllSmsData(smsResponse.data);
      }
      let imageResponse = await combinedServices.bannerImageDataAll();
      if (imageResponse.status == 200) {
        setIsLoder(false);
        setAllbannerImages(imageResponse.data);
      }
      
     
    }
  }, []);
  const getAllHomeContent = async () => {
    if (getLocalData("admin_info", "isLogin")) {
      let smsResponse = await combinedServices.homeContentDataAll();
      if (smsResponse.status == 200) {
        setIsLoder(false);
        setAllSmsData(smsResponse.data);
      }
    }
  };
  const getAllImageBanner = async () => {
    if (getLocalData("admin_info", "isLogin")) {
      let imageResponse = await combinedServices.bannerImageDataAll();
      if (imageResponse.status == 200) {
        setIsLoder(false);
        setAllbannerImages(imageResponse.data);
      }
    }
  };

  const handleChangeContent = (value) => {
    setContentDetails(value);
  };
  const handleOrder=(value)=>{
    console.log("value,",value.target.value)
    setOrderContent(Number(value.target.value))
  }

  const handleUploadImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      const objectUrl = URL.createObjectURL(img);
      const formdata = new FormData();
      formdata.append("image", img);
     
      setUploadImageUrl(formdata);
    }
  };

 const handleUploadUrl = (e)=>{
     
      setUrl(e.target.value)
      


 }

  const handleFormSubmit = async (e) => {
    

    e.preventDefault();
    // if (!uploadImageUrl) {
    //   setImageError("Please select image");
    // }
    if (editorData.toString("html") == "<p><br></p>") {
      setHtmlError("Please enter some content");
    }
    if(!orderContent){
      setOrderError("Please enter order preference")
  }

    if (editorData &&  orderContent) {
      setImageError("");
      setHtmlError("");
      setOrderError("")
      var image = "";
      console.log('imageur',imageUrl);
      // let response = await combinedServices.uploadImageFromAdmin(
      //   imageUrl
      // );
      // if (response.status == 200) {
      //   image = response.data.data.file_name;
      // }

      var data = {
        image: '',
        html: editorData.toString("html"),
        url: url,
        ordercontent:Number(orderContent)
      };

      let responseHomeContent = await combinedServices.createHomeContent(data);

      if (responseHomeContent.status == 200) {
        swal("Yes", "Home content Create Successfully", "success").then(
          (value) => {
            document.getElementById("myForm").reset();
            setEditorData('');
            setOrderContent('')
            setUrl('')
            getAllHomeContent();
          }
        );
      } else {
        swal("!Opps", responseHomeContent.msg, "error");
      }
    }
  };
  const handleImageFormSubmit= async (e)=>{
    e.preventDefault();
    if (!uploadImageUrl) {
      setImageError("Please select image");
    }
   
    if (uploadImageUrl && editorData) {
      setImageError("");
      setHtmlError("");

      var image = "";
      let response = await combinedServices.uploadImageFromAdmin(
        uploadImageUrl
      );
      if (response.status == 200) {
        image = response.data.data.file_name;
        setButtons("Create")
      }

      var data = {
        image: image,
        url: url
      };

      let responseHomeContent = await combinedServices.createBannerImage(data);
      setUrl("");

      if (responseHomeContent.status == 200) {
        swal("Yes", "Banner Image Created Successfully", "success").then(
          (value) => {
            document.getElementById("myForm").reset();
            getAllImageBanner();
          }
        );
      } else {
        swal("!Opps", responseHomeContent.msg, "error");
      }
    }

  }

  const handleDeleteHomeContent = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover home content!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        let response = await combinedServices.handleDeleteHomeContent(id);
        if (response.status == 200) {
          swal("Poof! Your home content  has been deleted!", {
            icon: "success",
          });

          getAllHomeContent();
        } else {
          swal("!Opps", response.msg, "error");
        }
      } else {
        swal("Your home content is safe!");
      }
    });
  };

  const handleUpdateForm = (id) => {
    navigate("../update-home-content/" + id, { replace: true });
  };
  const handleEditForm=(id)=>{
    setButtons("Edit")
  }
  const regex1 = /<img.*?src="(.*?)"/

    const removeImgTags = (htmlString) => {
      const regex = /<img[^>]*>/g; // Regex pattern to match img tags
      return htmlString.replace(regex, ''); // Replace img tags with an empty string
    };




    const toolbarSettings = {
      items: [
        "Bold",
        "Italic",
        "Underline",
        "StrikeThrough",
        "FontName",
        "FontSize",
        "FontColor",
        "BackgroundColor",
        "LowerCase",
        "UpperCase",
        "|",
        "Formats",
        "Alignments",
        "OrderedList",
        "UnorderedList",
        "Outdent",
        "Indent",
        "|",
        "CreateLink",
        "Image",
        "|",
        "ClearFormat",
        "Print",
        "SourceCode",
        "FullScreen",
        "|",
        "Undo",
        "Redo",
        "createTable",
      ],
    };
    const quickToolbarSettings = {
      image: [
        "Replace",
        "Align",
        "Caption",
        "Remove",
        "InsertLink",
        "OpenImageLink",
        "-",
        "EditImageLink",
        "RemoveImageLink",
        "Display",
        "AltText",
        "Dimension",
      ],
      link: ["Open", "Edit", "UnLink"],
    };
  
    // const saveContent = (e) => {
    //   setEditorData(e.value);
    // };
    const saveContent = (e) => {
    
      // Create a temporary DOM element to work with the HTML content
      const tempElement = document.createElement('div');
      tempElement.innerHTML = e.value;
    
      // Loop through all the images in the content
      const images = tempElement.querySelectorAll('img');
      images.forEach((img) => {
        // Check if the image has a blob URL
        if (img.src.startsWith('blob:')) {
          // Replace the blob URL with the actual image URL
          img.src = imageUrl; // Replace with your actual image URL
        }
      });
    
      // Convert the modified HTML content back to a string and update the editor data
      const modifiedContent = tempElement.innerHTML;
      setEditorData(modifiedContent);
    };
    
    const insertImageSettings = {
      saveUrl: "https://services.syncfusion.com/js/production/api/FileUploader/Save",
      removeUrl: "https://services.syncfusion.com/js/production/api/FileUploader/Remove",
      height: 165,
      width: 165,
    };
    const imageUploadHandler = async (args) => {
      try {
        const files = args.file.rawFile;
        const formData = new FormData();
        formData.append("image", files);
    
        const response = await combinedServices.uploadImageFromAdmin(formData);
        const imageUrl = (await baseURL) + "images/" + response.data.data.file_name;
    
        // Create a new image element
        const imageElement = document.createElement("img");
        imageElement.setAttribute("src", imageUrl);
        imageElement.setAttribute("alt", "Uploaded Image");
        imageElement.setAttribute("width", "165px"); // Adjust as needed
        imageElement.setAttribute("height", "165px"); // Adjust as needed
        setImageUrl(imageUrl)
        // Create a new paragraph element
        const paragraph = document.createElement("p");
        paragraph.appendChild(imageElement);
    
        // Append the new paragraph to the current editor content
        // const updatedContent = editorData + paragraph.outerHTML;
        // setEditorData(updatedContent);
        editorData.appendChild(paragraph)
      } catch (error) {
        console.error(error);
      }
    };




  return (
    <>
      <Header />

      <div className="dbAdminRight">
        <div className="dbMid dbInner">
          <div className="flexBox spacebetween topHdBox">
            <div className="left">
              <div className="dbMainHd">Home Content</div>
            </div>
          </div>
          <div className="flexBox spacebetween" >
            {/* <div className="stLeft whtBox" style={{width:'52%'}}> */}
            <div className="stLeft whtBox" >

              <div className="interfaceTable">
            
                   
                
                <table>
                     <thead>
                        <tr>
                            <th>Sr. No</th>
                            <th>Banner Image</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    {isLoder ? (
                    <div className="text-center mt-4 loadMore">
                      <Spinner color="primary" />
                    </div>
                  ) : (
                    <>
                      <tbody>
                        {allbannerImages.length > 0 ? (
                          allbannerImages.map((value, index) => {
                            return (
                              <tr key={value.id}>
                                <td>{index + 1}</td>
                                <td>
                                  <div className="contentImage uploadImg">
                                   <a href={value.url} target="_blank"> 
                                    <img className="icon" 
                                      src={baseURL + "images/" + value.image }
                                    />
                                     </a> 
                                  </div>
                                </td>

                               

                                <td>
                                  <div className="actionBtn flexBox nowrap">
                                    <button
                                      title="edit"
                                      type="button"
                                      onClick={() => handleEditForm(value.id)}
                                    >
                                      <span className="material-icons-outlined">
                                        edit
                                      </span>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <div className="noEvent">
                            Not Found Any Home Content
                          </div>
                        )}
                      </tbody>
                    </>
                  )}
                  <thead>
                    <tr>
                      <th>Sr. No</th>
                      <th>Image</th>
                      <th>Order</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {isLoder ? (
                    <div className="text-center mt-4 loadMore">
                      <Spinner color="primary" />
                    </div>
                  ) : (
                    <>
                      <tbody>
                        {allsmsData.length > 0 ? (
                          allsmsData.sort((a,b)=>a.ordercontent-b.ordercontent).map((value, index) => {
                            return (
                              <tr key={value.id}>
                                <td>{index + 1}</td>
                                <td>
                                  <div className="contentImage uploadImg">
                                   <a href={value.url} target="_blank">  
                                  <img
                                      src={value.html.match(regex1)?value.html.match(regex1)[1]:baseURL + "images/" + value.image}
                                    />
                                    </a> 
                                  </div>
                                </td>
                                <td>{value.ordercontent}</td>

                                <td>
                                  <div className="actionBtn flexBox nowrap">
                                    <button
                                      title="edit"
                                      type="button"
                                      onClick={() => handleUpdateForm(value.id)}
                                    >
                                      <span className="material-icons-outlined">
                                        edit
                                      </span>
                                    </button>

                                    <button
                                      title="Delete"
                                      type="button"
                                      onClick={() =>
                                        handleDeleteHomeContent(value.id)
                                      }
                                    >
                                      <span className="material-icons-outlined">
                                        delete
                                      </span>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <div className="noEvent">
                            Not Found Any Home Content
                          </div>
                        )}
                      </tbody>
                    </>
                  )}
                </table>
              </div>
            </div>

            {/* <div className="stRight whtBox" style={{width:'45%'}}> */}
            <div className="stRight whtBox">

                <div > 
            <div className="dbSubHd" >Change Banner Image</div>
              <form id="myForm">
                <ul className="flexBox editForm full">
                  <li>
                    <span className="fieldHd"> Images</span>
                    <input
                      type="file"
                      className="formInput"
                      onChange={handleUploadImage}
                      
                    />
                    {imageError ? imageError : ""}{<span style={{ fontStyle: 'italic', color: 'red', fontSize:"11px" }}>Image should be jpeg or png (max size: 1600 x 900 pixels)</span>}
                  </li>

                  <li>
                                     <span className="fieldHd"> URL</span>
                                     <input
                                     type="url"
                                     className="formInput"
                                    onChange={handleUploadUrl}
                                     />
                    
                               </li>
                </ul>
                {isHideSubmitButton ? (
                  " "
                ) : (
                  <div className="tar">
                    <button
                      type="submit"
                      className="formBtn full"
                      onClick={handleImageFormSubmit}
                    >
                      {buttons}
                    </button>
                  </div>
                )}
              </form>
              </div>
              <div className="space">
              <div className="dbSubHd">Create Home Content</div>
              <form id="myForm">
                <ul className="flexBox editForm full">
                  <li>
                    <span className="fieldHd"> Images</span>
                    {/* <input
                      type="file"
                      className="formInput"
                      onChange={handleUploadImage}
                    /> */}
                    {imageError ? imageError : ""}{<span style={{ fontStyle: 'italic', color: 'red', fontSize:"11px" }}>Image should be jpeg or png (max size: 600 x 270 pixels)</span>}
                  </li>

                  <li>
                    <span className="fieldHd"> URL</span>
                    <input
                      type="url"
                      className="formInput"
                      onChange={handleUploadUrl}
                      value={url}
                    />
                    
                  </li>
                  <li>
                    <span className="fieldHd">Order</span>
                    <input type="text"
                    className="formInput"
                    onChange={handleOrder}
                    value={orderContent}
                    />
                    
                     {orderError ? orderError : ""}
                  </li>

                  <li>
                    <span className="fieldHd">Content </span>


                    {/* <RichTextEditor
                      className="formInput"
                      value={contentDetails}
                      onChange={handleChangeContent}
                    /> */}









                    <RichTextEditorComponent
                                          // ref={(richtexteditor) => { richtexteditor = richtexteditor }}
                                          height={300}
                                          width={270}
                                          className="formInput"
                                          toolbarSettings={toolbarSettings}
                                          quickToolbarSettings={quickToolbarSettings}
                                          value={editorData}
                                          change={saveContent}
                                          imageUploadSuccess={imageUploadHandler}
                                          insertImageSettings={insertImageSettings}
                                        >
                                          <Inject
                                            services={[
                                              Toolbar,
                                              Image,
                                              Link,
                                              HtmlEditor,
                                              QuickToolbar,
                                              Table,
                                            ]}
                                          />
                                        </RichTextEditorComponent>

                    {htmlError ? htmlError : ""}
                  </li>
                </ul>
                {isHideSubmitButton ? (
                  " "
                ) : (
                  <div className="tar">
                    <button
                      type="submit"
                      className="formBtn full"
                      onClick={handleFormSubmit}
                    >
                      Create
                    </button>
                  </div>
                )}
              </form>

            
            </div>
    
            </div>
              
            
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminDashboard;
