import React from 'react'
import styled from 'styled-components'
import { Droppable, Draggable } from 'react-beautiful-dnd'

import Task from './task'
import moment from "moment-timezone";
const Container = styled.div`
  margin: 8px;  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 2px;
  width: 240px;

  display: flex;
  flex-direction: column;
  user-select: none;
`
const Title = styled.h4`
  padding: 8px;
  font-size:16px;
  line-height:24px;
  text-align:center;
`
const TaskList = styled.div`
  padding: 8px;
  transition: all 0.2s ease;
  background: ${(props) => (props.isDraggingOver ? 'lightgrey' : 'inherit')};
  flex-grow: 1;
  min-height: 100px;
  max-height:450px;
  overflow:auto;
`

const InnerTasks = React.memo((props) => {
  console.log("props",props)
  return props?.tasks?.map((task, index) => (
    <Task
      key={task.id}
      task={task}
      // heats={props.heats}
      index={index}
      column={props?.column}
      eventdata={props?.eventdata}
      data={props?.data}
    />
  ))
})
const handleTotalHeat = (x,y,z) => {
  var timezone=z.time_zone==''?'Canada/Eastern':z.time_zone;

  var totalMaxHeat = 0;
  var totalMinHeat = 0;
  const timeaccotolocation3 = (x) => {
    const utcMoment = moment.utc(x);
    const localMoment = utcMoment.tz(timezone);
    
    // const timeZoneAbbreviation = localMoment.format('z');
    const timeZoneOffset = localMoment.format("Z");
    return localMoment.format("ddd MMM DD YYYY");
  };
  x.filter((task) => {
    if (task) {
      if (moment(y).format("L") == moment(timeaccotolocation3(task?.run_date)).format("L")) {
        totalMinHeat =
          totalMinHeat +
          Math.ceil(
            task.participants_count /
              z.max_boats_per_heat
          );

        totalMaxHeat =
          totalMaxHeat +
          parseInt(
            task.participants_count /
              z.min_boats_per_class
          );
      }
    }
  });
  return totalMinHeat * z.rounds_per_class;
};
export default function Column(props) {

  return (
    <Draggable className="schedulerbox" draggableId={props?.column.id} index={props?.index}>
      {/* {console.log("props==========================>",props)} */}
      {(provided) => (
        <Container {...provided.draggableProps} ref={provided.innerRef}>
          {props.column.runDate===null?<Title {...provided.dragHandleProps}> 
          UnScheduled Classes
           </Title>:<Title {...provided.dragHandleProps}>{moment(props?.column.runDate).format("ddd , DD MMMM YYYY")} 
           <br /> Heats : ({handleTotalHeat(props?.data,props?.column.runDate,props?.eventdata)}){" "}
           </Title>}
          <Droppable droppableId={props?.column.id} type="task">
            {(provided, snapshot) => (
              <TaskList
                ref={provided.innerRef}
                {...provided.droppableProps}
                isDraggingOver={snapshot.isDraggingOver}
              >
               <InnerTasks
                  tasks={props?.tasks}
                  column={props?.column}
                  eventdata={props?.eventdata}
                  data={props?.data}
                />
                {provided.placeholder}
              </TaskList>
            )}
          </Droppable>
        </Container>
      )}
    </Draggable>
  )
}
