import Header from "../../../Components/clubDashboard/Header/Header";
const ApplyNewMember = () =>{

    return(
        <>
        <Header/>

        <div className="dbRight">
        <div className="dbMid dbInner">
            <div className="flexBox spacebetween itemCenter">
                <div className="searchBox flexBox spacebetween itemCenter nowrap">
                    <input type="text" className="searchInput" placeholder="Search"/>
                    <button className="searchBtn"><span className="material-icons">search</span></button>
                </div>
                <ul className="flexBox topLink">
                    <li><a href="#"><span className="material-icons">mail_outline</span></a></li>
                    <li><a href="#"><span className="material-icons-outlined">notifications</span></a></li>
                </ul>
            </div>
            <div className="flexBox spacebetween topHdBox">
                <div className="left">
                    <div className="dbMainHd">NAMBA MEMBERSHIP 2022</div>
                    <div className="text">Annual Fees for an Adult Member is $50, for an Adult Member (same address) is
                        $40 and for a Junior Member it is $25.</div>
                </div>
                <div className="right">
                    <div className="inner">Annual Fees for Primary Membership is <span>$50</span></div>
                    <div className="inner">Annual Fees for an Additional Member (same address) is <span>$40</span></div>
                    <div className="inner">Annual Fees for a Junior Member it is <span>$25</span></div>
                </div>
            </div>
            <div className="dbWhtBox">
                <div className="dbSubHd">Choose Membership Type for the new applicant:</div>
                <div className="memberOptionBox mb30">
                    <label className="labelText"><input value="Adult Member" type="radio" name="membershipType" className="radioInpt" /> Adult
                        Member</label>
                    <label className="labelText"><input value="Adult Member Same Address" type="radio" name="membershipType" className="radioInpt"/> Adult Member (same
                        address)</label>
                    <label className="labelText"><input value="Junior Member" type="radio" name="membershipType" className="radioInpt"/> Junior Member</label>
                </div>
                <div className="dbSubHd">Personal Information</div>
                <ul className="flexBox membershipForm">
                    <li>
                        <span className="fieldHd">First Name</span>
                        <input type="text" className="formInput" placeholder="Enter First Name"/>
                    </li>
                    <li>
                        <span className="fieldHd">Last Name</span>
                        <input type="text" className="formInput" placeholder="Enter Last Name"/>
                    </li>
                    <li>
                        <span className="fieldHd">Street Address Line 1</span>
                        <input type="text" className="formInput" placeholder="Enter Street Address Line 1"/>
                    </li>
                    <li>
                        <span className="fieldHd">Mobile Phone</span>
                        <input type="text" className="formInput" placeholder="Enter Mobile Phone"/>
                    </li>
                    <li>
                        <span className="fieldHd">Home Phone Number </span>
                        <input type="text" className="formInput" placeholder="Enter Home Phone Number"/>
                    </li>
                    <li>
                        <span className="fieldHd">Office Phone Number</span>
                        <input type="text" className="formInput" placeholder="Enter Office Phone Number"/>
                    </li>
                    <li>
                        <span className="fieldHd">Zip</span>
                        <input type="text" className="formInput" placeholder="Enter Zip"/>
                    </li>
                    <li>
                        <span className="fieldHd">City</span>
                        <input type="text" className="formInput" placeholder="Enter City"/>
                    </li>
                    <li>
                        <span className="fieldHd">State</span>
                        <select className="formSelect">
                            <option>Select</option>
                        </select>
                    </li>
                    <li>
                        <span className="fieldHd">Country </span>
                        <select className="formSelect">
                            <option>Select</option>
                        </select>
                    </li>
                    <li>
                        <span className="fieldHd">Specific Areas of Interest - Optional </span>
                        <select className="formSelect">
                            <option>Select</option>
                        </select>
                    </li>
                    <li>
                        <span className="fieldHd">Club Affiliations - If Applicable</span>
                        <select className="formSelect">
                            <option>Select</option>
                        </select>
                    </li>
                    <li>
                        <span className="fieldHd">Applicant's Date of Birth (dd-mm-yy)</span>
                        <div className="pr">
                            <input type="text" className="formInput datepicker" placeholder="Enter Date"/>
                            <span className="material-icons dateIcon">today</span>
                        </div>
                    </li>
                    <li>
                        <div className="emailNot"><label><input type="checkbox" className="checkInpt"/> Send email notification</label></div>
                    </li>
                </ul>
                <div><button type="button" className="formBtn">Sign Up</button></div>
            </div>
        </div>
    </div>
    </>
    )
}
export default ApplyNewMember;